import React, { createRef, useState } from 'react'
import { Button, Image, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, Modal, Pagination, Radio, Select, Table, TablePaginationConfig, Typography, Upload, UploadFile, UploadProps, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { cancelUserBill, confirmChargeWithdraw, createChargeWithdraw, fileUpload, getCompanyAccoutDetail, getCompanyAccoutList, getCompanyList, getStaffList, getUserBillList, reviewChargeWithdraw } from '../../../utils/api'
import { uniAddRowSubmit, uniAxList, uniAxSearchList, uniCancel, uniCancelConfirm, uniChgRowSubmit, uniCurrentTime, uniDatePikcerChange, uniDeepArray, uniExportExcel, uniFilterItem, uniHandleUpload, uniModifyAxList, uniQuitModal, uniSelectRows, uniStaticLast, uniTableChange, uniToProof } from '../../../utils/uniFucs'
import { NameValue, UserBillItem } from '../../../utils/beans'
import { cancelStatus, cashStatus, chargeOrWithdraw, chargeStatus, collectionStatus, csDateRages, materialBuyReviewList, materialReviewStatus, payStatus, payTypeList, proofStatusList, reviewStatusList, withdrawStatus } from '../../../utils/constant'
import FormModal from '../../../components/FormModal'
import TextArea from 'antd/lib/input/TextArea';
import SummeryRow from '../../../components/SummeryRow';
import UniOperationColumn from '../../../components/UniOperationColumn';
import { useLocation, useNavigate } from 'react-router-dom';
import { localGetUserId } from '../../../utils/localstorage';
import tablecss from '../../../css/custable.module.less'

export default function Recharge() {
  const pathname = "/indexpage/recharge"
  /* hooks */
  let location = useLocation()
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [staticModal, setStaticModal] = useState(false)
  const [confirmModal,setConfirmModal] = useState(false)
  const [reviewModal, setReviewModal] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [rowList, setRowList] = useState([])
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({showRealName:true, showCardNo:true, showBeginBalance:true, showBeginTime:true})
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [currentRow, setCurrentRow] = useState({} as UserBillItem)
  const [searchParams, setSearchParams] = useState({page:1,filter_INL_business_id:'69,70',orderBy:"create_date", orderDir: "desc",filter_EQB_is_cancel:false} as any)
  const [tempSearchParams, setTempSearchParams] = useState({page:1,filter_INL_business_id:'69,70',orderBy:"create_date", orderDir: "desc",filter_EQB_is_cancel:false} as any)
  const [staffList, setStaffList] = useState([])
  const [reinburseTypeList, setReinburseTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
  const [staticData, setStaticData] = useState([] as any)
  const [staffAccounts, setStaffAccounts] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [cancelModal, setCancelModal] = useState(false)
  const [cancelRemark, setCancelRemark] = useState('')
  const [loadFlag, setLoadFlag] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [liscenseList, setLiscenseList] = useState<UploadFile[]>([])
  const [reviewProofNoList,setReviewProofNoList] = useState({value:[]})
  const [exportLoading, setExportLoading] = useState(false)
  const [staticLoading,setStaticLoading] = useState(false)
  /* funcs */
  //路由刷新请求
  const routerFlash = () => {
    uniAxList(uniRowData)
    uniAxList(uniCompanyData)
    uniAxList(uniStaffData)
    uniAxList(uniSaData)
  }
  //点击批量审核按钮
  const onReview = () => {
    let userId = localGetUserId()
    let reviewProofNos:any = []
    selectedRows.forEach((item:any) => {
      if (item.bossReviewStatus !== 0 && item.bossReviewStatus !== 2 && userId ===item.bossReviewUserId) reviewProofNos.push(item.id)
    })
    if (reviewProofNos.length<=0) message.info('没有可以审核的记录')
    else {
      reviewProofNoList.value = reviewProofNos
      setReviewModal(()=>true)
    }
  }
  //批量审核modal确定
  const onBatchReview = (formValus:any) => {
    setModalLoading(()=>true)
    let axfuncs:Function[] = []
    reviewProofNoList.value.forEach((item:string)=>axfuncs.push(reviewChargeWithdraw({userBillRegisterId:item, ...formValus})))
    Promise.all(axfuncs).then((res)=>{
      setModalLoading(()=>false)
      setReviewModal(()=>false)
      uniAxList(uniRowData)
    })
  }
  //数据统计
  const onStatic = () => {
    setStaticModal(() => true)
    const charge = {name: '充值', doneSum: 0, notSum: 0, total: 0}
    const withDraw = {name: '提现', doneSum: 0, notSum: 0, total: 0}
    rowList.forEach((item:UserBillItem) => {
      if (item.businessId === 69) {
        charge.total += item.collectionPrice
        item.isCollection !==0 ? charge.doneSum += item.collectionPrice : charge.notSum += item.collectionPrice
      } else {
        withDraw.total += item.payPrice
        item.isPay !==0 ? withDraw.doneSum += item.payPrice : withDraw.notSum += item.payPrice
      }
    })
    setStaticData(() => [charge, withDraw])
  }

  /* elements */
  //列项
  const columns:any = [
    {
      title: '工号',
      dataIndex: 'userJobNum',
      key: 'userJobNum',
      fixed: 'left'
    },
    {
      title: '姓名',
      dataIndex: 'userName',
      key: 'userName',
      fixed: 'left'
    },
    {
      title: '作废状态',
      dataIndex: 'isCancel',
      key: 'isCancel',
      render: (value:any) => value ? '作废' : '正常'
    },
    {
      title: '所属公司',
      dataIndex: 'companyName',
      key: 'companyName'
    },
    {
      title: '操作类型',
      dataIndex: 'businessName',
      key: 'businessName'
    },
    {
      title: '金额',
      dataIndex: 'payPrice',
      key: 'payPrice',
      render: (payPrice: number, record:any) => record.incomeType === 1 || record.incomeType === 3 ? (record.collectionPrice).toFixed(2) : payPrice.toFixed(2)
    },
    {
      title: '审核状态',
      dataIndex: 'bossReviewStatus',
      key: 'bossReviewStatus',
      render: (value:any, record:any) => {
        let statusItem:any = uniFilterItem(materialReviewStatus, 'value', value)
        let userItem:any = uniFilterItem(staffList,'id',record.bossReviewUserId)
        return statusItem
          ? <div>
            {statusItem.name}
            {(value===1 || value===2 || value===4) ? <p>审核人:{userItem?userItem.name:''}</p>:null}
          </div>
          : ''
      }
    },
    {
      title: '凭证状态',
      dataIndex: 'proofStatus',
      key: 'proofStatus',
      render: (value:any) => {
        let tempItem:any = uniFilterItem(proofStatusList, 'value', value)
        return tempItem ? tempItem.name : value
      }
    },
    {
      title: '凭证号',
      dataIndex: 'proofNo',
      key: 'proofNo',
      render: (value:string,record:any) => {
        let prooflist = value ? value.split(',') : []
        return prooflist.map((item:string) => <span><Typography.Link onClick={()=>uniToProof(record,item, navigate)}>{item}</Typography.Link> </span>)
      }
    },
    {
      title: '收/付账户',
      dataIndex: 'payAccount',
      key: 'payAccount',
      render: (payAccount: string, record:any) => record.incomeType === 1 || record.incomeType === 3 ? record.collectionAccount : payAccount
    },
    {
      title: '收/付款状态',
      dataIndex: 'isPay',
      key: 'isPay',
      render: (isPay: number, record:any, index:number) => record.incomeType === 1 || record.incomeType === 3 
        ? record.isCollection !== 0 ? '已收款' : '未收款'
        : isPay !== 0 ? '已付款' : '未付款'
    },
    {
      title: '充值/提现凭证',
      dataIndex: 'rechargeFileUrl',
      key: 'rechargeFileUrl',
      render: (value:string) => value ? <Image width={40} src={value}/> : '无'
    },
    {
      title: '申请日期',
      dataIndex: 'billTime',
      key: 'billTime',
      render: (value:string) => value ? value.split(' ')[0] : ''
    },
    {
      title: '确认收/付款日期',
      dataIndex: 'payTime',
      key: 'payTime',
      render: (payTime: string, record:any) => record.incomeType === 1 || record.incomeType === 3 
        ? record.collectionTime ? record.collectionTime.split(' ')[0] : ''
        : payTime ? payTime.split(' ')[0] : ''
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark'
    },
    {
      title: '操作人',
      dataIndex: 'createBy',
      key: 'createBy'
    },
    {
      title: '创建时间',
      dataIndex: 'createDate',
      key: 'createDate'
    }
  ]
  //图片上传的prop
  const imageProps: UploadProps = {
    accept: ".jpg,.png,.jpeg,.PNG,.gif,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    onRemove: file => {
      const index = liscenseList.indexOf(file);
      const newFileList = liscenseList.slice();
      newFileList.splice(index, 1);
      setLiscenseList(newFileList);
    },
    beforeUpload: file => {
      setLiscenseList([...liscenseList, file]);
      return false;
    },
    fileList: liscenseList,
  };
  //添加和修改中的model项
  const modalItems = [
    {
      label: '操作类型',
      name: 'payType',
      rules: [{ required: true, message: '操作类型不能为空'}],
      element: <Radio.Group>
                {payTypeList.map((item:NameValue) => <Radio key={item.value} value={item.value}>{item.name}</Radio>)}
              </Radio.Group>
    },
    {
      label: '所属公司',
      name: 'companyId',
      rules: [{ required: true, message: '所属公司不能为空' }],
      element: <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="所属公司"
                onChange={(value) => {
                  const tempItem:any = companyList.find((item:any) => value === item.id)
                  setCustomAddKeys(()=>({...customAddKeys, companyName: tempItem?tempItem.name : null}))
                }}
              >
                {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
              </Select>
    },
    {
      label: '人员名称',
      name: 'userId',
      rules: [{ required: true, message: '人员名称不能为空' }],
      element: <Select
                showSearch
                optionFilterProp='children'
                allowClear
                style={{ width: '100%' }}
                placeholder="请选择人员名称"
                onChange={(value) => {
                  const tempItem:any = staffList.find((item:any) => value === item.id)
                  setCustomAddKeys(()=>({...customAddKeys, userJobNum: tempItem?tempItem.jobNum : null, userName: tempItem?tempItem.name : null}))
                }}
              >
                {staffList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
              </Select>
    },
    {
      label: '收/付款账户',
      name: 'accountId',
      rules: [{ required: true, message: '收/付款账户不能为空' }],
      element: <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="请选择收/付款账户"
                onChange={(value) => {
                  const tempItem:any = staffAccounts.find((item:any) => value === item.id)
                  setCustomAddKeys(()=>({...customAddKeys, account: tempItem?tempItem.name : null}))
                }}
              >
                {staffAccounts.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
              </Select>
    },
    {
      label: '运单号',
      name: 'trackNo',
      rules: [{ required: false }],
      element: <Input placeholder="请输入运单号"/>
    },
    {
      label: '金额',
      name: 'price',
      rules: [{ required: true, message: '金额不能为空' }],
      element: <InputNumber precision={2} style={{width:'100%'}} placeholder="请输入金额" onChange={(value:any)=>setCustomAddKeys(()=>({...customAddKeys,price:(value*100).toFixed()}))}/>
    },
    {
      label: '充值/提现时间',
      name: 'time',
      rules: [{ required: true, message: '充值时间不能为空' }],
      element:<ConfigProvider locale={locale}>
                <DatePicker disabledDate={(current)=>{return current && current > moment().endOf('day')}} style={{width:'100%'}} defaultValue={(chgModal && currentRow.time) ? moment((currentRow.time as string).split(' ')[0], 'YYYY-MM-DD') : null as any} onChange={(date, dateString)=>uniDatePikcerChange({formRef:formModalRef,dateString:dateString,keyWord:'time'})}/>
              </ConfigProvider>      
    },
    {
      label: '收/付款时间',
      name: 'actionTime',
      rules: [{ required: true, message: '收款时间不能为空' }],
      element:<ConfigProvider locale={locale}>
                <DatePicker disabledDate={(current)=>{return current && current > moment().endOf('day')}} style={{width:'100%'}} defaultValue={(chgModal && currentRow.actionTime) ? moment((currentRow.actionTime as string).split(' ')[0], 'YYYY-MM-DD') : null as any} onChange={(date, dateString)=>uniDatePikcerChange({formRef:formModalRef,dateString:dateString,keyWord:'actionTime'})}/>
              </ConfigProvider>
    },
    {
      label: '审核人',
      name: 'bossReviewUserId',
      rules: [{ required: true, message: '审核人不能为空' }],
      element: <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="请选择审核人"
              > 
                <Select.Option key={-1} value={-1}>无需审核</Select.Option>
                {staffList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
              </Select>
    },
    {
      label: '上传凭证',
      name: 'upload',
      rules: [{ required: false }],
      element: <Upload {...imageProps}><Button icon={<UploadOutlined />}>选择图片</Button></Upload>
    },
    {
      label: '备注',
      name: 'remark',
      rules: [{ required: false }],
      element: <TextArea style={{ height: 80, resize:'none' }} />
    },
  ]
  //审批modal中的item
  const reviewItems:any = [
    {
      label: '审核凭证数',
      name: 'count',
      rules: [{ required: false }],
      element: <Input readOnly/>
    },
    {
      label: '审批结果',
      name: 'reviewStatus',
      rules: [{ required: true, message: '审批结果不能为空' }],
      element:<Select
                allowClear
                style={{ width: '100%' }}
                placeholder="请选择审批结果"
              >
                {materialBuyReviewList.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
              </Select>     
    },
    {
      label: '备注',
      name: 'remark',
      rules: [{ required: false }],
      element: <TextArea style={{ height: 80, resize:'none' }} />
    }
  ]
  //统计列
  const staticColumns:any = [
    {
      title: '',
      dataIndex: 'rowName',
      key: 'rowName',
    },
    {
      title: '已收/付款条数',
      dataIndex: 'cashCount',
      key: 'cashCount',
      addValue: (record:any) => record.incomeType === 1 || record.incomeType === 3 
      ? record.isCollection === 1 ? 1 : 0
      : record.isPay === 1 ? 1 : 0
    },
    {
      title: '已收/付款',
      dataIndex: 'hasCash',
      key: 'hasCash',
      render: (value:any) => (value/100).toFixed(2),
      addValue: (record:any) => record.incomeType === 1 || record.incomeType === 3 
      ? record.isCollection === 1 ? record.collectionPrice : 0
      : record.isPay === 1 ? record.payPrice : 0
    },
    {
      title: '未收/付款条数',
      dataIndex: 'unCashCount',
      key: 'unCashCount',
      addValue: (record:any) => record.incomeType === 1 || record.incomeType === 3 
      ? record.isCollection === 0 ? 1 : 0
      : record.isPay === 0 ? 1 : 0
    },
    {
      title: '未收/付款',
      dataIndex: 'unCash',
      key: 'unCash',
      render: (value:any) => (value/100).toFixed(2),
      addValue: (record:any) => record.incomeType === 1 || record.incomeType === 3 
      ? record.isCollection === 0 ? record.collectionPrice : 0
      : record.isPay === 0 ? record.payPrice : 0
    },
    {
      title: '条数小计',
      dataIndex: 'countTotal',
      key: 'countTotal',
      addValue: (record:any) => 1
    },
    {
      title: '金额小计',
      dataIndex: 'total',
      key: 'total',
      render: (value:any) => (value/100).toFixed(2),
      addValue: (record:any) =>record.incomeType === 1 || record.incomeType === 3 ? record.collectionPrice : record.payPrice
    },
  ]
  //统计行
  const staticRows = [
    {rowName: '充值', checkKey: 'businessId', checkValue: 69,cashCount:0, hasCash:0, unCashCount:0,unCash:0,countTotal:0, total:0},
    {rowName: '提现', checkKey: 'businessId', checkValue: 70,cashCount:0, hasCash:0, unCashCount:0,unCash:0,countTotal:0, total:0},
    {rowName: '合计', checkKey: 'staticTotal',cashCount:0, hasCash:0, unCashCount:0,unCash:0,countTotal:0, total:0}
  ]

  //导出当前页，需要的数据修饰
  const exportModify:any = {
    splitTime: ['payTime','billTime'],
    copyKey: [
      {newKey: 'payPrice', checkKey: 'incomeType', checkInfo:[{value:1,copyKey:'collectionPrice'},{value:2,copyKey:'payPrice'},{value:3,copyKey:'collectionPrice'},{value:4,copyKey:'payPrice'}]},
      {newKey: 'payAccount', checkKey: 'incomeType', checkInfo:[{value:1,copyKey:'collectionAccount'},{value:2,copyKey:'payAccount'},{value:3,copyKey:'collectionAccount'},{value:4,copyKey:'payAccount'}]},
      {newKey: 'isPay', checkKey: 'incomeType', checkInfo:[{value:1,copyKey:'isCollection'},{value:2,copyKey:'isPay'},{value:3,copyKey:'isCollection'},{value:4,copyKey:'isPay'}]},
      {newKey: 'payTime', checkKey: 'incomeType', checkInfo:[{value:1,copyKey:'collectionTime'},{value:2,copyKey:'payTime'},{value:3,copyKey:'collectionTime'},{value:4,copyKey:'payTime'}]}
    ],
    checkStatus:[
      {newKey:'isCollection', checkKey: 'isCollection', sourceList:collectionStatus, sourceCheckKey:'value', sourceValueKey:'name'},
      {newKey:'isPay', checkKey: 'isPay', sourceList:payStatus, sourceCheckKey:'value', sourceValueKey:'name'},
      {newKey:'isCancel', checkKey: 'isCancel', sourceList:cancelStatus, sourceCheckKey:'value', sourceValueKey:'name'},
      {newKey:'bossReviewStatus', checkKey: 'bossReviewStatus', sourceList:materialReviewStatus, sourceCheckKey:'value', sourceValueKey:'name'},
      {newKey:'proofStatus', checkKey: 'proofStatus', sourceList:proofStatusList, sourceCheckKey:'value', sourceValueKey:'name'},
    ],
    notNullColumns: columns,
  }

  /* 接口参数 */
  const uniRowData:any = {
    params: {...searchParams, current:pagination.current, size:pagination.pageSize},
    axApi: getUserBillList,
    modifyFunc: uniModifyAxList,
    modifyData: {
      divideKey:[{newKey: 'collectionPrice', key:'collectionPrice', value: 100},{newKey: 'payPrice', key:'payPrice', value: 100}],
    },
    setList: setRowList,
    setTableLoading,
    pagination
  }
  const uniCompanyData:any = {
    axApi: getCompanyList,
    setList: setCompanyList,
  }
  const uniStaffData:any = {
    params: {size:999},
    axApi: getStaffList,
    setList: setStaffList
  }
  //个人账户
  const uniSaData:any = {
    axApi: getCompanyAccoutList,
    setList: setStaffAccounts
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    uniAxList(uniRowData)
    uniAxList(uniCompanyData)
    uniAxList(uniStaffData)
    uniAxList(uniSaData)
    setLoadFlag(()=>true)
  }, [])
  return (
    <>
      <Select
        showSearch
        optionFilterProp='children'
        allowClear
        style={{ width: 200 }}
        placeholder="选择人员"
        onChange={(userId) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_user_id:userId || null}))}
      > 
        {staffList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="作废状态"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQB_is_cancel:(value ===null || value===undefined ? false : value)}))
        }
      > 
        {cancelStatus.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="选择公司"
        onChange={(companyId) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_company_id:companyId || null}))}
      > 
        {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="操作类型"
        onChange={(businessId) => setTempSearchParams(() => ({...tempSearchParams, filter_EQL_business_id:businessId || null}))}
      > 
        {chargeOrWithdraw.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="选择账户"
        onChange={(accountId) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_collection_account_id_OR_pay_account_id:accountId || null}))}
      > 
        {staffAccounts.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="收付/款状态"
        onChange={(value) => {
          delete tempSearchParams.filter_EQL_is_collection_OR_is_pay
          delete tempSearchParams.filter_EQL_is_collection
          delete tempSearchParams.filter_EQL_is_pay
          let tempObj:any = null
          tempObj = value > 0 ? {filter_EQL_is_collection_OR_is_pay:value} : {filter_EQL_is_collection: 0, filter_EQL_is_pay:0}
          setTempSearchParams(() => ({...tempSearchParams, ...tempObj}))
        }}
      > 
        {cashStatus.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          placeholder={['申请开始日期', '申请结束日期']}
          ranges={csDateRages}
          onChange={(dates, dateStrings) => setTempSearchParams(()=>({...tempSearchParams, filter_BETWEENS_bill_time: dateStrings[0] ? dateStrings[0]+","+dateStrings[1]+' 23:59:59' : null}))}
        />
      </ConfigProvider>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          placeholder={['收/付款开始日期', '收/付款结束日期']}
          ranges={csDateRages}
          onChange={(dates, dateStrings) => setTempSearchParams(()=>({...tempSearchParams, filter_BETWEENS_collection_time_OR_pay_time: dateStrings[0] ? dateStrings[0]+","+dateStrings[1]+' 23:59:59' : null}))}
        />
      </ConfigProvider>&nbsp;
      <Input
        allowClear
        style={{ width: 200 }}
        placeholder="备注"
        onChange={(e) => setTempSearchParams(() => ({...tempSearchParams, filter_LIKES_remark:e.target.value || null}))}
      ></Input>&nbsp;
      <Button onClick={() => {pagination.current=1;uniAxSearchList({setSearchParams, uniRowData, params: {...tempSearchParams,size:pagination.pageSize}})}} type='primary'>查询</Button>
      <p></p>
      <p>
        <Button type="primary" onClick={() => setAddModal(() => true)}>新增</Button> &nbsp;
        <Button type="primary" disabled={selectedRows.length<=0} onClick={() => onReview()}>批量审核</Button> &nbsp;
        <Button type="primary" disabled={selectedRows.length !== 1} onClick={() => setConfirmModal(()=>true)}>充值/提现确认</Button> &nbsp;
        <Button type="primary" danger onClick={() => {setCancelRemark(()=>'');uniCancel({selectedRows,setCancelModal})}}>作废操作</Button> &nbsp;
        <Button type="primary" onClick={() => uniStaticLast({
          axApi:getUserBillList,
          params:{...searchParams, page:null},
          staticColumns,
          staticRows,
          setStaticModal,
          setStaticTableData,
          setStaticLoading
        })}>统计所有页数据</Button> &nbsp;
        <Button type="primary" onClick={() => uniExportExcel({columns, rowList: uniDeepArray(rowList), modifyFunc:uniModifyAxList, modifyData:exportModify,title:'充值提现',fileName: '充值提现'+uniCurrentTime({})})}>导出当前页</Button> &nbsp;
        <Button type="primary" loading={exportLoading} onClick={() => uniExportExcel({columns,setExportLoading, axApi:getUserBillList, params:{...searchParams, page:null}, modifyFunc:uniModifyAxList, modifyData:{...uniRowData.modifyData, ...exportModify},title:'充值提现',fileName: '充值提现'+uniCurrentTime({})})}>导出所有页码数据</Button> &nbsp;
      </p>
      <Table
        size='small'
        className={tablecss.height280}
        rowKey={'id'}
        columns={columns}
        rowSelection={{ ...uniSelectRows(setSelectedRowKeys, setSelectedRows), checkStrictly:true }}
        dataSource={rowList}
        pagination={false}
        loading={tableLoading}
        scroll={{x:2500,y:'calc(100vh - 340px)'}}
        // onChange={(newPagination)=>{pagination.pageSize = newPagination.pageSize;pagination.current=newPagination.current ;uniAxList({...uniRowData,params: {...searchParams, current:pagination.current, size:pagination.pageSize}})}}
        sticky
        summary={(pageData) => <SummeryRow pageData={pageData} sumInfo={[{mapIndex:6, mapKeys:['payPrice','collectionPrice'], sum:0}]} total={columns.length} title={'总金额'} />}
      />
      <div style={{textAlign:'right'}}>
        <p></p>
        <ConfigProvider locale={locale}>
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.current}
            total={pagination.total}
            showSizeChanger
            showQuickJumper
            showTotal={total => `共 ${total} 条`}
            onChange={(pageNumbe)=>{
              pagination.current = pageNumbe
              uniAxList({...uniRowData,params:{...searchParams, current:pageNumbe, size:pagination.pageSize}})
            }}
            onShowSizeChange={(current, pageSize)=>{
              pagination.pageSize = pageSize
              uniAxList({...uniRowData,params:{...searchParams, current:pagination.current, size:pageSize}})
            }}
          />
        </ConfigProvider>
      </div>
      <Modal
        width={600}
        maskClosable={false} title={'新增'}
        visible={addModal}
        onOk={() => formModalRef.current?.submit()}
        onCancel={()=>setAddModal(false)}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>setAddModal(false)}>取消</Button>, <Button key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}
      >
        <Form
          preserve={false}
          ref={formModalRef}
          name="form"
          labelCol={{ span: 6 }}
          initialValues={{}}
          wrapperCol={{ span: 16 }}
          onFinish={async (formValus) => {
            if (moment(formValus.time) > moment(formValus.actionTime)) message.error("收付款时间不能早于充值提现时间")
            else {
              setModalLoading(()=>true)
              let liscenseres:any
              if(liscenseList.length > 0) liscenseres = await uniHandleUpload({fileList:liscenseList,axUploadApi:fileUpload})
              let axvalue = {...formValus,bossReviewUserId:formValus.bossReviewUserId===-1?null:formValus.bossReviewUserId, rechargeFileUrl:liscenseres?liscenseres[0].data : null}
              uniAddRowSubmit({axGetData:uniRowData, axAddApi:createChargeWithdraw, setAddModal, customAddKeys,setModalLoading})(axvalue)
            }
          }}
          autoComplete="off"
        >
          {modalItems.map((item:any) => <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
        </Form>
      </Modal>
      <Modal width={800} visible={staticModal} title="数据统计" destroyOnClose onCancel={()=>setStaticModal(()=>false)} footer={[<Button key="back" onClick={()=>setStaticModal(()=>false)}>取消</Button>]}>
        <Table
          rowKey={'status'}
          columns={staticColumns}
          dataSource={staticTableData}
          pagination={false}
          loading={staticLoading}
        />
      </Modal>
      <Modal
        visible={cancelModal}
        title="确定进行作废操作吗？"
        destroyOnClose
        onCancel={()=>setCancelModal(()=>false)}
        onOk={() => uniCancelConfirm({selectedRows,cancelApi:cancelUserBill,cancelRemark,axData:uniRowData,setCancelModal,setModalLoading})}
        footer={[<Button key="back" onClick={()=>setCancelModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => uniCancelConfirm({selectedRows,cancelApi:cancelUserBill,cancelRemark,axData:uniRowData,setCancelModal,setModalLoading})}>确定</Button>]}
      >
        <TextArea style={{ height: 80, resize:'none' }} placeholder="请输入作废说明" onChange={(e) => setCancelRemark(() => e.target.value)} />
      </Modal>
      <Modal
        visible={confirmModal}
        title="提示"
        destroyOnClose
        onCancel={()=>setConfirmModal(()=>false)}
        footer={[<Button key="back" onClick={()=>setCancelModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => uniChgRowSubmit({currentRows:selectedRows, axData:uniRowData,updateApi:confirmChargeWithdraw,setModal:setConfirmModal,setModalLoading})({})}>确定</Button>]}
      >
        <h3>确定批量确认吗？</h3>
      </Modal>
      <Modal
        width={600}
        maskClosable={false}
        title={'批量审核'}
        visible={reviewModal}
        onCancel={()=>setReviewModal(false)}
        destroyOnClose
        onOk={() => formModalRef.current?.submit()}
        footer={[<Button key="back" onClick={()=>setReviewModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}
      >
        <Form
          preserve={false}
          ref={formModalRef}
          name="form"
          labelCol={{ span: 6 }}
          initialValues={{count:reviewProofNoList.value.length}}
          wrapperCol={{ span: 16 }}
          onFinish={(formValus) => {
            onBatchReview(formValus)
          }}
          autoComplete="off"
        >
          {reviewItems.map((item:any) => <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item> )}
        </Form>
      </Modal>
    </>
  )
}

