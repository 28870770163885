import { Avatar, Typography } from 'antd'
import React, { useState } from 'react'

import {FileProtectOutlined,ScheduleOutlined,ApartmentOutlined,FormOutlined,ReadOutlined,TransactionOutlined,FundViewOutlined,VideoCameraOutlined} from '@ant-design/icons'
import GuidTags from '../components/GuidTags'

export default function Guide() {
  /* hooks */
  const [checkedTag,setCheckedTag] = useState('基础信息设置')
  /* elements */
  const tags = [
    {
      icon: <FileProtectOutlined style={{color:checkedTag==='基础信息设置'?'#fff':'#3eaefa',fontSize:70}}/>,
      title: '基础信息设置',
      content: <div style={{display:'flex',justifyContent:'space-around'}}>
        <div style={{width:250}}>
          <h3 style={{textAlign:'center',fontWeight:'bold'}}>第一步 新增人员信息</h3>
          <div>
            <Typography.Link style={{fontSize:14,fontWeight:'bold'}}>1.新增人员信息(点击设置)</Typography.Link>
            <div>说明: 使用小兵财务系统的老板、财务、业务员、承包区等信息，需录如小兵财务系统【人员管理】中。</div>
          </div>
          <div>
            <Typography.Link style={{fontSize:14,fontWeight:'bold'}}>2.设置角色权限(点击设置)</Typography.Link>
            <div>说明：使用小兵财务系统的老板、财务、业务员、承包区等信息，需录如小兵财务系统【人员管理】中。</div>
          </div>
        </div>

        <div style={{width:250}}>
          <h3 style={{textAlign:'center',fontWeight:'bold'}}>第二步 设置账户及初始化</h3>
          <div>
            <Typography.Link style={{fontSize:14,fontWeight:'bold'}}>1.新增账户(点击设置)</Typography.Link>
            <div>说明：新增系统公司账户，填写账户相关信息。</div>
          </div>
          <div>
            <Typography.Link style={{fontSize:14,fontWeight:'bold'}}>2.修改初始化账户余额(点击设置)</Typography.Link>
            <div>说明：公司账户余额初始化后，系统将产生流水信息，方便每日对账（核对流水与账户余额）。</div>
          </div>
        </div>

        <div style={{width:250}}>
          <h3 style={{textAlign:'center',fontWeight:'bold'}}>第三步 设置权限及期初建账</h3>
          <div>
            <Typography.Link style={{fontSize:14,fontWeight:'bold'}}>1.设置审批权限(点击设置)</Typography.Link>
            <div>说明：启用账套时间设置后，此时间之后的凭证有效，且能同步生成财务报表。</div>
          </div>
          <div>
            <Typography.Link style={{fontSize:14,fontWeight:'bold'}}>2.网点期初建账(点击设置)</Typography.Link>
            <div>说明：期初建账时间为账套启用时间。期初建账中，银行存款及总部系统可用余额必须要填写。</div>
          </div>
        </div>

        <div style={{width:250}}>
          <h3 style={{textAlign:'center',fontWeight:'bold'}}>第四步 新增物料及客户信息</h3>
          <div>
            <Typography.Link style={{fontSize:14,fontWeight:'bold'}}>1.新增物料库存信息(点击设置)</Typography.Link>
            <div>说明：使用小兵财务系统的老板、财务、业务员、承包区等信息，需录入小兵财务系统【人员管理】中</div>
          </div>
          {/* <div>
            <Typography.Link style={{fontSize:14,fontWeight:'bold'}}>2.新增客户信息(点击设置)</Typography.Link>
            <div>说明：1）一个月及以上时间核算一次账单的客户为【月结】客户类型：一周／十天／半月核算一次账单的客户为【现付】客户类型；2）客户的业务人员须为【人员信息】中添加的人员。</div>
          </div> */}
        </div>

        <div style={{width:250}}>
          <h3 style={{textAlign:'center',fontWeight:'bold'}}>第五步 登记资产信息</h3>
          <div>
            <Typography.Link style={{fontSize:14,fontWeight:'bold'}}>登记资产信息(点击设置)</Typography.Link>
            <div>说明：需要折旧和摊销的车辆费用、保险费用、房租等需要登记到资产管理模块，系统每月月底自动生成折旧／摊销成本凭证。</div>
          </div>
        </div>
      </div>
    },
    {
      icon: <ScheduleOutlined  style={{color:checkedTag==='日常收支登记'?'#fff':'#3eaefa',fontSize:70}}/>,
      title: '日常收支登记'
    },
    {
      icon: <ApartmentOutlined style={{color:checkedTag==='物料管理使用'?'#fff':'#3eaefa',fontSize:70}}/>,
      title: '物料管理使用'
    },
    // {
    //   icon: <FormOutlined style={{color:checkedTag==='总部数据导入'?'#fff':'#3eaefa',fontSize:70}}/>,
    //   title: '总部数据导入'
    // },
    // {
    //   icon: <ReadOutlined style={{color:checkedTag==='客户账单登记'?'#fff':'#3eaefa',fontSize:70}}/>,
    //   title: '客户账单登记'
    // },
    {
      icon: <TransactionOutlined style={{color:checkedTag==='工资计提记账'?'#fff':'#3eaefa',fontSize:70}}/>,
      title: '工资计提记账'
    },
    {
      icon: <FundViewOutlined style={{color:checkedTag==='生成财务报表'?'#fff':'#3eaefa',fontSize:70}}/>,
      title: '生成财务报表'
    },
  ]
  return (<>
    <div style={{textAlign:'right'}}>
      <VideoCameraOutlined style={{fontSize:20,color:'skyblue'}}/>&nbsp;
      <span style={{fontSize:20,color:'skyblue'}}>视频教学,点击</span>
      <Typography.Link underline style={{fontSize:20}}>学习天地</Typography.Link>
    </div>
    <p></p>
    <GuidTags tags={tags} checkedtagFunc={(tagName:string)=>setCheckedTag(()=>tagName)}/>
  </>)
}
