import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker, FormInstance, Select, Table, TablePaginationConfig, Tooltip, Typography, message } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { getCompanyList, reportProfitList } from '../../utils/api'
import { uniAxList, uniAxSearchList, uniCurrentTime, uniDeepArray, uniExportExcel, uniFilterItem, uniFormProcess, uniModifyAxList, uniSelectRows, uniValueToName } from '../../utils/uniFucs'
import { leadBorrow } from '../../utils/constant'
import tablecss from '../../css/custable.module.less'
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../utils/interfaces';
import TopSearch from '../../components/TopSearch';
import SuperTable from '../../components/SuperTable';
import FormDialog from '../../components/FormDialog';

export default function ProductMonthProfit() {
const pathname = "/indexpage/productmonthprofit"
const searchRef = createRef<FormInstance>()
const modalRef = createRef<FormInstance>()
// const initSearchParams = {ym: moment().format('YYYY-MM')}
/* hooks */
let location = useLocation()
const navigate = useNavigate()
const formModalRef = createRef<FormInstance>()
const [addModal, setAddModal] = useState(false)
const [chgModal, setChgModal] = useState(false)
const [cancelModal, setCancelModal] = useState(false)
const [confirmModal, setConfirmModal] = useState(false)
const [staticModal, setStaticModal] =useState(false)
const [companyList, setCompanyList] = useState([])
const [rowList, setRowList] = useState([] as any)
let [searchParams, setSearchParams] = useState({} as any)
const [tempSearchParams, setTempSearchParams] = useState({page:1,companyId:1,ym:uniCurrentTime({day:false})} as any)
const [staffList, setStaffList] = useState([])
const [reinburseTypeList, setReinburseTypeList] = useState([])
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 10})
const [loadFlag, setLoadFlag] = useState(false)
const [rowDataObj,setRowDataObj] = useState({} as any)
const [searchYmRange,setSearchYmRange] = useState([] as any)
let [detailrowList,setDetailrowList] = useState([])
const [modalFlag, setModalFlag] = useState('')
const [showModal, setShowModal] = useState(false)

/* funcs */
const searchConfirm = async (formValus:any) => {setPagination({...pagination,current:1});
  searchParams = await uniFormProcess({...formValus},searchList)
  uniAxList(dataRow())
}
//修饰数据
const modifyAxData = (rowlist: any) => {
  const loopFunc = (list:any) => {
    list.forEach((item:any)=>{
      item.id = item.subjectId
      if (item.children.length > 0) loopFunc(item.children)
      else delete item.children
    })
  }
  loopFunc(rowlist)
  let modifylist = rowlist
  
  let mainIncome = modifylist.find((rowItem:any) => rowItem.name === '主营业务收入') //
  let otherIncome = modifylist.find((rowItem:any) => rowItem.name === '其他业务收入') //
  let mainCost = modifylist.find((rowItem:any) => rowItem.name === '主营业务成本')  //
  let otherCost = modifylist.find((rowItem:any) => rowItem.name === '其他业务支出')
  let taxplus = modifylist.find((rowItem:any) => rowItem.name === '税金及附加') //
  let manageFee = modifylist.find((rowItem:any) => rowItem.name === '管理费用') //
  let saleFee = modifylist.find((rowItem:any) => rowItem.name === '销售费用') //
  let finFee = modifylist.find((rowItem:any) => rowItem.name === '财务费用') //
  let investProfile = modifylist.find((rowItem:any) => rowItem.name === '投资收益') //
  let outIncome = modifylist.find((rowItem:any) => rowItem.name === '营业外收入') //
  let outCost = modifylist.find((rowItem:any) => rowItem.name === '营业外支出') //
  let taxfee = modifylist.find((rowItem:any) => rowItem.name === '所得税') //

  let expressCollect:any = {name: '业务收入',level:1, id:-1,special:true,objs:[{flag:true,obj:mainIncome},{flag:true,obj:otherIncome}],remark: '主营业务收入 + 其他业务收入'}
  let expressCost:any = {name: '业务成本',level:1, id:-2,special:true,objs:[{flag:true,obj:mainCost},{flag:true,obj:otherCost},{flag:true,obj:taxplus}],remark: '主营业务成本 +  其他业务成本 + 营业税金及附加'}
  let expressProfit:any = {name: '业务毛利润',level:1, id:-3,special:true,objs:[{flag:true,obj:expressCollect},{flag:false,obj:expressCost}],remark: '业务收入 - 业务成本'}
  let marketProfit:any = {name: '经营利润',level:1, id:-4,special:true,objs:[{flag:true,obj:expressProfit},{flag:false,obj:manageFee},{flag:false,obj:saleFee},{flag:false,obj:finFee}],remark: '业务毛利润-管理费用-销售费用-财务费用'}
  let profitMount:any = {name: '利润总额',level:1, id:-5,special:true,objs:[{flag:true,obj:marketProfit},{flag:true,obj:investProfile},{flag:true,obj:outIncome},{flag:false,obj:outCost}],remark: '经营利润 + 投资收益 + 营业外收入 - 营业外支出'}
  let netProfit:any = {name: '净利润',level:1, id:-6,special:true,objs:[{flag:true,obj:profitMount},{flag:false,obj:taxfee}],remark: '利润总额 - 所得税费用'}
  let divideRow:any = {name: '',level:1, id:-7,special:true, price: '-----具体项目----', total:'-----具体项目----'}

  let keys = ['monthPrice','yearPrice']
  let toparr = [expressCollect,expressCost,expressProfit,marketProfit,profitMount,netProfit]
  toparr.forEach((topitem:any)=>{
    keys.forEach((key:string)=>topitem[key]=0)
    topitem.objs.forEach((objitem:any)=>{
      keys.forEach((key:string)=>{
        if (objitem.flag) topitem[key] += objitem.obj[key]
        else topitem[key] -= objitem.obj[key]
      })
    })
  })

  setRowList(() =>[expressCollect, expressCost, expressProfit, marketProfit, profitMount, netProfit, divideRow, ...modifylist])
  return modifylist
}
//路由刷新请求
const routerFlash = () => {
  uniAxList(dataCompanyList)
}

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '公司', prop:'companyId',type:'select',dataList:companyList,required:true,change:(value:any)=>{
  let finditem:any = companyList.find((item:any)=>item.id===value)
  if (finditem.initialize===1) setSearchYmRange([finditem.startYm,finditem.endYm])
  else setSearchYmRange([])
}},
{label: '月份', prop:'ym',type:'datePicker',required:true,clearable:false,datepickerProps:{returnType:'string',type:'month',disabledDate:(current)=>{
  if (searchYmRange.length<2) return true
  else return current<moment(searchYmRange[0]).startOf('month') || current>moment(searchYmRange[1]).endOf('month')
}}},
]
//顶部操作
const topActions:ColumnAction[] = [
{label: '科目详情', type:'primary', action: () => {
  if (rowList.length <= 0) return message.error('请先搜索数据')
  setShowModal(true)
}},
]
//统计首行
const columns:ColumnItem[] = [
  {title: '项目',key: 'name'},
  {title: '本期金额',key: 'changePrice',type:'price'},
  {title: '本年累计金额',key: 'yearPrice',type:'price'}
  ]
//列首行
const statColumns:ColumnItem[] = [
{title: '科目编码',key: 'subjectNo',custval:({row,value}:any)=>{
  let tempstr = value
  if (row.level===2) tempstr = '\xA0\xA0\xA0\xA0' + value
  else if (row.level===3) tempstr = '\xA0\xA0\xA0\xA0\xA0\xA0' + value
  return tempstr
}},
{title: '科目名称',key: 'subjectName',custval:({row,value}:any)=>{
  let tempstr = value
  if (row.level===2) tempstr = '\xA0\xA0\xA0\xA0' + value
  else if (row.level===3) tempstr = '\xA0\xA0\xA0\xA0\xA0\xA0' + value
  return tempstr
}},
{title: '借贷方向',key: 'borrowLend',type:'tag',tagProps:{values:[1,2]},custval:({row,value}:any)=>uniValueToName({list:leadBorrow,value})},
{title: '本月借',key: 'subjectChangeBorrowPrice',type:'price'},
{title: '本月贷',key: 'subjectChangeLendPrice',type:'price'},
{title: '年度借',key: 'subjectYearChangeBorrowPrice',type:'price'},
{title: '年度贷',key: 'subjectYearChangeLendPrice',type:'price'},
]

/* 接口参数 */
const dataRow = ():AxDataform => ({
  params: searchParams,
  axApi: reportProfitList,
  setTableLoading,
  awaitFunc:(predata:any)=>{
    //数据统计
    let tempobjs:any = {}
    predata.forEach((preitem:any)=>{
      delete preitem.children
      if (preitem.borrowLend===1) {
        preitem.changePrice = preitem.subjectChangeBorrowPrice - preitem.subjectChangeLendPrice
        preitem.yearPrice = preitem.subjectYearChangeBorrowPrice - preitem.subjectYearChangeLendPrice
      } else {
        preitem.changePrice = preitem.subjectChangeLendPrice - preitem.subjectChangeBorrowPrice
        preitem.yearPrice = preitem.subjectYearChangeLendPrice - preitem.subjectYearChangeBorrowPrice
      }
      tempobjs[preitem.subjectNo] = preitem
    })
    console.log('predata---------********------',predata)
    let statrowList = []
    let item1 = {
      id:1,name:'一、营业收入',detail: '6001 ' + tempobjs['6001'].subjectName + '6050 ' + tempobjs['6001'].subjectName,
      changePrice:tempobjs['6001'].changePrice + tempobjs['6051'].changePrice,yearPrice:tempobjs['6001'].yearPrice + tempobjs['6051'].yearPrice
    }
    let item2 = {
      id:2,name:'\xA0\xA0减:营业收入',detail: '6401 ' + tempobjs['6401'].subjectName + '6402 ' + tempobjs['6402'].subjectName,
      changePrice:tempobjs['6401'].changePrice + tempobjs['6402'].changePrice,yearPrice:tempobjs['6401'].yearPrice + tempobjs['6402'].yearPrice
    }
    let item3 = {id:3,name:'\xA0\xA0\xA0\xA0营业税金及附加',detail: '6405 ' + tempobjs['6405'].subjectName,changePrice:tempobjs['6405'].changePrice,yearPrice:tempobjs['6405'].yearPrice}
    let item4 = {id:4,name:'\xA0\xA0\xA0\xA0销售费用',detail: '6601 ' + tempobjs['6601'].subjectName,changePrice:tempobjs['6601'].changePrice,yearPrice:tempobjs['6601'].yearPrice}
    let item5 = {id:5,name:'\xA0\xA0\xA0\xA0管理费用',detail: '6602 ' + tempobjs['6602'].subjectName,changePrice:tempobjs['6602'].changePrice,yearPrice:tempobjs['6602'].yearPrice}
    let item6 = {id:6,name:'\xA0\xA0\xA0\xA0财务费用',detail: '6603 ' + tempobjs['6603'].subjectName,changePrice:tempobjs['6603'].changePrice,yearPrice:tempobjs['6603'].yearPrice}
    let item7 = {id:7,name:'\xA0\xA0\xA0\xA0资产减值损失',detail: '6701 ' + tempobjs['6701'].subjectName,changePrice:tempobjs['6701'].changePrice,yearPrice:tempobjs['6701'].yearPrice}
    let item8 = {id:8,name:'\xA0\xA0加:公允价值变动收益(损失以"-"号填列)',detail: '6101 ' + tempobjs['6101'].subjectName,changePrice:tempobjs['6101'].changePrice,yearPrice:tempobjs['6101'].yearPrice}
    let item9 = {id:9,name:'\xA0\xA0\xA0\xA0投资收益(损失以"-"号填列)',detail: '6111 ' + tempobjs['6111'].subjectName,changePrice:tempobjs['6111'].changePrice,yearPrice:tempobjs['6111'].yearPrice}
    let item10 = {id:10,name:'\xA0\xA0\xA0\xA0其中:对联营企业和合营企业的投资收益',detail: '',changePrice:0,yearPrice:0}
    let item11 = {
      id:11,name:'二、营业利润(亏损以"-"号填列)',detail: '营业收入-营业成本-营业税金及附加-销售费用-管理费用-财务费用-资产减值损失+公允价值变动收益+投资收益+其中:对联营企业和合营企业的投资收益',
      changePrice:item1.changePrice - item2.changePrice - item3.changePrice - item4.changePrice - item5.changePrice - item6.changePrice - item7.changePrice + item8.changePrice + item9.changePrice + item10.changePrice,
      yearPrice:item1.yearPrice - item2.yearPrice - item3.yearPrice - item4.yearPrice - item5.yearPrice - item6.yearPrice - item7.yearPrice + item8.yearPrice + item9.yearPrice + item10.yearPrice
    }
    let item12 = {id:12,name:'\xA0\xA0加:营业外收入',detail: '6301 ' + tempobjs['6301'].subjectName,changePrice:tempobjs['6301'].changePrice,yearPrice:tempobjs['6301'].yearPrice}
    let item13 = {id:13,name:'\xA0\xA0减:营业外支出',detail: '6711 ' + tempobjs['6711'].subjectName,changePrice:tempobjs['6711'].changePrice,yearPrice:tempobjs['6711'].yearPrice}
    let item14 = {id:14,name:'\xA0\xA0\xA0\xA0其中:非流动资产处置损失',detail: '',changePrice:0,yearPrice:0}
    let item15 = {
      id:15,name:'三、利润总额(亏损总额以"-"号填列)',detail: '营业利润+营业外收入-营业外支出',
      changePrice:item11.changePrice + item12.changePrice - item13.changePrice, yearPrice:item11.yearPrice + item12.yearPrice - item13.yearPrice
    }
    let item16 = {id:16,name:'\xA0\xA0减:所得税费用',detail: '6801 ' + tempobjs['6801'].subjectName,changePrice:tempobjs['6801'].changePrice,yearPrice:tempobjs['6801'].yearPrice}
    let item17 = {
      id:17,name:'四、净利润(净亏损以"-"号填列)',detail: '利润总额-所得税费用',
      changePrice:item15.changePrice - item16.changePrice, yearPrice:item15.yearPrice - item16.yearPrice
    }
    let item18 = {id:18,name:'五、其他综合收益的税后净额',detail: '',changePrice:0, yearPrice:0}
    let item19 = {id:19,name:'\xA0\xA0(一)以后不能重分类进损益的其综合收益',detail: '',changePrice:0, yearPrice:0}
    let item20 = {id:20,name:'\xA0\xA0(二)以后将重分类进损益的其综合收益',detail: '',changePrice:0, yearPrice:0}

    let item24 = {id:24,name:'七、综合收益总额',detail: '净利润+其他综合收益的税后净额',changePrice:item17.changePrice+item18.changePrice, yearPrice:item17.yearPrice+item18.yearPrice}

    let item21 = {id:21,name:'六、每股收益',detail: '',changePrice:0, yearPrice:0}
    let item22 = {id:22,name:'\xA0\xA0(一)基本每股收益',detail: '综合收益总额/36620000',changePrice:Number((item24.changePrice/36620000).toFixed(2)), yearPrice:Number((item24.yearPrice/36620000).toFixed(2))}
    let item23 = {id:23,name:'\xA0\xA0(二)稀释每股收益',detail: '',changePrice:0, yearPrice:0}
    statrowList.push(...[item1,item2,item3,item4,item5,item6,item7,item8,item9,item10,item11,item12,item13,item14,item15,item16,item17,item18,item19,item20,item21,item22,item23,item24])
    console.log('statrowlist-------------',statrowList)
    setRowList(statrowList)
    setDetailrowList(predata)
  }
})
const dataCompanyList:AxDataform = {
  axApi: getCompanyList,
  setList: setCompanyList
}
/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])
/* mounted */
React.useEffect(() => {
  uniAxList(dataCompanyList)
  setLoadFlag(()=>true)
}, [])

return (<>
  <TopSearch
    ref={searchRef}
    topActions={topActions}
    initialValues={{}}
    searchList={searchList}
    searchConfirm={searchConfirm}
  />
  <SuperTable
    columns={columns}
    rowList={rowList}
    tableLoading={tableLoading}
    dataRow={dataRow}
  />
  <FormDialog
    ref={modalRef}
    type="drawer"
    drawerSize="70%"
    title="科目详情"
    show={showModal}
    formItems={[]}
    cancel={()=>setShowModal(false)}
    confirm={()=>{}}
    showCustom={true}
    hasConfirm={false}
  >
    <></>
    <SuperTable
      rowKey="subjectId"
      columns={statColumns}
      rowList={detailrowList}
    />
  </FormDialog>
</>)
}
