import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, Form, FormInstance, InputNumber, Modal, Pagination, Popconfirm, Radio, Select, Table, TablePaginationConfig, Tooltip, Typography } from 'antd'
import locale from 'antd/es/locale/zh_CN';

import { createMaterialPrice, deleteMaterialPrice, getCompanyList, getCustomerList, getMaterialList, getMaterialPriceList, getStaffList, updateMaterialPrice } from '../../utils/api'
import { uniAddRowSubmit, uniAxList, uniAxSearchList, uniChgRowSubmit, uniCurrentTime, uniDeepArray, UniDeleteAll, uniDeleteRow, uniExportExcel, uniModifyAxList, uniModifyRow, uniQuitModal, uniSelectRows, uniTableChange } from '../../utils/uniFucs'
import FormModal from '../../components/FormModal'
import { userTypeList } from '../../utils/constant'
import { NameValue } from '../../utils/beans'
import TextArea from 'antd/lib/input/TextArea'
import tablecss from '../../css/custable.module.less'

export default function MaterialPrice() {
  const pathname = "/indexpage/materialprice"
  /* hooks */
  let location = useLocation()
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [logModal, setLogModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [stockModal, setStockModal] = useState(false)
  const [staticModal, setStaticModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [logList, setLogList] = useState([])
  const [rowList, setRowList] = useState([])
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({userType:1, stock:0} as any)
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [currentRow, setCurrentRow] = useState({} as any)
  const [searchParams, setSearchParams] = useState({page:1} as any)
  const [tempSearchParams, setTempSearchParams] = useState({page:1} as any)
  const [staffList, setStaffList] = useState([])
  const [feeTypeList, setFeeTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
  const [accountList, setAccountList] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [unCashRows, setUnCashRows] = useState([] as any)
  const [unStockRows, setUnStockRows] = useState([] as any)
  const [isUnderLinePay, setIsUnderLinePay] = useState(true)
  const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
  const [cancelRemark, setCancelRemark] = useState('')
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [positionList, setPositionList] = useState([])
  const [custList, setCustList] = useState([])
  const [confirmItemVisible, setConfirmItemVisible] = useState({paytype: 2})
  const [confirmCompanyList, setConfirmCompanyList] = useState([])
  const [confirmModalInitial, setConfirmModalInitial] = useState({})
  const [currentConfirmRow, setCurrentConfirmRow] = useState({} as any)
  const [custCompanyList, setCustCompanyList] = useState([])
  const [addTableData, setAddTableData] = useState([])
  const [modalCpBusinessList, setModalCpBusinessList] = useState([])
  const [modalFeeTypeList, setModalFeeTypeList] = useState([])
  const [importModal, setImportModal] = useState(false)
  const [accountantSubjectList, setAccountantSubjectList] = useState([])
  const [businessList, setBusinessList] = useState([])
  const [currentSubjectId, setCurrentSubjectId] = useState({id:1})
  const [menuList, setMenuList] = useState([])
  const [initTreeIds, setInitTreeIds] = useState([])
  const [selectTreeIds, setSelectTreeIds] = useState([])
  const [materialList, setMaterialList] = useState([])
  const [customModalInitial, setCustomModalInitial] = useState({} as any)
  const [loadFlag, setLoadFlag] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [modalMaterialList, setModalMaterialList] = useState([])
  //路由刷新请求
  const routerFlash = () => {
    uniAxList(uniRowData)
    uniAxList(uniStaffData)
    uniAxList(uniCustomerData)
    uniAxList(uniMaterialData)
    uniAxList(uniCompanyData)
  }
  /* elements */
  //列项
  const columns:any = [
    {
      title: '人员性质',
      dataIndex: 'userType',
      key: 'userType',
      render: (value:any) => value === 1 ? '员工' : 
                              value === 2 ? '客户' :
                              value === 3 ? '承包区' : '其他'
    },
    {
      title: '人员名称',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '物料名称',
      dataIndex: 'materielName',
      key: 'materielName',
    },
    {
      title: '物料公司',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: '销售单价',
      dataIndex: 'salePrice',
      key: 'salePrice',
      render: (value:any) => (value / 100).toFixed(2)
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      ellipsis: {showTitle: false},
      render: (value:string) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      fixed: 'right',
      width: 120,
      render: (_: any, record: any) => {
        return (
          <span>
            <Typography.Link onClick={() => {
              if (record.userType === 1) modalItemVisible.userType = 1
              else if (record.userType === 2) modalItemVisible.userType = 2
              else if (record.userType === 3) modalItemVisible.userType = 3
              uniModifyRow({record, setCurrentRow, setChgModal})
            }} style={{ marginRight: 8 }}>修改</Typography.Link>
            <Popconfirm title={"确定删除该记录吗?"} onConfirm={() => uniDeleteRow({record,deleteApi:deleteMaterialPrice,uniAxListData:uniRowData})} okText="确定" cancelText="取消">
              <a href="#">删除</a>
            </Popconfirm>
          </span>
        )
      }
    }
  ]

  //添加和修改中的model项
  const modalItems = [
    {
      label: '人员性质',
      name: 'userType',
      rules: [{ required: true, message: '人员性质不能为空' }],
      element: <Radio.Group onChange={(e) => {
        setModalItemVisible(() => ({...modalItemVisible,userType:e.target.value}))
        formModalRef.current?.setFieldsValue({userId: null})
      }}>
                {userTypeList.map((item:NameValue) => <Radio key={item.value} value={item.value}>{item.name}</Radio>)}
              </Radio.Group>
    },
    {
      label: '人员名称',
      name: 'userId',
      rules: [{ required: true, message: '人员名称不能为空' }],
      element: <Select
                showSearch
                optionFilterProp='children'
                allowClear
                style={{ width: '100%' }}
                placeholder="请选择人员名称"
                onChange={(value, option:any) => {
                  setCustomAddKeys(()=>({...customAddKeys, userName: option ? option.children : null}))
                }}
              >
                { modalItemVisible.userType === 1
                  ? staffList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)
                  : modalItemVisible.userType === 2
                    ? custList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)
                    : []
                }
              </Select>
    },
    {
      label: '选择公司',
      name: 'companyId',
      rules: [{ required: true, message: '所属公司不能为空' }],
      element: <Select
                  showSearch
                  optionFilterProp='children'
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="请选择公司"
                  onChange={(value, option:any) => {
                    formModalRef.current?.setFieldsValue({materielId:null})
                    setCustomAddKeys(()=>({...customAddKeys, companyName: value ? option.children : null}))
                    uniAxList({...uniMaterialData,params:{filter_EQL_company_id:value},setList:setModalMaterialList})
                  }}
                >
                  {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                </Select>
    },
    {
      label: '物料名称',
      name: 'materielId',
      rules: [{ required: true, message: '物料名称不能为空' }],
      element: <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="请选择物料名称"
                onChange={(value, option:any) => {
                  setCustomAddKeys(()=>({...customAddKeys, materielName: option ? option.children : null}))
                }}
              >
                {modalMaterialList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
              </Select>
    },
    {
      label: '销售单价',
      name: 'salePrice',
      rules: [{ required: true, message: '销售单价不能为空' }],
      element: <InputNumber min={0} precision={2} style={{ width: '100%' }} placeholder="请输入采购单价" />
    },
    {
      label: '备注',
      name: 'remark',
      rules: [{ required: false }],
      element: <TextArea style={{ height: 80, resize:'none' }} />
    }
  ]
  //导出当前页，需要的数据修饰
  const exportModify:any = {
    checkStatus:[
      {newKey:'userType', checkKey: 'userType', sourceList:userTypeList, sourceCheckKey:'value', sourceValueKey:'name'},
    ],
    divideKey:[{newKey: 'salePrice', key:'salePrice', value: 100}],
    notNullColumns: columns,
  }

  /* 接口参数 */
  const uniRowData:any = {
    params: searchParams,
    axApi: getMaterialPriceList,
    setList: setRowList,
    setTableLoading,
    pagination
  }
  const uniStaffData:any = {
    params: {size:999},
    axApi: getStaffList,
    setList: setStaffList,
  }
  const uniCustomerData:any = {
    params: {size:9999},
    axApi: getCustomerList,
    setList: setCustList,
  }
  const uniMaterialData:any = {
    axApi: getMaterialList,
    setList: setMaterialList,
  }
  const uniCompanyData:any = {
    axApi: getCompanyList,
    setList: setCompanyList,
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    uniAxList(uniRowData)
    uniAxList(uniStaffData)
    uniAxList(uniCustomerData)
    uniAxList(uniMaterialData)
    uniAxList(uniCompanyData)
    setLoadFlag(()=>true)
  }, [])

  return (
    <>
      <p>
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="物料名称"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_materiel_id:value || null}))}
      > 
        {materialList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="人员性质"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQL_user_type:value || null}))}
      > 
        {userTypeList.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        showSearch
        optionFilterProp="children"
        allowClear
        style={{ width: 200 }}
        placeholder="人员名称"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_user_id:value || null}))}
      > 
        {[...staffList, ...custList].map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Button onClick={() => {pagination.current=1;uniAxSearchList({setSearchParams, uniRowData, params: {...tempSearchParams,size:pagination.pageSize}})}} type='primary'>查询</Button>
      </p>
      <p>
        <Button type="primary" onClick={() => {setAddModal(() => true);setModalMaterialList(()=>[])}}>新增</Button> &nbsp;
        {/* <Button type="primary" onClick={() => uniExportExcel({columns, axApi:getMaterialPriceList, fileName: '物料价格明细'})}>批量导出</Button> &nbsp; */}
        <Button type="primary" danger disabled={!selectedRowKeys.length}>
          <Popconfirm title="确定批量删除吗?" onConfirm={() => UniDeleteAll({deleteKeyList:selectedRowKeys, deleteApi:deleteMaterialPrice, setSelectedRowKeys, axParams:uniRowData})} okText="确定" cancelText="取消">批量删除</Popconfirm>
        </Button>&nbsp;
        <Button type="primary" onClick={() => uniExportExcel({columns, rowList: uniDeepArray(rowList), modifyFunc:uniModifyAxList, modifyData:exportModify,title:'物料价格设置', fileName: '物料价格设置'+uniCurrentTime({})})}>导出当前页</Button> &nbsp;
        <Button type="primary" loading={exportLoading} onClick={() => uniExportExcel({columns,setExportLoading, axApi:getMaterialPriceList, params:{...searchParams, page:null}, modifyFunc:uniModifyAxList, modifyData:{...uniRowData.modifyData, ...exportModify},title:'物料价格设置',fileName: '物料价格设置'+uniCurrentTime({})})}>导出所有页码数据</Button> &nbsp;
      </p>
      <Table
        size='small'
        className={tablecss.height270}
        rowKey={'id'}
        columns={columns}
        rowSelection={{ ...uniSelectRows(setSelectedRowKeys, setSelectedRows), checkStrictly:true }}
        dataSource={rowList}
        pagination={false}
        scroll={{y:'calc(100vh - 300px)'}}
        // onChange={(newPagination)=>{pagination.pageSize = newPagination.pageSize;pagination.current=newPagination.current ;uniAxList({...uniRowData,params: {...searchParams, current:pagination.current, size:pagination.pageSize}})}}
        loading={tableLoading}
        sticky
      />
      <div style={{textAlign:'right'}}>
        <p></p>
        <ConfigProvider locale={locale}>
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.current}
            total={pagination.total}
            showSizeChanger
            showQuickJumper
            showTotal={total => `共 ${total} 条`}
            onChange={(pageNumbe)=>{
              pagination.current = pageNumbe
              uniAxList({...uniRowData,params:{...searchParams, current:pageNumbe, size:pagination.pageSize}})
            }}
            onShowSizeChange={(current, pageSize)=>{
              pagination.pageSize = pageSize
              uniAxList({...uniRowData,params:{...searchParams, current:pagination.current, size:pageSize}})
            }}
          />
        </ConfigProvider>
      </div>
      <Modal
        width={600}
        maskClosable={false}
        title={'新增'}
        visible={addModal}
        onOk={() => formModalRef.current?.submit()}
        onCancel={()=>setAddModal(false)}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>setAddModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}
      >
        <Form
          preserve={false}
          ref={formModalRef}
          name="form"
          labelCol={{ span: 6 }}
          initialValues={{userType:1}}
          wrapperCol={{ span: 16 }}
          onFinish={(formValus) => {
            let axvalue = {...formValus, salePrice:(formValus.salePrice * 100).toFixed()}
            uniAddRowSubmit({axGetData:uniRowData,axAddApi:createMaterialPrice, setAddModal,setModalLoading, customAddKeys, needArray:true})(axvalue)
          }}
          autoComplete="off"
        >
          {modalItems.map((item:any) => <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
          {/* {modalItems.map((item:any) => (modalItemVisible && item.name in modalItemVisible && !modalItemVisible[item.name]) ? null : <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)} */}
        </Form>
      </Modal>
      <Modal
        width={600}
        maskClosable={false}
        title={'修改'}
        visible={chgModal}
        onOk={() => formModalRef.current?.submit()}
        onCancel={()=>setChgModal(false)}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>setChgModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}
      >
        <Form
          preserve={false}
          ref={formModalRef}
          name="form"
          labelCol={{ span: 6 }}
          initialValues={{...currentRow, salePrice: currentRow.salePrice / 100}}
          wrapperCol={{ span: 16 }}
          onFinish={(formValus) => {
            let axvalue = {...formValus, salePrice:(formValus.salePrice * 100).toFixed()}
            uniChgRowSubmit({currentRows:[currentRow],axData:uniRowData, updateApi:updateMaterialPrice,setModalLoading, setModal:setChgModal})(axvalue)
          }}
          autoComplete="off"
        >
          {modalItems.map((item:any) => <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
          {/* {modalItems.map((item:any) => (modalItemVisible && item.name in modalItemVisible && !modalItemVisible[item.name]) ? null : <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)} */}
        </Form>
      </Modal>
    </>
  )
}
