import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker, FormInstance, Input, Pagination, Select, Table, TablePaginationConfig } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { getCompanyAccoutList, getCompanyList, getCustomerList, getMaterialLog, getMaterialList, getStaffCharList, getStaffList } from '../../utils/api'
import { uniAxList, uniAxSearchList, uniExportExcel, uniFilterItem, uniFormProcess, uniSelectRows, uniValueToName } from '../../utils/uniFucs'
import recordvouchercss from '../css/recordvoucher.module.less'
import { csDateRages, csMaterialflowTypes, userTypeList } from '../../utils/constant'
import tablecss from '../../css/custable.module.less'
import TopSearch from '../../components/TopSearch';
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../utils/interfaces';
import SuperTable from '../../components/SuperTable';

export default function MaterialClaimlog() {
/* constant */
const pathname = "/indexpage/materialclaimlog"
const modalRef = createRef<FormInstance>()
const searchRef = createRef<FormInstance>()
const initSearchParams = {page:1,orderBy:"step_one_ymd", orderDir: "desc"}
const initsumProps:any = []

/* hooks */
let location = useLocation()
const [companyList, setCompanyList] = useState([])
const [rowList, setRowList] = useState([])
let [searchParams, setSearchParams] = useState({...initSearchParams} as any)
let [tableParams, setTableParams] = useState({...initSearchParams} as any)
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
const [materialList, setMaterialList] = useState([])
const [loadFlag, setLoadFlag] = useState(false)
const [exportLoading, setExportLoading] = useState(false)
const [searchYmRange,setSearchYmRange] = useState([] as any)

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '公司', prop:'filter_EQS_company_id',type:'select',dataList:companyList,required:true,change:(value:any)=>{
  searchRef.current?.setFieldsValue({'filter_BETWEENS_step_one_time':null,'filter_BETWEENS_step_two_time':null})
  let finditem:any = companyList.find((item:any)=>item.id===value)
  if (finditem.initialize===1) setSearchYmRange([finditem.startYm,finditem.endYm])
  else setSearchYmRange([])
}},
{label: '类型', prop:'filter_EQS_type',type:'select',dataList:csMaterialflowTypes},
{label: '物料', prop:'filter_LIKES_materiel_name'},
{label: '备注', prop:'filter_LIKES_remark'},
{label: '申领/采购人', prop:'filter_LIKES_step_one_user_name'},
{label: '发放/入库人', prop:'filter_LIKES_step_two_user_name'},
{label: '申领/采购时间', prop:'filter_BETWEENS_step_one_time',type:'dateRange',daterangeProps:{returnType:'string',disabledDate:(current)=>{
  if (searchYmRange.length<2) return true
  else return current<moment(searchYmRange[0]).startOf('month') || current>moment(searchYmRange[1]).endOf('month')
}}},
{label: '发放/入库时间', prop:'filter_BETWEENS_step_two_time',type:'dateRange',daterangeProps:{returnType:'string',disabledDate:(current)=>{
  if (searchYmRange.length<2) return true
  else return current<moment(searchYmRange[0]).startOf('month') || current>moment(searchYmRange[1]).endOf('month')
}}}
]
//列项
const columns:ColumnItem[] = [
// {title: '类型',key: 'type',type:'toname',tonameProps:{dataList:csMaterialflowTypes}},
{title: '类型',key: 'type',type:'tag',custval:({row,value}:any)=>uniValueToName({list:csMaterialflowTypes,value})},
{title: '物料名称',key: 'materielName'},
{title: '物料公司',key: 'companyName'},
{title: '数量',key: 'num'},
{title: '总金额',key: 'price',type:'price'},
{title: '申领/采购人',key: 'stepOneUserName'},
{title: '申领/采购日期',key: 'stepOneYmd',type:'datetime'},
{title: '发放/入库人',key: 'stepTwoUserName'},
{title: '发放/入库日期',key: 'stepTwoYmd',type:'datetime'},
{title: '备注',key: 'remark'},
]
//底部操作
const bottomActions:ColumnAction[] = [
{label: '导出',type:'primary',loading:exportLoading,disabled: rowList.length<=0,action: () =>uniExportExcel({
  exportLoading,columns:columns.slice(0,-1),title:'物料流水明细',fileName:'物料流水明细',
  showSummary:false,sumProps:initsumProps,axApi:getMaterialLog,tableParams,setExportLoading
})},
]
  
/* funcs */
const searchConfirm = async (formValus:any) => {
  pagination.current = 1
  setPagination({...pagination,current:1});
  // searchParams = {...initSearchParams,...formValus}
  // setSelectedRows([])
  // setSelectedRowKeys([])
  searchParams = await uniFormProcess({...initSearchParams,...formValus},searchList)
  setTableParams({...searchParams})
  setSearchParams(searchParams)
  uniAxList(dataRow())
}
//路由刷新请求
const routerFlash = () => {
  // uniAxList(dataRow())
  uniAxList(dataMaterialList)
  uniAxList(dataCompanyList)
}

/* 接口参数 */
const dataRow = (current?:number):AxDataform => ({
  params: {...searchParams, current: current || pagination.current, size:pagination.pageSize},
  axApi: getMaterialLog,
  setList: setRowList,
  setTableLoading,
  setPagination
})
//物料列表
const dataMaterialList:any = {
  axApi: getMaterialList,
  setList: setMaterialList,
}
//公司列表
const dataCompanyList:any = {
  axApi: getCompanyList,
  setList: setCompanyList,
}

/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])
/* mounted */
React.useEffect(() => {
  // uniAxList(dataRow())
  uniAxList(dataMaterialList)
  uniAxList(dataCompanyList)
  setLoadFlag(()=>true)
}, [])

return (<>
<TopSearch
  ref={searchRef}
  initialValues={initSearchParams}
  searchList={searchList}
  searchConfirm={searchConfirm}
/>
<SuperTable
  columns={columns}
  rowList={rowList}
  tableLoading={tableLoading}
  pagination={pagination}
  dataRow={dataRow}
  bottomActions={bottomActions}
/>
</>)}
