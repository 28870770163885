import React, { createRef, useState } from 'react'
import { Button, ConfigProvider, FormInstance, Input, InputNumber, message, Pagination, Popconfirm, Select, Table, TablePaginationConfig, TableProps, Typography } from 'antd'
import locale from 'antd/es/locale/zh_CN';

import { createPosition, updatePosition, deletePosition, getPositionList, axAffixSubjectList, axAddAffixDetail, axCreateAffixSubject, axUpdateAffixSubject, axDeleteAffixSubject, axAffixSubjectDetail, axUpdateAffixDetail, axDeleteAffixDetail, axSubjectList, axAffixExSubjectList, axCreateAffixExSubject, axDeleteAffixExSubject, getCompanyList, axAsycCustAndSupplier } from '../../utils/api'
import { PsItem } from '../../utils/beans'
import FormModal from '../../components/FormModal'
import {  uniSelectRows, uniAxList, uniAxSearchList, UniDeleteAll, uniQuitModal, uniChgRowSubmit, uniAddRowSubmit, uniModifyRow, uniDeleteRow, uniValueToName, uniOpAlarm, uniModifyAxList, uniFilterItem, uniFormProcess } from '../../utils/uniFucs'
import UniOperationColumn from '../../components/UniOperationColumn'
import { UNSAFE_NavigationContext, useLocation } from 'react-router-dom'
import tablecss from '../../css/custable.module.less'
import { csAffixTypes } from '../../utils/constant';
import SuperTable from '../../components/SuperTable';
import TopSearch from '../../components/TopSearch';
import FormDialog from '../../components/FormDialog';
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../utils/interfaces';

export default function TitleSetting() {
/* constant */
const searchRef = createRef<FormInstance>()
const firmsearchRef = createRef<FormInstance>()
const modalRef = createRef<FormInstance>()
const submodalRef = createRef<FormInstance>()
let location = useLocation()
const pathname = "/indexpage/affixmanage"
const initSearchParams = {page:1}
const initFirmSearchParams = {}
const initFormdata ={}
const initSubformdata ={}

/* data */
const [positionList, setPositionList] = useState([])
const [rowList, setRowList] = useState([])
const [addModal, setAddModal] = useState(false)
const [currentRow, setCurrentRow] = useState({} as any)
const [chgModal, setChgModal] = useState(false)
const [selectedRowKeys, setSelectedRowKeys] = useState([])
let [searchParams, setSearchParams] = useState({...initSearchParams} as any)
let [firmsearchParams, setFirmsearchParams] = useState({...initFirmSearchParams} as any)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
const [loadFlag, setLoadFlag] = useState(false)
const [modalLoading, setModalLoading] = useState(false)
const [tableLoading, setTableLoading] = useState(false)
const [showModal, setShowModal] = useState(false)
const [formInitials, setFormInitials] = useState({})
const [modalFlag, setModalFlag] = useState('new')
const [showSubmodal, setShowSubmodal] = useState(false)
const [submodalFlag, setSubmodalFlag] = useState('new')
const [currentSubrow, setCurrentSubrow] = useState({} as any)
const [subformInitials, setSubformInitials] = useState({})
const [submodalLoading, setSubmodalLoading] = useState(false)
const [subrowList, setSubrowList] = useState([])
const [subtableLoading,setSubtableLoading] = useState(false)
const [asList,setAsList] = useState([])
const [relsubrowList, setRelsubrowList] = useState([])
const [relsubtableLoading, setRelsubtableLoading] = useState(false)
let [companyList,setCompanyList] = useState([])

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '名称', prop:'filter_LIKES_name'}
]
//详情中搜索行
const firmsearchList:FormItemObj[] = [
{label: '公司', prop:'companyId',type:'select',dataList:companyList},
{label: '名称', prop:'namelikes'}
]
//辅助核算列
const columns:ColumnItem[] = [
{title: '名称',key:'name',type:'underline',unlineProps:{action:({row,value}:any)=>{
  setModalFlag(()=>'update')
  setCurrentRow(()=>({...row}))
  setFormInitials(()=>({...row}))
  setShowModal(()=>true)
  uniAxList(dataSubrow(row.id))
  uniAxList(dataRelsubrow(row.id))
}}},
// {title: '编码', key:'code'},
// {title: 'ID', key:'id'},
{title: '类型', key:'type',custval: ({row,value}:any)=>uniValueToName({list:csAffixTypes,value})},
{title: '创建时间',key:'createDate'},
{title: '操作',key:'operation',type: 'operation',width:60,opProps:{opList:[
  {name:'删除',show:({row}:any)=>row.type===3,action:({row}:any) => uniOpAlarm(()=>uniAxList(dataDelrow(row.id)))}
]}}
]
//辅助核算详情列
const subColumns:ColumnItem[] = [
{title: '编码', key:'no'},
{title: '公司', key:'companyName'},
{title: '名称', key:'name'},
{title: '操作',key:'operation',type: 'operation',width:120,opProps:{opList:[
  {name:'修改',show:({row}:any)=>currentRow.type===3,action:({row}:any) => {
    setSubmodalFlag(()=>'update')
    setCurrentSubrow(()=>({...row}))
    setSubformInitials(()=>({...row}))
    setShowSubmodal(()=>true)
  }},
  {name:'删除',show:({row}:any)=>currentRow.type===3,action:({row}:any) => uniOpAlarm(()=>uniAxList(dataDelSubrow(row.id)))}
]}}
]
//关联科目列表
const relsubColumns:ColumnItem[] = [
{title: '科目编码', key:'subjectNo'},
{title: '科目ID', key:'subjectId'},
{title: '科目名称', key:'subjectName'},
{title: '操作',key:'operation',type: 'operation',width:120,opProps:{opList:[
  {name:'删除',action:({row}:any) => uniOpAlarm(()=>uniAxList(dataDelAsBindAffix(row.id)))}
]}}
]
//底部操作
const bottomActions:ColumnAction[] = [
{label: '新建辅助核算', type:'primary', action: () => {
  setModalFlag(()=>'new')
  setFormInitials(()=>({...initFormdata}))
  setShowModal(()=>true)
}},
{label: '同步客户和供应商', type:'primary', action: () => {
  uniOpAlarm(()=>uniAxList(dataAsynCustAndSupplier()))
}},
]
//添加和修改中的modal项
const formItems:FormItemObj[] = [
{label: '名称',prop: 'name'}
]
//详情modal项
const subformItems:FormItemObj[] = [
{label: '编号', prop:'no'},
{label: '公司', prop:'companyId',type:'select',dataList:companyList,selectProps:{requireName:'companyName'}},
{label: '名称',prop: 'name'}
]
//关联科目modal项
const relsubformItems:FormItemObj[] = [
{label: '科目', prop:'subjectId',type:'tree',span:24,dataList:asList,treeProps:{propName:'title', checkable:true,strictMode:true}}
]

/* funcs */
const searchConfirm = async (formValus:any) => {
  setPagination({...pagination,current:1})
  searchParams = {...initSearchParams,...formValus}
  setSearchParams(searchParams)
  uniAxList(dataRow(1))
}
const firmsearchConfirm = (formValus:any) => {
  firmsearchParams = {...initFirmSearchParams,...formValus}
  setFirmsearchParams(firmsearchParams)
  uniAxList(dataSubrow(currentRow.id,formValus.namelikes))
}
//点击添加详情
const onAddsub = (flag:string) => {
  setSubmodalFlag(()=>flag)
  setSubformInitials(()=>({...initSubformdata,subjectId:relsubrowList.map((item:any)=>item.subjectId)}))
  setShowSubmodal(()=>true)
}
//submodal点击确认
const onSubmodalConfirm = async (formValues:any) => {
  if (submodalFlag==='new') {
    let tempparms = await uniFormProcess(formValues,subformItems)
    uniAxList(dataNewSubrow(tempparms))
  }
  else if (submodalFlag==='update') uniAxList(dataUpdateSubrow(formValues))
  else if (submodalFlag==='relnew') {
    let temparr = [...relsubrowList.map((item:any)=>({accountingId:currentRow.id,accountingName:currentRow.name,subjectId:item.subjectId})),...formValues.subjectId.map((iditem:any)=>({accountingId:currentRow.id,accountingName:currentRow.name,subjectId:iditem.value}))]
    uniAxList(dataAsBindAffix(temparr))
  }
}
//详情中快递公司搜索
const companyChange = () => {

}

/* 接口参数 */
const dataRow = (current?:number):AxDataform => ({
  params: {...searchParams, current: current || pagination.current, size:pagination.pageSize},
  axApi: axAffixSubjectList,
  setList: setRowList,
  setTableLoading,
  setPagination
})
//新建辅助核算
const dataNewrow = (params:any):AxDataform => ({
  params,
  axApi: axCreateAffixSubject,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//修改辅助核算
const dataUpdaterow = (params:any):AxDataform => ({
  params: {...params, id:currentRow.id},
  axApi: axUpdateAffixSubject,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//删除辅助核算
const dataDelrow = (id:any):AxDataform => ({
  params: {id},
  axApi: axDeleteAffixSubject,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//同步客户和供应商
const dataAsynCustAndSupplier = ():AxDataform => ({
  axApi: axAsycCustAndSupplier,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//辅助核算详情列表
const dataSubrow = (id:any,name?:any):AxDataform => ({
  params: {...firmsearchParams,accountingId: id},
  axApi: axAffixSubjectDetail,
  setList: setSubrowList,
  setTableLoading: setSubtableLoading,
  awaitFunc: (predata:any) => {
    if (name) setSubrowList(predata.filter((item:any)=>item.name.includes(name)))
    else setSubrowList(predata)
  }
})
//新建辅助核算详情
const dataNewSubrow = (params:any):AxDataform => ({
  params: {...params, accountingId:currentRow.id},
  axApi: axAddAffixDetail,
  setModalLoading:setSubmodalLoading,
  setShowModal:setShowSubmodal,
  awaitFunc: () => uniAxList(dataSubrow(currentRow.id))
})
//修改辅助核算详情
const dataUpdateSubrow = (params:any):AxDataform => ({
  params: {...params,id:currentSubrow.id},
  axApi: axUpdateAffixDetail,
  setModalLoading:setSubmodalLoading,
  setShowModal:setShowSubmodal,
  awaitFunc: () => uniAxList(dataSubrow(currentRow.id))
})
//删除辅助核算详情
const dataDelSubrow = (id:any):AxDataform => ({
  params: {id},
  axApi: axDeleteAffixDetail,
  awaitFunc: (predata:any) => uniAxList(dataSubrow(currentRow.id))
})
//辅助核算对应会计科目
const dataRelsubrow = (accountid:any):AxDataform => ({
  params: {filter_EQS_accounting_id: accountid},
  axApi: axAffixExSubjectList,
  modifyFunc: uniModifyAxList,
  modifyData: {custFunc:(rowitem:any)=>{
    let findItem:any = uniFilterItem(asList,'id',rowitem.subjectId)
    console.log('finditem----------',findItem,rowitem.subjectId)
    if (findItem) {
      rowitem.subjectNo = findItem.subjectNo
      rowitem.subjectName = findItem.name
    }
  }},
  setList: setRelsubrowList,
  setTableLoading: setRelsubtableLoading,
  awaitFunc: (predata:any) => console.log('predata--------',predata)
})

//辅助核算添加会计科目-先查会计科目，然后再会计科目中添加辅助核算
const dataAffixAddSubject = (subjectid:any):AxDataform => ({
  params: {filter_EQS_subject_id: subjectid},
  axApi: axAffixExSubjectList,
  setModalLoading:setSubmodalLoading,
  finishLoading:false
})
//会计科目绑定辅助核算
const dataAsBindAffix = (affixlist:any):AxDataform => ({
  params: affixlist,
  axApi: axCreateAffixExSubject,
  setModalLoading:setSubmodalLoading,
  awaitFunc: () => {
    uniAxList(dataRelsubrow(currentRow.id))
    setShowSubmodal(()=>false)
  }
})
//删除会计科目绑定
const dataDelAsBindAffix = (bindid:any):AxDataform => ({
  params: {id:bindid},
  axApi: axDeleteAffixExSubject,
  awaitFunc: () => uniAxList(dataRelsubrow(currentRow.id))
})

//会计科目
const dataAs:any = {
  modifyFunc: uniModifyAxList,
  modifyData: {titleList: ['title','subjectNo','name']},
  params: {page:1, size:999},
  axApi: axSubjectList,
  setList: setAsList
}
//公司列表
const dataCompany:AxDataform = {
  axApi: getCompanyList,
  setList: setCompanyList
}

/* useEffect */
//路由刷新请求
const routerFlash = () => {
  uniAxList(dataRow())
  uniAxList(dataCompany)
}
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])

/* mounted */
React.useEffect(() => {
  uniAxList(dataRow())
  uniAxList(dataAs)
  uniAxList(dataCompany)
  setLoadFlag(()=>true)
}, [])

return (
  <>
    <TopSearch
      ref={searchRef}
      searchList={searchList}
      searchConfirm={searchConfirm}
    />
    <SuperTable
      columns={columns}
      rowList={rowList}
      bottomActions={bottomActions}
      tableLoading={tableLoading}
      pagination={pagination}
      dataRow={dataRow}
    />
    <FormDialog
      ref={modalRef}
      type={modalFlag==='new'?'modal':'drawer'}
      title={modalFlag==='new'?'添加辅助核算':'修改辅助核算'}
      show={showModal}
      showCustom={modalFlag==='update'?true:false}
      formItems={formItems}
      modalLoading={modalLoading}
      formInitials={formInitials}
      hasConfirm={!(modalFlag==='update' && currentRow.type && [1,2].includes(currentRow.type))}
      cancel={()=>{
        setShowModal(()=>false)
        setCurrentRow({})
        setFirmsearchParams({...initFirmSearchParams})
      }}
      confirm={(formValues:any)=>modalFlag==='new'?uniAxList(dataNewrow(formValues)):uniAxList(dataUpdaterow(formValues))}
    >
      <span></span>
      <>
        <div style={{display:'flex',alignItems:'center'}}>
          <span style={{marginRight:10}}>辅助核算详情</span>
          <TopSearch
            ref={firmsearchRef}
            searchList={firmsearchList}
            searchConfirm={firmsearchConfirm}
          />
          {currentRow.type===3 ? <Button type='primary' size='small' onClick={()=>onAddsub('new')}>添加详情</Button>:null}
        </div>
        <SuperTable
          columns={subColumns}
          rowList={subrowList}
          tableLoading={subtableLoading}
        />
      </>
      {/* <div style={{height:'100%',display:'flex',flexDirection:'column'}}>
        <div style={{display:'flex',alignItems:'center'}}>
          <span style={{marginRight:10}}>辅助核算详情</span>
          <TopSearch
            ref={firmsearchRef}
            searchList={firmsearchList}
            searchConfirm={firmsearchConfirm}
          />
          {currentRow.type===3 ? <Button type='primary' size='small' onClick={()=>onAddsub('new')}>添加详情</Button>:null}
        </div>
        <SuperTable
          columns={subColumns}
          rowList={subrowList}
          tableLoading={subtableLoading}
        />
        <div>
          <span style={{marginRight:10}}>关联会计科目</span>
          <Button type='primary' size='small' onClick={()=>onAddsub('relnew')}>添加关联科目</Button>
        </div>
        <SuperTable
          columns={relsubColumns}
          rowList={relsubrowList}
          tableLoading={relsubtableLoading}
        />
      </div> */}
    </FormDialog>
    <FormDialog
      ref={submodalRef}
      title={submodalFlag==='new'?'添加辅助详情':submodalFlag==='update'?'修改辅助详情':submodalFlag==='relnew'?'添加关联科目':''}
      show={showSubmodal}
      formItems={submodalFlag==='relnew'?relsubformItems:subformItems}
      modalLoading={submodalLoading}
      formInitials={subformInitials}
      cancel={()=>setShowSubmodal(()=>false)}
      confirm={(formValues:any)=>onSubmodalConfirm(formValues)}
    />
  </>
)
}
