import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormInstance, Input, TablePaginationConfig, Tooltip, Typography } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';

import { createMaterial, deleteMaterial, getCompanyList, getMaterialLevel1, getMaterialLevel2, getMaterialList, updateMaterial } from '../../utils/api'
import { uniAddRowSubmit, uniAxList, uniAxSearchList, uniChgRowSubmit, uniCurrentTime, uniDatePikcerChange, uniDeepArray, UniDeleteAll, uniDeleteRow, uniExportExcel, uniExportToExcel, uniFormProcess, uniInitFormdata, uniModifyAxList, uniModifyRow, uniOpAlarm, uniQuitModal, uniSelectRows, uniStaticLast } from '../../utils/uniFucs'
import FormModal from '../../components/FormModal'
import { materialTypes, materialTypesDetail } from '../../utils/constant'
import { NameValue } from '../../utils/beans'
import tablecss from '../../css/custable.module.less'
import TopSearch from '../../components/TopSearch';
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../utils/interfaces';
import SuperTable from '../../components/SuperTable';
import FormDialog from '../../components/FormDialog';

export default function StockIn() {
/* constant */
const pathname = "/indexpage/stockin"
const modalRef = createRef<FormInstance>()
const searchRef = createRef<FormInstance>()
const initSearchParams = {page:1}
const initForminitial = {}
/* hooks */
let location = useLocation()
const navigate = useNavigate()
const [companyList, setCompanyList] = useState([])
const [logList, setLogList] = useState([])
const [rowList, setRowList] = useState([])
const [currentRow, setCurrentRow] = useState({} as any)
let [searchParams, setSearchParams] = useState({page:1,orderBy:"create_date", orderDir: "desc"} as any)
const [tempSearchParams, setTempSearchParams] = useState({page:1,orderBy:"create_date", orderDir: "desc"} as any)
const [staffList, setStaffList] = useState([])
const [feeTypeList, setFeeTypeList] = useState([])
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
const [materialList, setMaterialList] = useState([])
const [loadFlag, setLoadFlag] = useState(false)
const [modalLoading, setModalLoading] = useState(false)
const [exportLoading, setExportLoading] = useState(false)
const [staticLoading,setStaticLoading] = useState(false)
const [modalFlag, setModalFlag] = useState('new')
const [showModal, setShowModal] = useState(false)
let [formInitials, setFormInitials] = useState({...initForminitial})
const [levelList1, setLevelList1] = useState([])
const [levelList2, setLevelList2] = useState([])
const [plusModalKeys, setPlusModalKeys] = useState({} as any)
/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '名称', prop:'filter_LIKES_name'},
{label: '公司', prop:'filter_EQS_company_id',type:'select',dataList:companyList},
]
//列项
const columns:ColumnItem[] = [
{title: '物料编码', key:'code'},
{title: '物料名称',key: 'name'},
{title: '所属公司',key: 'companyName'},
{title: '物料单位',key: 'unit'},
{title: '当前库存',key: 'stock'},
{title: '初始库存',key: 'initialStock'},
{title: '采购价格',key: 'buyPrice',custval:({row,value}:any) => value ? (value/100).toFixed(2) : 0},
// {title: '销售价格',key: 'salePrice',custval:({row,value}:any) => value ? (value/100).toFixed(2) : 0},
{title: '物料排序号',key: 'orderNo'},
{title: '库存启用日期',key: 'startDate'},
{title: '备注',key: 'remark'},
{title: '操作',key:'operation',type: 'operation',width:100,opProps:{opList:[
  {name:'修改',action:({row}:any) => {
    setModalFlag('update')
    setCurrentRow(row)
    setFormInitials(uniInitFormdata(row,formItems))
    setShowModal(true)
    uniAxList(dataLevel2(row.levelOneId))
  }},
  {name:'删除',action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataDelrow(row.id)))}
]}}
]
//底部操作
const bottomActions:ColumnAction[] = [
{label: '新增', type:'primary', action: () => {setModalFlag('new');setShowModal(() => true)}},
{label: '导出当前页', type:'primary', action: () => uniExportExcel({columns, rowList: uniDeepArray(rowList), modifyFunc:uniModifyAxList, modifyData:exportModify, title:'物料库存设置', fileName: '物料库存设置'})},
{label: '导出所有页', type:'primary',loading:exportLoading,action: () => uniExportExcel({columns,setExportLoading, axApi:getMaterialList, params:{...searchParams,page:null}, modifyFunc:uniModifyAxList, modifyData:{...dataRow().modifyData, ...exportModify},title:'物料库存设置',fileName: '物料库存设置'+uniCurrentTime({})})}
]
//添加和修改中的modal项
const formItems:FormItemObj[] = [
{label: '编码',prop: 'code',type:'slot',slotProps:<Input.Search size='small' type={'number'}
  placeholder="输入编码"
  enterButton="自动生成"
  style={{width: '100%'}}
  onSearch={async (value) => {
    let res = await getMaterialList({orderBy:"code", orderDir: "desc",page:1, size:1})
    let maxcode = 0
    if (res.data.records.length > 0) maxcode = Number(res.data.records[0].code)
    let newcode = (maxcode+1).toString().padStart(7, '0')
    modalRef.current?.setFieldsValue({code: newcode})
  }}
  onBlur={(e) => {
    modalRef.current?.setFieldsValue({code: e.target.value.toString().padStart(7, '0')})
  }}/>
},
{label: '名称',prop: 'name'},
{label: '所属公司',prop: 'companyId',type:'select',dataList:companyList,selectProps:{requireName:'companyName'},change:(value:any)=>{
  if (modalFlag==='new') {
    let finditem:any = companyList.find((item:any)=>item.id===value)
    modalRef.current?.setFieldsValue({startDate: (finditem.beginYm?moment(finditem.beginYm):null)})
  }
}},
{label: '一级类目',prop: 'levelOneId',type:'select',dataList:levelList1,change:(value:any)=>{
  modalRef.current?.setFieldsValue({levelTwoId:null})
  uniAxList(dataLevel2(value))
}},
{label: '二级类目',prop: 'levelTwoId',type:'select',dataList:levelList2,change:(value:any)=>{
  if (value) {
    let finditem:any = levelList2.find((item:any)=>item.id===value)
    if (finditem) modalRef.current?.setFieldsValue({unit:finditem.unit})
  }
}},
{label: '单位',prop: 'unit'},
{label: '初始库存',prop: 'initialStock',type:'number', numProps: {min:0,precision:0}},
{label: '当前库存',prop: 'stock',type:'number',numProps: {min:0,precision:0},show:modalFlag==='update'},
{label: '采购价格',prop: 'buyPrice',type:'price',numProps: {min:0,precision:2}},
{label: '销售价格',prop: 'salePrice',type:'price',numProps: {min:0,precision:2}},
{label: '库存启用时间',prop: 'startDate',type:'datePicker',disabled:true,show:modalFlag==='new',datepickerProps:{type:'month',returnType:'string',disabledDate:(current)=>false}},
{label: '排序号',prop: 'orderNo',type:'number',required:false,numProps: {min:0,precision:0}},
{label: '备注',prop: 'remark',span:24,required:false}
]

const exportModify = {
  notNullColumns: columns,
  splitTime:['startDate'],
  checkStatus:[
    {newKey:'type', checkKey: 'type', sourceList:materialTypes, sourceCheckKey:'value', sourceValueKey:'name'},
  ]
}

/* funcs */
const searchConfirm = async (formValus:any) => {setPagination({...pagination,current:1});
  // searchParams = {...initSearchParams,...formValus}
  // setSelectedRows([])
  // setSelectedRowKeys([])
  searchParams = await uniFormProcess({...initSearchParams,...formValus},searchList)
  setSearchParams(searchParams)
  uniAxList(dataRow(1))
}
//modal点击确定
const modalConfirm = async (formValues:any) => {
  let tempitems = modalFlag === 'pay'?[]:formItems
  let datafuc = modalFlag === 'new'? dataNewrow : dataUpdaterow
  let tempparms = await uniFormProcess(formValues,tempitems)
  if (modalFlag==='new') tempparms = {...tempparms,stock: tempparms.initialStock}
  uniAxList(datafuc({...tempparms,...plusModalKeys}))
  // modalFlag==='new'?uniAxList(dataNewrow(await uniFormProcess(formValues,formItems))):uniAxList(dataUpdaterow(await uniFormProcess(formValues,formItems)))
}
//modal取消
const modalCancel = () => {
  setShowModal(()=>false)
  setPlusModalKeys([])
  setCurrentRow({})
  setLevelList2([])
  setFormInitials({...initForminitial})
}
//路由刷新请求
const routerFlash = () => {
  uniAxList(dataRow())
  uniAxList(uniCompanyData)
  uniAxList(uniMaterialData)
}

/* 接口参数 */
const dataRow = (current?:number):AxDataform => ({
  params: {...searchParams, current:current || pagination.current, size:pagination.pageSize},
  axApi: getMaterialList,
  setList: setRowList,
  setTableLoading,
  setPagination
})
//物料一级类目
const dataLevel1:AxDataform = {
  axApi: getMaterialLevel1,
  setList: setLevelList1
}
//物料二级类目
const dataLevel2 = (parentId:any):AxDataform => ({
  params: {parentId},
  axApi: getMaterialLevel2,
  setList: setLevelList2
})
//新建行
const dataNewrow = (params:any):AxDataform => ({
  params,
  axApi: createMaterial,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//修改行
const dataUpdaterow = (params:any):AxDataform => ({
  params: {...params, id:currentRow.id},
  axApi: updateMaterial,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//删除行
const dataDelrow = (rowid:any):AxDataform => ({
  params: {id:rowid},
  axApi: deleteMaterial,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})

const uniCompanyData:any = {
  axApi: getCompanyList,
  setList: setCompanyList,
}
const uniMaterialData:any = {
  axApi: getMaterialList,
  setList: setMaterialList,
}
/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])
/* mounted */
React.useEffect(() => {
  uniAxList(dataRow())
  uniAxList(uniCompanyData)
  uniAxList(uniMaterialData)
  uniAxList(dataLevel1)
  setLoadFlag(()=>true)
}, [])

return (<>
<TopSearch
  ref={searchRef}
  initialValues={initSearchParams}
  searchList={searchList}
  searchConfirm={searchConfirm}
/>
<SuperTable
  columns={columns}
  rowList={rowList}
  bottomActions={bottomActions}
  tableLoading={tableLoading}
  pagination={pagination}
  dataRow={dataRow}
/>
<FormDialog
  ref={modalRef}
  title={modalFlag==='new'?'新增物料':'修改物料'}
  show={showModal}
  formItems={formItems}
  modalLoading={modalLoading}
  formInitials={formInitials}
  cancel={modalCancel}
  confirm={modalConfirm}
/>
</>)}
