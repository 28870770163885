import React, { createRef, useState } from 'react'
import { Button, ConfigProvider, FormInstance, Input, Modal, Pagination, Select, Table, TablePaginationConfig, Tooltip, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import locale from 'antd/es/locale/zh_CN';

import { getCompanyAccoutList, getCompanyList, getStaffBillList, getStaffDetailList } from '../../../utils/api'
import { uniAxList, uniAxSearchList, uniCurrentTime, uniDeepArray, uniExportExcel, uniModifyAxList, uniSelectRows, uniStaticLast, uniTableChange } from '../../../utils/uniFucs'
import SummeryRow from '../../../components/SummeryRow'
import { balanceStatus, bankTypeList, postStatus } from '../../../utils/constant'
import { CompanyAccountItem } from '../../../utils/beans'
import { useLocation, useNavigate } from 'react-router-dom'
import tablecss from '../../../css/custable.module.less'

export default function UserBalance() {
  const pathname = "/indexpage/userbalance"
  /* hooks */
  const navigate = useNavigate()
  let location = useLocation()
  const formModalRef = createRef<FormInstance>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [staticModal, setStaticModal] =useState(false)
  const [companyList, setCompanyList] = useState([])
  const [rowList, setRowList] = useState([])
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({showRealName:true, showCardNo:true, showBeginBalance:true, showBeginTime:true})
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  // const [currentRow, setCurrentRow] = useState({} as UserBillItem)
  const [searchParams, setSearchParams] = useState({page:1} as any)
  const [tempSearchParams, setTempSearchParams] = useState({page:1} as any)
  const [staffList, setStaffList] = useState([])
  const [reinburseTypeList, setReinburseTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [staffAccounts, setStaffAccounts] = useState([])
  const [loadFlag, setLoadFlag] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [staticLoading,setStaticLoading] = useState(false)
  const [billModal, setBillModal] = useState(false)
  const [billRowList,setBillRowList] = useState([])
  //路由刷新请求
  const routerFlash = () => {
    uniAxList(uniRowData)
    uniAxList(uniCompanyData)
  }
  /* elements */
  //列项
  const columns:any = [
    {
      title: '工号',
      dataIndex: 'jobNum',
      key: 'jobNum'
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: ()=>
        <Tooltip placement="topLeft" title="点击跳转到明细">
          <span>客户账款余额</span>&nbsp;
          <InfoCircleOutlined />
        </Tooltip>,
      dataIndex: 'balance',
      key: 'balance',
      render: (value:number, record:any) => <Typography.Link onClick={()=>{
        navigate('/indexpage/userbalancedetail', {replace: true, state: {userId:record.id}})
      }}>{value.toFixed(2)}</Typography.Link>
    },
    {
      title: '面单数量',
      dataIndex: 'billNum',
      key: 'billNum'
    },
    {
      title: ()=>
      <Tooltip placement="topLeft" title="点击查看详情">
        <span>面单总额</span>&nbsp;
        <InfoCircleOutlined />
      </Tooltip>,
      dataIndex: 'billPrice',
      key: 'billPrice',
      render: (value:any,record:any)=><Typography.Link onClick={() => {
        setBillModal(()=>true)
        uniAxList({...uniBillRowData,params:{filter_EQS_user_id:record.id}})
      }}>{value}</Typography.Link>
    },
    {
      title: '所属公司',
      dataIndex: 'companyList',
      key: 'companyList'
    },
    {
      title: '部门',
      dataIndex: 'departmentName',
      key: 'departmentName'
    },
    {
      title: '在职状态',
      dataIndex: 'status',
      key: 'status'
    },
  ]

  //统计列
  const staticColumns:any = [
    {
      title: '',
      dataIndex: 'rowName',
      key: 'rowName'
    },
    {
      title: '小计',
      dataIndex: 'total',
      key: 'total',
      render: (value:any) => (value/100).toFixed(2),
      addValue: (record:any) => record.balance
    },
  ]

  //统计行
  const staticRows = [
    {rowName: '总余额', checkKey: 'staticTotal',total:0}
  ]
  //导出行
  const exportColumns = [
    ...columns.slice(0,-1),
    {
      title:'客户账款余额',
      dataIndex: 'balance',
      key: 'balance',
    }
  ]
  //导出当前页，需要的数据修饰
  const exportModify:any = {
    notNullColumns: columns,
  }

  /* 接口参数 */
  //个人账户
  const uniRowData:any = {
    params: {...searchParams, current:pagination.current, size:pagination.pageSize},
    axApi: getStaffDetailList,
    modifyFunc: uniModifyAxList,
    modifyData: {
      extractList:[{key:'company', value:'name'}],
      divideKey:[{newKey: 'balance', key:'balance', value: 100},{newKey: 'billPrice', key:'billPrice', value: 100}],
      checkStatus:[{newKey:'status', checkKey: 'status', sourceList:postStatus, sourceCheckKey:'value', sourceValueKey:'name'}],
    },
    setList: setRowList,
    setTableLoading,
    pagination
  }
  const uniCompanyData:any = {
    axApi: getCompanyList,
    setList: setCompanyList,
  }
  const uniBillRowData:any = {
    axApi: getStaffBillList,
    setList: setBillRowList
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    uniAxList(uniRowData)
    uniAxList(uniCompanyData)
    setLoadFlag(()=>true)
  }, [])

  return (
    <>
      <Input
        allowClear
        style={{ width: 200 }}
        placeholder="账户名称"
        onChange={(e) => setTempSearchParams(() => ({...tempSearchParams, filter_LIKES_name:e.target.value || null}))}
      ></Input>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="账户类型"
        onChange={(accountType) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_bank_type:accountType || null}))}
      > 
        {bankTypeList.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Input
        allowClear
        style={{ width: 200 }}
        placeholder="账户名卡号"
        onChange={(e) => setTempSearchParams(() => ({...tempSearchParams, filter_LIKES_card_no:e.target.value || null}))}
      ></Input>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="所属公司"
        onChange={(companyId) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_company_id:companyId || null}))}
      > 
        {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="余额正负"
        onChange={(balanceStatus) => setTempSearchParams(() => {
          if (balanceStatus === -1) return {...tempSearchParams, filter_LTL_balance:0, filter_EQL_balance:null, filter_GTL_balance:null}
          else if (balanceStatus === 0) return {...tempSearchParams, filter_LTL_balance:null, filter_EQL_balance:0, filter_GTL_balance:null}
          else if (balanceStatus === 1) return {...tempSearchParams, filter_LTL_balance:null, filter_EQL_balance:null, filter_GTL_balance:0}
          else return {...tempSearchParams, filter_LTL_balance:null, filter_EQL_balance:null, filter_GTL_balance:null}
        })}
      > 
        {balanceStatus.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Button onClick={() => {pagination.current=1;uniAxSearchList({setSearchParams, uniRowData, params: {...tempSearchParams,size:pagination.pageSize}})}} type='primary'>查询</Button>
      <p></p>
      <p>
        <Button type="primary" onClick={() => uniStaticLast({
          axApi:getStaffDetailList,
          params:{...searchParams, page:null},
          staticColumns,
          staticRows,
          setStaticModal,
          setStaticTableData,
          setStaticLoading
        })}>统计所有页数据</Button> &nbsp;
        <Button type="primary" onClick={() => uniExportExcel({columns:exportColumns, rowList: uniDeepArray(rowList),title:'账户余额', fileName: '账户余额'+uniCurrentTime({})})}>导出当前页</Button> &nbsp;
        <Button type="primary" loading={exportLoading} onClick={() => uniExportExcel({columns:exportColumns,setExportLoading, axApi:getStaffDetailList, params:{...searchParams, page:null}, modifyFunc:uniModifyAxList, modifyData:{...uniRowData.modifyData,...exportModify},title:'账户余额',fileName: '账户余额'+uniCurrentTime({})})}>导出所有页码数据</Button> &nbsp;
      </p>
      <Table
        size='small'
        className={tablecss.height280}
        rowKey={'id'}
        columns={columns}
        // rowSelection={{ ...uniSelectRows(setSelectedRowKeys, setSelectedRows), checkStrictly:true }}
        dataSource={rowList}
        pagination={false}
        scroll={{y:'calc(100vh - 300px)'}}
        loading={tableLoading}
        // onChange={(newPagination)=>{pagination.pageSize = newPagination.pageSize;pagination.current=newPagination.current ;uniAxList({...uniRowData,params: {...searchParams, current:pagination.current, size:pagination.pageSize}})}}
        sticky
        summary={(pageData) => <SummeryRow pageData={pageData} sumInfo={[{mapIndex:2, mapKeys:['balance'], sum:0},{mapIndex:3, mapKeys:['billNum'], sum:0},{mapIndex:4, mapKeys:['billPrice'], sum:0}]} total={columns.length} title={'小计'} />}
      />
      <div style={{textAlign:'right'}}>
        <p></p>
        <ConfigProvider locale={locale}>
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.current}
            total={pagination.total}
            showSizeChanger
            showQuickJumper
            showTotal={total => `共 ${total} 条`}
            onChange={(pageNumbe)=>{
              pagination.current = pageNumbe
              uniAxList({...uniRowData,params:{...searchParams, current:pageNumbe, size:pagination.pageSize}})
            }}
            onShowSizeChange={(current, pageSize)=>{
              pagination.pageSize = pageSize
              uniAxList({...uniRowData,params:{...searchParams, current:pagination.current, size:pageSize}})
            }}
          />
        </ConfigProvider>
      </div>
      <Modal visible={staticModal} title="统计" destroyOnClose onCancel={()=>setStaticModal(()=>false)} footer={[<Button key="back" onClick={()=>setStaticModal(()=>false)}>取消</Button>]}>
        <Table
          rowKey={'rowName'}
          columns={staticColumns}
          dataSource={staticTableData}
          pagination={false}
          loading={staticLoading}
        />
      </Modal>
      <Modal
        width={600}
        maskClosable={false}
        title={'面单价格'}
        visible={billModal}
        onCancel={()=>setBillModal(false)}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>setBillModal(false)}>取消</Button>]}
      >
        <Table
          rowKey={'id'}
          columns={[
            {title: '公司',dataIndex: 'companyName',key: 'companyName'},
            {title: '面单数量',dataIndex: 'billNum',key: 'billNum'},
            {title: '面单单价',dataIndex: 'singlePrice',key: 'singlePrice',render:(value,record:any)=>record.billNum ? ((record.billPrice)/(100*record.billNum)).toFixed(2) :'-'},
            {title: '面单总价',dataIndex: 'billPrice',key: 'billPrice',render:(value)=>(value / 100).toFixed(2)},
          ]}
          dataSource={billRowList}
          pagination={false}
          sticky
        />
      </Modal>
    </>
  )
}
