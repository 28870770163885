import React, { useState } from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';

import * as echarts from 'echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import { uniAxList, uniCurrentTime } from '../utils/uniFucs';
import { getHomeFindataYear } from '../utils/api';

type SourceDataType = {
  problemName: string;
  /** 热门问题火热度 */
  hotValue: number;
}[];
//https://blog.csdn.net/glorydx/article/details/105140604 //react+ts 引入echarts
//https://blog.csdn.net/weixin_43913262/article/details/115748090 // 左右双刻度如何对齐显示
export default function MainEcharts (props:any) {
  const {finYearData,year} = props
  
  // const monthIndex = Number(uniCurrentTime({day:false}).split('-')[1])
  // let minY:number = Math.min(...finYearData.incomeList,...finYearData.costList)
  // let maxY:number = Math.max(...finYearData.incomeList,...finYearData.costList)
  // let minProfitRate:number = Math.min(...finYearData.profitRateList)
  // let maxProfitRate:number = Math.max(...finYearData.profitRateList)
  // let isPreyear = year < uniCurrentTime({day:false})
  /* elements */
  const getOption = () => {
    return {
      title: {text: year+'年度利润分析表', x:'center',y:'px',textAlign:'left',textStyle:{color:'#3A7BD5',fontSize:16}},
      grid: {y: 70,x: 78},
      legend: {data: ['营业总收入(万元)', '营业总成本(万元)', '净利润额(万元)', '净利润率(%)'], x:'center',y:'bottom'},
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月','8月','9月','10月','11月','12月','-'],
        // data: isPreyear ? ['1月', '2月', '3月', '4月', '5月', '6月', '7月','8月','9月','10月','11月','12月','-']:['1月', '2月', '3月', '4月', '5月', '6月', '7月','8月','9月','10月','11月','12月'].slice(0,monthIndex+1),
      },
      yAxis: [
        {type: 'value',name:'单位(万元)', splitNumber: 6, splitLine:{show:false},},
        {type: 'value',splitNumber: 6, splitLine:{show:false},axisLabel: {formatter: (value:any)=>value+'%'}}
      ],
      series: [
        {
          name: '营业总收入(万元)',
          type: 'bar',
          // stack: 'Total', //配置statck会堆叠在一起
          yAxisIndex: 0,
          barGap: 0,
          data: finYearData.incomeList
        },
        {
          name: '营业总成本(万元)',
          type: 'bar',
          // stack: 'Total',
          yAxisIndex: 0,
          data: finYearData.costList
        },
        {
          name: '净利润额(万元)',
          type: 'bar',
          // stack: 'Total',
          yAxisIndex: 0,
          data: finYearData.profitList
        },
        {
          name: '净利润率(%)',
          type: 'line',
          // stack: 'Total',
          yAxisIndex: 1,
          data: finYearData.profitRateList
        },
      ],
      tooltip: {trigger: 'axis'},
    }
  }
  return (
    <ReactEchartsCore
      style={{ background: '#fff', height: '376px', maxWidth:'65vw' }}
      echarts={echarts}
      option={getOption()}
    />
  );
};
