import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, Form, FormInstance, Input, InputNumber, message, Modal, Pagination, Popconfirm, Radio, Select, Table, TablePaginationConfig, TreeSelect, Typography } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { authMenu, createRole, deleteMenu, deleteRole, axSubjectList, getAppMenuTree, getMenuTree, getRoleAuth, getRoleList, getSiteInfo, updateMenu, updateRole, updateSite } from '../../utils/api'
import { getUserLocal, setLocalItem } from '../../utils/localstorage'
import { uniAddRowSubmit, uniAxList, uniAxSearchList, uniChgRowSubmit, UniDeleteAll, uniDeleteRow, uniFilterItem, uniModifyAxList, uniModifyRow, uniQuitModal, uniRemoveItem, uniSelectRows } from '../../utils/uniFucs'
import FormModal from '../../components/FormModal'
import ImageUpload from '../../components/ImageUpload'
import { banOrNot, loginScopeList, menuObj, onOrOff } from '../../utils/constant'
import { NameValue } from '../../utils/beans'
import TextArea from 'antd/lib/input/TextArea'
import tablecss from '../../css/custable.module.less'

export default function UserPrivilege() {
  const pathname = "/indexpage/userprivilege"
  /* hooks */
  let location = useLocation()
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [logModal, setLogModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [staticModal, setStaticModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [logList, setLogList] = useState([])
  const [rowList, setRowList] = useState([])
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({menuIds:true,appIds:true} as any)
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [currentRow, setCurrentRow] = useState({} as any)
  const [searchParams, setSearchParams] = useState({page:1,orderBy:"sort", orderDir: "asc"} as any)
  const [tempSearchParams, setTempSearchParams] = useState({page:1,orderBy:"sort", orderDir: "asc"} as any)
  const [staffList, setStaffList] = useState([])
  const [feeTypeList, setFeeTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
  const [accountList, setAccountList] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [unCashRows, setUnCashRows] = useState([] as any)
  const [isUnderLinePay, setIsUnderLinePay] = useState(true)
  const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
  const [cancelRemark, setCancelRemark] = useState('')
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [positionList, setPositionList] = useState([])
  const [custList, setCustList] = useState([])
  const [confirmItemVisible, setConfirmItemVisible] = useState({paytype: 2})
  const [confirmCompanyList, setConfirmCompanyList] = useState([])
  const [confirmModalInitial, setConfirmModalInitial] = useState({})
  const [currentConfirmRow, setCurrentConfirmRow] = useState({} as any)
  const [custCompanyList, setCustCompanyList] = useState([])
  const [addTableData, setAddTableData] = useState([])
  const [modalCpBusinessList, setModalCpBusinessList] = useState([])
  const [modalFeeTypeList, setModalFeeTypeList] = useState([])
  const [importModal, setImportModal] = useState(false)
  const [accountantSubjectList, setAccountantSubjectList] = useState([])
  const [businessList, setBusinessList] = useState([])
  const [currentSubjectId, setCurrentSubjectId] = useState({id:1})
  const [menuList, setMenuList] = useState([])
  const [appMenuList, setAappMenuList] = useState([])
  const [initTreeIds, setInitTreeIds] = useState([])
  const [initAppIds, setInitAppIds] = useState([])
  const [selectTreeIds, setSelectTreeIds] = useState([])
  const [loadFlag, setLoadFlag] = useState(false)
  const [tempSiteInfo, setTempSiteInfo] = useState({} as any)
  const [siteInfo, setSiteInfo] = useState({} as any)
  const [modalLoading, setModalLoading] = useState(false)

  /* funcs */
  //路由刷新请求
  const routerFlash = () => {
    uniAxList(uniRowData)
    uniAxList(uniMenuData)
  }
  //获取角色授权menu的ids
  const getAuthIds = async () => {
  }
  //修饰menuTree节点加入父级和祖级id
  const modifyMenuTree = (menuList:any) => {
    menuList.forEach((item0:any) => {
      item0.title = item0.name
      item0.parentId0 = item0.parentId
      if (item0.children.length) {
        item0.children.forEach((item1:any) => {
          item1.title = item1.name
          item1.parentId0 = item0.id
          if (item1.children.length) {
            item1.children.forEach((item2:any) => {
              item2.title = item2.name
              item2.parentId0 = item0.id
              item2.parentId1 = item1.id
              delete item2.children
            })
          } else delete item1.children
        })
      } else delete item0.children
    })
  }
  //给角色授权菜单
  const authRoleMenu = (preres:any,formvalues:any) => {
    let predata
    if (preres.length) predata = preres[0].data
    else predata = preres.data
    let axIds:any = []
    if (formvalues.menuIds.length > 0) {
      let tempIds:any = []
      formvalues.menuIds.forEach((item:any)=>{
        if (item) tempIds.push(item)
        let finditem:any = uniFilterItem(menuList,'id',item)
        if (finditem && finditem.parentId) {
          tempIds.push(finditem.parentId0)
          tempIds.push(finditem.parentId1)
        }
      })
      axIds = Array.from(new Set(tempIds))
    }
    if (formvalues.appIds && formvalues.appIds.length > 0) axIds.push(...formvalues.appIds)
    authMenu({menuIds:axIds.filter((item:any)=>item), roleId:predata.id})
  }

  //修改menuTreeSelect
  const modifyMenuTreeSelect = (checkedKeys:any) => {
    checkedKeys.forEach((item:any) => {
      let menuItem:any = uniFilterItem(menuList, 'id', item)
      if (menuItem.type === 1) uniRemoveItem(checkedKeys, item)
    })
  }
  //点击修改按钮
  const onModify = async (record:any) => {
    const res:any = await getRoleAuth({id:record.id})
    if (record.loginScope === 1) setModalItemVisible(()=>({menuIds:true,appIds:false}))
    else if (record.loginScope === 2) setModalItemVisible(()=>({menuIds:false,appIds:true}))
    else setModalItemVisible(()=>({menuIds:true,appIds:true}))

    uniModifyRow({record, setCurrentRow, setChgModal:setChgModal})
    // modifyMenuTreeSelect(res.data.checkedKeys)
    let initAppIds:any = []
    let initPcIds:any = []
    res.data.checkedKeys.forEach((resId:string) => {
      if (appMenuList.find((appItem:any)=>resId === appItem.id)) initAppIds.push(resId)
      else {
        let finditem:any = uniFilterItem(menuList,'id',resId)
        if (finditem && (!finditem.children || finditem.children.length<=0)) initPcIds.push(resId)}
    })
    setInitTreeIds(() => initPcIds)
    setInitAppIds(()=>initAppIds)
  }

  /* elements */
  //列项
  const columns:any = [
    {
      title: '角色名称',
      dataIndex: 'roleName',
      key: 'roleName'
    },
    {
      title: '是否启用',
      dataIndex: 'status',
      key: 'status',
      render: (value:string) => value === '0' ? '启用' : '禁用'
    },
    {
      title: '登录权限',
      dataIndex: 'loginScope',
      key: 'loginScope',
      render: (value:number) => {
        let tempItem:any = uniFilterItem(loginScopeList,'value',value)
        return tempItem ? tempItem.name : value
      }
    },
    {
      title: '排序',
      dataIndex: 'sort',
      key: 'sort'
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark'
    },
    {
      title: '创建时间',
      dataIndex: 'createDate',
      key: 'createDate'
    },
    {
      title: '操作',
      dataIndex: 'operation',
      fixed: 'right',
      width: 120,
      render: (_: any, record: any) => {
        return (
          <span>
            <Typography.Link onClick={() => onModify(record)} style={{ marginRight: 8 }}>修改</Typography.Link>
            <Popconfirm title={"确定删除该角色吗?"} onConfirm={() => uniDeleteRow({record,deleteApi:deleteRole,uniAxListData:uniRowData})} okText="确定" cancelText="取消">
              <a href="#">删除</a>
            </Popconfirm>
          </span>
        )
      }
    }
  ]

  //添加和修改中的model项
  const modalItems = [
    {
      label: '角色名称',
      name: 'roleName',
      rules: [{ required: true, message: '角色名称不能为空' }],
      element: <Input placeholder="请输入角色名称"/>
    },
    {
      label: '登录权限',
      name: 'loginScope',
      rules: [{ required: true, message: '登录权限不能为空' }],
      element: <Radio.Group onChange={(event)=>{
        if (event.target.value === 1) setModalItemVisible(()=>({menuIds:true,appIds:false}))
        else if (event.target.value === 2) setModalItemVisible(()=>({menuIds:false,appIds:true}))
        else setModalItemVisible(()=>({menuIds:true,appIds:true}))
      }}>
        {loginScopeList.map((item:NameValue)=><Radio key={item.value} value={item.value}>{item.name}</Radio>)}
      </Radio.Group>
    },
    {
      label: '是否启用',
      name: 'status',
      rules: [{ required: false }],
      element: <Radio.Group>
                {onOrOff.map((item:NameValue)=><Radio key={item.value} value={item.value}>{item.name}</Radio>)}
              </Radio.Group>
    },
    {
      label: '排序',
      name: 'sort',
      rules: [{ required: false }],
      element: <InputNumber style={{width:'100%'}} min={0} />
    },
    {
      label: '备注',
      name: 'remark',
      rules: [{ required: false }],
      element: <Input />
    },
    {
      label: '后台权限',
      name: 'menuIds',
      rules: [{ required: false }],
      element: <TreeSelect
                maxTagCount='responsive'
                multiple
                treeCheckable
                showCheckedStrategy={TreeSelect.SHOW_ALL}
                fieldNames={{value: 'id'} as any}
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="选择权限"
                treeData={menuList}
              />
    },
    {
      label: 'APP权限',
      name: 'appIds',
      rules: [{ required: false, message: 'APP权限不能为空' }],
      element: <Select
        mode='multiple'
        maxTagCount='responsive'
        showSearch
        optionFilterProp='children'
        allowClear
        style={{ width: '100%' }}
        placeholder="选择权限"
      > 
        {appMenuList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>
    },
  ]
  //上传modal中的item
  const importItems = [

  ]

  /* 接口参数 */
  const uniRowData:any = {
    params: {...searchParams, current:pagination.current, size:pagination.pageSize},
    axApi: getRoleList,
    setList: setRowList,
    setTableLoading,
    pagination
  }
  const uniMenuData:any = {
    modifyFunc: modifyMenuTree,
    axApi: getMenuTree,
    setList: setMenuList,
  }
  const uniAppMenuData:any = {
    axApi: getAppMenuTree,
    setList: setAappMenuList,
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    uniAxList(uniRowData)
    uniAxList(uniMenuData)
    uniAxList(uniAppMenuData)
    setLoadFlag(()=>true)
  }, [])

  return (
    <>
      <p>
      <Input
        allowClear
        style={{ width: 200 }}
        placeholder="角色名称"
        onChange={(e) => setTempSearchParams(() => ({...tempSearchParams, filter_LIKES_role_name: e.target.value || null}))}
      ></Input>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="是否启用"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_status:value || null}))
        }
      > 
        {onOrOff.map((item:NameValue)=><Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="登录权限"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQL_login_scope:value || null}))
        }
      > 
        {loginScopeList.map((item:NameValue)=><Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Button onClick={() => {pagination.current=1;uniAxSearchList({setSearchParams, uniRowData, params: {...tempSearchParams,size:pagination.pageSize}})}} type='primary'>查询</Button>
      </p>
      <p>
      <Button type="primary" onClick={() => {setModalItemVisible(()=>({menuIds:true,appIds:true}));setAddModal(()=>true)}}>新增角色</Button> &nbsp;
      <Button type="primary" danger disabled={!selectedRowKeys.length}>
        <Popconfirm title="确定批量删除吗?" onConfirm={() => UniDeleteAll({deleteKeyList:selectedRowKeys, deleteApi:deleteRole, setSelectedRowKeys, axParams:uniRowData})} okText="确定" cancelText="取消">批量删除</Popconfirm>
      </Button>
      </p>
      <Table
        size='small'
        className={tablecss.height270}
        rowKey={'id'}
        columns={columns}
        rowSelection={{ ...uniSelectRows(setSelectedRowKeys, setSelectedRows), checkStrictly:true }}
        dataSource={rowList}
        pagination={false}
        loading={tableLoading}
        scroll={{y:'calc(100vh - 300px)'}}
        sticky
      />
      <div style={{textAlign:'right'}}>
        <p></p>
        <ConfigProvider locale={locale}>
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.current}
            total={pagination.total}
            showSizeChanger
            showQuickJumper
            showTotal={total => `共 ${total} 条`}
            onChange={(pageNumbe)=>{
              pagination.current = pageNumbe
              uniAxList({...uniRowData,params:{...searchParams, current:pageNumbe, size:pagination.pageSize}})
            }}
            onShowSizeChange={(current, pageSize)=>{
              pagination.pageSize = pageSize
              uniAxList({...uniRowData,params:{...searchParams, current:pagination.current, size:pageSize}})
            }}
          />
        </ConfigProvider>
      </div>
      <FormModal
        ref={formModalRef}
        title='修改'
        modalVisible={chgModal}
        initialValues={{...currentRow,status: currentRow.status, roleName: currentRow.roleName, sort:currentRow.sort, remark:currentRow.remark, menuIds:initTreeIds,appIds:initAppIds}}
        quitModal={uniQuitModal(setChgModal)}
        modalConfirm={uniChgRowSubmit({currentRows:[currentRow],axData:uniRowData, updateApi:updateRole, setModal:setChgModal,awaitFunc:authRoleMenu})}
        modelItems={modalItems}
        modalItemVisible={modalItemVisible}
      />
      <Modal
        width={600}
        maskClosable={false} title={'新增'}
        visible={addModal}
        onOk={() => formModalRef.current?.submit()}
        onCancel={()=>setAddModal(false)}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>setAddModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}
      >
        <Form
          preserve={false}
          ref={formModalRef}
          name="form"
          labelCol={{ span: 6 }}
          initialValues={{}}
          wrapperCol={{ span: 16 }}
          onFinish={(formValus) => {
            uniAddRowSubmit({axGetData:uniRowData,axAddApi:createRole, setAddModal,setModalLoading,awaitFunc:authRoleMenu})(formValus)
          }}
          autoComplete="off"
        >
          {modalItems.map((item:any) => (modalItemVisible && item.name in modalItemVisible && !modalItemVisible[item.name]) ? null : <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
        </Form>
      </Modal>
    </>
  )
}
