import React, { Children, createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker,Tree, Divider, FormInstance, Input, List, Pagination, Select, Table, TablePaginationConfig, Tabs, Tooltip, TreeSelect, Typography, Spin } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { axSubjectBindList, axSubjectList, getAcountDetailList, getCompanyList, getProofInitBalance, getProofList } from '../../utils/api'
import { uniAxList, uniAxSearchList, uniCurrentTime, uniDeepArray, uniExportExcel, uniFilterItem, uniFormProcess, uniModifyAxList, uniSelectRows, uniToProof, uniValueToName } from '../../utils/uniFucs'
import { borrowOrLend, leadBorrow, monthRages } from '../../utils/constant';
import tablecss from '../../css/custable.module.less'
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../utils/interfaces';
import TopSearch from '../../components/TopSearch';
import SuperTable from '../../components/SuperTable';

export default function DetailBook() {
  const pathname = "/indexpage/detailbook"
  const searchRef = createRef<FormInstance>()
  /* hooks */
  let location = useLocation()
  const {state} = useLocation()
  const navigate = useNavigate()
  const [companyList, setCompanyList] = useState([])
  const [logList, setLogList] = useState([])
  const [rowList, setRowList] = useState([])
  let [searchParams, setSearchParams] = useState({} as any)
  const [tableLoading, setTableLoading] = useState(false)
  const [accountantSubjectList, setAccountantSubjectList] = useState([])
  const [businessList, setBusinessList] = useState([])
  const [currentSubjectId, setCurrentSubjectId] = useState({id:1,borrowLend:1})
  const [loadFlag, setLoadFlag] = useState(false)
  const [keyRow,setKeyRow] = useState({} as any)
  const [selectControl, setSelectControl] = useState({billYmd:[],companyId:null} as any)
  const [tablist,setTablist] = useState({tab1:[],tab2:[],tab3:[],tab4:[],tab5:[],tab6:[]} as any)
  const [searchYmRange,setSearchYmRange] = useState([] as any)
  let [subjectbindList,setSubjectbindList] = useState([])

  /* funcs */
  //路由刷新请求
  const routerFlash = () => {
    // tablist.tab1 = []
    // tablist.tab2 = []
    // tablist.tab3 = []
    // tablist.tab4 = []
    // tablist.tab5 = []
    // tablist.tab6 = []
    // uniAxList(uniRowData)
    // uniAxList(dataAslist)
    uniAxList(uniCompanyData)
  }
  const modifyRowlist = (rowlist:any) => {
    setKeyRow(()=>({...rowlist[0],key:1,borrowTotal:0,lendTotal:0,level:0}))
    return new Promise((resolve,reject) => {
      if (rowlist && rowlist.length > 0) {
        let resultList:any = []
        let res = {data:0}
        resultList.push({billYmd:'初始累计余额', summary:'账户余额', subjectName:'', subjectNo:'', restCash: res.data})
        for (let i = 0; i < rowlist.length; i++) {
          let innerobj = rowlist[i].detailList[0]
          rowlist[i].borrowPrice = innerobj.borrowLend === 1 ? innerobj.price: 0
          rowlist[i].lendPrice = innerobj.borrowLend === 2 ? innerobj.price: 0
          rowlist[i].borrowLend = innerobj.borrowLend
          if (i === 0) {
            if (currentSubjectId.borrowLend===1) rowlist[i].restCash = (innerobj.borrowLend === 1) ? (res.data  + innerobj.price): (res.data - innerobj.price)
            else rowlist[i].restCash = (innerobj.borrowLend === 1) ? (res.data  - innerobj.price): (res.data + innerobj.price)
          }
          else {
            if (currentSubjectId.borrowLend===1) rowlist[i].restCash = (innerobj.borrowLend === 1) ? (rowlist[i-1].restCash + innerobj.price): (rowlist[i-1].restCash - innerobj.price)
            else rowlist[i].restCash = (innerobj.borrowLend === 1) ? (rowlist[i-1].restCash - innerobj.price): (rowlist[i-1].restCash + innerobj.price)
          }
          //插入到日期列
          let ymdItem:any = resultList.find((resultItem:any) => resultItem.billYmd === rowlist[i].billYmd.substr(0,7))
          if (ymdItem) {
            if (innerobj.borrowLend === 1) ymdItem.borrowPrice += innerobj.price
            else if (innerobj.borrowLend === 2) ymdItem.lendPrice += innerobj.price
            ymdItem.restCash += rowlist[i].restCash
            ymdItem.children.push(rowlist[i])
          }
          else resultList.push({
            billYmd:rowlist[i].billYmd.substr(0,7),
            summary:'月度统计',
            subjectName:'',
            subjectNo:'',
            borrowLend: innerobj.borrowLend,
            borrowPrice:innerobj.borrowLend === 1?innerobj.price:0,
            lendPrice:innerobj.borrowLend === 2?innerobj.price:0,
            restCash:rowlist[i].restCash,
            children: [rowlist[i]]
          })
          // resultList.push(rowlist[i])
        }
        resolve(resultList)
        // getProofInitBalance( {subjectId:currentSubjectId.id,proofId:rowlist[0].id, ymd:rowlist[0].billYmd}).then((res:any)=>{
        //   resultList.push({billYmd:'初始累计余额', summary:'账户余额', subjectName:'', subjectNo:'', restCash: res.data})
        //   for (let i = 0; i < rowlist.length; i++) {
        //     let innerobj = rowlist[i].detailList[0]
        //     rowlist[i].borrowPrice = innerobj.borrowLend === 1 ? innerobj.price: 0
        //     rowlist[i].lendPrice = innerobj.borrowLend === 2 ? innerobj.price: 0
        //     rowlist[i].borrowLend = innerobj.borrowLend
        //     if (i === 0) {
        //       if (currentSubjectId.borrowLend===1) rowlist[i].restCash = (innerobj.borrowLend === 1) ? (res.data  + innerobj.price): (res.data - innerobj.price)
        //       else rowlist[i].restCash = (innerobj.borrowLend === 1) ? (res.data  - innerobj.price): (res.data + innerobj.price)
        //     }
        //     else {
        //       if (currentSubjectId.borrowLend===1) rowlist[i].restCash = (innerobj.borrowLend === 1) ? (rowlist[i-1].restCash + innerobj.price): (rowlist[i-1].restCash - innerobj.price)
        //       else rowlist[i].restCash = (innerobj.borrowLend === 1) ? (rowlist[i-1].restCash - innerobj.price): (rowlist[i-1].restCash + innerobj.price)
        //     }
        //     //插入到日期列
        //     let ymdItem:any = resultList.find((resultItem:any) => resultItem.billYmd === rowlist[i].billYmd.substr(0,7))
        //     if (ymdItem) {
        //       if (innerobj.borrowLend === 1) ymdItem.borrowPrice += innerobj.price
        //       else if (innerobj.borrowLend === 2) ymdItem.lendPrice += innerobj.price
        //       ymdItem.restCash += rowlist[i].restCash
        //       ymdItem.children.push(rowlist[i])
        //     }
        //     else resultList.push({
        //       billYmd:rowlist[i].billYmd.substr(0,7),
        //       summary:'月度统计',
        //       subjectName:'',
        //       subjectNo:'',
        //       borrowLend: innerobj.borrowLend,
        //       borrowPrice:innerobj.borrowLend === 1?innerobj.price:0,
        //       lendPrice:innerobj.borrowLend === 2?innerobj.price:0,
        //       restCash:rowlist[i].restCash,
        //       children: [rowlist[i]]
        //     })
        //     // resultList.push(rowlist[i])
        //   }
        //   resolve(resultList)
        // })
        
      } else resolve(rowlist)
    })
  }
  //拖拽展开
  const resizeFn = () => {
    let resize:any = document.querySelector('#listresize')
    let element:any = document.querySelector('#listref')
    let tablewrap:any = document.querySelector('#tablewrap')
    // let listtab:any = document.querySelector('.listtab')
    resize.addEventListener('mousedown', function(e:any){
      let startX = e.pageX
      let width = element.offsetWidth
      // let listwidth = listtab.offsetWidth
      let tablewith = tablewrap.offsetWidth
      document.addEventListener('mousemove', move)
      let timer:any = null
      function move(e:any){
        let moveX:any // 左右这里切换计算顺序即可
        moveX = startX - e.pageX
        clearTimeout(timer)
        timer = setTimeout(() => {
          element.style.width = (width + moveX) + 'px'
          // listtab.style.width = (listwidth + moveX) + 'px'
          tablewrap.style.width = (tablewith - moveX) + 'px'
        }, 5);
      }
      document.addEventListener('mouseup',function(){
        document.removeEventListener('mousemove', move)
      }, {once: true})
    })
  }
  //动态生成treenode
  const getTreeNode = (data:any) => {
    if (data && data.length > 0) {
      return data.map((item:any) => {
        if (item.children) return <Tree.TreeNode key={item.key} title={item.title} isLeaf={item.isLeaf}>{getTreeNode(item.children)}</Tree.TreeNode>
        return <Tree.TreeNode key={item.key} title={item.title} isLeaf={item.isLeaf}/>
      })
    }
    return []
  }
  const searchConfirm = async (formValus:any) => {
    searchParams = {...await uniFormProcess(formValus,searchList)}
    uniAxList(dataRow())
  }

/* elements */
//顶部操作
const topActions:ColumnAction[] = [
{label: '导出', type:'primary', action: () => uniExportExcel({columns,rowList,title:'明细账', fileName: '明细账'})},
]
//搜索行
const searchList:FormItemObj[] = [
{label: '公司', prop:'companyId',type:'select',dataList:companyList,required:true,clearable:false,change:(value:any)=>{
  let finditem:any = companyList.find((item:any)=>item.id===value)
  if (finditem.initialize===1) setSearchYmRange([finditem.startYm,finditem.endYm])
  else setSearchYmRange([])
  let subjectid = searchRef.current?.getFieldValue('subjectId')
  if (subjectid) {
    uniAxList(dataSubjectBindList(value,subjectid))
    searchRef.current?.setFieldsValue({subjectAccountingDetailId:null})
  }
}},
{label: '月份', prop:'ym',type:'dateRange',required:true,daterangeProps:{type:'month',returnType:'string',rangeKeys:['beginYm','endYm'],shortCuts:{},disabledDate:(current)=>{
  if (searchYmRange.length<2) return true
  else return current<moment(searchYmRange[0]).startOf('month') || current>moment(searchYmRange[1]).endOf('month')
}}},
{label: '科目', prop:'subjectId',required:true,clearable:false,width:180,type:'tree',treeProps:{propName:'title'},dataList:accountantSubjectList,change:(value:any)=>{
  let companyid = searchRef.current?.getFieldValue('companyId')
  if (companyid) {
    uniAxList(dataSubjectBindList(companyid,value))
    searchRef.current?.setFieldsValue({subjectAccountingDetailId:null})
  }
}},
{label: '详情组合', prop:'subjectAccountingDetailId',width:250,type:'select',dataList:subjectbindList,selectProps:{propName:'noName'}}
]
//列项
const columns:ColumnItem[] = [
{title: '日期',key: 'billYmd'},
{title: '凭证号',key: 'proofNo',width:120,type:'underline',unlineProps:{color: '#1890ff',action:({row,value}:any)=>uniToProof(row,row.proofId, navigate)}},
{title: '摘要',key: 'summary'},
// {title: '所属业务',key: 'businessName'},
{title: '借方金额',key: 'borrowPrice',type:'price'},
{title: '贷方金额',key: 'lendPrice',type:'price'},
{title: '科目方向',key: 'subjectBorrowLend',type:'tag',tagProps:{values: [1,2]},custval:({row,value}:any)=>uniValueToName({list:borrowOrLend,value})},
{title: '余额',key: 'endPrice',type:'price'},
]
  /* 接口参数 */
  const dataRow = (subjectlist?:any):AxDataform => ({
    params: {...searchParams},
    // modifyFunc: modifyRowlist,
    axApi: getAcountDetailList,
    setTableLoading: setTableLoading,
    awaitFunc: (predata:any) => {
      let templist = accountantSubjectList
      if (subjectlist) templist = subjectlist
      let findAsitem:any = uniFilterItem(templist,'id',searchParams.subjectId)
      let totalend = predata.startPrice || 0
      let yearborrow = predata.yearBorrowPrice || 0
      let yearlend = predata.yearLendPrice || 0
      let monthborrow = 0
      let monthlend = 0
      let temparr:any = [{id:-1,billYmd:searchParams.beginYm+'-01',summary:'期初余额',borrowPrice:predata.yearBorrowPrice,lendPrice:predata.yearLendPrice,subjectBorrowLend:findAsitem.borrowLend,endPrice:totalend}]
      let idcout = -2
      if (predata.detail.length <=0) {
        temparr.push({id:idcout,summary:'本期合计',borrowPrice:predata.yearBorrowPrice,lendPrice:predata.yearLendPrice,subjectBorrowLend:findAsitem.borrowLend,endPrice:totalend})
        temparr.push({id:idcout-1,summary:'本年累计',borrowPrice:predata.yearBorrowPrice,lendPrice:predata.yearLendPrice,subjectBorrowLend:findAsitem.borrowLend,endPrice:totalend})
      }
      else predata.detail.forEach((item:any,index:any)=>{
        if (index > 0 && item.billYmd.slice(0,7) !== predata.detail[index-1].billYmd.slice(0,7)) {
          temparr.push({id:idcout,summary:'本期合计',borrowPrice:monthborrow,lendPrice:monthlend,subjectBorrowLend:findAsitem.borrowLend,endPrice:totalend})
          temparr.push({id:idcout-1,summary:'本年累计',borrowPrice:yearborrow,lendPrice:yearlend,subjectBorrowLend:findAsitem.borrowLend,endPrice:totalend})
          idcout-=3
          monthborrow = 0
          monthlend = 0
        }
        item.id = index
        item.subjectBorrowLend = findAsitem.borrowLend
        if (findAsitem.borrowLend === item.borrowLend) totalend += item.price
        else totalend -= item.price
        item.endPrice = totalend
        if (item.borrowLend===1) {
          item.borrowPrice = item.price
          item.lendPrice = 0
          yearborrow += item.price
          monthborrow += item.price
        }
        else {
          item.borrowPrice = 0
          item.lendPrice = item.price
          yearlend += item.price
          monthlend += item.price
        }
        temparr.push(item)
        if (index===predata.detail.length-1) {
          temparr.push({id:idcout,summary:'本期合计',borrowPrice:monthborrow,lendPrice:monthlend,subjectBorrowLend:findAsitem.borrowLend,endPrice:totalend})
          temparr.push({id:idcout-1,summary:'本年累计',borrowPrice:yearborrow,lendPrice:yearlend,subjectBorrowLend:findAsitem.borrowLend,endPrice:totalend})
          idcout-=3
        }
      })
      setRowList(temparr)
    }
  })
  const dataAslist:AxDataform = {
    params: {page:1, size:9999},
    axApi: axSubjectList,
    itemModify: {modifyFunc: ({item}:any)=>item.title = item.subjectNo + ' ' + item.name},
    setList: setAccountantSubjectList,
  }
  const uniCompanyData:AxDataform = {
    axApi: getCompanyList,
    setList: setCompanyList,
  }
  //获取科目详情组合
const dataSubjectBindList = (companyId:any,subjectId:any):AxDataform => {
  return {
    params:{companyId,subjectId},
    axApi: axSubjectBindList,
    setList: setSubjectbindList
  }
}

  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
    let mystate:any = state
    if (state && location.pathname===pathname) {
      console.log('mystat=-----------',mystate)
      let ymarr:any = []
      if (mystate.ymarr) ymarr = [moment(mystate.ymarr[0]),moment(mystate.ymarr[1])]
      else if (mystate.ym) ymarr = [moment(mystate.ym),moment(mystate.ym)]
      searchRef.current?.setFieldsValue({'companyId':mystate.companyId,'ym':ymarr,'subjectId':mystate.subjectId,'subjectAccountingDetailId':mystate.subjectAccountingDetailId})
      searchParams = {'companyId':mystate.companyId,'subjectId':mystate.subjectId,subjectAccountingDetailId:mystate.subjectAccountingDetailId,beginYm:(mystate.ymarr?mystate.ymarr[0]:mystate.ym),endYm:(mystate.ymarr?mystate.ymarr[1]:mystate.ym)}
      uniAxList(dataSubjectBindList(mystate.companyId,mystate.subjectId))
      if (!loadFlag) uniAxList(dataAslist).then((subjectlist)=>uniAxList(dataRow(subjectlist)))
      else uniAxList(dataRow())
    } else uniAxList(dataAslist)
  }, [location])

  /* mounted */
  React.useEffect(() => {
    // if(!state) uniAxList(uniRowData)
    // uniAxList(dataAslist)
    uniAxList(uniCompanyData)
    // resizeFn()
    setLoadFlag(()=>true)
  }, [])

  // React.useEffect(() => {
  //   if (state && location.pathname===pathname) {
  //     let mySate:any = state
  //     let billYmd
  //     console.log('state------------',state)
  //     selectControl.billYmd = []
  //     selectControl.companyId = null
  //     if (mySate.companyId) {
  //       selectControl.companyId = mySate.companyId
  //       tempSearchParams.filter_EQL_company_id = mySate.companyId
  //       searchParams.filter_EQL_company_id = mySate.companyId
  //     }
  //     if (mySate.startYmd && mySate.endYmd) {
  //       let start = moment(mySate.startYmd).startOf('month').format("YYYY-MM-DD")
  //       let end = moment(mySate.endYmd).endOf('month').format("YYYY-MM-DD")
  //       billYmd = start+','+end
  //       selectControl.billYmd = [moment(start,"YYYY-MM"),moment(end,"YYYY-MM")]
  //       tempSearchParams['filter_BETWEENS_p.bill_ymd'] = billYmd
  //       searchParams['filter_BETWEENS_p.bill_ymd'] = billYmd
  //     }
  //     currentSubjectId.id = mySate.subjectId
  //     tempSearchParams.filter_EQS_subject_id = mySate.subjectId
  //     searchParams.filter_EQS_subject_id = mySate.subjectId
  //     uniAxList({...dataRow(), params: {...searchParams, filter_EQS_subject_id:currentSubjectId.id,'filter_BETWEENS_p.bill_ymd':billYmd,filter_EQL_company_id:mySate.companyId}})
  //   }
  // }, [state])

  return (
    <>
    <TopSearch
      ref={searchRef}
      topActions={topActions}
      searchList={searchList}
      searchConfirm={searchConfirm}
    />
    <SuperTable
      bordered={true}
      columns={columns}
      rowList={rowList}
      tableLoading={tableLoading}
      dataRow={dataRow}
    />
    </>
  )
}
