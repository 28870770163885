import React, { createRef, useState } from 'react'
import { Button, ConfigProvider, FormInstance, Input, InputNumber, message, Pagination, Popconfirm, Table, TablePaginationConfig, Typography } from 'antd'
import locale from 'antd/es/locale/zh_CN';

import { createPosition, updatePosition, deletePosition, getPositionList } from '../../../utils/api'
import { PsItem } from '../../../utils/beans'
import FormModal from '../../../components/FormModal'
import {  uniSelectRows, uniAxList, uniAxSearchList, UniDeleteAll, uniQuitModal, uniChgRowSubmit, uniAddRowSubmit, uniModifyRow, uniDeleteRow } from '../../../utils/uniFucs'
import UniOperationColumn from '../../../components/UniOperationColumn'
import { useLocation } from 'react-router-dom'
import tablecss from '../../../css/custable.module.less'

export default function TitleSetting() {
  /* 非刷新constuctor */
  const pathname = "/indexpage/titlesetting"
  const initPsItem:PsItem = {createBy: '', createDate: '', id: '', isDeleted: false, name: '', sort: 0, updateBy: '', updateDate: ''}
  /* hooks */
  let location = useLocation()
  const modalRef = createRef<FormInstance>()
  const [positionList, setPositionList] = useState([])
  const [rowList, setRowList] = useState([])
  const [addModal, setAddModal] = useState(false)
  const [currentRow, setCurrentRow] = useState(initPsItem)
  const [chgModal, setChgModal] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [searchParams, setSearchParams] = useState({page:1,orderBy:"sort", orderDir: "asc"} as any)
  const [tempSearchParams, setTempSearchParams] = useState({page:1,orderBy:"sort", orderDir: "asc"} as any)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
  const [loadFlag, setLoadFlag] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  //路由刷新请求
  const routerFlash = () => {
    uniAxList(uniRowData)
  }
   /* elements */
  //列首行
  const columns:any = [
    {title: '职位名称',dataIndex: 'name',key: 'name'},
    {title: '排序',dataIndex: 'sort',key: 'sort',width: '12%'},
    {title: '创建时间',dataIndex: 'createDate',key: 'createDate'},
    {title: '操作',dataIndex: 'operation',fixed: 'right',width: 120,
      render: (_: any, record: any) => {
        return (
          <span>
            <Typography.Link onClick={() => {
              uniModifyRow({record, setCurrentRow, setChgModal})
            }} style={{ marginRight: 8 }}>修改</Typography.Link>
            <Popconfirm title={"确定删除该人员吗?"} onConfirm={() => uniDeleteRow({record,deleteApi:deletePosition,uniAxListData:uniRowData})} okText="确定" cancelText="取消">
              <a href="#">删除</a>
            </Popconfirm>
          </span>
        )
      }
    }
  ]
  //添加和修改中的modal项
  const modalItems = [
    {label: '职位名称',name: 'name',rules: [{ required: true, message: '职位不能为空' }],element: <Input placeholder="请输入职位名称"/>},
    {label: '排序',name: 'sort',rules: [{ required: true, message: '排序不能为空' }],element: <InputNumber style={{width:'100%'}} precision={0} min={0} max={100} />}
  ]
  //接口参数
  const uniRowData:any = {
    params: searchParams,
    axApi: getPositionList,
    setList: setRowList,
    pagination
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    uniAxList(uniRowData)
    setLoadFlag(()=>true)
  }, [])
  
  return (
    <>
      <p>
        <Input.Search
          placeholder="请输入职位"
          allowClear
          enterButton="查询"
          style={{ width: '20%' }}
          onChange={(e) => setTempSearchParams(() => ({filter_LIKES_name: e.target.value || null}))}
          onSearch={() => {pagination.current=1;uniAxSearchList({setSearchParams, uniRowData, params: {...tempSearchParams,size:pagination.pageSize}})}}
        />&nbsp;
        <Button type="primary" onClick={() => setAddModal(()=>true)}>新增职位</Button>&nbsp;
        <Popconfirm title="确定批量删除吗?" onConfirm={() => UniDeleteAll({deleteKeyList:selectedRowKeys, deleteApi:deletePosition, setSelectedRowKeys, axParams:uniRowData})} okText="确定" cancelText="取消">
          <Button type="primary" danger disabled={!selectedRowKeys.length}>批量删除</Button>
        </Popconfirm>&nbsp;
      </p>
      <Table
        size='small'
        className={tablecss.height260}
        rowKey={'id'}
        columns={columns}
        rowSelection={{ ...uniSelectRows(setSelectedRowKeys), checkStrictly: true }}
        pagination={false}
        scroll={{y:'calc(100vh - 260px)'}}
        dataSource={rowList}
        sticky
      />
      <div style={{textAlign:'right'}}>
        <p></p>
        <ConfigProvider locale={locale}>
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.current}
            total={pagination.total}
            showSizeChanger
            showQuickJumper
            showTotal={total => `共 ${total} 条`}
            onChange={(pageNumbe)=>{
              pagination.current = pageNumbe
              uniAxList({...uniRowData,params:{...searchParams, current:pageNumbe, size:pagination.pageSize}})
            }}
            onShowSizeChange={(current, pageSize)=>{
              pagination.pageSize = pageSize
              uniAxList({...uniRowData,params:{...searchParams, current:pagination.current, size:pageSize}})
            }}
          />
        </ConfigProvider>
      </div>
      <FormModal
        ref={modalRef}
        title="新增"
        modalVisible={addModal}
        initialValues={{sort: 0}}
        quitModal={uniQuitModal(setAddModal)}
        modalConfirm={uniAddRowSubmit({axGetData:uniRowData,axAddApi:createPosition, setAddModal, setModalLoading})}
        modelItems={modalItems}
        modalLoading={modalLoading}
      />
      <FormModal
        ref={modalRef}
        title="修改"
        modalVisible={chgModal}
        initialValues={{name: currentRow.name, sort: currentRow.sort}}
        quitModal={uniQuitModal(setChgModal)}
        modalConfirm={uniChgRowSubmit({currentRows:[currentRow], axData:uniRowData, updateApi:updatePosition, setModal:setChgModal,setModalLoading})}
        modelItems={modalItems}
        modalLoading={modalLoading}
      />
    </>
  )
}
