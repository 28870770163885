import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker, FormInstance, InputNumber, message, Modal, Popconfirm, Select, Table, TablePaginationConfig, TreeSelect, Typography } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { axCompanyFlow, axCreateCashflowSubject, axcreateFlowAdjust, axDeleteCashflowSubject, axFlowAdjustsum, axupdateFlowAdjust, axYearFlowAdjustsum, axSubjectList, getCompanyList, xjllAnalyse } from '../../utils/api'
import { uniAxList, uniAxSearchList, uniCurrentTime, uniFilterItem, uniFormProcess, uniModifyAxList, uniSelectRows, uniValueToName } from '../../utils/uniFucs'
import { calculateTypes, calculateTypeTypes, csFlowTypes, monthRages } from '../../utils/constant';
import modalcss from '../../css/formModal.module.less'
import tablecss from '../../css/custable.module.less'
import { AxDataform, ColumnAction, FormItemObj } from '../../utils/interfaces';
import TopSearch from '../../components/TopSearch';

export default function GpCashflow() {
  const pathname = "/indexpage/gpcashflow"
  const initSubjectModalControl = {selectSubjectId:null,selectSubjectName:null,selectCalculate:null,selectCalculateType:null,subjectList:[],selectSubjectNo:null}
  const initMainControl = {selectYear:uniCurrentTime({selectYear:moment(),beginYm:moment(),endYm:moment(),isBalance:false,selectType:1,selectedPicer:uniCurrentTime({day:false})})}
  const searchRef = createRef<FormInstance>()

  /* hooks */
  let location = useLocation()
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [staticModal, setStaticModal] =useState(false)
  const [companyList, setCompanyList] = useState([])
  const [rowList, setRowList] = useState([] as any)
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({} as any)
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [currentRow, setCurrentRow] = useState({} as any)
  let [searchParams, setSearchParams] = useState({} as any)
  // let [searchParams, setSearchParams] = useState({type:1,number:Number(uniCurrentTime({year:false,day:false})),year:uniCurrentTime({month:false,day:false}),ym:uniCurrentTime({day:false})} as any)
  const [tempSearchParams, setTempSearchParams] = useState({type:1,number:Number(uniCurrentTime({year:false,day:false})),year:uniCurrentTime({month:false,day:false}),ym:uniCurrentTime({day:false})} as any)
  const [staffList, setStaffList] = useState([])
  const [reinburseTypeList, setReinburseTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 10})
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [staffAccounts, setStaffAccounts] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [unCashRows, setUnCashRows] = useState([] as any)
  const [isUnderLinePay, setIsUnderLinePay] = useState(true)
  const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
  const [businessList, setBusinessList] = useState([])
  const [cancelRemark, setCancelRemark] = useState('')
  const [loadFlag, setLoadFlag] = useState(false)
  const [subjectModal,setSubjectModal] = useState(false)
  const [subjectList,setSubjectList] = useState([])
  const [modalLoading, setModalLoading] = useState(false)
  const [subjectModalControl, setSubjectModalControl] = useState({selectSubjectId:null,selectSubjectName:null,selectSubjectNo:null,selectCalculate:null,selectCalculateType:null,subjectList:[]})
  const [subjectTableLoading, setSubjectTableLoading] = useState(false)
  const [mainControl, setMainControl] = useState({selectYear:moment(),beginYm:moment(),endYm:moment(),isBalance:false,selectType:1,selectedPicker:uniCurrentTime({day:false})} as any)
  const [adjustModal,setAdjustModal] = useState(false)
  const [companyFlowList,setCompanyFlowList] = useState([] as any)
  const [adjustTableLoading,setAdjustTableLoading] = useState(false)
  const [companyFlowListInit,setCompanyFlowListInit] = useState([])
  const [searchYmRange,setSearchYmRange] = useState([] as any)

  /* funcs */
  //路由刷新请求
  const routerFlash = () => {
    // uniAxList(dataFlowAdjustsum)
    uniAxList(uniCompanyData)
    uniAxList(uniSubjectData)
  }
  const searchConfirm = async (formValus:any) => {
    searchParams = {...await uniFormProcess(formValus,searchList)}
    uniAxList(uniAxList(dataFlowAdjustsum))
  }
  const modifyFunc = ({detail}:any) => {
    const topRow1:any = {key:-1, title:'经营活动产生的现金流量净额', monthPrice:0, yearPrice:0, children:[
      {key:-2, title:'现金流入小计', monthPrice:0, yearPrice:0, children:[]},
      {key:-3, title:'现金流出小计', monthPrice:0, yearPrice:0, children:[]},
    ]}
    const topRow2:any = {key:-4, title:'投资活动产生的现金流量净额', monthPrice:0, yearPrice:0, children:[
      {key:-5, title:'现金流入小计', monthPrice:0, yearPrice:0, children:[]},
      {key:-6, title:'现金流出小计', monthPrice:0, yearPrice:0, children:[]},
    ]}
    const topRow3:any = {key:-7, title:'筹资活动产生的现金流量净额', monthPrice:0, yearPrice:0, children:[
      {key:-8, title:'现金流入小计', monthPrice:0, yearPrice:0, children:[]},
      {key:-9, title:'现金流出小计', monthPrice:0, yearPrice:0, children:[]},
    ]}

    for (let key in detail) {
      let rowItem:any = detail[key]
      rowItem.key = rowItem.line
      if ([1,2].indexOf(rowItem.line) !== -1) {
        topRow1.children[0].children.push(rowItem)
        topRow1.children[0].monthPrice += rowItem.monthPrice
        topRow1.children[0].yearPrice += rowItem.yearPrice
      } else if ([4,5,6,7].indexOf(rowItem.line) !== -1) {
        topRow1.children[1].children.push(rowItem)
        topRow1.children[1].monthPrice += rowItem.monthPrice
        topRow1.children[1].yearPrice += rowItem.yearPrice
      } else if ([10,11,12].indexOf(rowItem.line) !== -1) {
        topRow2.children[0].children.push(rowItem)
        topRow2.children[0].monthPrice += rowItem.monthPrice
        topRow2.children[0].yearPrice += rowItem.yearPrice
      } else if ([14,15,16].indexOf(rowItem.line) !== -1) {
        topRow2.children[1].children.push(rowItem)
        topRow2.children[1].monthPrice += rowItem.monthPrice
        topRow2.children[1].yearPrice += rowItem.yearPrice
      } else if ([19,20,21].indexOf(rowItem.line) !== -1) {
        topRow3.children[0].children.push(rowItem)
        topRow3.children[0].monthPrice += rowItem.monthPrice
        topRow3.children[0].yearPrice += rowItem.yearPrice
      } else if ([23,24,25,26].indexOf(rowItem.line) !== -1) {
        topRow3.children[1].children.push(rowItem)
        topRow3.children[1].monthPrice += rowItem.monthPrice
        topRow3.children[1].yearPrice += rowItem.yearPrice
      } 
    }
    topRow1.monthPrice = topRow1.children[0].monthPrice-topRow1.children[1].monthPrice
    topRow1.yearPrice = topRow1.children[0].yearPrice-topRow1.children[1].yearPrice
    topRow2.monthPrice = topRow2.children[0].monthPrice-topRow2.children[1].monthPrice
    topRow2.yearPrice = topRow2.children[0].yearPrice-topRow2.children[1].yearPrice
    topRow3.monthPrice = topRow3.children[0].monthPrice-topRow3.children[1].monthPrice
    topRow3.yearPrice = topRow3.children[0].yearPrice-topRow3.children[1].yearPrice
    return [topRow1,topRow2,topRow3]
  }

/* elements */
//搜索行
const searchList:FormItemObj[] = [
  {label: '公司', prop:'companyId',type:'select',dataList:companyList,required:true,change:(value:any)=>{
    let finditem:any = companyList.find((item:any)=>item.id===value)
    if (finditem.initialize===1) setSearchYmRange([finditem.startYm,finditem.endYm])
    else setSearchYmRange([])
  }},
  {label: '月份', prop:'ym',type:'datePicker',required:true,datepickerProps:{type:'month',returnType:'string',disabledDate:(current)=>{
    if (searchYmRange.length<2) return true
    else return current<moment(searchYmRange[0]).startOf('month') || current>moment(searchYmRange[1]).endOf('month')
  }}},
  ]
  //顶部操作
const topActions:ColumnAction[] = [
  {label: '是否平衡', type:'primary',render: mainControl.isBalance
    ? <img src={require('../../materials/img/pingheng.png')} alt="" /> 
    : <img src={require('../../materials/img/bupingheng.png')} alt="" />
  }
  ]
  //列首行
  const columns:any = [
    {
      title: '项目',
      dataIndex: 'name',
      key: 'name',
      width: 600,
      render: (value:any,record:any) => record.id >= 0 ? <Typography.Link onClick={()=>{
        setSubjectModalControl(()=>({...initSubjectModalControl,subjectList:record.detail}))
        setCurrentRow(()=>record)
        setSubjectModal(()=>true)
      }}>{value}</Typography.Link> :value
    },
    {
      title: '行次',
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: '运算规则',
      dataIndex: 'calculate',
      key: 'calculate',
      render:(value:any) => {
        let tempItem:any = uniFilterItem(calculateTypes,'value',value)
        return tempItem ? tempItem.name : ''
      }
    },
    {
      title: '本月金额',
      dataIndex: 'monthPrice',
      key: 'monthPrice',
      render: (value:any) => (value / 100).toFixed(2)
    },
    {
      title: '本年累计金额',
      dataIndex: 'yearPrice',
      key: 'yearPrice',
      render: (value:any) => (value / 100).toFixed(2)
    },
    // {
    //   title: '操作',
    //   dataIndex: 'operation',
    //   fixed: 'right',
    //   width: 120,
    //   render: (_: any, record: any) => 
    //   record.id >= 0
    //   ?mainControl.selectType === 1
    //     ?<Typography.Link onClick={()=>{
    //       setCompanyFlowList(()=>[])
    //       setCurrentRow(()=>record)
    //       setAdjustModal(()=>true)
    //       uniAxList({...dataCompanyFlowList,params:{filter_EQS_ym:searchParams.ym,filter_EQS_cash_flow_id:record.id},awaitFunc:(companyFlowlist:any)=>{
    //         let temparr:any
    //         if (companyFlowlist.length === 0) {
    //           temparr = companyList.map((company:any)=>({balance:0,calculate:record.calculate,cashFlowId:record.id,cashFlowName:record.name,cashFlowType:record.type,companyId:company.id,companyName:company.name,ym:searchParams.ym}))
    //         } else {
    //           temparr = companyList.map((company:any)=>{
    //             let finditem = companyFlowlist.find((axitem:any)=>axitem.companyId === company.id)
    //             if (finditem) return finditem
    //             else return {balance:0,calculate:record.calculate,cashFlowId:record.id,cashFlowName:record.name,cashFlowType:record.type,companyId:company.id,companyName:company.name,ym:searchParams.ym}
    //           })
    //         }
    //         setCompanyFlowList(()=>[...temparr])
    //       }})
    //     }}>调整</Typography.Link>
    //     :<span style={{color:'#ccc'}}>调整</span>
    //   :null
    // }
  ]
  //modal中table列项
  const columnsInSubjectModal:any = [
    {
      title: '科目',
      dataIndex: 'subjectName',
      key: 'subjectName',
    },
    {
      title: '运算符号',
      dataIndex: 'calculate',
      key: 'calculate',
      render:(value:any) => {
        let tempItem:any = uniFilterItem(calculateTypes,'value',value)
        return tempItem ? tempItem.name : ''
      }
    },
    {
      title: '取数规则',
      dataIndex: 'calculateType',
      key: 'calculateType',
      render:(value:any) => uniValueToName({list:calculateTypeTypes,value,propId:'value'})
    },
    {
      title: '本期金额',
      dataIndex: 'monthPrice',
      key: 'monthPrice',
      render: (value:any) => value && !isNaN(value) ? (value/100).toFixed(2) : 0
    },
    {
      title: '本年金额',
      dataIndex: 'yearPrice',
      key: 'yearPrice',
      render: (value:any) => value && !isNaN(value) ? (value/100).toFixed(2) : 0
    },
    {
      title: '操作',
      dataIndex: 'operation',
      fixed: 'right',
      width: 120,
      render: (_: any, record: any) => 
            <Popconfirm title={"确定删除该记录吗?"} okText="确定" cancelText="取消" onConfirm={() => uniAxList({...dataDeleteCashflowSubject,params:{id:record.cashFlowSubjectId}})}>
              <a href="#">删除</a>
            </Popconfirm>

    }
  ]
  //修改余额table列项
  const adjustColumns = [
    {
      title: '公司',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: '调整金额',
      dataIndex: 'balance',
      key: 'balance',
      width:150,
      render:(value:any,record:any)=><InputNumber defaultValue={record.balance/100} style={{width:'100%'}} precision={2}  onChange={(balance:any)=>record.balance=balance*100}/>
    },
  ]

  /* 接口参数 */
  const dataRow = ():any => ({
    params: searchParams,
    axApi: xjllAnalyse
  })
  const uniCompanyData:any = {
    axApi: getCompanyList,
    setList: setCompanyList,
  }
  const uniSubjectData:AxDataform = {
    // params: {filter_EQL_level:1},
    params: {page:1, size:999},
    axApi: axSubjectList,
    itemModify:{modifyFunc:({item}:any)=>item.title=item.subjectNo+' '+item.name},
    setList: setSubjectList
  }
  //添加现金流和会计科目对应关系
  const dataCreateCashflowSubject:any = {
    axApi: axCreateCashflowSubject,
    awaitFunc: ()=>uniAxList({...dataRow(),setTableLoading:setSubjectTableLoading,awaitFunc:(predata:any)=>{
      let prerowlist = predata.response
      let tempcurrent:any = uniFilterItem(prerowlist,'id',currentRow.id)
      currentRow.detail = tempcurrent.detail
      setSubjectModalControl(()=>({...subjectModalControl,subjectList:tempcurrent.detail}))
    }})
  }
  //删除现金流和会计科目对应关系
  const dataDeleteCashflowSubject:any = {
    axApi: axDeleteCashflowSubject,
    awaitFunc: ()=>uniAxList({...dataRow(),setTableLoading:setSubjectTableLoading,awaitFunc:(predata:any)=>{
      let prerowlist = predata.response
      let tempcurrent:any = uniFilterItem(prerowlist,'id',currentRow.id)
      currentRow.detail = tempcurrent.detail
      setSubjectModalControl(()=>({...subjectModalControl,subjectList:tempcurrent.detail}))
    }})
  }
  //分公司查询本月金额
  const dataCompanyFlowList:any = {
    axApi: axCompanyFlow,
    setList: setCompanyFlowListInit,
    setTableLoading:setAdjustTableLoading,
  }
  //创建流量调整
  const dataCreateFlowAdjust:any = {
    axApi: axcreateFlowAdjust,
    setModalLoading,
    setModal:setAdjustModal,
  }
  //更新流量调整
  const dataUpdateFlowAdjust:any = {
    axApi: axupdateFlowAdjust,
    setModalLoading,
    setModal:setAdjustModal
  }
  //本年累计金额
  const dataYearFlowAdjustSum:any={
    axApi:axYearFlowAdjustsum
  }
  //本期调整金额
  const dataFlowAdjustsum:any = {
    params:tempSearchParams,
    axApi: axFlowAdjustsum,
    setTableLoading,
    finishLoading:false,
    awaitFunc: (currentFlows:any)=> {
      let beginYm = tempSearchParams.year+'-01'
      let endYm:any
      if (mainControl.selectType===1) endYm =tempSearchParams.ym
      else {
        if (tempSearchParams.number = 1) endYm = tempSearchParams.year+'-03'
        else if (tempSearchParams.number = 2) endYm = tempSearchParams.year+'-06'
        else if (tempSearchParams.number = 3) endYm = tempSearchParams.year+'-09'
        else endYm = tempSearchParams.year+'-12'
      }
      uniAxList({...dataYearFlowAdjustSum,params:{beginYm,endYm},awaitFunc:(yearFlows:any)=>uniAxList({...dataRow(),awaitFunc: (predata:any)=>{
        let prerowlist = predata.response.filter((item:any)=>item.calculate!==3)
        let count = 1
        let topRow1:any = {id:-1,name:'一、经营活动产生的现金流量',monthPrice:0,yearPrice:0}
        let topRow2:any = {id:-2,name:'二、投资活动产生的现金流量',monthPrice:0,yearPrice:0}
        let topRow3:any = {id:-3,name:'三、筹资活动产生的现金流量',monthPrice:0,yearPrice:0}
        let topRow4:any = {id:-4,name:'四、现金净增加额',monthPrice:0,yearPrice:0}
        let topRow5:any = {id:-5,name:'加：期初现金余额',monthPrice:predata.beginPrice || 0,yearPrice:predata.yearBeginPrice || 0}
        let topRow6:any = {id:-6,name:'五、期末现金余额',monthPrice:predata.endPrice || 0,yearPrice:predata.yearEndPrice || 0}
        prerowlist.forEach((prerowitem:any)=>{
          let findCurrenAdjust = currentFlows.find((currentItem:any)=>currentItem.cashFlowId === prerowitem.id)
          let findYearAdjust = yearFlows.find((yearItem:any)=>yearItem.cashFlowId === prerowitem.id)
          if (findCurrenAdjust) prerowitem.monthPrice += findCurrenAdjust.balance
          if (findYearAdjust) prerowitem.yearPrice += findCurrenAdjust.balance
          if (prerowitem.calculate===1) {
            topRow4.monthPrice += prerowitem.monthPrice
            topRow4.yearPrice += prerowitem.yearPrice
          } else {
            topRow4.monthPrice -= prerowitem.monthPrice
            topRow4.yearPrice -= prerowitem.yearPrice
          }
          let topRow
          if (prerowitem.type==='1') topRow = topRow1
          else if (prerowitem.type==='2') topRow = topRow2
          else if (prerowitem.type==='3') topRow = topRow3
          if (topRow) {
            prerowitem.count = count
            count++
            if (prerowitem.calculate===1) {
              topRow.monthPrice += prerowitem.monthPrice
              topRow.yearPrice += prerowitem.yearPrice
            } else {
              topRow.monthPrice -= prerowitem.monthPrice
              topRow.yearPrice -= prerowitem.yearPrice
            }
            if (topRow.children) topRow.children.push(prerowitem)
            else topRow.children = [prerowitem]
          }
        })
        topRow4.count = count
        topRow5.count = count + 1
        topRow6.count = count + 2
        if (topRow4.monthPrice+topRow5.monthPrice===topRow6.monthPrice && topRow4.yearPrice+topRow5.yearPrice===topRow6.yearPrice) mainControl.isBalance = true
        setRowList(()=>[topRow1,topRow2,topRow3,topRow4,topRow5,topRow6])
        setTableLoading(()=>false)
      }})})
    }
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    // uniAxList(dataFlowAdjustsum)
    // uniAxList(dataRow())
    uniAxList(uniCompanyData)
    uniAxList(uniSubjectData)
    setLoadFlag(()=>true)
  }, [])

  return (
    <>
    <TopSearch
      ref={searchRef}
      topActions={topActions}
      searchList={searchList}
      searchConfirm={searchConfirm}
    />
      {/* <p>
      <Select
        defaultValue={1}
        style={{ width: 100 }}
        placeholder="类型"
        onChange={(value) => {
          mainControl.selectType = value
          let number:any
          if (value === 1) {
            mainControl.selectedPicker = uniCurrentTime({})
            number = Number(uniCurrentTime({year:false,day:false}))
          } else {
            mainControl.selectedPicker = uniCurrentTime({month:false,day:false})+'-Q1'
            number = 1
          }
          setTempSearchParams(()=>({...tempSearchParams, type:value,number,ym:uniCurrentTime({day:false})}))
        }}
      > 
        {csFlowTypes.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker
          style={{ width: 200 }}
          format={(value)=>{
            if (mainControl.selectType === 1) return value.format('YYYY-MM')
            else return value.format('YYYY年第Q季度')
          }}
          allowClear={false}
          value={mainControl.selectType===1?moment(mainControl.selectedPicker,'YYYY-MM'):moment(mainControl.selectedPicker,'YYYY-Q')}
          picker={mainControl.selectType===1?'month':'quarter'}
          disabledDate={(current)=>{return current && current > moment().endOf('year')}}
          onChange={(date:any, dateString)=>{
            mainControl.selectedPicker = dateString
            let year:any
            let number:any
            if (mainControl.selectType===1) {
              let dates = dateString.split('-')
              year = dates[0]
              number = Number(dates[1])
            } else {
              year = dateString.slice(0,4)
              number = dateString.slice(6,7)
            }
            setTempSearchParams(()=>({...tempSearchParams, year,number,ym:mainControl.selectType===1?dateString:null}))
          }}
        />
      </ConfigProvider>
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="所属公司"
        onChange={(companyId) => {
          setTempSearchParams(() => ({...tempSearchParams, companyId: companyId || null}))
        }}
      > 
        {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Button onClick={() => {
        if (!tempSearchParams.companyId) return message.error('请选择公司')
        setSearchParams(()=>({...tempSearchParams}))
        uniAxList(dataFlowAdjustsum)
      }} type='primary'>查询</Button>&nbsp;
      {
        mainControl.isBalance
        ? <img src={require('../../materials/img/pingheng.png')} alt="" /> 
        : <img src={require('../../materials/img/bupingheng.png')} alt="" />
      }
      </p> */}
      <div className={tablecss.detailtable}>
        <Table
          size='small'
          rowKey={'id'}
          columns={columns}
          // rowSelection={{ ...uniSelectRows(setSelectedRowKeys, setSelectedRows), checkStrictly:true }}
          dataSource={rowList}
          pagination={false}
          scroll={{y:'calc(100vh - 232px)'}}
          loading={tableLoading}
          expandable={{defaultExpandedRowKeys:[-1,-2,-3]}}
          sticky
        />
      </div>
      <Modal
        width={800}
        maskClosable={false}
        title={'编辑公式-'+currentRow.name}
        visible={subjectModal}
        onCancel={()=>{
          setSubjectModal(false)
        }}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>{
          setSubjectModal(false)
        }}>取消</Button>]}
      >
        {/* <Select
          showSearch
          optionFilterProp='children'
          allowClear
          style={{ width: 200 }}
          placeholder="会计科目"
          onChange={(value:any,operation:any)=>{
            setSubjectModalControl(()=>({...subjectModalControl,selectSubjectId:value,selectSubjectNo:value?operation.children.split(' ')[0]:null,selectSubjectName:value?operation.children.split(' ')[1]:null}))
          }}
        > 
          {subjectList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.subjectNo+' '+item.name}</Select.Option>)}
        </Select>&nbsp; */}
        <TreeSelect
          showSearch
          treeNodeFilterProp='title'
          fieldNames={{value: 'id'} as any}
          style={{ width: 200 }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          placeholder="会计科目"
          treeData={subjectList}
          onChange={(value, children:any, record) => {
            let temparr = children[0].split(' ')
            setSubjectModalControl(()=>({...subjectModalControl,selectSubjectId:value,selectSubjectNo:temparr[0],selectSubjectName:temparr[1]}))
          }}
        />&nbsp;
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="运算符号"
          onChange={(value) => setSubjectModalControl(()=>({...subjectModalControl,selectCalculate:value}))}
        > 
          {calculateTypes.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
        </Select>&nbsp;
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="取数规则"
          onChange={(value) => setSubjectModalControl(()=>({...subjectModalControl,selectCalculateType:value}))}
        > 
          {calculateTypeTypes.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
        </Select>&nbsp;
        <Button loading={modalLoading} onClick={() => {
          if (subjectModalControl.selectSubjectId && subjectModalControl.selectCalculate && subjectModalControl.selectCalculateType) {
            uniAxList({...dataCreateCashflowSubject,params:{
              calculate:subjectModalControl.selectCalculate,
              calculateType:subjectModalControl.selectCalculateType,
              cashFlowId:currentRow.id,
              cashFlowName:currentRow.name,
              subjectId:subjectModalControl.selectSubjectId,
              subjectName:subjectModalControl.selectSubjectName,
              subjectNo:subjectModalControl.selectSubjectNo
            }})
          }
        }} type='primary'>添加</Button>
        <p></p>
        <Table
          size='small'
          rowKey={'cashFlowSubjectId'}
          columns={columnsInSubjectModal}
          dataSource={subjectModalControl.subjectList}
          pagination={false}
          loading={subjectTableLoading}
          sticky
        />
      </Modal>
      <Modal
        className={modalcss.fixheight}
        visible={adjustModal}
        title="调整本月金额"
        destroyOnClose
        onCancel={()=>setAdjustModal(()=>false)}
        footer={[<Button key="back" onClick={()=>setAdjustModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => {
          console.log('companflowlist--------',companyFlowList)
          let creatArr:any = []
          let updateArr:any = []
          companyFlowList.forEach((item:any)=>{
            if (item.id) updateArr.push(item)
            else creatArr.push(item)
          })
          if(creatArr.length === 0) uniAxList({...dataUpdateFlowAdjust,params:updateArr,awaitFunc:()=>uniAxList(dataFlowAdjustsum)})
          else if(updateArr.length === 0) uniAxList({...dataCreateFlowAdjust,params:creatArr,awaitFunc:()=>uniAxList(dataFlowAdjustsum)})
          else {
            uniAxList({...dataCreateFlowAdjust,params:creatArr,finishLoading:false,awaitFunc:()=>{
              uniAxList({...dataUpdateFlowAdjust,params:updateArr,awaitFunc:()=>uniAxList(dataFlowAdjustsum)})
            }})
          }
        }}>确定</Button>]}
      >
        <div className={tablecss.default12}>
          <div style={{display:'flex',justifyContent:'space-between'}}>
            <span><b>项目:</b> {currentRow.name}</span>
            <span><b>本月金额:</b> {(currentRow.monthPrice/100).toFixed(2)}</span>
          </div>
          <p></p>
          <Table
            rowKey={'companyId'}
            loading={adjustTableLoading}
            size='small'
            columns={adjustColumns}
            dataSource={companyFlowList}
            pagination={false}
          />
        </div>
      </Modal>
    </>
  )
}
