import React from 'react'
import { Table, Typography } from 'antd'

export default function SummeryRow(props:any) {
  const {pageData, sumInfo, total, title,excludeRow} = props
  const copyInfo = sumInfo.map((item:any)=>({...item})) //深拷贝，react会执行两次，浅拷贝或直接用sumInfo会重复计算

  const elements = () => {
    let elementList = []
    let renderInfo = null
    //计算统计值
    pageData.forEach((pageItem:any) => {
      for (let i=0; i<copyInfo.length; i++) {
        if (copyInfo[i].alterKey && pageItem[copyInfo[i].alterKey] && (!excludeRow || pageItem[excludeRow.key]===null || pageItem[excludeRow.key] !== excludeRow.value)) {
          if (sumInfo[i].type && sumInfo[i].type==='price') sumInfo[i].sum += (pageItem[copyInfo[i].alterKey]/100)
          else sumInfo[i].sum += pageItem[copyInfo[i].alterKey]
        }
        else if (!excludeRow || pageItem[excludeRow.key]===null || pageItem[excludeRow.key] !== excludeRow.value) {
          copyInfo[i].mapKeys.forEach((mapKey:string) => {
            if (pageItem[mapKey]) {
              if (copyInfo[i].type && copyInfo[i].type==='price') copyInfo[i].sum += (pageItem[mapKey]/100)
              else copyInfo[i].sum += pageItem[mapKey]
            }
          })
        }
      }
    })
    //生成dom
    for (let i=2; i<total; i++) {
      if (renderInfo=copyInfo.find((item:any)=>item.mapIndex===i)) elementList.push(<Table.Summary.Cell key={i} index={i}><Typography.Text >{renderInfo.sum.toFixed(2)}</Typography.Text></Table.Summary.Cell>)
      else elementList.push(<Table.Summary.Cell key={i} index={i}><Typography.Text ></Typography.Text></Table.Summary.Cell>)
    }

    return elementList
  }
  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0}><Typography.Text ></Typography.Text></Table.Summary.Cell>
      <Table.Summary.Cell index={1}><Typography.Text >{title}</Typography.Text></Table.Summary.Cell>
      {
        elements()
      }
    </Table.Summary.Row>
  )
}
