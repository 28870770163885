import React,{ Fragment } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, Route, Router, Routes, useLocation, useNavigate, useRoutes } from "react-router-dom";

import './css/app.less'
import NavRoutes from "./route/NavRoutes";
import lazyRouteList from './route/routes'
import { getLocalItem, setLocalItem, getUserLocal, getUserToken } from './utils/localstorage'
import { setNavigator } from './utils/store'

function App(props:any) {
  const pathname = '/'
  //hooks
  let location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  /* useeffect */
  //监听路由变化
  React.useEffect(() => {
    if (location.pathname===pathname && getUserToken()) navigate('/indexpage', {replace:true})
  }, [location])
  /* mounted-将navigate加入到store */
  React.useEffect(() => {
    dispatch(setNavigator(navigate))
  }, []);
  // const approutes = useRoutes(lazyRouteList)
  return (
    <div className="app">
      <Outlet />
      <NavRoutes />
      {/* {approutes} */}
    </div>
  );
}

export default App;
