import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, ConfigProvider, DatePicker, FormInstance, Input, Modal, Pagination, Select, Table, TablePaginationConfig, Tooltip, TreeSelect, Typography } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { getCompanyList, getCostTypeTree, getHeadbillList, getHeadbillTotal, importHeadBill } from '../../../utils/api'
import { uniAddRowSubmit, uniApiStatic, uniAxList, uniAxSearchList, uniChgRowSubmit, uniCurrentTime, uniDeepArray, uniExportExcel, uniFilterItem, uniModifyAxList, uniModifyRow, uniQuitModal, uniSelectRows, uniToProof } from '../../../utils/uniFucs'
import { csDateRages, dateTypes, monthRages, proofStatusList, staffCashType } from '../../../utils/constant'
import FormModal from '../../../components/FormModal';
import ImportModal from '../../../components/ImportModal';
import numrangecss from '../../../css/numrange.module.less'
import formModal from '../../../css/formModal.module.less'
import tablecss from '../../../css/custable.module.less'

export default function HQData() {
  const pathname = "/indexpage/hqdata"
  /* hooks */
  let location = useLocation()
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [logModal, setLogModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [staticModal, setStaticModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [logList, setLogList] = useState([])
  const [rowList, setRowList] = useState([])
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({businessId:true,costTypeId:true,collectionPrice:true,payPrice:false,bothPrice:false,servicePrice:false,collectionAccountId:true,payAccountId:false,serviceAccountId:false,collectionTime:true,payTime:false,bothTime:false})
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [currentRow, setCurrentRow] = useState({} as any)
  const [searchParams, setSearchParams] = useState({page:1,orderBy:'create_date',orderDir:'desc'} as any)
  const [tempSearchParams, setTempSearchParams] = useState({page:1,orderBy:'create_date',orderDir:'desc'} as any)
  const [staffList, setStaffList] = useState([])
  const [feeTypeList, setFeeTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
  const [accountList, setAccountList] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [unCashRows, setUnCashRows] = useState([] as any)
  const [isUnderLinePay, setIsUnderLinePay] = useState(true)
  const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
  const [cancelRemark, setCancelRemark] = useState('')
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [positionList, setPositionList] = useState([])
  const [custList, setCustList] = useState([])
  const [confirmItemVisible, setConfirmItemVisible] = useState({paytype: 2})
  const [confirmCompanyList, setConfirmCompanyList] = useState([])
  const [confirmModalInitial, setConfirmModalInitial] = useState({})
  const [currentConfirmRow, setCurrentConfirmRow] = useState({} as any)
  const [custCompanyList, setCustCompanyList] = useState([])
  const [addTableData, setAddTableData] = useState([])
  const [modalCpBusinessList, setModalCpBusinessList] = useState([])
  const [modalFeeTypeList, setModalFeeTypeList] = useState([])
  const [importModal, setImportModal] = useState(false)
  const [loadFlag, setLoadFlag] = useState(false)
  const [importAxdata, setImportAxdata] = useState({} as any)
  const [modalFresh, setModalFresh] = useState({value:true})
  const [exportLoading, setExportLoading] = useState(false)
  const [staticLoading,setStaticLoading] = useState(false)
  const [selectControl,setSelectControl] = useState({minIn:'',maxIn:'',minOut:'',maxOut:''} as any)

  /* funcs */
  //路由刷新请求
  const routerFlash = () => {
    uniAxList(uniRowData)
    uniAxList(uniCompanyData)
    uniAxList(uniCostTypeData)
  }
  /* const modifyStatic = (staticRows:any) => {
    let totalin = 0
    let totalout = 0
    staticRows.forEach((item:any) => {
      item.inPrice = Number((item.inPrice / 100).toFixed(2))
      item.outPrice = Number((item.outPrice / 100).toFixed(2))
      totalin += item.inPrice
      totalout += item.outPrice
    })
    staticRows.push({companyId: 500, companyName: "总计", inPrice: totalin.toFixed(2), outPrice: totalout.toFixed(2)})
    return staticRows
  } */
  //数据统计
  const onStatic = async () => {
    setStaticModal(()=>true)
    setStaticLoading(()=>true)
    let totalRow = {costTypeId:-1,costTypeName:'合计',inPrice:0,outPrice:0,netPrice:0}
    if (searchParams.filter_BETWEENS_pay_ym && searchParams.filter_BETWEENS_bill_ym) {
      setStaticTableData(()=>[totalRow])
      setStaticLoading(()=>false)
    } else {
      let payrange = searchParams.filter_BETWEENS_pay_ym ?searchParams.filter_BETWEENS_pay_ym:searchParams.filter_BETWEENS_bill_ym
      let tempRows:any = []
      let res = await getHeadbillList({...searchParams,filter_EQL_type:2,page:0,filter_BETWEENS_bill_ym:null,filter_BETWEENS_pay_ym:payrange})
      res.data.forEach((item:any)=>{
        totalRow.inPrice+=item.inPrice
        totalRow.outPrice+=item.outPrice
        let tempRecord = tempRows.find((tempItem:any)=>tempItem.costTypeId === item.costTypeId)
        if (tempRecord) {
          tempRecord.inPrice += item.inPrice
          tempRecord.outPrice += item.outPrice
          tempRecord.netPrice += (item.inPrice - item.outPrice)
        }
        else tempRows.push({costTypeId:item.costTypeId,costTypeName:item.costTypePidName+'-'+item.costTypeName,inPrice:item.inPrice,outPrice:item.outPrice,netPrice:(item.inPrice - item.outPrice)})
      })
      setStaticTableData(()=>[...tempRows,{...totalRow,netPrice:totalRow.inPrice-totalRow.outPrice}])
      setStaticLoading(()=>false)
    }
  }

  /* elements */
  //列项
  const columns:any = [
    {
      title: '所属公司',
      dataIndex: 'companyName',
      key: 'companyName'
    },
    {
      title: '费用类型',
      dataIndex: 'costTypeName',
      key: 'costTypeName'
    },
    {
      title: '父费用类型',
      dataIndex: 'costTypePidName',
      key: 'costTypePidName'
    },
    {
      title: '备注摘要',
      dataIndex: 'remark',
      key: 'remark',
      ellipsis: {
        showTitle: false,
      },
      render: (remark:string) => (
        <Tooltip placement="topLeft" title={remark}>
          {remark}
        </Tooltip>
      ),
    },
    {
      title: '收入金额',
      dataIndex: 'inPrice',
      key: 'inPrice',
      sorter: (a:any, b:any) => a.inPrice - b.inPrice,
      render: (value: any, record:any) =><Typography.Link
        onClick={()=>navigate('/indexpage/hqorigin', {replace: true, state: {companyId:record.companyId,costTypeName:record.costTypeName,billYm:record.billYm,payYm:record.payYm,type:record.type}})}
      >{(value/100).toFixed(2)}</Typography.Link>
    },
    {
      title: '支付金额',
      dataIndex: 'outPrice',
      key: 'outPrice',
      sorter: (a:any, b:any) => a.outPrice - b.outPrice,
      render: (value: any, record:any) =><Typography.Link
        onClick={()=>navigate('/indexpage/hqorigin', {replace: true, state: {companyId:record.companyId,costTypeName:record.costTypeName,billYm:record.billYm,payYm:record.payYm,type:record.type}})}
      >{(value/100).toFixed(2)}</Typography.Link>
    },
    {
      title: '账单月份',
      dataIndex: 'billYm',
      key: 'billYm',
      render: (value:string) => value ? value.split(' ')[0] : ''
    },
    {
      title: '结算月份',
      dataIndex: 'payYm',
      key: 'payYm',
      render: (value:string) => value ? value.split(' ')[0] : ''
    },
    {
      title: '最大付款时间',
      dataIndex: 'payMaxTime',
      key: 'payMaxTime'
    },
    {
      title: '凭证状态',
      dataIndex: 'proofStatus',
      key: 'proofStatus',
      render: (proofStatus: number) => {
        let proofStatusItem:any = uniFilterItem(proofStatusList,'value',proofStatus)
        return proofStatusItem ? proofStatusItem.name : proofStatus
      }
    },
    {
      title: '凭证号',
      dataIndex: 'proofNo',
      key: 'proofNo',
      render: (value:string,record:any) => {
        let prooflist = value ? value.split(',') : []
        return prooflist.map((item:string) => <span><Typography.Link onClick={()=>uniToProof(record,item, navigate)}>{item}</Typography.Link> </span>)
      }
    },
    {
      title: '导入时间',
      dataIndex: 'importTime',
      key: 'importTime'
    }
  ]
  // 导入modal中的item
  const importItems = [
    {
      label: '公司',
      name: 'companyId',
      rules: [{ required: true, message: '所属公司不能为空' }],
      element: <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="所属公司"
                  onChange={(value)=>setImportAxdata(()=>({...importAxdata,companyId:value}))}
                >
                  {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                </Select>
    },
  ]
  //统计table的列
  const staticColumns:any = [
    {
      title: '费用类型',
      dataIndex: 'costTypeName',
      key: 'costTypeName',
      width: 400
    },
    {
      title: '收入',
      dataIndex: 'inPrice',
      key: 'inPrice',
      render:(value:any)=>(value && !isNaN(value)) ? (value/100).toFixed(2) : value
    },
    {
      title: '支出',
      dataIndex: 'outPrice',
      key: 'outPrice',
      render:(value:any)=>(value && !isNaN(value)) ? (value/100).toFixed(2) : value
    },
    {
      title: '收入-支出',
      dataIndex: 'netPrice',
      key: 'netPrice',
      render:(value:any)=>(value && !isNaN(value)) ? (value/100).toFixed(2) : value
    }
  ]
  /* const staticColumns:any = [
    {
      title: '',
      dataIndex: 'companyName',
      key: 'companyName'
    },
    {
      title: '收入',
      dataIndex: 'inPrice',
      key: 'inPrice',
    },
    {
      title: '支出',
      dataIndex: 'outPrice',
      key: 'outPrice',
    }
  ] */

  /* 接口参数 */
  const uniRowData:any = {
    params: {...searchParams, current:pagination.current, size:pagination.pageSize},
    axApi: getHeadbillList,
    setList: setRowList,
    setTableLoading,
    pagination
  }
  const uniCompanyData:any = {
    params:{filter_EQL_type:1},
    axApi: getCompanyList,
    setList: setCompanyList,
  }
  //费用类型
  const uniCostTypeData:any = {
    params: {size:9999},
    axApi: getCostTypeTree,
    modifyFunc: uniModifyAxList,
    modifyData: {titleList:['title','name']},
    setList: setFeeTypeList
  }
  /* const uniStaticData:any = {
    axApi: getHeadbillTotal,
    modifyFunc: modifyStatic,
    setList: setStaticTableData,
  } */

  //导出当前页，需要的数据修饰
  const exportModify:any = {
    splitTime: ['payTime','billTime'],
    divideKey:[
      {newKey: 'inPrice', key:'inPrice', value: 100},
      {newKey: 'outPrice', key:'outPrice', value: 100}
    ],
    checkStatus:[
      {newKey:'proofStatus', checkKey: 'proofStatus', sourceList:proofStatusList, sourceCheckKey:'value', sourceValueKey:'name'},
    ],
    notNullColumns: columns,
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    uniAxList(uniRowData)
    uniAxList(uniCompanyData)
    uniAxList(uniCostTypeData)
    setLoadFlag(()=>true)
  }, [])

  return (
    <>
      <p>
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="所属公司"
        onChange={(companyId) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_company_id:companyId || null}))}
      > 
        {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <TreeSelect
        showSearch
        treeNodeFilterProp='title'
        fieldNames={{value: 'id'} as any}
        style={{ width: 200 }}
        treeData={feeTypeList}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        placeholder="费用类型"
        allowClear
        onChange={(costTypeId)=> setTempSearchParams(() => ({...tempSearchParams, filter_EQL_cost_type_id:costTypeId || null}))}
      />&nbsp;
      <Input
        allowClear
        style={{ width: 200 }}
        placeholder="父费用类型"
        onChange={(e) => setTempSearchParams(() => ({...tempSearchParams, filter_LIKES_cost_type_pid_name:e.target.value || null}))}
      ></Input>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="日期类型"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQL_type:value || null}))}
      > 
        {dateTypes.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="收支类型"
        onChange={(value) => {
          if (value===1) setTempSearchParams(() => ({...tempSearchParams, filter_GTL_in_price:0, filter_GTL_out_price:null}))
          else if (value===2) setTempSearchParams(() => ({...tempSearchParams, filter_GTL_in_price:null, filter_GTL_out_price:0}))
          else setTempSearchParams(() => ({...tempSearchParams, filter_GTL_in_price:null, filter_GTL_out_price:null}))
        }}
      > 
        {staffCashType.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Input className={numrangecss['site-input-left']} placeholder="最小收入" onChange={(e)=>setSelectControl(()=>({...selectControl,minIn:e.target.value}))}/>
      <Input className={numrangecss['site-input-split']} placeholder="~" disabled/>
      <Input className={numrangecss['site-input-right']} placeholder="最大收入" onChange={(e)=>setSelectControl(()=>({...selectControl,maxIn:e.target.value}))}/>&nbsp;
      <Input className={numrangecss['site-input-left']} placeholder="最小支出" onChange={(e)=>setSelectControl(()=>({...selectControl,minOut:e.target.value}))}/>
      <Input className={numrangecss['site-input-split']} placeholder="~" disabled/>
      <Input className={numrangecss['site-input-right']} placeholder="最大支出" onChange={(e)=>setSelectControl(()=>({...selectControl,maxOut:e.target.value}))}/>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="凭证状态"
        onChange={(proofStatus) => setTempSearchParams(() => ({...tempSearchParams, filter_EQL_proof_status:proofStatus || null}))}
      > 
        {proofStatusList.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          ranges={monthRages}
          placeholder={['账单开始月份', '账单结束月份']}
          picker='month'
          onChange={(dates, dateStrings) => setTempSearchParams(()=>({...tempSearchParams, filter_BETWEENS_bill_ym: dateStrings[0] ? (dateStrings[0]+","+dateStrings[1]): null}))}
        />
      </ConfigProvider>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          ranges={monthRages}
          placeholder={['结算开始月份', '结算结束月份']}
          picker='month'
          onChange={(dates, dateStrings) => setTempSearchParams(()=>({...tempSearchParams, filter_BETWEENS_pay_ym: dateStrings[0] ? (dateStrings[0]+","+dateStrings[1]): null}))}
        />
      </ConfigProvider>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          placeholder={['创建开始日期', '创建结束日期']}
          ranges={csDateRages}
          onChange={(dates, dateStrings) => setTempSearchParams(()=>({...tempSearchParams, filter_BETWEENS_create_date: dateStrings[0] ? dateStrings[0]+","+dateStrings[1]+' 23:59:59' : null}))}
        />
      </ConfigProvider>&nbsp;
      <Button type='primary' onClick={() => {
        let minIn = (!selectControl.minIn || isNaN(selectControl.minIn)) ? '0':selectControl.minIn*100
        let maxIn = (!selectControl.maxIn || isNaN(selectControl.maxIn)) ? '99999999':selectControl.maxIn*100
        let minOut = (!selectControl.minOut || isNaN(selectControl.minOut)) ? '0':selectControl.minOut*100
        let maxOut = (!selectControl.maxOut || isNaN(selectControl.maxOut)) ? '99999999':selectControl.maxOut*100
        let instring = minIn+','+maxIn
        let outstring = minOut+','+maxOut
        pagination.current=1;
        uniAxSearchList({setSearchParams, uniRowData, params: {...tempSearchParams,filter_BETWEENL_in_price:instring,filter_BETWEENL_out_price:outstring,size:pagination.pageSize}})
      }}
      >查询</Button>
      </p>
      <p>
        {/* <Button type="primary" onClick={() => uniApiStatic({
          axApi:getHeadbillTotal,
          modifyFunc: modifyStatic,
          setStaticModal,
          setStaticTableData,
          setStaticLoading
        })}>数据统计</Button> &nbsp; */}
        <Button type="primary" onClick={() => onStatic()}>数据统计</Button> &nbsp;
        <Button type="primary" onClick={() => {modalFresh.value=true;setImportModal(()=>true)}}>批量导入</Button> &nbsp;
        <Button type="primary" onClick={() => uniExportExcel({columns, rowList: uniDeepArray(rowList), modifyFunc:uniModifyAxList, modifyData:exportModify,title:'总部数据导入', fileName: '总部数据导入'+uniCurrentTime({})})}>导出当前页</Button> &nbsp;
        <Button type="primary" loading={exportLoading} onClick={() => uniExportExcel({columns,setExportLoading, axApi:getHeadbillList, params:{...searchParams, page:null}, modifyFunc:uniModifyAxList, modifyData:{...uniRowData.modifyData, ...exportModify},title:'总部数据导入',fileName: '总部数据导入'+uniCurrentTime({})})}>导出所有页码数据</Button> &nbsp;
      </p>
      <ConfigProvider locale={locale}>
        <Table
          size='small'
          className={tablecss.height280}
          rowKey={'id'}
          columns={columns}
          // rowSelection={{ ...uniSelectRows(setSelectedRowKeys, setSelectedRows), checkStrictly:true }}
          dataSource={rowList}
          pagination={false}
          loading={tableLoading}
          scroll={{x:2000, y:'calc(100vh - 340px)'}}
          // onChange={(newPagination)=>{pagination.pageSize = newPagination.pageSize;pagination.current=newPagination.current ;uniAxList({...uniRowData,params: {...searchParams, current:pagination.current, size:pagination.pageSize}})}}
          sticky
        />
        </ConfigProvider>
      <div style={{textAlign:'right'}}>
        <p></p>
        <ConfigProvider locale={locale}>
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.current}
            total={pagination.total}
            showSizeChanger
            showQuickJumper
            showTotal={total => `共 ${total} 条`}
            onChange={(pageNumbe)=>{
              pagination.current = pageNumbe
              uniAxList({...uniRowData,params:{...searchParams, current:pageNumbe, size:pagination.pageSize}})
            }}
            onShowSizeChange={(current, pageSize)=>{
              pagination.pageSize = pageSize
              uniAxList({...uniRowData,params:{...searchParams, current:pagination.current, size:pageSize}})
            }}
          />
        </ConfigProvider>
      </div>
      <Modal width={1000} visible={staticModal} title="统计" destroyOnClose onCancel={()=>setStaticModal(()=>false)} footer={[<Button key="back" onClick={()=>setStaticModal(()=>false)}>取消</Button>]}>
        <Table
          rowKey={'name'}
          columns={staticColumns}
          dataSource={staticTableData}
          pagination={false}
          scroll={{y:'58.2vh'}}
          loading={staticLoading}
        />
      </Modal>
      <ImportModal
        ref={formModalRef}
        title='批量导入'
        axAction="/xbfs/fin/headBill/import"
        modalVisible={importModal}
        initialValues={{}}
        quitModal={uniQuitModal(setImportModal)}
        modalConfirm={() => uniAxList(uniRowData)}
        companyList={companyList}
        modalFresh={modalFresh}
      />
    </>
  )
}
