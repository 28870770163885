import React, { createRef, useState } from 'react'
import { Button, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, message, Modal, Pagination, Popconfirm, Radio, RadioChangeEvent, Select, Table, TablePaginationConfig, TreeSelect, Typography } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';
import pickerLocale from 'antd/es/date-picker/locale/zh_CN';

import { getCompanyList, updateCompanyAccout, deleteCompanyAccout, getCompanyAccoutDetail, createCompanyAccout, axSubjectList, initCompanyAccout, getBankInfo, getReviewTempList } from '../../../utils/api'
import { CompanyAccountItem, NameValue } from '../../../utils/beans'
import { accountType, bankTypeList, banOrNot, initComapnyAccountItem } from '../../../utils/constant'
import { uniSelectRows, uniAxList, uniAxSearchList, UniDeleteAll, uniQuitModal, uniAddRowSubmit, uniChgRowSubmit, uniModifyRow, uniDeleteRow, companyAccountModifyRow, uniDatePikcerChange, uniModifyAxList, uniFilterItem, uniCurrentTime, uniExportExcel, uniDeepArray, uniFormProcess, uniValueToName, uniInitFormdata, uniOpAlarm } from '../../../utils/uniFucs';
import UniOperationColumn from '../../../components/UniOperationColumn';
import { useLocation } from 'react-router-dom';
import tablecss from '../../../css/custable.module.less'
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../../utils/interfaces';
import TopSearch from '../../../components/TopSearch';
import SuperTable from '../../../components/SuperTable';
import FormDialog from '../../../components/FormDialog';

export default function FirmAccountSetting() {
const pathname = "/indexpage/firmaccountsetting"
const modalRef = createRef<FormInstance>()
const searchRef = createRef<FormInstance>()
const initSearchParams = {page:1,filter_EQB_status:true}
const initItemshow = {realName:false, cardNo:false, beginBalance:false, beginTime:false}
/* hooks */
let location = useLocation()
const formModalRef = createRef<FormInstance>()
const [addModal, setAddModal] = useState(false)
const [chgModal, setChgModal] = useState(false)
const [initModal, setInitModal] = useState(false)
const [companyList, setCompanyList] = useState([])
const [rowList, setRowList] = useState([])
const [backRowList, setBackRowList] = useState([])
const [modalItemShow, setModalItemShow] = useState({...initItemshow} as any)
const [subItemVisile, setSubItemVisile] = useState({cashRadio:true})
const [selectedRowKeys, setSelectedRowKeys] = useState([])
const [selectedRows, setSelectedRows] = useState([] as any)
const [currentRow, setCurrentRow] = useState({} as any)
let [searchParams, setSearchParams] = useState({...initSearchParams} as any)
const [tempSearchParams, setTempSearchParams] = useState({page:1,filter_EQB_status:true} as any)
const [accountantSubjectList, setAccountantSubjectList] = useState([])
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
const [customAddKeys, setCustomAddKeys] = useState({beginBalance:null} as any)
const [loadFlag, setLoadFlag] = useState(false)
const [modalLoading, setModalLoading] = useState(false)
const [bankList, setBankList] = useState([])
const [tableLoading, setTableLoading] = useState(false)
const [exportLoading, setExportLoading] = useState(false)
const [showModal, setShowModal] = useState(false)
const [modalFlag, setModalFlag] = useState('new')
let [formInitials, setFormInitials] = useState({})
let [reviewTempList,setReviewTempList] = useState([{ztStartDate:''}] as any)

/* funcs */
const searchConfirm = async (formValus:any) => {setPagination({...pagination,current:1});
  // searchParams = {...initSearchParams,...formValus}
  searchParams = await uniFormProcess({...initSearchParams,...formValus},searchList)
  uniAxList(dataRow(1))
}
//modal点击取消
const modalCancel = () => {
  setShowModal(()=>false)
  setCurrentRow({})
  setFormInitials({})
  setModalItemShow({...initItemshow})
}
//modal点击确定
const modalConfirm = async (formValues:any) => {
  let tempItems = modalFlag==='init'?initItems:formItems
  let tempparms = await uniFormProcess(formValues,tempItems)
  if (modalFlag === 'new') uniAxList(dataNewrow(tempparms))
  else if (modalFlag === 'update') uniAxList(dataUpdaterow(tempparms))
  else if (modalFlag === 'init') uniAxList(dataInit(tempparms))
}
//批量删除
const onBatchDelete = () => {
  let deleteKeys:any = []
  selectedRows.forEach((item:any) => {
    if (item.canDelete === 1) deleteKeys.push(item.id)
  })
  if (deleteKeys.length <= 0) message.info('没有可以删除的记录')
  else UniDeleteAll({deleteKeyList:selectedRowKeys, deleteApi:deleteCompanyAccout, setSelectedRowKeys,axParams:dataRow()})
}
//路由刷新请求
const routerFlash = () => {
  uniAxList(dataRow())
  uniAxList(uniCompanyData)
  uniAxList(uniAsData)
  uniAxList(uniBankData)
}
//开户类型radio变化，展示/隐藏个别选项
const radioChange = (event:RadioChangeEvent) => {
  if (event.target.value === '银行卡') setModalItemShow(() => ({realName:true, cardNo:true, beginBalance:true, beginTime:true}))
  else if (event.target.value === '支付宝' || event.target.value === '微信') setModalItemShow(() => ({realName:false, cardNo:true, beginBalance:true, beginTime:true}))
  else if (event.target.value === '现金账户') setModalItemShow(() => ({realName:false, cardNo:false, beginBalance:true, beginTime:true}))
  else setModalItemShow(() => ({realName:false, cardNo:false, beginBalance:false, beginTime:false}))
}

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '账户名', prop:'name'},
{label: '公司', prop:'companyId',type:'select',dataList:companyList},
]
//列首行
const columns:ColumnItem[] = [
{title: '账户名称', key: 'name'},
{title: '账户类型',key: 'accountType',custval:({row,value}:any)=>uniValueToName({list:accountType,value})},
{title: '所属公司',key: 'companyId',custval:({row,value}:any)=>uniValueToName({list:companyList,value})},
{title: '开户类型',key: 'bankType'},
{title: '初始余额',key: 'beginBalance',type:'price'},
{title: '当前余额',key: 'balance',type:'price'},
{title: '开户行',key: 'realName'},
{title: '卡号/账号',key: 'cardNo'},
{title: '关联会计科目',key: 'subjectName',custval:({row,value}:any)=>row.subjectNo + value},
{title: '启用状态',key: 'status',type:'tag',tagProps:{values:[false,true]},custval:({row,value}:any)=>uniValueToName({list:banOrNot,value})},
{title: '初始日期',key: 'beginTime',custval:({row,value}:any)=>value ? value.split(' ')[0] : ''},
{title: '创建时间',key: 'createDate'},
{title: '操作',key:'operation',type: 'operation',width:100,opProps:{opList:[
  {name:'修改',action:({row}:any) => {
    setModalFlag('update')
    setCurrentRow(row)
    setFormInitials({...uniInitFormdata(row,formItems),accountSubjectId:row.subjectId})
    if (row.bankType === '银行卡') setModalItemShow(() => ({realName:true, cardNo:true, beginBalance:false, beginTime:false}))
    else if (row.bankType === '支付宝' || row.bankType === '微信') setModalItemShow(() => ({realName:false, cardNo:true, beginBalance:false, beginTime:false}))
    else if (row.bankType === '现金账户') setModalItemShow(() => ({realName:false, cardNo:false, beginBalance:false, beginTime:false}))
    setShowModal(true)
  }},
  {name:'删除',action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataDelrow(row.id)))},
  // {name:'初始化',show:({row}:any)=>row.canInitialize === 1,action:({row}:any) => {
  //   setModalFlag('init')
  //   setCurrentRow(row)
  //   setShowModal(true)
  // }}
]}}
]
//底部操作
const bottomActions:ColumnAction[] = [
{label: '新增', type:'primary', action: () => {setModalFlag('new');setShowModal(() => true)}}
]
//添加和修改中的model项
const formItems:FormItemObj[] = [
{label: '所属公司',prop: 'companyId',type:'select',dataList:companyList,change:(value:any)=>{
  if (modalFlag==='new') {
    let finditem:any = companyList.find((item:any)=>item.id===value)
    modalRef.current?.setFieldsValue({beginTime:(finditem.beginYm?moment(finditem.beginYm):null)})
  }
}},
{label: '账户类型',prop: 'accountType',type:'select',dataList:accountType},
{label: '开户类型',prop: 'bankType',type:'select',dataList:bankTypeList,change:(value:any)=>{
  if (value === '银行卡') setModalItemShow(() => ({realName:true, cardNo:true, beginBalance:false, beginTime:false}))
  else if (value === '支付宝' || value === '微信') setModalItemShow(() => ({realName:false, cardNo:true, beginBalance:false, beginTime:false}))
  else if (value === '现金账户') setModalItemShow(() => ({realName:false, cardNo:false, beginBalance:false, beginTime:false}))
  else setModalItemShow(() => ({realName:false, cardNo:false, beginBalance:false, beginTime:false}))
}},
{label: '账户名称',prop: 'name'},
{label: '开户行',prop: 'realName',required:false,show:modalItemShow.realName},
{label: '卡号/账号',prop: 'cardNo',required:false,show:modalItemShow.cardNo},
{label: '关联会计科目',prop: 'accountSubjectId',type:'tree',treeProps:{propName:'title'},dataList:accountantSubjectList},
// {label: '初始余额',prop: 'beginBalance',type:'price',numProps:{precision:2},show:modalItemShow.beginBalance},
// {label: '初始时间',prop: 'beginTime',type:'datePicker',datepickerProps:{type:'month',returnType:'string'},show:modalItemShow.beginTime},
{label: '初始化余额',prop: 'beginBalance',type:'price',numProps:{precision:2}},
{label: '初始时间',prop: 'beginTime',type:'datePicker',datepickerProps:{type:'month',returnType:'string'},disabled:true,show:modalFlag==='new'},
{label: '启用状态',prop: 'status',required:false,type:'select',dataList:banOrNot}
]
//初始化modal项
const initItems:FormItemObj[] = [
{label: '初始化余额',prop: 'beginBalance',type:'price',numProps:{precision:2}},
{label: '初始时间',prop: 'beginTime',type:'datePicker',datepickerProps:{type:'month',returnType:'string'}},
// {label: '初始时间',prop: 'beginTime',type:'datePicker',datepickerProps:{returnType:'string',disabledDate:(current)=>current && current < moment(reviewTempList[0].ztStartDate, 'YYYY-MM-DD').add(1, 'days')}},
]
//导出当前页，需要的数据修饰
const exportModify:any = {
  checkStatus:[
    {newKey:'accountType', checkKey: 'accountType', sourceList:accountType, sourceCheckKey:'value', sourceValueKey:'name'},
    {newKey:'companyId', checkKey: 'companyId', sourceList:companyList, sourceCheckKey:'id', sourceValueKey:'name'},
    {newKey:'status', checkKey: 'status', sourceList:banOrNot, sourceCheckKey:'value', sourceValueKey:'name'},
  ],
  divideKey:[{newKey: 'beginBalance', key:'beginBalance', value: 100},{newKey: 'balance', key:'balance', value: 100}],
  titleList: ['subjectName','subjectNo','subjectName'],
  splitTime: ['beginTime'],
  notNullColumns: columns
}
/* 接口参数 */
const dataRow = (current?:number):AxDataform => ({
  params: {...searchParams, current: current || pagination.current, size:pagination.pageSize},
  axApi: getCompanyAccoutDetail,
  setList: setRowList,
  setTableLoading,
  setPagination
})
//新建账户
const dataNewrow = (params:any):AxDataform => ({
  params,
  axApi: createCompanyAccout,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//修改账户
const dataUpdaterow = (params:any):AxDataform => ({
  params: {...params, id:currentRow.id},
  axApi: updateCompanyAccout,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//删除行
const dataDelrow = (rowid:any):AxDataform => ({
params: {id:rowid},
axApi: deleteCompanyAccout,
awaitFunc: (predata:any) => uniAxList(dataRow())
})
//初始化余额
const dataInit = (params:any):AxDataform => ({
  params: {...params, id:currentRow.id},
  axApi: initCompanyAccout,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
const uniCompanyData:any = {
  axApi: getCompanyList,
  setList: setCompanyList,
}
const uniAsData = {
  modifyFunc: uniModifyAxList,
  modifyData: {titleList: ['title','subjectNo','name']},
  params: {page:1, size:999},
  axApi: axSubjectList,
  setList: setAccountantSubjectList
}
const uniBankData = {
  axApi: getBankInfo,
  setList: setBankList
}
//账套启用时间
// const uniReviewTempData = {
//   axApi: getReviewTempList,
//   setList: setReviewTempList,
// }
/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])
/* mounted */
React.useEffect(() => {
  uniAxList(dataRow())
  uniAxList(uniCompanyData)
  uniAxList(uniAsData)
  uniAxList(uniBankData)
  // uniAxList(uniReviewTempData)
  setLoadFlag(()=>true)
}, [])
return (
  <>
  <TopSearch
    ref={searchRef}
    searchList={searchList}
    searchConfirm={searchConfirm}
  />
  <SuperTable
    columns={columns}
    rowList={rowList}
    bottomActions={bottomActions}
    tableLoading={tableLoading}
    pagination={pagination}
    dataRow={dataRow}
  />
  <FormDialog
    ref={modalRef}
    title={modalFlag==='new'?'新增账户':modalFlag==='update'?'修改账户':'初始化余额'}
    show={showModal}
    formItems={modalFlag==='init'?initItems:formItems}
    modalLoading={modalLoading}
    formInitials={formInitials}
    showCustom={modalFlag==='init'?true:false}
    cancel={modalCancel}
    confirm={modalConfirm}
  >
    <span>提示: 初始化后，初始化流水是插入到流水明细中的，初始化时间之前的流水将变成无效，此初始化时间之后的流水是有效的</span>
    <span></span>
  </FormDialog>
    {/* <div>
    <Input
      allowClear
      style={{ width: 200 }}
      placeholder="账户名称"
      onChange={(e) => setTempSearchParams(() => ({...tempSearchParams, name: e.target.value || null}))}
    ></Input>&nbsp;
    <Select
      allowClear
      style={{ width: 200 }}
      placeholder="选择公司"
      onChange={(companyId) => setTempSearchParams(() => ({...tempSearchParams, companyId: companyId || null}))}
    >
      {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
    </Select>&nbsp;
    <Button onClick={() => {pagination.current=1;uniAxSearchList({setSearchParams, uniRowData:dataRow(), params: {...tempSearchParams,size:pagination.pageSize}})}} type='primary'>查询</Button>
    </div>
    <p></p>
    <div>
    <Button type="primary" onClick={() => {setModalItemShow(()=>({showRealName:true, showCardNo:true, showBeginBalance:true, showBeginTime:true, showSubject:false})); setAddModal(() => true)}}>新增</Button> &nbsp;
    <Button type="primary" danger disabled={selectedRowKeys.length <= 0}>
      <Popconfirm title="确定批量删除吗?" onConfirm={() => onBatchDelete()} okText="确定" cancelText="取消">批量删除</Popconfirm>
    </Button>&nbsp;
    <Button type="primary" onClick={() => uniExportExcel({columns, rowList: uniDeepArray(rowList), modifyFunc:uniModifyAxList, modifyData:exportModify,title:'公司账户设置', fileName: '公司账户设置'+uniCurrentTime({})})}>导出当前页</Button> &nbsp;
    <Button type="primary" loading={exportLoading} onClick={() => uniExportExcel({columns,setExportLoading, axApi:getCompanyAccoutDetail, params:{...searchParams, page:null}, modifyFunc:uniModifyAxList, modifyData:{...dataRow().modifyData, ...exportModify},title:'公司账户设置',fileName: '公司账户设置'+uniCurrentTime({})})}>导出所有页码数据</Button> &nbsp;
    </div>
    <p></p> */}
    {/* <Table
      size='small'
      className={tablecss.height270}
      rowKey={'id'}
      columns={columns}
      rowSelection={{ ...uniSelectRows(setSelectedRowKeys, setSelectedRows), checkStrictly:true }}
      dataSource={rowList}
      pagination={false}
      loading={tableLoading}
      scroll={{x: 2500,y:'calc(100vh - 300px)'}}
      sticky
    />
    <div style={{textAlign:'right'}}>
      <p></p>
      <ConfigProvider locale={locale}>
        <Pagination
          pageSize={pagination.pageSize}
          current={pagination.current}
          total={pagination.total}
          showSizeChanger
          showQuickJumper
          showTotal={total => `共 ${total} 条`}
          onChange={(pageNumbe)=>{
            pagination.current = pageNumbe
            uniAxList({...dataRow(),params:{...searchParams, current:pageNumbe, size:pagination.pageSize}})
          }}
          onShowSizeChange={(current, pageSize)=>{
            pagination.pageSize = pageSize
            uniAxList({...dataRow(),params:{...searchParams, current:pagination.current, size:pageSize}})
          }}
        />
      </ConfigProvider>
    </div> */}
    {/* <Modal title='新增' visible={addModal} onOk={() => formModalRef.current?.submit()} onCancel={()=> uniQuitModal(setAddModal)(false)} destroyOnClose footer={[<Button key="back" onClick={()=>setAddModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}>
      <Form
        ref={formModalRef}
        name="form"
        labelCol={{ span: 7 }}
        initialValues={{status:true}}
        wrapperCol={{ span: 16 }}
        onFinish={(formValus) => {
          let axValue = {...formValus, beginBalance:formValus.beginBalance * 100}
          uniAddRowSubmit({axGetData:dataRow(),axAddApi:createCompanyAccout,setAddModal,setModalLoading})(axValue)
        }}
        autoComplete="off"
      >
        {modalItems.map((item:any) => (modalItemShow && item.name in modalItemShow && !modalItemShow[item.name]) ? null : <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
      </Form>
    </Modal>
    <Modal title='修改' visible={chgModal} onOk={() => formModalRef.current?.submit()} onCancel={()=> uniQuitModal(setChgModal)(false)} destroyOnClose footer={[<Button key="back" onClick={()=>setChgModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}>
      <Form
        ref={formModalRef}
        name="form"
        labelCol={{ span: 7 }}
        initialValues={{...currentRow}}
        wrapperCol={{ span: 16 }}
        onFinish={(formValus) => {
          let axValue = {...formValus, beginBalance:(formValus.beginBalance * 100).toFixed()}
          uniChgRowSubmit({currentRows:[currentRow],axData:dataRow(),updateApi:updateCompanyAccout,setModal:setChgModal,setModalLoading,customKeys:customAddKeys})(axValue)
        }}
        autoComplete="off"
      >
        {modalItems.map((item:any) => (modalItemShow && item.name in modalItemShow && !modalItemShow[item.name]) ? null : <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
      </Form>
    </Modal> */}
    {/* <Modal width={600} maskClosable={false} title={'初始化余额'} visible={initModal} onOk={() => formModalRef.current?.submit()} onCancel={()=>setInitModal(false)} destroyOnClose footer={[<Button key="back" onClick={()=>setInitModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}>
      <Form
        preserve={false}
        ref={formModalRef}
        name="form"
        labelCol={{ span: 6 }}
        initialValues={{balance:currentRow.beginBalance/100,beginTime:(initModal && currentRow.beginTime) ? moment(currentRow.beginTime.split(' ')[0], 'YYYY-MM-DD') : null as any}}
        wrapperCol={{ span: 16 }}
        onFinish={(formValus) => {
          let axvalue = {...formValus, balance:(formValus.balance*100).toFixed()}
          uniChgRowSubmit({currentRows:[currentRow],axData:dataRow(),updateApi:initCompanyAccout,setModal:setInitModal,setModalLoading})(axvalue)
        }}
        autoComplete="off"
      >
        {initModalItems.map((item:any) => <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
      </Form>
    </Modal> */}
  </>
)
}
