import axios from "axios";
import { message } from 'antd'
import { Md5 } from 'ts-md5'

import { getBaseURL } from "./constant";
import store, { setUser, setAuth } from "./store";
import { getUserLocal, getUserToken, setLocalItem } from "./localstorage";
import { uniAesEncrypt } from "./uniFucs";
import {createHashHistory,createBrowserHistory} from 'history'

declare const window: any;

/**
 * axios相关：拦截器等
 */

// 创建axios实例
const service = axios.create({
  timeout: 30000, // 请求超时时间
  responseType: 'json'
})

// request拦截器
service.interceptors.request.use(config => {
  console.log('store.state-----', store.getState())
  const t = Date.now()
  // application/x-www-form-urlencoded' -config.url === '/xbfs/sys/user/create' ? 'multipart/form-data' : 
  config.headers!['Content-Type'] = 'application/json;charset=UTF-8'
  config.headers!['Access-Control-Allow-Origin'] = '*'
  config.headers!['Access-Control-Allow-Credentials'] = true
  config.headers!['timestamp'] = t
  let tokenString:string = getUserToken()
  if (tokenString) {
    config.headers!['Authorization'] = tokenString.slice(0,tokenString.length-3)
  }

  //打印提交的信息
  console.log('axiosdata-----------: ', config.data)
  // 手动编码，用来传递空格
  // if (config.url === '/xbfs/materiel/materielDayLog/list/day')
  // 序列化 -- 加密提交的信息
  if (config.url === '/xbfs/sys/user/create' || config.url === '/xbfs/sys/user/login' || config.url === '/xbfs/sys/user/changePwd' || config.url === '/xbfs/sys/user/changePwdWithMessageCode') {
    const data:any = uniAesEncrypt(config.data)
    config.data = {data}
    // 签名请求 防止重复提交
    const md5 = new Md5()
    config.headers!['sign'] = md5.appendStr(data + t).end() as string
  }

  config.baseURL = getBaseURL()
  return config
}, error => {
  message.error(error.message);
  return Promise.reject(error)
})

//response拦截器
service.interceptors.response.use(function (response) {
  console.log('response----------',response)
  if (response.data && response.data.code === 200) {
      return response.data
  } else {
      return Promise.reject(response.data.message)
  }
}, function (error) {
  if (error.response) {
    console.log('errorresponese', error.response)
    console.log('windowheref', window.location.href)
    // store.getState().userStore.loadingFunc(()=>false)
    if (error.response.data.message === '认证失败' && (window.location.href.indexOf('/login') !== -1 || window.location.href === getBaseURL())) {
      message.error('用户名或密码错误')
    } else if (error.response.status === 401) {
      // store.getState().navigatorStore.navigator('/login')
      setLocalItem('userLocal', { ...getUserLocal(), token: '' })
      message.error('登录失效，请重新登录')
      createHashHistory().push('/login')
      window.location.reload()
      // window.location.href= process.env.NODE_ENV === 'production' ? 'http://cw.xtgyl.cn/#/login' : 'http://localhost:3000/#/login'
      // window.location.reload()
      // store.dispatch(setUser({isLogin: false}))
    } else if (error.response.status === 404) {
      message.error('记录不存在')
    } else if (error.response['data']) {
      message.error(error.response.data['message'] ? error.response.data['message'] : error.response['data'])
    }
  } else message.error(error['message'] === 'Network Error' ? '网络错误,请检测您的网络' : error['message'])
  return Promise.reject(error.response && error.response.data ? error.response.data : error)
})

export default service
// message.error(error.response.data['message'])