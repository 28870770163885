import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Checkbox, ConfigProvider, DatePicker, FormInstance, Input, Select, Table, TablePaginationConfig } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import numrangecss from '../../css/numrange.module.less'
import { axProofCollection, getCompanyList } from '../../utils/api'
import { uniAxList, uniAxSearchList, uniChildCalculation, uniDeepArray, uniFilterItem, uniFormProcess, uniModifyAxList } from '../../utils/uniFucs'
import { csDateRages, monthRages } from '../../utils/constant';
import tablecss from '../../css/custable.module.less'
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../utils/interfaces';
import TopSearch from '../../components/TopSearch';
import SuperTable from '../../components/SuperTable';

export default function ProofCollection() {
  const pathname = "/indexpage/proofcollection"
  const initMainControl = {showDatarow:false,proofMin:null,proofMax:null}
  const searchRef = createRef<FormInstance>()
  /* hooks */
  let location = useLocation()
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [logModal, setLogModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [staticModal, setStaticModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [logList, setLogList] = useState([])
  const [rowList, setRowList] = useState([])
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({})
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [currentRow, setCurrentRow] = useState({} as any)
  let [searchParams, setSearchParams] = useState({} as any)
  const [tempSearchParams, setTempSearchParams] = useState({} as any)
  const [staffList, setStaffList] = useState([])
  const [feeTypeList, setFeeTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 11})
  const [accountList, setAccountList] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [unCashRows, setUnCashRows] = useState([] as any)
  const [isUnderLinePay, setIsUnderLinePay] = useState(true)
  const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
  const [cancelRemark, setCancelRemark] = useState('')
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [positionList, setPositionList] = useState([])
  const [custList, setCustList] = useState([])
  const [confirmItemVisible, setConfirmItemVisible] = useState({paytype: 2})
  const [confirmCompanyList, setConfirmCompanyList] = useState([])
  const [confirmModalInitial, setConfirmModalInitial] = useState({})
  const [currentConfirmRow, setCurrentConfirmRow] = useState({} as any)
  const [custCompanyList, setCustCompanyList] = useState([])
  const [addTableData, setAddTableData] = useState([])
  const [modalCpBusinessList, setModalCpBusinessList] = useState([])
  const [modalFeeTypeList, setModalFeeTypeList] = useState([])
  const [importModal, setImportModal] = useState(false)
  const [accountantSubjectList, setAccountantSubjectList] = useState([])
  const [businessList, setBusinessList] = useState([])
  const [currentSubjectId, setCurrentSubjectId] = useState({id:1})
  const [loadFlag, setLoadFlag] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [summeryrowlist, setsummeryrolist] = useState([] as any)
  const [affixSummeryrowlist, setaffixSummeryrolist] = useState([] as any)
  const [affixInitRowList, setAffixInitRowList] = useState([] as any)
  const [initRowlist, setInitRowlist] = useState([] as any)
  const [dataRowlist, setDataRowlist] = useState([])
  const [searchYmRange,setSearchYmRange] = useState([] as any)
  let [showDatarow, setShowDatarow] = useState(false)

  /* funcs */
  //路由刷新请求
  const routerFlash = () => {
    uniAxList(uniCompanyData)
  }
  const searchConfirm = async (formValus:any) => {
    setShowDatarow(false)
    searchParams = {...await uniFormProcess(formValus,searchList)}
    setSearchParams({...searchParams})
    uniAxList(dataRow())
  }
  //去除0数据
  const omitNull = (list:any,parent?:any) => {
    let temparr:any = []
    list.forEach((item:any)=>{
      if (item.subjectChangeBorrowPrice || item.subjectChangeLendPrice || item.accountingChangeBorrowPrice || item.accountingChangeLendPrice || item.childaccountborrow || item.childaccountlend) {
        temparr.push(item)
        if (item.children) omitNull(item.children,item)
      }
    })
    if (parent) parent.children = temparr
    return temparr
  }

  /* elements */
  //搜索行
const searchList:FormItemObj[] = [
{label: '公司', prop:'companyId',type:'select',dataList:companyList,required:true,change:(value:any)=>{
  let finditem:any = companyList.find((item:any)=>item.id===value)
  if (finditem.initialize===1) setSearchYmRange([finditem.startYm,finditem.endYm])
  else setSearchYmRange([])
}},
{label: '月份', prop:'ym',type:'datePicker',required:true,datepickerProps:{type:'month',returnType:'string',disabledDate:(current)=>{
  if (searchYmRange.length<2) return true
  else return current<moment(searchYmRange[0]).startOf('month') || current>moment(searchYmRange[1]).endOf('month')
}}}
]
//顶部操作
const topActions:ColumnAction[] = [
{label: '数据切换', type:'primary',render:<Checkbox checked={showDatarow} onChange={(e)=>{
  setShowDatarow(e.target.checked)
  if (e.target.checked) setRowList(()=>dataRowlist)
  else setRowList(()=>initRowlist)
}}>仅显示有数据项</Checkbox>}
]
//列项
const columns:ColumnItem[] = [
{title: '科目编码',key: 'subjectNo',},
{title: '科目名称',key: 'subjectName',},
{title: '科目借方',key: 'subjectChangeBorrowPrice',type:'underline',custval:({row,value}:any)=>value?Number((value/100).toFixed(2)):'',unlineProps:{action:({row,value}:any)=>navigate('/indexpage/detailbook', {replace: true, state: {...row,ym:searchParams.ym,companyId:searchParams.companyId}})}},
{title: '科目贷方',key: 'subjectChangeLendPrice',type:'underline',custval:({row,value}:any)=>value?Number((value/100).toFixed(2)):'',unlineProps:{action:({row,value}:any)=>navigate('/indexpage/detailbook', {replace: true, state: {...row,ym:searchParams.ym,companyId:searchParams.companyId}})}},
{title: '辅助借方',key: 'accountingChangeBorrowPrice',type:'underline',custval:({row,value}:any)=>value?Number((value/100).toFixed(2)):'',unlineProps:{action:({row,value}:any)=>navigate('/indexpage/affixdetail', {replace: true, state: {...row,ym:searchParams.ym,companyId:searchParams.companyId}})}},
{title: '辅助贷方',key: 'accountingChangeLendPrice',type:'underline',custval:({row,value}:any)=>value?Number((value/100).toFixed(2)):'',unlineProps:{action:({row,value}:any)=>navigate('/indexpage/affixdetail', {replace: true, state: {...row,ym:searchParams.ym,companyId:searchParams.companyId}})}},
]

  /* 接口参数 */
  const dataRow = ():AxDataform => ({
    params: {...searchParams},
    axApi: axProofCollection,
    itemModify:{modifyFunc:({item,parentItem}:any)=>{
      if (parentItem) {
        parentItem.childaccountborrow = (parentItem.childaccountborrow || 0) + item.accountingChangeBorrowPrice
        parentItem.childaccountlend = (parentItem.childaccountlend || 0) + item.accountingChangeLendPrice
      }
      if (item.children && item.children.length<=0) delete item.children
    }},
    setList: setRowList,
    setTableLoading,
    awaitFunc: (predata:any) => {
      setInitRowlist([...predata])
      let temparr:any = uniDeepArray(predata)
      let templist = omitNull(temparr)
      setDataRowlist(templist)
    }
  })
  // const uniRowData:any = {
  //   params: searchParams,
  //   axApi: axProofCollection,
  //   modifyFunc: uniChildCalculation,
  //   modifyData: ['inPrice','outPrice'],
  //   setList: mainControl.showDatarow ? null : setRowList,
  //   setTableLoading,
  //   awaitFunc: (preRowlist:any) => {
  //     let copyrowlist = uniDeepArray(preRowlist)
  //     copyrowlist.forEach((copyItem:any)=>{
  //       if (copyItem.level===1 && copyItem.inPrice===0 && copyItem.outPrice === 0) copyrowlist = copyrowlist.filter((filteritem:any)=>filteritem.subjectId !== copyItem.subjectId)
  //       else uniModifyAxList([copyItem],{custFunc:(modifyItem:any)=>{
  //         let parentitem:any = uniFilterItem([copyItem],'subjectId',modifyItem.parentId)
  //         if (parentitem && parentitem.children && modifyItem.inPrice===0 && modifyItem.outPrice===0) {
  //           parentitem.children = parentitem.children.filter((childitem:any)=>childitem.subjectId !== modifyItem.subjectId)
  //           if (parentitem.children.length === 0) delete parentitem.children
  //         }
  //       }})
  //     })
  //     setInitRowlist(()=>preRowlist)
  //     setDataRowlist(()=>copyrowlist)
  //     if (mainControl.showDatarow) setRowList(()=>copyrowlist)
  //   }
  // }
  const uniCompanyData:any = {
    axApi: getCompanyList,
    setList: setCompanyList,
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location]);
  /* mounted */
  React.useEffect(() => {
    uniAxList(uniCompanyData)
  }, [])

  return (<>
    <TopSearch
      ref={searchRef}
      searchList={searchList}
      topActions={topActions}
      searchConfirm={searchConfirm}
    />
    <SuperTable
      rowKey='subjectId'
      bordered={true}
      columns={columns}
      rowList={rowList}
      tableLoading={tableLoading}
      dataRow={dataRow}
      rowSelection={{setSelectedRowKeys, setSelectedRows}}
    />
    {/* <p>
    <Select
      allowClear
      style={{ width: 200 }}
      placeholder="所属公司"
      onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, companyId:value || null}))}
    > 
      {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
    </Select>&nbsp;
    <ConfigProvider locale={locale}>
      <DatePicker.RangePicker
        placeholder={['开始日期', '结束日期']}
        ranges={csDateRages}
        onChange={(dates, dateStrings) => {
          setTempSearchParams(()=>({...tempSearchParams, startYmd: dateStrings[0] ? dateStrings[0]:null, endYmd:dateStrings[1]?(dateStrings[1]+' 23:59:59') : null}))
        }}
      />
    </ConfigProvider>&nbsp;
    <Input className={numrangecss['site-input-left']} placeholder="最小凭证号" value={mainControl.proofMin} onBlur={()=>setMainControl(()=>({...mainControl,proofMin:tempSearchParams.beginProofNo}))} onChange={(e)=>{
      let value:any = e.target.value
      mainControl.proofMin = value
      let proofMin:any
      if (value && isNaN(value)) proofMin = '0000001'
      else if (value) proofMin = value.padStart(7,"0")
      else proofMin = null
      setTempSearchParams(() => ({...tempSearchParams, beginProofNo:proofMin || null}))
    }}/>
    <Input className={numrangecss['site-input-split']} placeholder="~" disabled/>
    <Input className={numrangecss['site-input-right']} placeholder="最大凭证号" value={mainControl.proofMax} onBlur={()=>setMainControl(()=>({...mainControl,proofMax:tempSearchParams.endProofNo}))} onChange={(e)=>{
      let value:any = e.target.value
      mainControl.proofMax = value
      let proofMax:any
      if (value && isNaN(value)) proofMax = '0000001'
      else if (value) proofMax = value.padStart(7,"0")
      else proofMax = null
      setTempSearchParams(() => ({...tempSearchParams, endProofNo:proofMax || null}))
    }}/>&nbsp;
    <Button onClick={() => uniAxSearchList({setSearchParams, uniRowData, params:tempSearchParams})} type='primary'>查询</Button>&nbsp;
    <Checkbox checked={mainControl.showDatarow} onChange={(e)=>{
        mainControl.showDatarow = e.target.checked
        if (e.target.checked) setRowList(()=>dataRowlist)
        else setRowList(()=>initRowlist)
      }}>仅显示有数据项</Checkbox>&nbsp;
    </p> */}
    {/* <Table
      size='small'
      className={tablecss.height260}
      bordered={true}
      rowKey={'subjectId'}
      columns={columns}
      dataSource={rowList}
      pagination={false}
      scroll={{y:'calc(100vh - 260px)'}}
      loading={tableLoading}
      summary={(pageData) => <SummeryRow pageData={pageData} excludeRow={{key:'isSummary',value:true}} sumInfo={summeryRow} total={8} title={'总金额'} />}
      sticky
    />
    <div style={{width:'100',display:'flex'}}>
      <div style={{flex:1,paddingLeft:'16px',paddingTop:'10px'}}></div>
      <div style={{flex:1,paddingLeft:'16px',paddingTop:'10px'}}>总计</div>
      <div style={{flex:1,paddingLeft:'16px',paddingTop:'10px'}}>{(rowList.reduce((total:number,rowitem:any)=>total+rowitem.inPrice,0) / 100).toFixed(2)}</div>
      <div style={{flex:1,paddingLeft:'16px',paddingTop:'10px'}}>{(rowList.reduce((total:number,rowitem:any)=>total+rowitem.outPrice,0) / 100).toFixed(2)}</div>
    </div> */}
  </>)

}
