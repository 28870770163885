import React, { Children, createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker,Tree, Divider, FormInstance, Input, List, Pagination, Select, Table, TablePaginationConfig, Tabs, Tooltip, TreeSelect, Typography, Spin, message } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { axSubjectList, getCompanyList, getProofInitBalance, getProofList, singleSubjectRemainder } from '../../utils/api'
import { uniAxList, uniAxSearchList, uniCurrentTime, uniDeepArray, uniExportExcel, uniFilterItem, uniModifyAxList, uniSelectRows, uniToProof } from '../../utils/uniFucs'
import { leadBorrow, monthRages } from '../../utils/constant';
import tablecss from '../../css/custable.module.less'

export default function CashDaily() {
  const pathname = "/indexpage/cashdaily"
  const initMainControl = {selectSubject:null,beginYm:uniCurrentTime({day:false}),endYm:uniCurrentTime({day:false})}
  /* hooks */
  let location = useLocation()
  const {state} = useLocation()
  const navigate = useNavigate()
  const treeRef = createRef<any>()
  const listRef = createRef<any>()
  const formModalRef = createRef<FormInstance>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [logModal, setLogModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [staticModal, setStaticModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [logList, setLogList] = useState([])
  const [rowList, setRowList] = useState([] as any)
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({businessId:true,costTypeId:true,collectionPrice:true,payPrice:false,bothPrice:false,servicePrice:false,collectionAccountId:true,payAccountId:false,serviceAccountId:false,collectionTime:true,payTime:false,bothTime:false})
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [currentRow, setCurrentRow] = useState({} as any)
  const [searchParams, setSearchParams] = useState({filter_BETWEENS_bill_ymd:moment().startOf('month').format('YYYY-MM-DD')+','+moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')} as any)
  const [tempSearchParams, setTempSearchParams] = useState({filter_BETWEENS_bill_ymd:moment().startOf('month').format('YYYY-MM-DD')+','+moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')} as any)
  const [staffList, setStaffList] = useState([])
  const [feeTypeList, setFeeTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 11})
  const [accountList, setAccountList] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [unCashRows, setUnCashRows] = useState([] as any)
  const [isUnderLinePay, setIsUnderLinePay] = useState(true)
  const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
  const [cancelRemark, setCancelRemark] = useState('')
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [positionList, setPositionList] = useState([])
  const [custList, setCustList] = useState([])
  const [confirmItemVisible, setConfirmItemVisible] = useState({paytype: 2})
  const [confirmCompanyList, setConfirmCompanyList] = useState([])
  const [confirmModalInitial, setConfirmModalInitial] = useState({})
  const [currentConfirmRow, setCurrentConfirmRow] = useState({} as any)
  const [custCompanyList, setCustCompanyList] = useState([])
  const [addTableData, setAddTableData] = useState([])
  const [modalCpBusinessList, setModalCpBusinessList] = useState([])
  const [modalFeeTypeList, setModalFeeTypeList] = useState([])
  const [importModal, setImportModal] = useState(false)
  const [accountantSubjectList, setAccountantSubjectList] = useState([])
  const [businessList, setBusinessList] = useState([])
  const [currentSubjectId, setCurrentSubjectId] = useState({id:1,borrowLend:1})
  const [loadFlag, setLoadFlag] = useState(false)
  const [keyRow,setKeyRow] = useState({} as any)
  const [selectControl, setSelectControl] = useState({payYmd:[],companyId:null} as any)
  const [tablist,setTablist] = useState({tab1:[],tab2:[],tab3:[],tab4:[],tab5:[],tab6:[]} as any)
  const [mainControl,setMainControl] = useState({selectSubject:null,beginYm:uniCurrentTime({day:false}),endYm:uniCurrentTime({day:false})} as any)
  const [repeatFlag, setRepeatFlag] = useState(false)
  
  /* funcs */
  //路由刷新请求
  const routerFlash = () => {
    uniAxList(uniAsData)
    uniAxList(uniCompanyData)
  }
  /* elements */
  //列项
  const columns:any = [
    {
      title: '日期',
      dataIndex: 'billYmd',
      key: 'billYmd',
      width: 150,
    },
    {
      title: '凭证号',
      dataIndex: 'proofNo',
      key: 'proofNo',
      render: (value:string,record:any) => {
        let prooflist = value ? value.split(',') : []
        return prooflist.map((item:string) => <span><Typography.Link onClick={()=>uniToProof(record,item, navigate,null,'list2')}>{item}</Typography.Link> </span>)
      }
    },
    {
      title: '摘要',
      dataIndex: 'summary',
      key: 'summary',
      ellipsis: {showTitle: false},
      render: (value:string) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>
    },
    {
      title: '公司',
      dataIndex: 'companyName',
      key: 'companyName'
    },
    {
      title: '借方金额',
      dataIndex: 'inPrice',
      key: 'inPrice',
      render: (value:any, record:any) => record.borrowLend === 1 && !isNaN(record.price) ? (record.price / 100).toFixed(2) : 0
    },
    {
      title: '贷方金额',
      dataIndex: 'outPrice',
      key: 'outPrice',
      render: (value:any, record:any) => record.borrowLend === 2 && !isNaN(record.price) ? (record.price / 100).toFixed(2) : 0
    },
    {
      title: '方向',
      dataIndex: 'borrowLend',
      key: 'borrowLend',
      render: (value:any) => {
        let tempItem:any = uniFilterItem(leadBorrow, 'value', value)
        return tempItem ? tempItem.name : value
      }
    },
    {
      title: '余额',
      dataIndex: 'balance',
      key: 'balance',
      render: (value:any,record:any) => (value / 100).toFixed(2)
    },
  ]
  /* 接口参数 */
  const uniRowData:any = {
    params: searchParams,
    axApi: getProofList
  }
  const uniAsData = {
    params: {filter_EQL_cash_status:1},
    axApi: axSubjectList,
    setList: setAccountantSubjectList,
    setTableLoading: !loadFlag? setTableLoading : null,
    finishLoading:false,
    awaitFunc:(preSubjectList:any)=>{
      if (preSubjectList.length===0) {
        if(!repeatFlag) {
          message.warning('还没有设置现金日记账科目')
          setRepeatFlag(()=>true)
        }
        setTableLoading(()=>false)
        return
      }
      tempSearchParams.filter_EQS_subject_id = preSubjectList[0].id
      searchParams.filter_EQS_subject_id = preSubjectList[0].id
      mainControl.selectSubject = preSubjectList[0].id
      uniAxList({...dataInitSubjectBalance,params:{date:mainControl.beginYm+'-01 00:00:00', id:preSubjectList[0].id},awaitFunc:(balancedata:any)=>{
        uniAxList({...uniRowData,params:{...uniRowData.params,filter_EQS_subject_id:preSubjectList[0].id},awaitFunc:(prerowlist:any)=>{
          let initrow = {id:-1,proofNo:'',billYmd:moment().startOf('month').format('YYYY-MM-DD'),summary:'期初余额',inPrice:'',outPrice:'',borrowLend:preSubjectList[0].borrowLend,balance:balancedata.changeBalance+balancedata.balance}
          prerowlist.forEach((prerowitem:any,index:number) => {
            if (index===0) {
              if (prerowitem.borrowLend === preSubjectList[0].borrowLend) prerowitem.balance = balancedata.changeBalance+balancedata.balance + prerowitem.price
              else prerowitem.balance = balancedata.changeBalance+balancedata.balance - prerowitem.price
            } else {
              if (prerowitem.borrowLend === prerowlist[index-1].borrowLend) prerowitem.balance = prerowlist[index-1].balance + prerowitem.price
              else prerowitem.balance = prerowlist[index-1].balance - prerowitem.price
            }
          })
          setRowList(()=>[initrow,...prerowlist])
          setTableLoading(()=>false)
        }})
      }})
    }
  }
  //获取会计科目初始余额
  const dataInitSubjectBalance = {
    params: {date:tempSearchParams.beginYm+'-01 00:00:00', id:mainControl.selectSubject},
    axApi: singleSubjectRemainder,
    setTableLoading,
    finishLoading:false,
  }
  const uniCompanyData:any = {
    axApi: getCompanyList,
    setList: setCompanyList,
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    uniAxList(uniAsData)
    uniAxList(uniCompanyData)
    setLoadFlag(()=>true)
  }, [])

  return (<><p>
    <ConfigProvider locale={locale}>
      <DatePicker.RangePicker
        allowClear={false}
        defaultValue={[moment(),moment()] as any}
        ranges={monthRages}
        picker="month"
        placeholder={['开始月份', '结束月份']}
        onChange={(dates, dateStrings) => {
          mainControl.beginYm = dateStrings[0]
          mainControl.endYm = dateStrings[1]
          setTempSearchParams(()=>({...tempSearchParams, filter_BETWEENS_bill_ymd: dateStrings[0]+'-01'+','+moment(dateStrings[1]).endOf('month').format('YYYY-MM-DD')+' 23:59:59'}))
        }}
      />
    </ConfigProvider>&nbsp;
    <Select
      allowClear
      value={selectControl.companyId}
      style={{ width: 200 }}
      placeholder="所属公司"
      onChange={(value) => {
        selectControl.companyId = value || null
        setTempSearchParams(() => ({...tempSearchParams, filter_EQS_company_id:value || null}))
      }}
    > 
      {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
    </Select>&nbsp;
    <Select
      value={mainControl.selectSubject}
      style={{ width: 200 }}
      placeholder="会计科目"
      onChange={(value) => {
        mainControl.selectSubject = value
        setTempSearchParams(() => ({...tempSearchParams, filter_EQS_subject_id:value || null}))
      }}
    > 
      {accountantSubjectList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.subjectNo+' '+item.name}</Select.Option>)}
    </Select>&nbsp;
    <Button onClick={() => {
      if (accountantSubjectList.length===0) return message.warning('还没有设置现金日记账科目')
      setSearchParams(() => tempSearchParams)
      uniAxList({...dataInitSubjectBalance,params:{date:mainControl.beginYm+'-01 00:00:00', id:tempSearchParams.filter_EQS_subject_id},awaitFunc:(balancedata:any) => {
        uniAxList({...uniRowData,params:{...uniRowData.params,filter_EQS_subject_id:tempSearchParams.filter_EQS_subject_id,filter_EQS_company_id:tempSearchParams.filter_EQS_company_id},awaitFunc:(prerowlist:any)=>{
          let subjectitem:any = uniFilterItem(accountantSubjectList,'id',tempSearchParams.filter_EQS_subject_id)
          let initrow = {id:-1,proofNo:'',billYmd:moment().startOf('month').format('YYYY-MM-DD'),summary:'期初余额',inPrice:'',outPrice:'',borrowLend:subjectitem.borrowLend,balance:balancedata.changeBalance+balancedata.balance}
          prerowlist.forEach((prerowitem:any,index:number) => {
            if (index===0) {
              if (prerowitem.borrowLend === subjectitem.borrowLend) prerowitem.balance = balancedata.changeBalance+balancedata.balance + prerowitem.price
              else prerowitem.balance = balancedata.changeBalance+balancedata.balance - prerowitem.price
            } else {
              if (prerowitem.borrowLend === subjectitem.borrowLend) prerowitem.balance = prerowlist[index-1].balance + prerowitem.price
              else prerowitem.balance = prerowlist[index-1].balance - prerowitem.price
            }
          })
          setRowList(()=>[initrow,...prerowlist])
          setTableLoading(()=>false)
        }})
      }})
    }} type='primary'>查询</Button>&nbsp;
    </p>
    <div className={tablecss.detailtable}>
      <Table
        rowKey={'id'}
        columns={columns}
        dataSource={rowList}
        scroll={{y:'calc(100vh - 232px)'}}
        pagination={false}
        loading={tableLoading}
        sticky
      />
    </div>
  </>)
}

