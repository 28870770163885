import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, Modal, Popconfirm, Popover, Radio, Select, Table, TablePaginationConfig, Typography } from 'antd'
import {EditOutlined,DeleteOutlined} from '@ant-design/icons'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { createBalanceSheet, createBalanceSubject, createSheetSubject, deleteBalanceSheet, deleteBalanceSubject, deleteSheetSubject, axSubjectList, getBalanceSheet, getBalanceSubject, getCompanyList, getSubjectSheet, newBalanceSheet, updateBalanceSheet, updateBalanceSubject, updateSheetSubject, zcfzAnalyse } from '../../utils/api'
import { uniAddRowSubmit, uniAxList, uniAxSearchList, uniChgRowSubmit, uniCurrentTime, uniDeleteRow, uniExportTable, uniFilterItem, uniFormProcess, uniModifyAxList, uniSelectRows } from '../../utils/uniFucs'

import * as XLSX from "xlsx"
import FileSaver from "file-saver"

import { NameValue } from '../../utils/beans'
import { calculateTypes, subjectTypeList } from '../../utils/constant'
import gpbalancesheetcss from '../css/gpbalancesheet.module.less'
import tablecss from '../../css/custable.module.less'
import { ColumnAction, FormItemObj } from '../../utils/interfaces';
import TopSearch from '../../components/TopSearch';

export default function GpBalanceSheet() {
  const pathname = "/indexpage/gpbalancesheet"
  const searchRef = createRef<FormInstance>()
  /* hooks */
  let location = useLocation()
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [subjectModal, setSubjectModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [staticModal, setStaticModal] =useState(false)
  const [companyList, setCompanyList] = useState([])
  const [rowList, setRowList] = useState([] as any)
  const [rowList2, setRowList2] = useState([] as any)
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({subjectId:true,calculate:true,startPrice:true,endPrice:true} as any)
  const [modalItemDisable, setModalItemDisable] = useState({subjectId:false} as any)
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [currentRow, setCurrentRow] = useState({} as any)
  let [searchParams, setSearchParams] = useState({page:1} as any)
  const [tempSearchParams, setTempSearchParams] = useState({} as any)
  const [staffList, setStaffList] = useState([])
  const [reinburseTypeList, setReinburseTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 10})
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [staffAccounts, setStaffAccounts] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [unCashRows, setUnCashRows] = useState([] as any)
  const [isUnderLinePay, setIsUnderLinePay] = useState(true)
  const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
  const [businessList, setBusinessList] = useState([])
  const [cancelRemark, setCancelRemark] = useState('')
  const [loadFlag, setLoadFlag] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [balanceSubjectList, setBalanceSubjectList] = useState([])
  const [subjectList, setSubjectList] = useState([])
  const [sheetSubjectList,setSheetSubjectList] = useState([])
  const [balanceMap, setBalanceMap] = useState({} as any)
  const [searchYmRange,setSearchYmRange] = useState([] as any)

  /* funs */
  //路由刷新请求
  const routerFlash = () => {
    // uniAxList(dataRow())
    uniAxList(uniCompanyData)
    uniAxList(uniBalanceSubjectData)
  }
  const modifyRowList = ({endPriceMap, startPriceMap}:any) => {

    let flowAssetRow:any = {key:-1, name:'流动资产合计', balance: 0, endBalance: 0, children:[{key:-2, name:'货币资金', balance: 0, endBalance: 0,children:[]},{key:-3, name:'库存商品', balance: 0, endBalance: 0,children:[]}]}
    let unflowAssetRow:any = {key:-4, name:'非流动资产合计', balance: 0, endBalance: 0,children:[{key:-5, name:'固定资产净值', balance: 0, endBalance: 0,children:[]}]}
    let flowDebt:any = {key:-6, name:'流动负债合计', balance: 0, endBalance: 0,children:[]}
    let unFlowDebt:any = {key:-7, name:'非流动负债合计', balance: 0, endBalance: 0,children:[]}
    let owner:any = {key:-8, name:'所有者权益合计', balance: 0, endBalance: 0,children:[]}

    let startKeys = Object.keys(startPriceMap)
    startKeys.forEach((key:string) => {
      delete startPriceMap[key].children
      startPriceMap[key].key = startPriceMap[key].id
      startPriceMap[key].balance /= 100
      if (endPriceMap[key]) {
        endPriceMap[key].price /= 100
        startPriceMap[key].endBalance = startPriceMap[key].balance + endPriceMap[key].price
      }
      else startPriceMap[key].endBalance = startPriceMap[key].balance
      if (['1','2','15'].indexOf(key) > -1) { //货币资金
        flowAssetRow.children[0].children.push(startPriceMap[key])
        flowAssetRow.children[0].balance += startPriceMap[key].balance
        flowAssetRow.children[0].endBalance += startPriceMap[key].endBalance
        flowAssetRow.balance += startPriceMap[key].balance
        flowAssetRow.endBalance += startPriceMap[key].endBalance
      } else if (['44','45','46'].indexOf(key) > -1) { //库存商品
        flowAssetRow.children[1].children.push(startPriceMap[key])
        flowAssetRow.children[1].balance += startPriceMap[key].balance
        flowAssetRow.children[1].endBalance += startPriceMap[key].endBalance
        flowAssetRow.balance += startPriceMap[key].balance
        flowAssetRow.endBalance += startPriceMap[key].endBalance
      } else if (['17','18','19','25','35','36','37'].indexOf(key) > -1) { //除货币和库存以外的流动资产
        flowAssetRow.children.push(startPriceMap[key])
        flowAssetRow.balance += startPriceMap[key].balance
        flowAssetRow.endBalance += startPriceMap[key].endBalance
      } else if (['49','57'].indexOf(key) > -1) { //固定资产净值
        unflowAssetRow.children[0].children.push(startPriceMap[key])
        unflowAssetRow.children[0].balance += startPriceMap[key].balance
        unflowAssetRow.children[0].endBalance += startPriceMap[key].endBalance
        unflowAssetRow.balance += startPriceMap[key].balance
        unflowAssetRow.endBalance += startPriceMap[key].endBalance
      } else if (['47','48','65','66','67','68','69','70'].indexOf(key) > -1) { //除固定净值外的非流动资产
        unflowAssetRow.children.push(startPriceMap[key])
        unflowAssetRow.balance += startPriceMap[key].balance
        unflowAssetRow.endBalance += startPriceMap[key].endBalance
      } else if (['71','76','77','84','89','97','114','115','116'].indexOf(key) > -1) { //流动负债
        flowDebt.children.push(startPriceMap[key])
        flowDebt.balance += startPriceMap[key].balance
        flowDebt.endBalance += startPriceMap[key].endBalance
      } else if (['123','126'].indexOf(key) > -1) { //非流动负债
        unFlowDebt.children.push(startPriceMap[key])
        unFlowDebt.balance += startPriceMap[key].balance
        unFlowDebt.endBalance += startPriceMap[key].endBalance
      } else if (['127', '128', '129', '131'].indexOf(key) > -1) { //所有者权益
        owner.children.push(startPriceMap[key])
        owner.balance += startPriceMap[key].balance
        owner.endBalance += startPriceMap[key].endBalance
      }
    })
    return [flowAssetRow,unflowAssetRow,flowDebt,unFlowDebt,owner]
  }
  //接口数据处理
  const modifyRow = (axrows:any) => {
    let childCount = 1
    let rowCount1 = 0
    let rowCount2 = 0
    let rowlist:any = []
    let rowlist2:any = []
    let fillArr = []
    setBalanceMap(()=>axrows.balanceMap)
    axrows.balanceSubjectList.forEach((rowItem:any) => {
      if (rowItem.balanceSheet && rowItem.balanceSheet.length<=0) delete rowItem.balanceSheet
      else if (rowItem.balanceSheet && rowItem.balanceSheet.length>0) {
        let rowItemStartsum = 0
        let rowItemEndsum = 0
        rowItem.balanceSheet.forEach((childItem:any) => {
          if (childItem.calculate===1) {
            rowItemStartsum += childItem.startPrice
            rowItemEndsum += childItem.endPrice
          } else if (childItem.calculate===2) {
            rowItemStartsum -= childItem.startPrice
            rowItemEndsum -= childItem.endPrice
          }
          childItem.count = childCount
          childCount++
        })
        rowItem.balanceSheet.push({name:rowItem.name+'合计',count:childCount,startPrice:rowItemStartsum,endPrice:rowItemEndsum,isSum:true,id:rowItem.name+'合计'})
        childCount++
      }
      if (rowItem.subjectType === '1') {
        rowCount1+=1
        if (rowItem.balanceSheet) rowCount1 += rowItem.balanceSheet.length
        rowlist.push(rowItem)
        if (rowItem.id === 2) {
          let allstart = 0
          let allend = 0
          rowlist.forEach((rowlistItem:any)=>{
            allstart+=rowlistItem.balanceSheet[rowlistItem.balanceSheet.length-1].startPrice
            allend+=rowlistItem.balanceSheet[rowlistItem.balanceSheet.length-1].endPrice
          })
          rowlist.push({name:'资产合计',count:childCount,startPrice:allstart,endPrice:allend,isSum:true,id:'资产合计'})
          childCount++
          rowCount1+=1
        }
      } else {
        rowCount2+=1
        if (rowItem.balanceSheet) rowCount2 += rowItem.balanceSheet.length
        rowlist2.push(rowItem)
        if (rowItem.id === 5) {
          let allstart = 0
          let allend = 0
          rowlist2.forEach((rowlist2Item:any)=>{
            allstart+=rowlist2Item.balanceSheet[rowlist2Item.balanceSheet.length-1].startPrice
            allend+=rowlist2Item.balanceSheet[rowlist2Item.balanceSheet.length-1].endPrice
          })
          rowlist2.push({name:'负债和所有者权益合计',count:childCount,startPrice:allstart,endPrice:allend,isSum:true,id:'负债和所有者权益合计'})
          childCount++
          rowCount2+=1
        }
      }
    })
    
    for (let i=0;i<Math.abs(rowCount1-rowCount2);i++) fillArr.push({})
    console.log('rowCount-------',rowCount1,rowCount2)
    

    if (rowCount1 <= rowCount2) rowlist.splice(rowlist.length,0,...fillArr)
    else rowlist2.splice(rowlist2.length-1,0,...fillArr)

    setRowList(()=>rowlist)
    setRowList2(()=>rowlist2)
  }
  //打开modal时设置初始值
  const initModal = () => {
    uniAxList(uniBalanceSubjectData)
    setCustomAddKeys(()=>[])
  }
  //表格导出
  const exportTables = () => {
    let wb = XLSX.utils.book_new()
    let ws1 = XLSX.utils.table_to_sheet(document.getElementById('gpbalancesheet1'))
    let ws2 = XLSX.utils.table_to_sheet(document.getElementById('gpbalancesheet2'))
    let wsqKeys = Object.keys(ws1)
    let wsqKeys2 = Object.keys(ws2)
    let counts2 = wsqKeys2.filter((item:string)=>item.startsWith('C')).length
    let counts = wsqKeys.filter((item:string)=>item.startsWith('C')).length
    let maxCounts = Math.max(counts,counts2)
    const headKeys = ['A1','B1','C1','E1']
    //修改table2
    wsqKeys2.forEach((key:string) => {
      if (headKeys.indexOf(key) === -1 && ws2[key]['v'] && isNaN(ws2[key]['v']) && ws2[key]['v'].endsWith('_')) ws2[key]['v'] = '   ' + ws2[key]['v'].slice(0,-1)
    })
    //修改table1，并将table2合并到table1
    ws1['!fullref'] = "A1:H"+maxCounts
    ws1['!ref'] = "A1:H"+maxCounts
    wsqKeys.forEach((key:string) => {
      if (headKeys.indexOf(key) === -1 && ws1[key]['v'] && isNaN(ws1[key]['v']) && ws1[key]['v'].endsWith('_')) ws1[key]['v'] = '   ' + ws1[key]['v'].slice(0,-1)
    })
    for (let i = 1; i<=counts2;i++) {
      ws1['E'+i] = ws2['A'+i]
      ws1['F'+i] = ws2['B'+i]
      ws1['G'+i] = ws2['C'+i]
      ws1['H'+i] = ws2['D'+i]
    }
    ws1['A2']['v'] = ws1['A1']['v']
    ws1['B2']['v'] = ws1['B1']['v']
    ws1['C2']['v'] = ws1['C1']['v']
    ws1['D2']['v'] = ws1['D1']['v']
    ws1['E2']['v'] = ws1['E1']['v']
    ws1['F2']['v'] = ws1['F1']['v']
    ws1['G2']['v'] = ws1['G1']['v']
    ws1['H2']['v'] = ws1['H1']['v']
    ws1['A1']['v'] = uniCurrentTime({day:false})
    ws1['B1']['v'] = ''
    ws1['C1']['v'] = ''
    ws1['D1']['v'] = ''
    ws1['E1']['v'] = ''
    ws1['F1']['v'] = ''
    ws1['G1']['v'] = ''
    ws1['H1']['v'] = '单位：元'

    // console.log('sheet1-------------',ws1)
    // console.log('sheet2-------------',ws2)

    XLSX.utils.book_append_sheet(wb, ws1, 'sheet1')

    let wb_out = XLSX.write(wb, { type: 'buffer'})

    try {
      let pageTme = searchParams.ym?searchParams.ym:uniCurrentTime({day:false})
      FileSaver.saveAs(new Blob([wb_out], {
        type: 'application/octet-stream'
      }), '资产负债表'+pageTme+'.xlsx');   // 导出的文件名
    } catch (e) {
      console.log(e, wb_out) ;
    }
    return wb_out;
  }
  const searchConfirm = async (formValus:any) => {
    searchParams = await uniFormProcess(formValus,searchList)
    setSearchParams(searchParams)
    uniAxList(dataRow())
  }

  /* elements */
//搜索
const searchList:FormItemObj[] = [
{label: '公司', prop:'companyId',type:'select',dataList:companyList,required:true,clearable:false,change:(value:any)=>{
  searchRef.current?.setFieldsValue({'ym':null})
  let finditem:any = companyList.find((item:any)=>item.id===value)
  if (finditem.initialize===1) setSearchYmRange([finditem.startYm,finditem.endYm])
  else setSearchYmRange([])
}},
{label: '月份', prop:'ym',type:'datePicker',required:true,datepickerProps:{type:'month',returnType: 'string',disabledDate:(current)=>{
  if (searchYmRange.length<2) return true
  else return current<moment(searchYmRange[0]).startOf('month') || current>moment(searchYmRange[1]).endOf('month')
}}},
]
//顶部操作
const topActions:ColumnAction[] = [
{label: '新增', type:'primary',action:()=>{
  setModalItemVisible(()=>({subjectId:true,calculate:true,startPrice:true,endPrice:true}))
  modalItemDisable.subjectId = false
  setAddModal(()=>true)
}},
{label: '导出', type:'primary',action:()=>exportTables()},
{label: '是否平衡', type:'primary',render: (rowList[0] && rowList2[0] && rowList[rowList.length-1].yearBeginPrice === rowList2[rowList2.length-1].yearBeginPrice && rowList[rowList.length-1].endPrice === rowList2[rowList2.length-1].endPrice)
  ? <img src={require('../../materials/img/pingheng.png')} alt="" /> 
  : <img src={require('../../materials/img/bupingheng.png')} alt="" />
}
]
  //列项
  const columns:any = [
    {
      title: '行次',
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: '年初余额',
      dataIndex: 'yearBeginPrice',
      key: 'yearBeginPrice',
      width:150,
      render: (value:any,record:any) => {
        if (!record.rootId && record.balanceSheet && record.balanceSheet.length>0) return ''
        else return record.name ? (value && !isNaN(value) ? (value / 100).toFixed(2) : 0) : ''
      }
    },
    {
      title: '期末余额',
      dataIndex: 'endPrice',
      key: 'endPrice',
      width:150,
      render: (value:any,record:any) => {
        if (!record.rootId && record.balanceSheet && record.balanceSheet.length>0) return ''
        else return record.name ? (value && !isNaN(value) ? (value / 100).toFixed(2) : 0) : ''
      }
    },
  ]
  const columns1:any = [
    {
      title: '资产',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render:(value:any,record:any) => 
        <Popover placement="right" content={(
          <div>
            <EditOutlined onClick={()=>{
              // initModal()
              setCurrentRow(()=>record)
              setChgModal(()=>true)
            }}/>&nbsp;&nbsp;&nbsp;
            <Popconfirm title={"确定删除该记录吗?"} onConfirm={() => {
              if (record.rootId) uniDeleteRow({record,deleteApi:deleteBalanceSheet,uniAxListData:dataRow()})
              else uniDeleteRow({record,deleteApi:deleteBalanceSubject,uniAxListData:dataRow()})
            }} okText="确定" cancelText="取消">
              <DeleteOutlined />
            </Popconfirm>
          </div>
        )}>
            {
              record.rootId
              ?<span>{record.calculate === 2 ? <span>减：</span> : null}
                <Typography.Link underline onClick={() => {
                  // initModal()
                  uniAxList({...uniSubjectData,params:{filter_EQL_level:1,filter_EQS_subject_type:record.subjectType}})
                  setCurrentRow(()=>record)
                  // uniAxList({...uniSheetSubjectData,params:{filter_EQL_balance_sheet_id:record.id}})
                  setSheetSubjectList(()=>record.balanceSheetSubjects)
                  setSubjectModal(()=>true)
                }}>{value+'_'}</Typography.Link>
              </span>
              :value
            }
        </Popover>
    },
    ...columns
  ]
  const columns2:any = [
    {
      title: '负债和权益',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render:(value:any,record:any) =>
      record.isSum
      ?value
      :<Popover placement="right" content={(
        <div>
          <EditOutlined onClick={()=>{
            // initModal()
            setCurrentRow(()=>record)
            setChgModal(()=>true)
          }}/>&nbsp;&nbsp;&nbsp;
          <Popconfirm title={"确定删除该记录吗?"} onConfirm={() => {
            if (record.rootId) uniDeleteRow({record,deleteApi:deleteBalanceSheet,uniAxListData:dataRow()})
            else uniDeleteRow({record,deleteApi:deleteBalanceSubject,uniAxListData:dataRow()})
          }} okText="确定" cancelText="取消">
            <DeleteOutlined />
          </Popconfirm>
        </div>
      )}>
          {
            record.rootId
            ?<Typography.Link underline onClick={() => {
              // initModal()
              uniAxList({...uniSubjectData,params:{filter_EQL_level:1,filter_EQS_subject_type:record.subjectType}})
              setCurrentRow(()=>record)
              // uniAxList({...uniSheetSubjectData,params:{filter_EQL_balance_sheet_id:record.id}})
              setSheetSubjectList(()=>record.balanceSheetSubjects)
              setSubjectModal(()=>true)
            }}>{value+'_'}</Typography.Link>
            :value
          }
      </Popover>
    },
    ...columns
  ]
  //添加和修改中的model项
  const modalItems:any = [
    {
      label: '上级项目',
      name: 'rootId',
      rules: [{ required: true, message: '上级项目不能为空' }],
      element: <Select
                showSearch
                optionFilterProp='children'
                allowClear
                style={{ width: '100%' }}
                placeholder="选择上级项目"
                onChange={(value,operation:any) => {
                  if (value === 0) setModalItemVisible(()=>({subjectId:false,calculate:false,startPrice:false,endPrice:false}))
                  else {
                    setModalItemVisible(()=>({subjectId:true,calculate:true,startPrice:true,endPrice:true}))
                    setCustomAddKeys(()=>({...customAddKeys,rootName: value ? operation.children : null}))
                  }
                }}
              > 
                <Select.Option disabled={chgModal && currentRow.rootId} key={0} value={0}>{"一级项目"}</Select.Option>
                {balanceSubjectList.map((item:any) => <Select.Option disabled={chgModal && !currentRow.rootId} key={item.id} value={item.id}>{item.name}</Select.Option>)}
              </Select>
    },
    {
      label: '项目名称',
      name: 'name',
      rules: [{ required: true, message: '项目名称不能为空' }],
      element: <Input placeholder="请输入项目名称" />
    },
    {
      label: '资产类型',
      name: 'subjectType',
      rules: [{ required: true, message: '资产类型不能为空' }],
      element: <Radio.Group>
                {subjectTypeList.map((item:NameValue) => <Radio key={item.value} value={item.value}>{item.name}</Radio>)}
              </Radio.Group>
    },
    {
      label: '统计方式',
      name: 'calculate',
      rules: [{ required: true, message: '统计方式不能为空' }],
      element: <Radio.Group>
                <Radio key={1} value={1}>{'加'}</Radio>
                <Radio key={2} value={2}>{'减'}</Radio>
              </Radio.Group>
    },
  ]
  //modal中table列项
  const columnsInSubjectModal:any = [
    {
      title: '科目',
      dataIndex: 'subjectName',
      key: 'subjectName'
    },
    {
      title: '运算符号',
      dataIndex: 'calculate',
      key: 'calculate',
      render:(value:any) => {
        let tempItem:any = uniFilterItem(calculateTypes,'value',value)
        return tempItem ? tempItem.name : ''
      }
    },
    {
      title: '开始余额',
      dataIndex: 'yearBeginPrice',
      key: 'yearBeginPrice',
      render:(value:any,record:any) => value && !isNaN(value) ? (value/100).toFixed(2) : 0
    },
    {
      title: '期末余额',
      dataIndex: 'endPrice',
      key: 'endPrice',
      render:(value:any,record:any) => value && !isNaN(value) ? (value/100).toFixed(2) : 0
    },
    {
      title: '操作',
      dataIndex: 'operation',
      fixed: 'right',
      width: 120,
      render: (_: any, record: any) => 
            <Popconfirm title={"确定删除该记录吗?"} onConfirm={() => uniDeleteRow({record,deleteApi:deleteSheetSubject,uniAxListData:{...uniSheetSubjectData,params:{filter_EQL_balance_sheet_id:currentRow.id}}})} okText="确定" cancelText="取消">
              <a href="#">删除</a>
            </Popconfirm>

    }
  ]

  /* 接口参数 */
  const dataRow = ():any => ({
    params: searchParams,
    axApi: newBalanceSheet,
    setTableLoading,
    finishLoading:false,
    awaitFunc: (predata:any) => {
      let count = 1
      let tempRowlist:any = []
      let tempRowlist2:any = []
      let assetSumrow = {id:-6,name:'资产总计',yearBeginPrice:0,endPrice:0}
      let detSumrow = {id:-7,name:'负债和所有者权益（或股东权益）总计',yearBeginPrice:0,endPrice:0}
      predata.forEach((rootitem:any)=>{
        let summaryItem:any = {id:-rootitem.id,name:rootitem.name+'合计',yearBeginPrice:0,endPrice:0}
        rootitem.balanceSheet.forEach((childitem:any)=>{
          childitem.count = count
          count++
          if (childitem.calculate===1) {
            summaryItem.yearBeginPrice += childitem.yearBeginPrice
            summaryItem.endPrice += childitem.endPrice 
          } else if (childitem.calculate===2) {
            summaryItem.yearBeginPrice -= childitem.yearBeginPrice
            summaryItem.endPrice -= childitem.endPrice
          }
        })
        summaryItem.count = count
        count++
        rootitem.balanceSheet.push(summaryItem)
        if ([1,2].includes(rootitem.id)) {
          assetSumrow.yearBeginPrice += summaryItem.yearBeginPrice
          assetSumrow.endPrice += summaryItem.endPrice
          tempRowlist.push(rootitem)
        } else {
          detSumrow.yearBeginPrice += summaryItem.yearBeginPrice
          detSumrow.endPrice += summaryItem.endPrice
          tempRowlist2.push(rootitem)
        }
      })

      tempRowlist2.splice(tempRowlist2.length-1,0,{})

      setRowList(()=>[...tempRowlist,assetSumrow])
      setRowList2(()=>[...tempRowlist2,detSumrow])
      setTableLoading(()=>false)
    }
  })
  const uniBalanceSubjectData:any = {
    axApi: getBalanceSubject,
    setList: setBalanceSubjectList,
  }
  const uniSubjectData:any = {
    params: {filter_EQL_level:1},
    axApi: axSubjectList,
    setList: setSubjectList
  }
  const uniSheetSubjectData:any = {
    axApi: getSubjectSheet,
    setList: setSheetSubjectList
  }
  const uniCompanyData:any = {
    axApi: getCompanyList,
    setList: setCompanyList,
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    // uniAxList(dataRow())
    uniAxList(uniCompanyData)
    uniAxList(uniBalanceSubjectData)
    setLoadFlag(()=>true)
  }, [])

  return (
    <>
    <TopSearch
      ref={searchRef}
      searchList={searchList}
      topActions={topActions}
      searchConfirm={searchConfirm}
    />
      {/* <p>
      <ConfigProvider locale={locale}>
        <DatePicker disabledDate={(current)=>{return current && current > moment().endOf('day')}} picker='month' style={{width:200}} defaultValue={moment(uniCurrentTime({day:false}), 'YYYY-MM')} onChange={(date, dateString)=>setTempSearchParams(()=>({...tempSearchParams,ym:dateString}))}/>
      </ConfigProvider>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="所属公司"
        onChange={(companyId) => {
          setTempSearchParams(() => ({...tempSearchParams, companyId: companyId || null}))
        }}
      > 
        {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Button onClick={() => uniAxSearchList({setSearchParams, uniRowData:dataRow(), params: tempSearchParams})} type='primary'>查询</Button>&nbsp;
      <Button type="primary" onClick={() => {
        setModalItemVisible(()=>({subjectId:true,calculate:true,startPrice:true,endPrice:true}))
        modalItemDisable.subjectId = false
        // initModal()
        setAddModal(()=>true)
      }}>新增</Button> &nbsp;
      <Button onClick={() => exportTables()} type='primary'>导出</Button>&nbsp;
        {
          (rowList[0] && rowList2[0] && rowList[rowList.length-1].yearBeginPrice === rowList2[rowList2.length-1].yearBeginPrice && rowList[rowList.length-1].endPrice === rowList2[rowList2.length-1].endPrice)
          ? <img src={require('../../materials/img/pingheng.png')} alt="" /> 
          : <img src={require('../../materials/img/bupingheng.png')} alt="" />
        }
      </p> */}
      <div style={{display:'flex',justifyContent:'space-between'}}>
        <span>{searchParams.ym ?searchParams.ym :''}</span>
        <span>单位：元</span>
      </div>
      <div className={tablecss.gpbalancetable}>
        <div style={{borderRight:'1px solid #eee'}}>
          <Table
            size='small'
            id='gpbalancesheet1'
            rowKey={'id'}
            columns={columns1}
            expandable={{childrenColumnName:'balanceSheet',defaultExpandedRowKeys:[1,2,3,4,5]}}
            dataSource={rowList}
            pagination={false}
            loading={tableLoading}
            sticky
          />
        </div>
        <div>
          <Table
            size='small'
            id='gpbalancesheet2'
            rowKey={'id'}
            columns={columns2}
            expandable={{childrenColumnName:'balanceSheet',defaultExpandedRowKeys:[1,2,3,4,5]}}
            dataSource={rowList2}
            pagination={false}
            loading={tableLoading}
            sticky
          />
        </div>
      </div>
      <Modal
        width={600}
        maskClosable={false} title={'新增'}
        visible={addModal}
        onOk={() => formModalRef.current?.submit()}
        onCancel={()=>setAddModal(false)}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>setAddModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}
      >
        <Form
          preserve={false}
          ref={formModalRef}
          name="form"
          labelCol={{ span: 6 }}
          initialValues={{calculate:1}}
          wrapperCol={{ span: 16 }}
          onFinish={async (formValus) => {
            let axValues = {...formValus}
            if (formValus.rootId === 0) uniAddRowSubmit({axGetData:dataRow(),axAddApi:createBalanceSubject, setAddModal,setModalLoading, customAddKeys})(axValues)
            else if (!formValus.subjectId) uniAddRowSubmit({axGetData:dataRow(),axAddApi:createBalanceSheet, setAddModal,setModalLoading, customAddKeys})(axValues)
            else if (formValus.subjectId) {
              let newSheetRes = await createBalanceSheet({...formValus,rootName:customAddKeys.rootName})
              uniAddRowSubmit({axGetData:dataRow(),axAddApi:createSheetSubject, setAddModal,setModalLoading, customAddKeys:{
                ...customAddKeys,
                balanceSheetId: newSheetRes.data.id,
                balanceSheetName: newSheetRes.data.name
              }})(axValues)
            }
          }}
          autoComplete="off"
        >
          {modalItems.map((item:any) => (modalItemVisible && item.name in modalItemVisible && !modalItemVisible[item.name]) ? null : <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
        </Form>
      </Modal>
      <Modal
        getContainer={false}
        width={600}
        maskClosable={false}
        title={'修改'}
        visible={chgModal}
        onOk={() => formModalRef.current?.submit()}
        onCancel={()=>setChgModal(false)}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>setChgModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}
      >
        <Form
          preserve={false}
          ref={formModalRef}
          name="form"
          labelCol={{ span: 6 }}
          initialValues={{
            ...currentRow,
            rootId:currentRow.rootId?currentRow.rootId:0,
          }}
          wrapperCol={{ span: 16 }}
          onFinish={async (formValus) => {
            let axValues = {...formValus}
            if (formValus.rootId===0) uniChgRowSubmit({currentRows:[currentRow],axData:dataRow(), updateApi:updateBalanceSubject, setModal:setChgModal,setModalLoading, customKeys:customAddKeys})(axValues)
            else if (!formValus.subjectId) uniChgRowSubmit({currentRows:[currentRow],axData:dataRow(), updateApi:updateBalanceSheet, setModal:setChgModal,setModalLoading, customKeys:customAddKeys})(axValues)
            else if (formValus.subjectId) {
              setModalLoading(()=>true)
              await updateSheetSubject({...axValues,id:currentRow.id})
              uniChgRowSubmit({currentRows:[currentRow],axData:dataRow(), updateApi:updateBalanceSheet, setModal:setChgModal,setModalLoading, customKeys:customAddKeys})(axValues)
            }
          }}
          autoComplete="off"
        >
          {modalItems.map((item:any) => (modalItemVisible && item.name in modalItemVisible && !modalItemVisible[item.name]) ? null : <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
        </Form>
      </Modal>
      <Modal
        getContainer={false}
        width={800}
        maskClosable={false}
        title={'编辑公式-货币资金'}
        visible={subjectModal}
        onCancel={()=>{
          uniAxList(dataRow())
          setSubjectModal(false)
        }}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>{
          uniAxList(dataRow())
          setSubjectModal(false)
        }}>取消</Button>]}
      >
        <Select
          showSearch
          optionFilterProp='children'
          allowClear
          style={{ width: 200 }}
          placeholder="选择科目"
          onChange={(value,operation:any) => {
            setCustomAddKeys(()=>({...customAddKeys,subjectId:value || null, subjectName:value?operation.children:null}))
          }}
        > 
          {subjectList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.subjectNo+' '+item.name}</Select.Option>)}
        </Select>&nbsp;
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="运算符号"
          onChange={(value) => {
            setCustomAddKeys(()=>({...customAddKeys,calculate:value || null}))
          }}
        > 
          {calculateTypes.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
        </Select>&nbsp;
        <Button loading={modalLoading} onClick={() => {
          uniAddRowSubmit({axGetData:{...uniSheetSubjectData,params:{filter_EQL_balance_sheet_id:currentRow.id}},axAddApi:createSheetSubject,setModalLoading, customAddKeys})({balanceSheetId:currentRow.id,balanceSheetName:currentRow.name})
        }} type='primary'>添加</Button>
        <p></p>
        <Table
          rowKey={'id'}
          columns={columnsInSubjectModal}
          dataSource={sheetSubjectList}
          pagination={false}
          sticky
        />
      </Modal>
    </>
  )
}
