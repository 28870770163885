import React, { createRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, ConfigProvider, Form, FormInstance, Input, InputNumber, InputRef, message, Modal, Pagination, Popconfirm, Select, Table, TablePaginationConfig, Tooltip, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import locale from 'antd/es/locale/zh_CN';

import { getCompanyList,axTrialBalance, createAccountantSubject, deleteAccountantSubjectList, axSubjectList, getReviewTempList, updateAccountantSubject, updateAccountantSubjectPrice, axSubjectAffixBalance, axAffixExSubjectList, axAffixSubjectDetail, axCreateAffixBalance, axAffixtrialBalance } from '../../../utils/api'
import { uniAssetButton, uniAxList, uniAxSearchList, uniChgRowSubmit, uniFilterItem, uniModifyAxList, uniModifyRow, uniSelectRows,uniDeleteRow, uniDeepArray, uniFormProcess, uniValueToName, uniInitFormdata } from '../../../utils/uniFucs'
import { AccountSubjectItem, EditableCellProps, NameValue } from '../../../utils/beans'
import { assetType, borrowOrLend, csYesNo, forbidenStatus, initAccountSubjectItem, leadBorrow } from '../../../utils/constant'
import tablecss from '../../../css/custable.module.less'
import { findSourceMap } from 'module';
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../../utils/interfaces';
import TopSearch from '../../../components/TopSearch';
import SuperTable from '../../../components/SuperTable';
import FormDialog from '../../../components/FormDialog';
import { QuestionCircleOutlined } from '@ant-design/icons'

export default function InitialAccouts() {
const pathname = "/indexpage/initialaccounts"
const initMainControl = {selectCompany:-1}
const searchRef = createRef<FormInstance>()
const modalRef = createRef<FormInstance>()
const initSearchParams = {page:1}
const initstatus = [{id:0,name:'不平衡'},{id:1,name:'平衡'}]
/* hooks */
let location = useLocation()
const inputRef = createRef<InputRef>()
const formModalRef = createRef<FormInstance>()
const [tableLoading, setTableLoading] = useState(false)
const [subtableLoading, setSubtableLoading] = useState(false)
let [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
const [rowList, setRowList] = useState([] as any)
const [backList, setBackList] = useState([])
const [selectedRowKeys, setSelectedRowKeys] = useState([])
let [searchParams, setSearchParams] = useState({ ...initSearchParams } as any)
let [tableParams, setTableParams] = useState({ ...initSearchParams } as any)
const [tempSearchParams, setTempSearchParams] = useState({ page:1, companyId:-1, companyName:'全部' } as any)
const [activeButton, setActiveButton] = useState('')
const [isEdit, setIsEdit] = useState(false)
const [chgModal, setChgModal] = useState(false)
const [addModal, setAddModal] = useState(false)
const [currentRow, setCurrentRow] = useState(initAccountSubjectItem as any)
const [subjectNoPrefix, setSubjectNoPrefix] = useState('')
const [initSubjectNo, setInitSubjectNo] = useState('')
const [selectTreeNode, setSelectTreeNode] = useState({} as any)
const [tempBalance, setTempBalance] = useState('')
const [editingKey, setEditingKey] = useState('')
const [customAddKeys, setCustomAddKeys] = useState({balance:null} as any)
const [loadFlag, setLoadFlag] = useState(false)
const [modalLoading,setModalLoading] = useState(false)
const [companyList,setCompanyList] = useState([] as any)
const [selectedRows, setSelectedRows] = useState([] as any)
const [modalFlag, setModalFlag] = useState('')
const [surbrowList,setSubrowList] = useState([] as any)
const [backsubrowList,setBacksubrowList] = useState([] as any)
const [showModal, setShowModal] = useState(false)
let [formInitials, setFormInitials] = useState({} as any)
let [subjectList,setSubjectList] = useState([])
let [companItem,setCompanyItem] = useState({} as any)

/* funcs */
const searchConfirm = async (formValus:any) => {
  let finditem:any = companyList.find((item:any)=>item.id===formValus.companyId)
  setCompanyItem(finditem)
  pagination = {...pagination,current:1}
  setPagination(pagination);
  setSelectedRows([])
  setSelectedRowKeys([])
  searchParams = await uniFormProcess({...initSearchParams,...formValus},searchList)
  setSearchParams({...searchParams})
  setTableParams({...searchParams})
  uniAxList(dataRow())
}
//路由刷新请求
const routerFlash = () => {
  // uniAxList(dataRow())
  // uniAxList(uniReviewTempData)
  uniAxList(uniCompanyData)
}
const onCancel = () => {
  setIsEdit(()=>false)
  setTempBalance(() => '')
  setEditingKey(()=>'')
}
const onSave = (record:AccountSubjectItem) => {
  let saveNum = formModalRef.current?.getFieldValue('balance')
  customAddKeys.balance = (saveNum * 100).toFixed(0)
  formModalRef.current?.submit()
  setTempBalance(()=>'')
  setEditingKey(()=>'')
}
const onEdit = (record:AccountSubjectItem) => {
  setCustomAddKeys(()=>({balance:null}))
  setIsEdit(()=>true)
  setEditingKey(()=>record.id.toString())
}
//modal点击确定
const modalConfirm = async (formValues:any) => {
  let tempparms = await uniFormProcess(formValues,formItems)
  // if (modalFlag === 'confirm') uniAxList(dataConfirmRow(currentRow.billType,[{...tempparms,transTime:tempparms.collectionPayTime,id:currentRow.id}]))
  // let temparr = surbrowList.filter((item:any)=>item.canupdate)
  // if (temparr.length <= 0) return setShowModal(false)
  if (modalFlag==='balance') uniAxList(dataUpdateBalance(tempparms))
  // else if (modalFlag==='affix') uniAxList(dataCreateAffixBalance(temparr))
}

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '公司', prop:'companyId',type:'select',dataList:companyList,required:true},
{label: '类型', prop:'subjectType',type:'select',dataList:assetType},
{label: '借贷方向', prop:'borrowLend',type:'select',dataList:borrowOrLend},
{label: '名称', prop:'name'},
{label: '编码', prop:'subjectNo'}
]
//顶部操作
// const topActions:ColumnAction[] = [
// {label: '账套时间', type:'primary',render:<div>
//   账套启用时间：{(reviewTempList.length>0 && reviewTempList[0].ztStartDate) ? reviewTempList[0].ztStartDate.split(' ')[0] : ''}
// </div>}
// ]
//列首行hide:!companItem.startYm || companItem.initialize,
const columns:ColumnItem[] = [
{ title: '科目编码',key: 'subjectNo',render:(value:any,row:any)=>{
  let tempstr = value
  if (row.level===2) tempstr = '\xA0\xA0' + value
  else if (row.level===3) tempstr = '\xA0\xA0\xA0\xA0' + value
  return <>
    <span>{tempstr}</span>
    {row.accounting?<span style={{color:'red'}}> 辅</span>:null}
  </>
}},
{title: '科目名称', key: 'name',},
{title: '类型',key: 'subjectType', custval: ({row,value}:any)=>uniValueToName({list:assetType,value:value})},
{title: '期初余额', key: 'balance',type:'price'},
{title: '借贷方向',key: 'borrowLend',type:'tag',custval: ({row,value}:any)=>uniValueToName({list:leadBorrow,value})},
{title: '状态',key: 'status',type:'tag',custval: ({row,value}:any)=>uniValueToName({list:forbidenStatus,value})},
{title: ()=><><span>操作 </span><Tooltip placement="topRight" title="公司启用且未初始化, 可修改余额" ><QuestionCircleOutlined /></Tooltip></>,
  key:'operation',type: 'operation',width:90,opProps:{opList:[
  {name:'修改余额',disabled:()=>!companItem.startYm || companItem.initialize,show:({row,index}:any)=>row.accounting || index===rowList.length-1 || (index<=rowList.length-1 &&  row.level >= rowList[index+1].level),action:({row}:any) =>{
    setCurrentRow(row)
    setModalFlag('balance')
    setFormInitials(uniInitFormdata(row,formItems))
    setShowModal(true)
  }},
  // {name:'辅助核算',show:({row}:any)=>!row.children,action:({row}:any) =>{
  //   setCurrentRow(row)
  //   setModalFlag('affix')
  //   // setFormInitials(uniInitFormdata(row,formItems))
  //   uniAxList(dataAffixList(row.id))
  //   setShowModal(true)
  // }}
]}}
]
//余额详情table列
const balanceColumns:ColumnItem[] = [
{title: '公司',key: 'companyId',custval:({row,value}:any)=>uniValueToName({list:companyList,value})},
{title: '启用时间',key: 'startYm'},
{title: '初始化',key: 'initialize',type:'tag',custval:({row,value}:any)=>uniValueToName({list:csYesNo,value})},
{title: '余额',key: 'balance',type:'number',numProps:{precision:2},disabled:({row,value}:any)=>!row.canupdate}
]
//修改辅助核算table列
const affixColumns:ColumnItem[] = [
{title: '辅助核算',key: 'name',custval:({row,value}:any)=>row.accountingName+'_'+row.no+'_'+value},
{title: '公司',key: 'companyName'},
{title: '启用时间',key: 'startYm'},
{title: '初始化',key: 'initialize',type:'tag',custval:({row,value}:any)=>uniValueToName({list:csYesNo,value})},
{title: '余额',key: 'balance',type:'number',numProps:{precision:2},disabled:({row,value}:any)=>!row.canupdate},
]
//失算平衡table的列项
const trialColumns:ColumnItem[] = [
{title: '平衡状态',key: 'status',type:'tag',custval:({row,value}:any)=>uniValueToName({list:initstatus,value})},
{title: '借方金额',key: 'borrowPrice',type:'price'},
{title: '贷方金额',key: 'lendPrice',type:'price'},
{title: '差额',key: 'diffprice',type:'price'}
]
//辅助失算平衡table的列项
const affixtrialColumns:ColumnItem[] = [
{title: '会计科目',key: 'subjectId',custval:({row,value}:any)=>{
  let finditem:any = uniFilterItem(subjectList,'id',value)
  return finditem?.name || ''
}},
{title: '平衡状态',key: 'status',type:'tag',custval:({row,value}:any)=>uniValueToName({list:initstatus,value})},
{title: '科目金额',key: 'subjectPrice',type:'price'},
{title: '辅助核算金额',key: 'accountingPrice',type:'price'},
{title: '差额',key: 'diffprice',type:'price'}
]
//底部操作
const bottomActions:ColumnAction[] = [
{label: '公司试算平衡', type:'primary', action: () => {
  if (rowList.length<=0) return message.error('请先搜索数据')
  uniAxList(dataTrialBalance())
  setModalFlag('level')
  setShowModal(true)
}},
{label: '辅助核算试算平衡', type:'primary', action: () => {
  if (rowList.length<=0) return message.error('请先搜索数据')
  uniAxList(dataAffixlevelBalance())
  setModalFlag('affixlevel')
  setShowModal(true)
}}
]
//添加和修改中的model项
const formItems:FormItemObj[] = [
{label: '余额',prop: 'balance',type:'price',numProps:{min:-99999999}},
]
  //编辑columns
  const mergedColumns = columns.map((col:any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: AccountSubjectItem) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  })
  // //修改余额table列项
  // const balanceColumns = [
  //   {
  //     title: '公司',
  //     dataIndex: 'companyName',
  //     key: 'companyName',
  //   },
  //   {
  //     title: '余额',
  //     dataIndex: 'balance',
  //     key: 'balance',
  //     render:(value:any,record:any)=><InputNumber style={{width:'100%'}} precision={2} defaultValue={value/100} onChange={(balance)=>record.balance = Number((balance*100).toFixed(0))}/>
  //   },
  // ]
  //余额详情辅助核算table列
  const affixBalanceColumns = [
    {
      title: '辅助核算',
      dataIndex: 'accountingDetailName',
      key: 'accountingDetailName',
      render:(value:any,record:any)=>record.accountingDetailNo + ' '+value
    },
    {
      title: '余额',
      dataIndex: 'balance',
      key: 'balance',
      render:(value:any) => value && !isNaN(value) ? (value/100).toFixed(2) : 0
    },
  ]
  //定义可以编辑的cell
  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {(record && record.id.toString() === editingKey && dataIndex==='balance')
          ? <Form ref={formModalRef} initialValues={{balance:record.balance}} onFinish={(formValus) => uniChgRowSubmit({currentRows:[record],axData:dataRow(),updateApi:updateAccountantSubjectPrice,setModal:setIsEdit,customKeys:customAddKeys})(formValus)}><Form.Item name={'balance'} style={{margin:0,height:30}}><InputNumber/></Form.Item></Form>
          : children}
      </td>
    );
  };
/* 接口参数 */
const dataRow = ():AxDataform => ({
  params: {...searchParams,showAccountingDetail:true,showBalance:true, current:pagination.current, size:pagination.pageSize},
  axApi: axSubjectList,
  setList: setRowList,
  setTableLoading:setTableLoading,
  setPagination
})
//账套启用时间
// const uniReviewTempData = {
//   axApi: getReviewTempList,
//   setList: setReviewTempList,
// }
//修改余额
const dataUpdateBalance = (params:any):AxDataform => ({
  params:{...params,companyId:companItem.id,id:currentRow.id,accounting:currentRow.accounting},
  axApi: updateAccountantSubjectPrice,
  setModalLoading,
  setShowModal,
  awaitFunc:()=>uniAxList(dataRow())
})
//创建辅助核算余额
const dataCreateAffixBalance = (temparr:any):AxDataform => ({
  params: {subjectId:currentRow.id,companyId:tableParams.companyId,updateSubject:true,innerData:temparr.map((item:any)=>({accountingId:item.accountingId,accountingDetailId:item.id,balance:item.balance}))},
  axApi:axCreateAffixBalance,
  setModalLoading,
  setShowModal,
  awaitFunc:()=>uniAxList(dataRow())
})
//试算平衡
const dataTrialBalance = ():AxDataform => ({
  params: {companyId:companItem.id},
  axApi: axTrialBalance,
  setTableLoading:setSubtableLoading,
  awaitFunc:(predata:any) => {
    setSubrowList([{...predata,diffprice:predata.borrowPrice-predata.lendPrice,status: (predata.borrowPrice === predata.lendPrice ?1:0)}])
  }
})
//辅助核算试算平衡
const dataAffixlevelBalance = ():AxDataform => ({
  params: {companyId:companItem.id},
  axApi: axAffixtrialBalance,
  itemModify: {modifyFunc: ({item,index}:any)=>{
    let temp = item.subjectPrice - item.accountingPrice
    item.status = temp===0?1:0
    item.diffprice = temp
    item.id = index
  }},
  setTableLoading:setSubtableLoading,
  setList:setSubrowList
})
const uniCompanyData:any = {
  axApi: getCompanyList,
  setList:setCompanyList,
  awaitFunc:(predata:any)=>{
    if(predata && predata.length===1) {
      setSearchParams({...searchParams,companyId:predata[0].id})
      setTableParams({...tableParams,companyId:predata[0].id})
    }
  }
}
//辅助核算余额列表
const dataAffixBalance = (subjectId:any):AxDataform => ({
  params: {subjectId},
  axApi: axSubjectAffixBalance,
  // setList:setAffixBalanceList
})
//辅助核算列表
const dataAffixList = (subjectId:any):AxDataform => ({
  params:{companyId:tableParams.companyId,filter_EQS_subject_id:subjectId},
  axApi: axAffixExSubjectList,
  setTableLoading: setSubtableLoading,
  finishLoading:false,
  awaitFunc: async (predata:any) => {
    if (predata.length<=0) return setSubtableLoading(false)
    let temparr:any = []
    let affixbalancelist = await uniAxList(dataAffixBalance(subjectId))
    //查询并填充辅助核算详情余额
    let allres = await Promise.all(predata.map((affixitem:any)=>uniAxList(dataAffixDetail(affixitem.accountingId))))
    console.log('allllres-------------',allres)
    allres.forEach((allresitem:any)=>{
      allresitem.forEach((deepitem:any)=>{
        let finditem = companyList.find((companitem:any)=>companitem.id===deepitem.companyId)
        if (finditem) {
          deepitem.startYm = finditem.startYm
          deepitem.initialize = finditem.initialize
          deepitem.canupdate = (finditem.startYm && finditem.initialize===0?true:false)
        }
      })
      temparr.push(...allresitem)
    })
    temparr.forEach((item:any)=>{
      let finditem = affixbalancelist.find((subitem:any)=>item.id===subitem.accountingDetailId)
      if (finditem) item.balance = finditem.balance
    })
    setSubrowList([...temparr])
    setBacksubrowList([...temparr])
    setSubtableLoading(false)
  }
})
//辅助核算详情列表
const dataAffixDetail = (accountingId:any):AxDataform => ({
  params: {companyId:tableParams.companyId,accountingId},
  axApi: axAffixSubjectDetail
})
//会计科目列表
const dataSubjectList:AxDataform = {
  params: {page:1, size:9999},
  axApi: axSubjectList,
  setList: setSubjectList
}

/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])

React.useEffect(() => {
  if (searchRef && companyList.length===1) searchRef.current?.setFieldsValue({companyId:companyList[0].id})
}, [searchRef])

/* mounted */
React.useEffect(() => {
  // uniAxList(dataRow())
  // uniAxList(uniReviewTempData)
  uniAxList(uniCompanyData)
  uniAxList(dataSubjectList)
  setLoadFlag(()=>true)
}, [])

return (<>
  <TopSearch
    ref={searchRef}
    searchList={searchList}
    searchConfirm={searchConfirm}
    initialValues={initSearchParams}
  />
  <SuperTable
    columns={columns}
    rowList={rowList}
    bottomActions={bottomActions}
    tableLoading={tableLoading}
    pagination={pagination}
    dataRow={dataRow}
    rowSelection={{setSelectedRowKeys, setSelectedRows, selectedRowKeys}}
  />
  <FormDialog
    ref={modalRef}
    drawerSize="70%"
    title={modalFlag==='balance'?`修改余额 ${companItem.name}_${currentRow.subjectNo}_${currentRow.name}`:modalFlag==='affix'?'辅助核算余额':modalFlag==='level'?`公司试算平衡 ${companItem.name}`:`辅助核算试算平衡 ${companItem.name}`}
    show={showModal}
    formItems={modalFlag==='balance'?formItems:[]}
    modalLoading={modalLoading}
    formInitials={formInitials}
    cancel={()=>{
      setShowModal(()=>false)
      setCurrentRow({})
      setSubrowList([])
      setBacksubrowList([])
      setFormInitials({})
    }}
    confirm={modalConfirm}
    showCustom={['affix','level','affixlevel'].includes(modalFlag)}
    hasConfirm={!((modalFlag==='affix' && !tableParams.companyId) || ['level','affixlevel'].includes(modalFlag))}
  >
    <span></span>
    <>
      {
        modalFlag==='balance'
        ?<b style={{fontSize:16}}>注意: 修改余额将清空辅助核算余额!!!</b>
        :modalFlag==='affix'
          ?<div style={{display:'flex',justifyContent:'space-between'}}>
            <span>{'科目余额: '+(currentRow.initbalance/100)}</span>
            <Input.Search style={{width:240}} placeholder="请输入" allowClear enterButton="辅助搜索" size="small" onSearch={(value)=>{
              if (!value) return setSubrowList([...backsubrowList])
              let temparr = backsubrowList.filter((item:any)=>(item.accountingName+'_'+item.no+'_'+item.name).includes(value))
              setSubrowList(()=>temparr)
            }}/>
          </div>
          : null
      }
      <SuperTable columns={modalFlag==='balance'?balanceColumns:modalFlag==='level'?trialColumns:modalFlag==='affixlevel'?affixtrialColumns:affixColumns} rowList={surbrowList} tableLoading={subtableLoading} />
    </>
  </FormDialog>
  </>
)
}
