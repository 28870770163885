import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormInstance, Input, TablePaginationConfig, Tooltip, Typography } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../utils/interfaces';
import { axCreateCat1, axCreateCat2, axDelcat, axUpdatecat, axSubjectList, getMaterialLevel1, getMaterialLevel2 } from '../../utils/api';
import { uniAxList, uniFormProcess, uniInitFormdata, uniOpAlarm } from '../../utils/uniFucs';
import SuperTable from '../../components/SuperTable';
import FormDialog from '../../components/FormDialog';
import { getUserInfoKey } from '../../utils/localstorage';

export default function StockIn() {
  /* constant */
const pathname = "/indexpage/mcategory"
const modalRef = createRef<FormInstance>()
const searchRef = createRef<FormInstance>()
const initSearchParams = {page:1}
const initForminitial = {}
const opauth = getUserInfoKey('roleKey') === 'ADMIN'
/* hooks */
let location = useLocation()
const navigate = useNavigate()
const [companyList, setCompanyList] = useState([])
const [logList, setLogList] = useState([])
const [rowList, setRowList] = useState([])
const [currentRow, setCurrentRow] = useState({} as any)
const [currentSubrow, setCurrentSubrow] = useState({} as any)
let [searchParams, setSearchParams] = useState({page:1,orderBy:"create_date", orderDir: "desc"} as any)
const [tempSearchParams, setTempSearchParams] = useState({page:1,orderBy:"create_date", orderDir: "desc"} as any)
const [staffList, setStaffList] = useState([])
const [feeTypeList, setFeeTypeList] = useState([])
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
const [materialList, setMaterialList] = useState([])
const [loadFlag, setLoadFlag] = useState(false)
const [modalLoading, setModalLoading] = useState(false)
const [exportLoading, setExportLoading] = useState(false)
const [staticLoading,setStaticLoading] = useState(false)
const [modalFlag, setModalFlag] = useState('new')
const [showModal, setShowModal] = useState(false)
let [formInitials, setFormInitials] = useState({...initForminitial})
const [levelList1, setLevelList1] = useState([])
const [levelList2, setLevelList2] = useState([])
const [plusModalKeys, setPlusModalKeys] = useState({} as any)
const [subjectList, setSubjectList] = useState([])
let [expandedRowKeys,setExpandedRowKeys] = useState([] as any)

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '名称', prop:'filter_LIKES_name'},
{label: '公司', prop:'filter_EQS_company_id',type:'select',dataList:companyList},
]
//列项
const columns:ColumnItem[] = [
{title: '名称', key:'name'},
{title: '单位', key:'unit'},
{title: '科目',key: 'subjectId',custval:({row,value}:any)=>value?(value+' '+row.subjectName):''},
{title: '申领绑定科目',key: 'typeSubjectId', custval:({row,value}:any)=>value?(value+' '+row.typeSubjectName):''},
{title: '备注',key: 'remark'},
{title: '操作',key:'operation',type: 'operation',width:100,opProps:{fixed:false,opList:[
  {name:'新增子类',show:({row}:any)=>row.parentId===0,action:({row}:any) => {
    setFormInitials({parentId:row.id})
    setModalFlag('subnew')
    setCurrentRow(row)
    setShowModal(true)
  }},
  {name:'修改',show:()=>opauth,action:({row}:any) => {
    let tempitems = !row.parentId?formItems:subformItems
    let tempflag = !row.parentId?'update':'subupdate'
    setModalFlag(tempflag)
    if (!row.parentId) setCurrentRow(row)
    else if (row.parentId) setCurrentSubrow(row)
    setFormInitials(uniInitFormdata(row,tempitems))
    setShowModal(true)
  }},
  {name:'删除',show:()=>opauth,action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataDelrow(row.id)))}
]}}
]
//底部操作
const bottomActions:ColumnAction[] = [
{label: '新增', type:'primary', action: () => {setModalFlag('new');setShowModal(() => true)}},
]
//添加和修改中的modal项
const formItems:FormItemObj[] = [
{label: '名称',prop: 'name'},
{label: '科目', prop: 'subjectId', type:'tree',dataList:subjectList},
{label: '申领绑定科目', prop: 'typeSubjectId', type:'tree',dataList:subjectList},
{label: '备注',prop: 'remark',span:24,required:false}
]
//添加和修改子类中的modal项
const subformItems:FormItemObj[] = [
{label: '父类', prop: 'parentId',disabled:true,type:'select',dataList:rowList},
{label: '名称',prop: 'name'},
{label: '单位', prop: 'unit'},
{label: '备注',prop: 'remark',span:24,required:false}
]

/* funcs */
// const searchConfirm = async (formValus:any) => {setPagination({...pagination,current:1});
//   // searchParams = {...initSearchParams,...formValus}
//   // setSelectedRows([])
//   // setSelectedRowKeys([])
//   searchParams = await uniFormProcess({...initSearchParams,...formValus},searchList)
//   setSearchParams(searchParams)
//   uniAxList(dataRow(1))
// }
//modal点击确定
const modalConfirm = async (formValues:any) => {
  let tempitems = ['new','update'].includes(modalFlag)?formItems:subformItems
  let datafuc = ['update','subupdate'].includes(modalFlag)?dataUpdaterow:modalFlag === 'new'? dataNewrow : dataNewSubrow
  let tempparms = await uniFormProcess(formValues,tempitems)
  uniAxList(datafuc({...tempparms,...plusModalKeys}))
  // modalFlag==='new'?uniAxList(dataNewrow(await uniFormProcess(formValues,formItems))):uniAxList(dataUpdaterow(await uniFormProcess(formValues,formItems)))
}
//modal取消
const modalCancel = () => {
  setShowModal(()=>false)
  setPlusModalKeys([])
  if (modalFlag==='subupdate') setCurrentSubrow({})
  else setCurrentRow({})
  setLevelList2([])
  setFormInitials({...initForminitial})
}
//路由刷新请求
const routerFlash = () => {
  uniAxList(dataRow())
  uniAxList(dataSubjectList)
}

/* 接口参数 */
const dataRow = (current?:number):AxDataform => ({
  params: {...searchParams, current:current || pagination.current, size:pagination.pageSize},
  axApi: getMaterialLevel1,
  setList: setRowList,
  setTableLoading,
  awaitFunc: ()=>setExpandedRowKeys([])
})
//新建一级类目
const dataNewrow = (params:any):AxDataform => ({
  params,
  axApi: axCreateCat1,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//获取o物料二级类目
const dataLevel2 = (row:any):AxDataform => ({
  params: {parentId: row.id},
  axApi: getMaterialLevel2,
  awaitFunc: (predata:any) => {
    row.child = [...predata]
    setRowList([...rowList])
  }
})
//新建二级类目
const dataNewSubrow = (params:any):AxDataform => ({
  params,
  axApi: axCreateCat2,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataLevel2(currentRow))
})
//修改行
const dataUpdaterow = (params:any):AxDataform => {
  return {
    params: {...params, id:currentSubrow.id},
    axApi: axUpdatecat,
    setModalLoading,
    setShowModal,
    awaitFunc: async (predata:any) => {
      if (modalFlag==='subupdate') uniAxList(dataLevel2(currentRow))
      else uniAxList(dataRow())
    }
  }
}
//删除行
const dataDelrow = (rowid:any):AxDataform => ({
  params: {id:rowid},
  axApi: axDelcat,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
const dataSubjectList:AxDataform = {
  params: {page:1, size:9999},
  axApi: axSubjectList,
  // itemModify: {modifyFunc: ({item}:any)=>item.title = item.subjectNo+' '+item.name},
  setList: setSubjectList
}

/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])
/* mounted */
React.useEffect(() => {
  uniAxList(dataRow())
  uniAxList(dataSubjectList)
  setLoadFlag(()=>true)
}, [])

return <>
  <SuperTable
    columns={columns}
    rowList={rowList}
    bottomActions={opauth?bottomActions:[]}
    tableLoading={tableLoading}
    dataRow={dataRow}
    expandable={{
    expandedRowKeys,
    onExpand:(flag:boolean,row:any)=>{
      if (flag) {
        setCurrentRow(row)
        setExpandedRowKeys([...expandedRowKeys,row.id])
        uniAxList(dataLevel2(row))
      } else {
        setCurrentRow({})
        setExpandedRowKeys((prekeys:any)=>prekeys.filter((key:any)=>key!==row.id))
      }
    },
    expandedRowRender:(row:any)=>{
      return row.child?row.child.map((childitem:any,childindex:any)=><div style={{display:'flex',paddingLeft:50,justifyContent:'space-between',boxSizing:'border-box'}} key={childitem.id}>
        {columns.map((colitem:any, index:number)=>{
          if(colitem.key!=='operation') return <div style={{flex:2,borderBottom:'1px solid #ccc',boxSizing:'border-box'}} key={colitem.key}>{colitem.custval?colitem.custval({row:childitem,value:childitem[colitem.key]}):childitem[colitem.key]}</div>
          else return <div style={{borderBottom:'1px solid #ccc',boxSizing:'border-box'}}>{
            colitem.opProps.opList.map((opitem:any)=>{
              if(!opitem.show || opitem.show({row:childitem}))  return <Typography.Link key={opitem.name} style={{ marginRight: 20}} onClick={()=>opitem.action({row:childitem,index:childindex})}>{opitem.name}</Typography.Link>
              else return null
            })
        }</div>
        })}
      </div>):null
    }}}
  />
  <FormDialog
    ref={modalRef}
    title={modalFlag==='new'?'新增一级类目':modalFlag==='update'?'修改一级类目':modalFlag==='subnew'?'新建子类':'修改子类'}
    show={showModal}
    formItems={['new','update'].includes(modalFlag)?formItems:subformItems}
    modalLoading={modalLoading}
    formInitials={formInitials}
    cancel={modalCancel}
    confirm={modalConfirm}
  />
</>
}