import React, { createRef, useState } from 'react'
import { Button, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, message, Modal, Pagination, Radio, Select, Table, TablePaginationConfig, Tooltip, TreeSelect, Typography } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { cancelUserBill, createReinbursement, getCompanyAccoutList, getCompanyList, getCostTypeTree, getSiteInfo, getStaffList, getUserBillList, reinbursementPay } from '../../../utils/api'
import { uniAddRowSubmit, uniAxList, uniAxSearchList, uniCancel, uniCancelConfirm, uniChgRowSubmit, uniConfirm, uniCurrentTime, uniDatePikcerChange, uniDeepArray, uniDeleteRow, uniExportExcel, uniFilterItem, uniFormProcess, uniInitFormdata, uniModifyAxList, uniModifyRow, uniOpAlarm, uniQuitModal, uniSelectRows, uniStaticLast, uniTableChange, uniToProof, uniValueToName } from '../../../utils/uniFucs'
import UniOperationColumn from '../../../components/UniOperationColumn'
import { NameValue, UserBillItem } from '../../../utils/beans'
import FormModal from '../../../components/FormModal'
import { cancelStatus, csDateRages, onlineAccounts, payStatus, payWay, proofStatusList, staticSgPayColumns } from '../../../utils/constant'
import TextArea from 'antd/lib/input/TextArea';
import SummeryRow from '../../../components/SummeryRow';
import { useLocation, useNavigate } from 'react-router-dom';
import tablecss from '../../../css/custable.module.less'
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../../utils/interfaces';
import TopSearch from '../../../components/TopSearch';
import SuperTable from '../../../components/SuperTable';
import FormDialog from '../../../components/FormDialog';

export default function Reimbursement() {
const pathname = "/indexpage/reimbursement"
const modalRef = createRef<FormInstance>()
const searchRef = createRef<FormInstance>()
const initSearchParams = {page:1,filter_EQL_business_id:5,orderBy:"create_date", orderDir: "desc",filter_EQB_is_cancel:false}
const initForminitial = {}
/* hooks */
let location = useLocation()
const navigate = useNavigate()
const [companyList, setCompanyList] = useState([])
const [rowList, setRowList] = useState([])
const [currentRow, setCurrentRow] = useState({} as any)
let [searchParams, setSearchParams] = useState({...initSearchParams} as any)
const [tempSearchParams, setTempSearchParams] = useState({page:1,filter_EQL_business_id:68,orderBy:"create_date", orderDir: "desc",filter_EQB_is_cancel:false} as any)
const [staffList, setStaffList] = useState([])
const [costTypeList, setCostTypeList] = useState([])
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
const [staticTableData, setStaticTableData] = useState([] as any)
const [firmAccounts, setFirmAccounts] = useState([])
const [plusModalKeys, setPlusModalKeys] = useState({} as any)
const [loadFlag, setLoadFlag] = useState(false)
const [modalLoading, setModalLoading] = useState(false)
const [siteInfo, setSiteInfo] = useState({} as any)
const [modalFlag, setModalFlag] = useState('new')
const [showModal, setShowModal] = useState(false)
let [formInitials, setFormInitials] = useState({...initForminitial})

/* funcs */
//路由刷新请求
const routerFlash = () => {
  uniAxList(dataRow())
  uniAxList(uniCompanyData)
  // uniAxList(uniStaffData)
  uniAxList(uniRtData)
  // uniAxList(uniSaData)
  // uniAxList(uniSiteData)
}
const searchConfirm = async (formValus:any) => {setPagination({...pagination,current:1});
  // searchParams = {...initSearchParams,...formValus}
  // setSelectedRows([])
  // setSelectedRowKeys([])
  searchParams = await uniFormProcess({...initSearchParams,...formValus},searchList)
  setSearchParams(searchParams)
  uniAxList(dataRow(1))
}
//modal点击确定
const modalConfirm = async (formValues:any) => {
  if (modalFlag === 'new') {
    if (formValues.payAccountId && !formValues.payTime) return message.error('请选择付款时间')
    else if (!formValues.payAccountId && formValues.payTime) return message.error('请选择付款账户')
  }
  let tempitems = modalFlag === 'pay'?payItems:formItems
  let datafuc = modalFlag === 'new'? dataNewrow : dataPayrow
  let tempparms = await uniFormProcess(formValues,tempitems)
  uniAxList(datafuc({...tempparms,...plusModalKeys}))
}
//modal取消
const modalCancel = () => {
  setShowModal(()=>false)
  setPlusModalKeys({})
  setCurrentRow({})
  setFormInitials({...initForminitial})
}
/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '人员', prop:'filter_LIKES_user_name'},
{label: '作废状态', prop:'filter_EQB_is_cancel',type:'select',dataList:cancelStatus},
{label: '公司', prop:'filter_EQS_company_id',type:'select',dataList:companyList},
{label: '付款状态', prop:'filter_EQL_is_pay',type:'select',dataList:payStatus},
{label: '费用类型', prop:'filter_EQS_cost_type_id',type:'select',dataList:costTypeList},
{label: '备注', prop:'filter_LIKES_remark'},
{label: '申请日期', prop:'filter_BETWEENS_bill_time',type:'dateRange'},
{label: '付款日期', prop:'filter_BETWEENS_pay_time',type:'dateRange'}
]
//列项
const columns:ColumnItem[] = [
{title: '工号',key: 'userJobNum'},
{title: '姓名',key: 'userName'},
{title: '作废状态',key: 'isCancel',custval: ({row,value}:any) =>uniValueToName({list:cancelStatus,value})},
{title: '所属公司',key: 'companyName'},
{title: '费用类型',key: 'costTypeName',},
{title: '金额',key: 'payPrice',type:'price'},
{title: '付款状态',key: 'isPay',type:'tag',custval: ({row,value}:any) =>uniValueToName({list:payStatus,value})},
{title: '付款账户',key: 'payAccount'},
{title: '报销付款日期',key: 'payTime', custval: ({row,value}:any) =>value ? value.split(' ')[0] : ''},
{title: '备注',key: 'remark'},
{title: '申请日期',key: 'billTime',custval: ({row,value}:any) =>value ? value.split(' ')[0] : ''},
{title: '凭证状态',key: 'proofStatus', custval: ({row,value}:any) =>uniValueToName({list:proofStatusList,value})},
{title: '凭证号',key: 'accountProof',ellipsis:false,render: (value:any,row:any) => {
  return <div style={{maxWidth:170}}>
    {value.map((item:any) => <span key={item.accountProofId}><Typography.Link onClick={()=>uniToProof(row,item.accountProofId,navigate)}>{item.accountProofNo}</Typography.Link> </span>)}
  </div>
}},
{title: '操作',key:'operation',type: 'operation',width:100,opProps:{opList:[
  {name:'付款',disabled:({row}:any)=>row.isPay===1,action:({row}:any) => {
    uniAxList(dataFirmacountList(row.companyId))
    setModalFlag('pay')
    setCurrentRow(row)
    setFormInitials(uniInitFormdata(row,formItems))
    setShowModal(true)
  }},
  {name:'作废',disabled:({row}:any)=>row.isPay===1,action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataDelrow(row.id)))}
]}}
]
//底部操作
const bottomActions:ColumnAction[] = [
{label: '新增', type:'primary', action: () => {setModalFlag('new');setShowModal(() => true)}}
]
//添加和修改中的model项
const formItems:FormItemObj[] = [
{label: '所属公司', prop: 'companyId', type:'select',selectProps:{requireName:'companyName'},dataList:companyList,change:(value:any)=>{
  uniAxList(dataStafflist(value))
  uniAxList(dataFirmacountList(value))
}},
//可能要
{label: '人员',prop: 'userId', type:'select', selectProps:{requireName:'userName'},dataList:staffList,change:(value:any)=>{
  let finditem:any = staffList.find((item:any)=>item.id===value)
  if (finditem) setPlusModalKeys((preda:any)=>({...preda,userJobNum:finditem?.jobNum}))
}},
{label: '费用类型',prop: 'costTypeId',type:'select',selectProps:{requireName:'costTypeName'},dataList:costTypeList},
// {label: '付款账户',prop: 'payAccountId',type:'select',selectProps:{requireName:'payAccount',childrenKeys:['name','cardNo']},dataList:firmAccounts},
{label: '金额', prop: 'price', type:'price'},
{label: '申请时间',prop: 'actionTime',type:'datePicker',datepickerProps:{returnType:'string'}},
{label: '备注',prop: 'remark',required:false},
{label: '付款账户',prop: 'payAccountId',type:'select',selectProps:{requireName:'payAccount',childrenKeys:['name','cardNo']},dataList:firmAccounts,required:false},
{label: '付款日期',prop: 'payTime',type:'datePicker',datepickerProps:{returnType:'string'},required:false}
]
//确认收款modal中的item
const payItems:FormItemObj[] = [
// {label: '收款方式',prop: 'payWay',type:'select',dataList:payWay},
{label: '付款账户',prop: 'payAccountId',type:'select',selectProps:{requireName:'payAccount',childrenKeys:['name','cardNo']},dataList:firmAccounts},
{label: '确认付款日期',prop: 'payTime',type:'datePicker',datepickerProps:{returnType:'string'}}
]
//统计列
const staticColumns:any = [
  {
    title: '已付款',
    dataIndex: 'hascash',
    key: 'hascash',
    render: (value:any) => (value/100).toFixed(2),
    addValue: (record:any) => record.isPay === 1 ? record.payPrice: 0
  },
  {
    title: '未付款',
    dataIndex: 'uncash',
    key: 'uncash',
    render: (value:any) => (value/100).toFixed(2),
    addValue: (record:any) => record.isPay === 0 ? record.payPrice: 0
  },
  {
    title: '合计',
    dataIndex: 'rowTotal',
    key: 'rowTotal',
    render: (value:any) => (value/100).toFixed(2),
    addValue: (record:any) => record.payPrice
  },
]

//统计行
const staticRows = [
  {rowKey: 1, checkKey: 'staticTotal',hascash:0,uncash:0,rowTotal:0}
]

/* 接口参数 */
const dataRow = (current?:number):AxDataform => ({
  params: {...searchParams, current: current || pagination.current, size:pagination.pageSize},
  axApi: getUserBillList,
  setList: setRowList,
  setTableLoading,
  setPagination
})
//新建行
const dataNewrow = (params:any):AxDataform => ({
  params,
  axApi: createReinbursement,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//作废删除行
const dataDelrow = (rowid:any):AxDataform => ({
  params: {id:rowid},
  axApi: cancelUserBill,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//确认付款行
const dataPayrow = (params:any):AxDataform => ({
  params: {...params, id:currentRow.id},
  axApi: reinbursementPay,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//公司员工列表人员列表
const dataStafflist =(companyid:any):AxDataform => ({
  params: {groupItemId:companyid,size:999},
  axApi: getStaffList,
  setList: setStaffList
})
//公司账户
const dataFirmacountList = (companyId:any):AxDataform => ({
  params:{companyId},
  axApi: getCompanyAccoutList,
  setList: setFirmAccounts
})
const uniCompanyData:any = {
  axApi: getCompanyList,
  setList: setCompanyList,
}
//报销费用类型
const uniRtData:any = {
params: {businessId: 5, size:9999},
axApi: getCostTypeTree,
// modifyFunc: uniModifyAxList,
// modifyData: {titleList:['title', 'name']},
setList: setCostTypeList
}
const uniSiteData:any = {
  axApi: getSiteInfo,
  setList: setSiteInfo,
}
/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])
/* mounted */
React.useEffect(() => {
  uniAxList(dataRow())
  uniAxList(uniCompanyData)
  uniAxList(uniRtData)
  // uniAxList(uniSiteData)
  setLoadFlag(()=>true)
}, [])
return (
  <>
    <TopSearch
      ref={searchRef}
      initialValues={initSearchParams}
      searchList={searchList}
      searchConfirm={searchConfirm}
    />
    <SuperTable
      columns={columns}
      rowList={rowList}
      bottomActions={bottomActions}
      tableLoading={tableLoading}
      pagination={pagination}
      dataRow={dataRow}
    />
    {/* summary={(pageData:any) => <SummeryRow pageData={pageData} sumInfo={[{mapIndex:6, mapKeys:['payPrice'], sum:0}]} total={columns.length} title={'总金额'} />} */}
    <FormDialog
      ref={modalRef}
      title={modalFlag==='new'?'新增报销':'确认付款'}
      show={showModal}
      formItems={modalFlag==='new'?formItems:payItems}
      modalLoading={modalLoading}
      formInitials={formInitials}
      cancel={modalCancel}
      confirm={modalConfirm}
    />
    {/* <Modal visible={staticModal} title="统计" destroyOnClose onCancel={()=>setStaticModal(()=>false)} footer={[<Button key="back" onClick={()=>setStaticModal(()=>false)}>取消</Button>]}>
      <Table
        rowKey={'rowKey'}
        columns={staticColumns}
        dataSource={staticTableData}
        pagination={false}
        loading={staticLoading}
      />
    </Modal> */}
  </>
)
}
