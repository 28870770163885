import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, Modal, Pagination, Select, Table, TablePaginationConfig, Typography } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { createFmdCost, createMdCost, getCompanyList, getMaterialCostList, getMaterialList } from '../../utils/api'
import { uniAddRowSubmit, uniAxList, uniAxSearchList, uniCurrentTime, uniDatePikcerChange, uniDeepArray, uniExportExcel, uniFilterItem, uniModifyAxList, uniQuitModal, uniSelectRows, uniTableChange, uniToProof } from '../../utils/uniFucs'
import FormModal from '../../components/FormModal';
import { csDateRages, materialTypes, proofStatusList, reviewStatusList } from '../../utils/constant'
import tablecss from '../../css/custable.module.less'

export default function MaterialCost() {
  const pathname = "/indexpage/materialcost"
  /* hooks */
  let location = useLocation()
  const {state} = useLocation()
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const [addModal, setAddModal] = useState(false)
  const [addFmdModal, setAddFmdModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [logModal, setLogModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [stockModal, setStockModal] = useState(false)
  const [staticModal, setStaticModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [logList, setLogList] = useState([])
  const [rowList, setRowList] = useState([])
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({} as any)
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [currentRow, setCurrentRow] = useState({} as any)
  const [searchParams, setSearchParams] = useState({page:1} as any)
  const [tempSearchParams, setTempSearchParams] = useState({page:1} as any)
  const [staffList, setStaffList] = useState([])
  const [feeTypeList, setFeeTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
  const [accountList, setAccountList] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [unCashRows, setUnCashRows] = useState([] as any)
  const [unStockRows, setUnStockRows] = useState([] as any)
  const [isUnderLinePay, setIsUnderLinePay] = useState(true)
  const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
  const [cancelRemark, setCancelRemark] = useState('')
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [positionList, setPositionList] = useState([])
  const [custList, setCustList] = useState([])
  const [confirmItemVisible, setConfirmItemVisible] = useState({paytype: 2})
  const [confirmCompanyList, setConfirmCompanyList] = useState([])
  const [confirmModalInitial, setConfirmModalInitial] = useState({})
  const [currentConfirmRow, setCurrentConfirmRow] = useState({} as any)
  const [custCompanyList, setCustCompanyList] = useState([])
  const [addTableData, setAddTableData] = useState([])
  const [modalCpBusinessList, setModalCpBusinessList] = useState([])
  const [modalFeeTypeList, setModalFeeTypeList] = useState([])
  const [importModal, setImportModal] = useState(false)
  const [accountantSubjectList, setAccountantSubjectList] = useState([])
  const [businessList, setBusinessList] = useState([])
  const [currentSubjectId, setCurrentSubjectId] = useState({id:1})
  const [menuList, setMenuList] = useState([])
  const [initTreeIds, setInitTreeIds] = useState([])
  const [selectTreeIds, setSelectTreeIds] = useState([])
  const [materialList, setMaterialList] = useState([])
  const [customModalInitial, setCustomModalInitial] = useState({} as any)
  const [loadFlag, setLoadFlag] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [selectControl, setSelectControl] = useState({ymd:[]} as any)
  //路由刷新请求
  const routerFlash = () => {
    uniAxList(uniRowData)
    uniAxList(uniCompanyData)
    uniAxList(uniMaterialData)
  }
  /* elements */
  //列项
  const columns:any = [
    {
      title: '物料名称',
      dataIndex: 'materielName',
      key: 'materielName',
    },
    {
      title: '所属公司',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      render: (value:any) => {
        let tempItem:any = uniFilterItem(materialTypes,'value',value)
        return tempItem?tempItem.name:value
      }
    },
    {
      title: '发放/核算日期',
      dataIndex: 'ymd',
      key: 'ymd',
    },
    {
      title: '发放/使用数量',
      dataIndex: 'num',
      key: 'num',
    },
    {
      title: '成本均价/单价',
      dataIndex: 'price',
      key: 'price',
      render: (value:any) => (value / 100).toFixed(2)
    },
    {
      title: '成本合计',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (value:any) => (value / 100).toFixed(2)
    },
    {
      title: '凭证状态',
      dataIndex: 'proofStatus',
      key: 'proofStatus',
      render: (value:any) => {
        let tempItem:any = uniFilterItem(proofStatusList, 'value', value)
        return tempItem ? tempItem.name : value
      }
    },
    {
      title: '凭证号',
      dataIndex: 'proofNo',
      key: 'proofNo',
      render: (value:string,record:any) => {
        let prooflist = value ? value.split(',') : []
        return prooflist.map((item:string) => <span><Typography.Link onClick={()=>uniToProof(record,item, navigate)}>{item}</Typography.Link> </span>)
      }
    },
    {
      title: '操作人',
      dataIndex: 'createBy',
      key: 'createBy',
    },
    {
      title: '创建时间',
      dataIndex: 'createDate',
      key: 'createDate',
    },
  ]

  //添加和修改中的model项
  const modalItems = [
    {
      label: '月份',
      name: 'ym',
      rules: [{ required: true, message: '申请时间不能为空' }],
      element:<ConfigProvider locale={locale}>
                <DatePicker disabledDate={(current)=>{return current && current > moment().endOf('day')}} style={{ width: '100%' }} picker='month' defaultValue={(chgModal && currentRow.ymd) ? moment(currentRow.ymd, 'YYYY-MM-DD') : null as any} onChange={(date, dateString)=>{
                  uniDatePikcerChange({formRef:formModalRef,dateString,keyWord:'ym'})
                  setCustomAddKeys(() => ({...customAddKeys, ymd:dateString+"-01"}))
                }}/>
              </ConfigProvider>      
    },
    {
      label: '所属公司',
      name: 'companyId',
      rules: [{ required: true, message: '所属公司不能为空' }],
      element: <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="所属公司"
                onChange={(value, option:any) => setCustomAddKeys(()=>({...customAddKeys, companyName: option ? option.children : null}))}
              >
                {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
              </Select>
    },
    {
      label: '月发件总票数',
      name: 'num',
      rules: [{ required: true, message: '票数不能为空' }],
      element: <InputNumber style={{width:'100%'}} precision={0} min={0} placeholder="请输入月发件总票数" onChange={(value)=>{
        formModalRef.current?.setFieldsValue({totalPrice: (value || 0) * formModalRef.current?.getFieldValue('price')})
      }}/>
    },
    {
      label: '面单成本单价',
      name: 'price',
      rules: [{ required: true, message: '面单成本单价不能为空' }],
      element: <InputNumber style={{width:'100%'}} min={0} placeholder="请输入面单成本单价" onChange={(value)=>{
        formModalRef.current?.setFieldsValue({totalPrice: (value || 0) * formModalRef.current?.getFieldValue('num')})
      }}/>
    },
    {
      label: '月面单成本合计',
      name: 'totalPrice',
      rules: [{ required: true, message: '月面单成本合计不能为空' }],
      element: <InputNumber style={{width:'100%'}} min={0} placeholder="请输入月面单成本合计"/>
    },
  ]

  //添加和修改非面单中的model项
  const fmdModalItems = [
    {
      label: '提示',
      name: 'tip',
      rules: [{ required: false }],
      element: <span>请确保所有物料采购已做入库处理！</span>    
    },
    {
      label: '生成月份',
      name: 'ym',
      rules: [{ required: true, message: '生成月份不能为空' }],
      element:<ConfigProvider locale={locale}>
                <DatePicker disabledDate={(current)=>{return current && current > moment().endOf('day')}} style={{ width: '100%' }} picker='month' defaultValue={(chgModal && currentRow.ymd) ? moment(currentRow.ymd, 'YYYY-MM-DD') : null as any} onChange={(date, dateString)=>{
                  uniDatePikcerChange({formRef:formModalRef,dateString,keyWord:'ym'})
                }}/>
              </ConfigProvider>      
    }
  ]

  //导出当前页，需要的数据修饰
  const exportModify:any = {
    checkStatus:[
      {newKey:'proofStatus', checkKey: 'proofStatus', sourceList:proofStatusList, sourceCheckKey:'value', sourceValueKey:'name'},
      {newKey:'type', checkKey: 'type', sourceList:materialTypes, sourceCheckKey:'value', sourceValueKey:'name'}
    ],
    divideKey:[{newKey: 'price', key:'price', value: 100}, {newKey: 'totalPrice', key:'totalPrice', value: 100}],
    notNullColumns: columns,
  }

  /* 接口参数 */
  const uniRowData:any = {
    params: searchParams,
    axApi: getMaterialCostList,
    setList: setRowList,
    setTableLoading,
    pagination
  }
  const uniCompanyData:any = {
    axApi: getCompanyList,
    setList: setCompanyList,
  }
  const uniMaterialData:any = {
    axApi: getMaterialList,
    setList: setMaterialList,
  }
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    if(!state) uniAxList(uniRowData)
    uniAxList(uniCompanyData)
    uniAxList(uniMaterialData)
    setLoadFlag(()=>true)
  }, [])
  //路由跳转
  React.useEffect(() => {
    if (state && location.pathname===pathname) {
      selectControl.ymd = []
      let mySate:any = state
      let start = moment(mySate.ym).startOf('month').format("YYYY-MM-DD")
      let end = moment(mySate.ym).endOf('month').format("YYYY-MM-DD")
      let tempYmd = start+','+end
      selectControl.ymd = [moment(start,"YYYY-MM-DD"),moment(end,"YYYY-MM-DD")]
      tempSearchParams.filter_BETWEENS_ymd = tempYmd
      searchParams.filter_BETWEENS_ymd = tempYmd
      uniAxList({...uniRowData,params:{page:1,filter_BETWEENS_ymd:tempYmd}})
    }
  }, [state])

  return (
    <>
      <p>
      <Input
        allowClear
        style={{ width: 200 }}
        placeholder="操作人"
        onChange={(e) => setTempSearchParams(() => ({...tempSearchParams, filter_LIKES_create_by:e.target.value || null}))}
      ></Input>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="物料类型"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQL_type:value || null}))}
      > 
        {materialTypes.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="物料名称"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_materiel_id:value || null}))}
      > 
        {materialList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="选择公司"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_company_id:value || null}))}
      > 
        {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="凭证状态"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQL_proof_status:value || null}))}
      > 
        {proofStatusList.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          value={selectControl.ymd}
          placeholder={['核算开始日期', '核算结束日期']}
          ranges={csDateRages}
          onChange={(dates, dateStrings) => {
            selectControl.ymd = dateStrings[0] ? [moment(dateStrings[0],"YYYY-MM-DD"),moment(dateStrings[1],"YYYY-MM-DD")] : []
            setTempSearchParams(()=>({...tempSearchParams, filter_BETWEENS_ymd: dateStrings[0] ? dateStrings[0]+","+dateStrings[1]+' 23:59:59' : null}))
          }}
        />
      </ConfigProvider>&nbsp;
      <Button onClick={() => {pagination.current=1;uniAxSearchList({setSearchParams, uniRowData, params: {...tempSearchParams,size:pagination.pageSize}})}} type='primary'>查询</Button>
      </p>
      <p>
        <Button type="primary" onClick={() => setAddModal(() => true)}>新增面单成本</Button> &nbsp;
        <Button type="primary" onClick={() => setAddFmdModal(() => true)}>新增非面单成本</Button> &nbsp;
        <Button type="primary" onClick={() => uniExportExcel({columns, rowList: uniDeepArray(rowList), modifyFunc:uniModifyAxList, modifyData:exportModify,title:'物料成本核算表', fileName: '物料成本核算表'+uniCurrentTime({})})}>导出当前页</Button> &nbsp;
        <Button type="primary" loading={exportLoading} onClick={() => uniExportExcel({columns,setExportLoading, axApi:getMaterialCostList, params:{...searchParams, page:null}, modifyFunc:uniModifyAxList, modifyData:{...uniRowData.modifyData, ...exportModify},title:'物料成本核算表',fileName: '物料成本核算表'+uniCurrentTime({})})}>导出所有页码数据</Button> &nbsp;
      </p>
      <p style={{fontWeight:'bold'}}>物料成本核算方式: 全月平均单价 = （上月库存成本金额+本月采购金额累计）/（上月库存余量+本月入库累计）</p>
      <Table
        size='small'
        className={tablecss.height280}
        rowKey={'id'}
        columns={columns}
        // rowSelection={{ ...uniSelectRows(setSelectedRowKeys, setSelectedRows), checkStrictly:true }}
        dataSource={rowList}
        pagination={false}
        scroll={{y:'calc(100vh - 340px)'}}
        loading={tableLoading}
        // onChange={(newPagination)=>{pagination.pageSize = newPagination.pageSize;pagination.current=newPagination.current ;uniAxList({...uniRowData,params: {...searchParams, current:pagination.current, size:pagination.pageSize}})}}
        sticky
      />
      <div style={{textAlign:'right'}}>
        <p></p>
        <ConfigProvider locale={locale}>
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.current}
            total={pagination.total}
            showSizeChanger
            showQuickJumper
            showTotal={total => `共 ${total} 条`}
            onChange={(pageNumbe)=>{
              pagination.current = pageNumbe
              uniAxList({...uniRowData,params:{...searchParams, current:pageNumbe, size:pagination.pageSize}})
            }}
            onShowSizeChange={(current, pageSize)=>{
              pagination.pageSize = pageSize
              uniAxList({...uniRowData,params:{...searchParams, current:pagination.current, size:pageSize}})
            }}
          />
        </ConfigProvider>
      </div>
      <Modal
        width={600}
        maskClosable={false}
        title={'新增面单成本'}
        visible={addModal}
        onOk={() => formModalRef.current?.submit()}
        onCancel={()=>setAddModal(false)}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>setAddModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}
      >
        <Form
          preserve={false}
          ref={formModalRef}
          name="form"
          labelCol={{ span: 6 }}
          initialValues={{}}
          wrapperCol={{ span: 16 }}
          onFinish={(formValus) => {
            let axvalue = {...formValus, price:(formValus.price * 100).toFixed(), totalPrice:(formValus.totalPrice * 100).toFixed()}
            uniAddRowSubmit({axGetData:uniRowData,axAddApi:createMdCost, setAddModal,setModalLoading, customAddKeys})(axvalue)
          }}
          autoComplete="off"
        >
          {modalItems.map((item:any) => (modalItemVisible && item.name in modalItemVisible && !modalItemVisible[item.name]) ? null : <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
        </Form>
      </Modal>
      <FormModal
        ref={formModalRef}
        title='新增非面单成本'
        modalVisible={addFmdModal}
        initialValues={{}}
        quitModal={uniQuitModal(setAddFmdModal)}
        modalConfirm={uniAddRowSubmit({axGetData:uniRowData,axAddApi:createFmdCost, setAddModal:setAddFmdModal,setModalLoading})}
        modelItems={fmdModalItems}
        modalLoading={modalLoading}
      />
    </>
  )
}
