import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, Modal, Pagination, Radio, Select, Table, TablePaginationConfig } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { createAssetInout, getAssets, getAssetsInouts, getCompanyList, getStaffList, getUserAssetNum } from '../../utils/api'
import { uniAddRowSubmit, uniAxList, uniAxSearchList, uniDatePikcerChange, uniFilterItem } from '../../utils/uniFucs'
import { cancelStatus, csAssetInouts, csAssetTypes } from '../../utils/constant'
import TextArea from 'antd/lib/input/TextArea';
import tablecss from '../../css/custable.module.less'

export default function FinanceInout() {
  const pathname = "/indexpage/financeinout"
  /* hooks */
  let location = useLocation()
  const {state} = useLocation()
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [logModal, setLogModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [staticModal, setStaticModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [proofModal, setProofModal] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [logList, setLogList] = useState([])
  const [rowList, setRowList] = useState([])
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({scrapValueRate:true} as any)
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([] as any)
  const [currentRow, setCurrentRow] = useState({} as any)
  const [searchParams, setSearchParams] = useState({page:1,orderBy:"create_date", orderDir: "desc",filter_EQB_is_cancel:false} as any)
  const [tempSearchParams, setTempSearchParams] = useState({page:1,orderBy:"create_date", orderDir: "desc",filter_EQB_is_cancel:false} as any)
  const [staffList, setStaffList] = useState([])
  const [feeTypeList, setFeeTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
  const [accountList, setAccountList] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [unCashRows, setUnCashRows] = useState([] as any)
  const [isUnderLinePay, setIsUnderLinePay] = useState(true)
  const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
  const [cancelRemark, setCancelRemark] = useState('')
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [positionList, setPositionList] = useState([])
  const [custList, setCustList] = useState([])
  const [confirmItemVisible, setConfirmItemVisible] = useState({accountId: true} as any)
  const [confirmCompanyList, setConfirmCompanyList] = useState([])
  const [confirmModalInitial, setConfirmModalInitial] = useState({})
  const [currentConfirmRow, setCurrentConfirmRow] = useState({} as any)
  const [custCompanyList, setCustCompanyList] = useState([])
  const [addTableData, setAddTableData] = useState([] as any)
  const [prepayValue, setPrepayValue] = useState({value:0})
  const [loadFlag, setLoadFlag] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [cancelItemList,setCancelItemList] = useState([])
  const [currentCust,setCurrentCust] = useState({} as any)
  const [currentPrepay,setCurrentPrepay] = useState({value:0})
  const [staticLoading,setStaticLoading] = useState(false)
  const [modalFeeTypeList, setModalFeeTypeList] = useState([])
  const [proofItemControl, setProofItemControl] = useState({maxYm:''})
  const [assetList, setAssetList] = useState([])
  const [modalControl, setModalControl] = useState({status:1,restNum: 0, userNum:0})

  /* funcs */
  //路由刷新请求
  const routerFlash = () => {
    uniAxList(uniRowData)
    uniAxList(uniAssetData)
    uniAxList(uniStaffData)
    uniAxList(uniCompanyData)
  }
  //点击新增
  const onAdd = () => {
    modalControl.status = 1
    modalControl.restNum = 0
    modalControl.userNum = 0
    setAddModal(() => true)
    setCustomAddKeys(()=>[])
  }
  /* elements */
  // 列首行
  const columns:any = [
    {
      title: '借还人',
      dataIndex: 'useByName',
      key: 'useByName'
    },
    {
      title: '借还类型',
      dataIndex: 'status',
      key: 'status',
      render:(value:any) => {
        let tempItem:any = uniFilterItem(csAssetInouts,'value',value)
        return tempItem?tempItem.name:value
      }
    },
    {
      title: '借还数量',
      dataIndex: 'number',
      key: 'number'
    },
    {
      title: '资产名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '资产类型',
      dataIndex: 'assetsType',
      key: 'assetsType',
      render:(value:any) => {
        let tempItem:any = uniFilterItem(csAssetTypes,'value',value)
        return tempItem?tempItem.name:value
      }
    },
    {
      title: '所属公司',
      dataIndex: 'companyName',
      key: 'companyName'
    },
    {
      title: '费用类型',
      dataIndex: 'costTypeName',
      key: 'costTypeName'
    },
    {
      title: '作废状态',
      dataIndex: 'isCancel',
      key: 'isCancel',
      render: (value:any) => value?'作废':'正常'
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark'
    },
    {
      title: '创建人',
      dataIndex: 'createBy',
      key: 'createBy'
    },
    {
      title: '创建时间',
      dataIndex: 'createDate',
      key: 'createDate'
    }
  ]
  //添加和修改中的model项
  const modalItems = [
    {
      label: '类型',
      name: 'status',
      rules: [{ required: true, message: '类型不能为空' }],
      element: <Radio.Group onChange={(e)=>{
        formModalRef.current?.setFieldsValue({number:null})
        setModalControl(()=>({...modalControl,status:e.target.value}))
        if (e.target.value === 2) {
          let fixedAssetsId = formModalRef.current?.getFieldValue('fixedAssetsId')
          let userId = formModalRef.current?.getFieldValue('useById')
          if (userId && fixedAssetsId) uniAxList({
            ...uniUserAssetNumData,
            params:{fixedAssetsId,userId},
            awaitFunc: (predata:any) =>{
              setModalControl(()=>({...modalControl,status:2,userNum:predata}))
            }
          })
        }
      }}>
        {csAssetInouts.map((item:any) => <Radio key={item.value} value={item.value}>{item.name}</Radio>)}
      </Radio.Group>
    },
    {
      label: '资产名称',
      name: 'fixedAssetsId',
      rules: [{ required: true, message: '资产名称不能为空' }],
      element: <Select
                  showSearch
                  optionFilterProp='children'
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="资产名称"
                  onChange={(value,option:any) => {
                    let userId = formModalRef.current?.getFieldValue('useById')
                    if (value) {
                      let tempItem:any = uniFilterItem(assetList,'id',value)
                      if (modalControl.status===1 || modalControl.status===3) setModalControl(()=>({...modalControl,restNum:tempItem.number-tempItem.useNumber}))
                      else if (modalControl.status===2 && userId) uniAxList({
                        ...uniUserAssetNumData,
                        params:{fixedAssetsId:value,userId},
                        awaitFunc: (predata:any) => {
                          setModalControl(()=>({...modalControl,restNum:tempItem.number-tempItem.useNumber,userNum:predata}))
                        }
                      })
                    }
                  }}
                >
                  {assetList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                </Select>
    },
    {
      label: '人员',
      name: 'useById',
      rules: [{ required: true, message: '人员不能为空' }],
      element: <Select
                  showSearch
                  optionFilterProp='children'
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="选择人员"
                  onChange={(value,option:any) => {
                    let fixedAssetsId = formModalRef.current?.getFieldValue('fixedAssetsId')
                    if (value && fixedAssetsId && modalControl.status===2) uniAxList({
                      ...uniUserAssetNumData,
                      params:{fixedAssetsId,userId:value},
                      awaitFunc: (predata:any) => setModalControl(()=>({...modalControl,userNum:predata}))
                    })
                    setCustomAddKeys(()=>({...customAddKeys, useByName: value?option.children : null}))
                  }}
                >
                  {staffList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                </Select>
    },
    {
      label: '数量',
      name: 'number',
      rules: [
        { required: true, message: '数量不能为空' },
        (modalControl.status===1 || modalControl.status===3)
        ?({ getFieldValue }:any ) => ({validator(_:any, value:any) {
          if (modalControl.restNum >= value) return Promise.resolve();
          return Promise.reject(new Error('借出数量不能大于剩余数量'))
        }})
        :({ getFieldValue }:any ) => ({validator(_:any, value:any) {
          if (modalControl.userNum >= value) return Promise.resolve();
          return Promise.reject(new Error('归还数量不能大于用户持有数量'))
        }})
      ],
      element: <InputNumber readOnly={(modalControl.status===1||modalControl.status===3)?modalControl.restNum<=0:modalControl.userNum<=0} precision={0} style={{width: '100%'}} placeholder={(modalControl.status===1||modalControl.status===3)?('剩余数量'+modalControl.restNum):('用户持有数'+modalControl.userNum)}/>
    },
    {
      label: '时间',
      name: 'applyReturnDate',
      rules: [{ required: true, message: '时间不能为空' }],
      element:<ConfigProvider locale={locale}>
                <DatePicker disabledDate={(current)=>{return current && current > moment().endOf('day')}} style={{width: '100%'}} defaultValue={(chgModal && currentRow.applyReturnDate) ? moment((currentRow.applyReturnDate as string).split(' ')[0], 'YYYY-MM-DD') : null as any} onChange={(date, dateString)=>uniDatePikcerChange({formRef:formModalRef,dateString:dateString,keyWord:'applyReturnDate'})}/>
              </ConfigProvider>      
    },
    {
      label: '备注',
      name: 'remark',
      rules: [{ required: false }],
      element: <TextArea style={{ height: 80, resize:'none' }} />
    },
  ]

  /* 接口参数 */
  const uniRowData:any = {
    params: {...searchParams,current:pagination.current,size:pagination.pageSize},
    axApi: getAssetsInouts,
    setList: setRowList,
    setTableLoading,
    pagination
  }
  const uniAssetData:any = {
    axApi: getAssets,
    setList: setAssetList,
  }
  const uniStaffData:any = {
    params: {size:999},
    axApi: getStaffList,
    setList: setStaffList
  }
  const uniUserAssetNumData:any = {
    axApi: getUserAssetNum
  }
  const uniCompanyData:any = {
    axApi: getCompanyList,
    setList: setCompanyList,
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    uniAxList(uniRowData)
    uniAxList(uniAssetData)
    uniAxList(uniStaffData)
    uniAxList(uniCompanyData)
    setLoadFlag(()=>true)
  }, [])

  return (
    <>
      <p>
      <Select
        showSearch
        optionFilterProp='children'
        allowClear
        style={{ width: 200 }}
        placeholder="借还人"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_use_by_id:value || null}))
        }
      > 
        {staffList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="借还类型"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQL_status:value || null}))
        }
      > 
        {csAssetInouts.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        showSearch
        optionFilterProp='children'
        allowClear
        style={{ width: 200 }}
        placeholder="资产名称"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_fixed_assets_id:value || null}))
        }
      > 
        {assetList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="选择公司"
        onChange={(companyId) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_company_id:companyId || null}))}
      > 
        {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="作废状态"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQB_is_cancel:(value ===null || value===undefined ? false : value)}))
        }
      > 
        {cancelStatus.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Button onClick={() => {pagination.current=1;uniAxSearchList({setSearchParams, uniRowData, params: {...tempSearchParams,size:pagination.pageSize}})}} type='primary'>查询</Button>&nbsp;
      <Button type="primary" onClick={()=>onAdd()}>新增</Button> &nbsp;
      </p>
      <Table
        size='small'
        className={tablecss.height260}
        rowKey={'id'}
        columns={columns}
        // rowSelection={{ ...uniSelectRows(setSelectedRowKeys, setSelectedRows), checkStrictly:true }}
        dataSource={rowList}
        pagination={false}
        loading={tableLoading}
        // onChange={(newPagination)=>{pagination.pageSize = newPagination.pageSize;pagination.current=newPagination.current ;uniAxList({...uniRowData,params: {...searchParams, current:pagination.current, size:pagination.pageSize}})}}
        scroll={{y:'calc(100vh - 260px)'}}
        sticky
      />
      <div style={{textAlign:'right'}}>
        <p></p>
        <ConfigProvider locale={locale}>
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.current}
            total={pagination.total}
            showSizeChanger
            showQuickJumper
            showTotal={total => `共 ${total} 条`}
            onChange={(pageNumbe)=>{
              pagination.current = pageNumbe
              uniAxList({...uniRowData,params:{...searchParams, current:pageNumbe, size:pagination.pageSize}})
            }}
            onShowSizeChange={(current, pageSize)=>{
              pagination.pageSize = pageSize
              uniAxList({...uniRowData,params:{...searchParams, current:pagination.current, size:pageSize}})
            }}
          />
        </ConfigProvider>
      </div>
      <Modal
        width={600}
        maskClosable={false} title={'新增'}
        visible={addModal}
        onOk={() => formModalRef.current?.submit()}
        onCancel={()=>setAddModal(false)}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>setAddModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}
      >
        <Form
          preserve={false}
          ref={formModalRef}
          name="form"
          labelCol={{ span: 6 }}
          initialValues={{status:1}}
          wrapperCol={{ span: 16 }}
          onFinish={async (formValus) => {
            uniAddRowSubmit({axGetData:uniRowData,axAddApi:createAssetInout, setAddModal, customAddKeys,setModalLoading})(formValus)
            uniAxList(uniAssetData)
          }}
          autoComplete="off"
        >
          {modalItems.map((item:any) => (modalItemVisible && item.name in modalItemVisible && !modalItemVisible[item.name]) ? null : <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
        </Form>
      </Modal>
    </>
  )
}
