import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker, FormInstance, Select, Table, TablePaginationConfig } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { getCompanyList, getMaterialDayLog, getMaterialList } from '../../utils/api'
import { uniAxList, uniAxSearchList, uniCurrentTime, uniDeepArray, uniExportExcel, uniFormProcess, uniModifyAxList, uniSelectRows, uniTableChange } from '../../utils/uniFucs'
import SummeryRow from '../../components/SummeryRow'
import { csDateRages } from '../../utils/constant'
import tablecss from '../../css/custable.module.less'
import TopSearch from '../../components/TopSearch';
import { AxDataform, ColumnItem, FormItemObj } from '../../utils/interfaces';
import SuperTable from '../../components/SuperTable';

export default function MaterialDailyCheck() {
  const pathname = "/indexpage/materialdailycheck"
  const searchRef = createRef<FormInstance>()
  const initSearchParams = {}
  /* hooks */
  let location = useLocation()
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [logModal, setLogModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [stockModal, setStockModal] = useState(false)
  const [staticModal, setStaticModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [logList, setLogList] = useState([])
  const [rowList, setRowList] = useState([])
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({userType:1, stock:0})
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [currentRow, setCurrentRow] = useState({} as any)
  let [searchParams, setSearchParams] = useState({...initSearchParams} as any)
  const [tempSearchParams, setTempSearchParams] = useState({materielId: ''} as any)
  const [staffList, setStaffList] = useState([])
  const [feeTypeList, setFeeTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 10})
  const [accountList, setAccountList] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [unCashRows, setUnCashRows] = useState([] as any)
  const [unStockRows, setUnStockRows] = useState([] as any)
  const [isUnderLinePay, setIsUnderLinePay] = useState(true)
  const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
  const [cancelRemark, setCancelRemark] = useState('')
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [positionList, setPositionList] = useState([])
  const [custList, setCustList] = useState([])
  const [confirmItemVisible, setConfirmItemVisible] = useState({paytype: 2})
  const [confirmCompanyList, setConfirmCompanyList] = useState([])
  const [confirmModalInitial, setConfirmModalInitial] = useState({})
  const [currentConfirmRow, setCurrentConfirmRow] = useState({} as any)
  const [custCompanyList, setCustCompanyList] = useState([])
  const [addTableData, setAddTableData] = useState([])
  const [modalCpBusinessList, setModalCpBusinessList] = useState([])
  const [modalFeeTypeList, setModalFeeTypeList] = useState([])
  const [importModal, setImportModal] = useState(false)
  const [accountantSubjectList, setAccountantSubjectList] = useState([])
  const [businessList, setBusinessList] = useState([])
  const [currentSubjectId, setCurrentSubjectId] = useState({id:1})
  const [menuList, setMenuList] = useState([])
  const [initTreeIds, setInitTreeIds] = useState([])
  const [selectTreeIds, setSelectTreeIds] = useState([])
  const [materialList, setMaterialList] = useState([] as any)
  const [customModalInitial, setCustomModalInitial] = useState({} as any)
  const [materialDefault, setMaterialDefault] = useState({value:0})
  const [loadFlag, setLoadFlag] = useState(false)
  let [searchYmrange, setSearchYmrange] = useState([]  as any)

  /* funcs */
  //路由刷新请求
  const routerFlash = () => {
    uniAxList(uniCompanyData)
    // getRowList()
  }
  const searchConfirm = async (formValus:any) => {setPagination({...pagination,current:1});
    searchParams = await uniFormProcess({...initSearchParams,...formValus},searchList)
    uniAxList(dataRow())
  }
  //接口获取数据修饰
  const modifyRowList = ({initNum, materielDayLogList}:any) => {
    let templist = materielDayLogList.reverse()
    initNum.forEach((initItem:any) => {
      initItem.buyPrice = initItem.buyPrice / 100
      initItem.salePrice = initItem.salePrice / 100
    })
    templist.forEach((item:any, index:number) => {
      item.applyTotalPrice = item.applyTotalPrice / 100
      item.buyTotalPrice = item.buyTotalPrice / 100
      if (index === 0) item.initNum = initNum[0].initNum
      else item.initNum = templist[index-1].initNum + templist[index-1].inNum - templist[index-1].outNum + templist[index-1].changeNum
      item.restNum = item.initNum + item.inNum - item.outNum + item.changeNum
    })
    // return materielDayLogList.reverse()
    return templist
  }
  // const getRowList = async () => {
  //   let matres:any = await getMaterialList({})
  //   materialDefault.value = matres.data[0].id
  //   searchParams.materielId = matres.data[0].id
  //   tempSearchParams.materielId = matres.data[0].id
  //   uniAxList(dataRow())
  // }

  /* elements */
  //搜索行
const searchList:FormItemObj[] = [
{label: '公司', prop:'filter_EQS_company_id',type:'select',dataList:companyList,clearable:false,required:true,change:(value:any)=>{
  if (value) {
    searchRef.current?.setFieldsValue({'myrange':null})
    let finditem:any = companyList.find((item:any)=>item.id===value)
    if (finditem.initialize===1) setSearchYmrange([finditem.startYm,finditem.endYm])
    else setSearchYmrange([0])
  } else setSearchYmrange([])
  uniAxList(dataMaterial(value))
  searchRef.current?.setFieldsValue({materielId:null})
}},
{label: '物料', prop:'materielId',type:'select',dataList:materialList,required:true},
{label: '日期范围', prop:'myrange',type:'dateRange',daterangeProps:{returnType:'string',rangeKeys:['beginYmd','endYmd'],disabledDate:(current)=>{
  return searchYmrange.length>1
    ?(current<moment(searchYmrange[0]).startOf('month') || current>moment(searchYmrange[1]).endOf('month'))
    :searchYmrange.length===1?true:current > moment().endOf('day')
}}}
]
//列项
const columns:ColumnItem[] = [
{title: '物料名称',key: 'materielName',},
{title: '所属公司',key: 'companyName',},
{title: '出入库日期',key: 'ymd'},
{title: '期初余量',key: 'initNum'},
{title: '入库数量',key: 'inNum'},
{title: '采购金额',key: 'buyTotalPrice'},
{title: '出库数量',key: 'outNum'},
{title: '申领金额',key: 'applyTotalPrice'},
// {title: '库存手动修改数量',key: 'changeNum'},
{title: '库存余量',key: 'restNum'}
]
//table统计列
const staticColumns = [
{mapIndex:4, mapKeys:['inNum'], sum:0},
{mapIndex:5, mapKeys:['buyTotalPrice'], sum:0},
{mapIndex:6, mapKeys:['outNum'], sum:0},
{mapIndex:7, mapKeys:['applyTotalPrice'], sum:0},
]

/* 接口参数 */
const dataRow = ():AxDataform => ({
  params:searchParams,
  axApi: getMaterialDayLog,
  modifyFunc: modifyRowList,
  setList: setRowList,
  setTableLoading,
})
//物料列表
const dataMaterial = (companyId:any):AxDataform=>({
  params: {filter_EQS_company_id: companyId},
  axApi: getMaterialList,
  setList: setMaterialList,
})
const uniCompanyData:any = {
  axApi: getCompanyList,
  setList: setCompanyList,
}
/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])
/* mounted */
React.useEffect(() => {
  uniAxList(uniCompanyData)
  // uniAxList(dataMaterial)
  // getRowList()
  setLoadFlag(()=>true)
}, [])

return (
  <>
    <TopSearch
      ref={searchRef}
      searchList={searchList}
      searchConfirm={searchConfirm}
    />
    <SuperTable
      columns={columns}
      rowList={rowList}
      tableLoading={tableLoading}
      dataRow={dataRow}
      summary={(pageData:any) => <SummeryRow pageData={pageData} sumInfo={staticColumns} total={columns.length} title={'合计'} />}
    />
  </>
)
}
