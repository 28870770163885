import React, { createRef, useState } from 'react'
import { Button, ConfigProvider, DatePicker, FormInstance, Input, message, Modal, Pagination, Select, Table, TablePaginationConfig, Tooltip, Typography } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { ModifyParams, StaffItem, UserBillItem } from '../../../utils/beans'
import { cancelUserBill, getBusinessList, getCompanyAccoutList, getCompanyList, getCostTypeTree, getPositionList, getStaffList, getUserBillList } from '../../../utils/api'
import { uniAxList, uniAxSearchList, uniCancel, uniCancelConfirm, uniCurrentTime, uniDeepArray, uniExportExcel, uniFilterItem, uniModifyAxList, uniSelectRows, uniStaticLast, uniTableChange, uniToProof } from '../../../utils/uniFucs'
import UniOperationColumn from '../../../components/UniOperationColumn'
import SummeryRow from '../../../components/SummeryRow'
import { businessTypes, cancelStatus, csDateRages, incomeTypes, payWay, proofStatusList, staticAllColumns } from '../../../utils/constant'
import TextArea from 'antd/lib/input/TextArea';
import { useLocation, useNavigate } from 'react-router-dom';
import tablecss from '../../../css/custable.module.less'

export default function BillSummary() {
  const pathname = "/indexpage/billsummary"
  /* hooks */
  let location = useLocation()
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [staticModal, setStaticModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [rowList, setRowList] = useState([])
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({showRealName:true, showCardNo:true, showBeginBalance:true, showBeginTime:true})
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [currentRow, setCurrentRow] = useState({} as UserBillItem)
  const [searchParams, setSearchParams] = useState({page:1,orderBy:"create_date", orderDir: "desc",filter_EQB_is_cancel:false} as any)
  const [tempSearchParams, setTempSearchParams] = useState({page:1,orderBy:"create_date", orderDir: "desc",filter_EQB_is_cancel:false} as any)
  const [staffList, setStaffList] = useState([])
  const [feeTypeList, setFeeTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
  const [staffAccounts, setStaffAccounts] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [unCashRows, setUnCashRows] = useState([] as any)
  const [isUnderLinePay, setIsUnderLinePay] = useState(true)
  const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
  const [cancelRemark, setCancelRemark] = useState('')
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [positionList, setPositionList] = useState([])
  const [businessList, setBusinessList] = useState([])
  const [loadFlag, setLoadFlag] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [staticLoading,setStaticLoading] = useState(false)

  /* funcs */
  //路由刷新请求
  const routerFlash = () => {
    uniAxList(uniRowData)
    uniAxList(uniCompanyData)
    uniAxList(uniStaffData)
    uniAxList(uniSaData)
    uniAxList(uniRtData)
    uniAxList(uniPositionData)
    uniAxList(uniBusinessData)
  }
  /* elements */
  //列项
  const columns:any = [
    {
      title: '工号',
      dataIndex: 'userJobNum',
      key: 'userJobNum',
      fixed: 'left'
    },
    {
      title: '姓名',
      dataIndex: 'userName',
      key: 'userName',
      fixed: 'left'
    },
    {
      title: '作废状态',
      dataIndex: 'isCancel',
      key: 'isCancel',
      render: (value:any) => value ? '作废' : '正常'
    },
    {
      title: '所属公司',
      dataIndex: 'companyName',
      key: 'companyName'
    },
    {
      title: '所属部门',
      dataIndex: 'userId',
      key: 'userId',
      render: (userId:string) => {
        const tempItem:any = staffList.find((item:StaffItem) => userId === item.id)
        if (tempItem && positionList.length) {
          const tempDp:any = uniFilterItem(positionList, 'id', tempItem.positionId)
          return tempDp ? tempDp.name : ''
        } else return ''
      }
    },
    {
      title: '收付类型',
      dataIndex: 'incomeType',
      key: 'incomeType',
      render: (value:any) => {
        let tempItem:any = uniFilterItem(incomeTypes,'value',value)
        return tempItem ? tempItem.name : value
      }
    },
    {
      title: '业务类型',
      dataIndex: 'businessName',
      key: 'businessName'
    },
    {
      title: '费用类型',
      dataIndex: 'costTypeName',
      key: 'costTypeName'
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      ellipsis: {showTitle: false},
      render: (value:string) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: '金额',
      dataIndex: 'collectionPrice',
      key: 'collectionPrice',
      render: (collectionPrice:number, record:any) => {
        if (record.businessId===73 || record.businessId===79) {
          if (record.isPay === 0) return collectionPrice.toFixed(2)
          else if (record.isPay === 1) return record.payPrice.toFixed(2)
          else return '-'
        } else {
          if (record.incomeType === 1 || record.incomeType === 3) return collectionPrice.toFixed(2)
          else return record.payPrice.toFixed(2)
        }
      }
    },
    {
      title: '收款方式',
      dataIndex: 'collectionWay',
      key: 'collectionWay',
      render: (value:any) => {
        let tempItem:any = uniFilterItem(payWay,'value',value)
        return tempItem ? tempItem.name : value
      }
    },
    {
      title: '确认收/付款时间',
      dataIndex: 'collectionTime',
      key: 'collectionTime',
      render: (collectionTime:string, record:any) => {
        if (record.incomeType === 1 || record.incomeType === 3) return collectionTime ? collectionTime.split(' ')[0] : ''
        else return record.payTime ? record.payTime.split(' ')[0] : ''
      }
    },
    {
      title: '凭证状态',
      dataIndex: 'proofStatus',
      key: 'proofStatus',
      render: (value:any) => {
        let tempItem:any = uniFilterItem(proofStatusList, 'value', value)
        return tempItem ? tempItem.name : value
      }
    },
    {
      title: '凭证号',
      dataIndex: 'proofNo',
      key: 'proofNo',
      render: (value:string, record:any) => {
        let prooflist = value ? value.split(',') : []
        return prooflist.map((item:string) => <span><Typography.Link onClick={()=>uniToProof(record,item, navigate)}>{item}</Typography.Link> </span>)
      }
    },
    {
      title: '账单日期',
      dataIndex: 'billTime',
      key: 'billTime',
      render: (value:string) => value ? value.split(' ')[0] : ''
    },
    {
      title: '操作人',
      dataIndex: 'createBy',
      key: 'createBy'
    },
    {
      title: '创建时间',
      dataIndex: 'createDate',
      key: 'createDate'
    }
  ]
  //统计table的列
  const staticColumns:any = [
    {
      title: '收付类型',
      dataIndex: 'rowName',
      key: 'rowName',
    },
    {
      title: '费用类型',
      dataIndex: 'costTypeName',
      key: 'costTypeName',
    },
    {
      title: '记录条数',
      dataIndex: 'count',
      key: 'count',
      addValue: (record:any,children:any) => {
        let price = (record.incomeType === 1 || record.incomeType === 3) ? record.collectionPrice : record.payPrice
        if (children) {
          let childItem = children.find((item:any)=>item.costTypeId === record.costTypeId)
          if (childItem) {
            childItem.count += 1
            childItem.rowTotal += price
          } else children.push({...record,rowName:'',count:1,rowTotal:price})
        }
        return 1
      }
    },
    {
      title: '小计',
      dataIndex: 'rowTotal',
      key: 'rowTotal',
      render: (value:any) => (value/100).toFixed(2),
      addValue: (record:any) => (record.incomeType === 1 || record.incomeType === 3) ? record.collectionPrice : record.payPrice,
      totalAdd: (record:any) => (record.incomeType === 1 || record.incomeType === 3) ? record.collectionPrice : -record.payPrice
    }
  ]
  //统计table中的行
  const staticRows:any = [
    {rowName: '应收', checkKey: 'incomeType', checkValue: 1, costTypeName:'应收费用', count:0, rowTotal:0, children:[]},
    {rowName: '应付', checkKey: 'incomeType', checkValue: 2, costTypeName:'应付费用', count:0, rowTotal:0, children:[]},
    {rowName: '实收', checkKey: 'incomeType', checkValue: 3, costTypeName:'实收费用', count:0, rowTotal:0, children:[]},
    {rowName: '实付', checkKey: 'incomeType', checkValue: 4, costTypeName:'实付费用', count:0, rowTotal:0, children:[]},
    {rowName: '合计', checkKey: 'staticTotal', costTypeName:'应收-应付+实收-实付', count:0, rowTotal:0}
  ]

  //导出当前页，需要的数据修饰
  const exportModify:ModifyParams = {
    funcKey:(record:any)=>{
      if (record.businessId===73 || record.businessId===79) {
        if (record.isPay === 1) record.collectionPrice = record.payPrice
      } else {
        if (record.incomeType === 2 || record.incomeType === 4) record.collectionPrice = record.payPrice
      }
    },
    copyKey: [
      {newKey: 'collectionTime', checkKey: 'incomeType', checkInfo:[{value:1,copyKey:'collectionTime'},{value:2,copyKey:'payTime'},{value:3,copyKey:'collectionTime'},{value:4,copyKey:'payTime'}]},
    ],
    splitTime: ['collectionTime','actionTime'],
    checkStatus:[
      {newKey:'isCancel', checkKey: 'isCancel', sourceList:cancelStatus, sourceCheckKey:'value', sourceValueKey:'name'},
      {newKey:'userId', checkKey: 'userId', sourceList:staffList, sourceCheckKey:'id', sourceValueKey:'positionId'},
      {newKey:'incomeType', checkKey: 'incomeType', sourceList:incomeTypes, sourceCheckKey:'value', sourceValueKey:'name'},
      {newKey:'collectionWay', checkKey: 'collectionWay', sourceList:payWay, sourceCheckKey:'value', sourceValueKey:'name'},
      {newKey:'proofStatus', checkKey: 'proofStatus', sourceList:proofStatusList, sourceCheckKey:'value', sourceValueKey:'name'},
    ],
    notNullColumns: columns,
  }

  /* 接口参数 */
  const uniRowData:any = {
    params: {...searchParams, current:pagination.current, size:pagination.pageSize},
    axApi: getUserBillList,
    modifyFunc: uniModifyAxList,
    modifyData: {
      divideKey:[{newKey: 'collectionPrice', key:'collectionPrice', value: 100},{newKey: 'payPrice', key:'payPrice', value: 100}],
    },
    setList: setRowList,
    setTableLoading,
    pagination
  }
  const uniCompanyData:any = {
    axApi: getCompanyList,
    setList: setCompanyList,
  }
  const uniStaffData:any = {
    params: {size:999},
    axApi: getStaffList,
    setList: setStaffList
  }
  const uniPositionData:any = {
    axApi: getPositionList,
    setList: setPositionList
  }
  //费用类型
  const uniRtData:any = {
    params:{size:9999},
    axApi: getCostTypeTree,
    modifyFunc: uniModifyAxList,
    modifyData: {titleList:['title', 'name']},
    setList: setFeeTypeList
  }
  //个人账户
  const uniSaData:any = {
    axApi: getCompanyAccoutList,
    setList: setStaffAccounts
  }
  const uniBusinessData:any = {
    // params:{filter_EQL_type:1},
    axApi: getBusinessList,
    setList: setBusinessList,
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    uniAxList(uniRowData)
    uniAxList(uniCompanyData)
    uniAxList(uniStaffData)
    uniAxList(uniSaData)
    uniAxList(uniRtData)
    uniAxList(uniPositionData)
    uniAxList(uniBusinessData)
    setLoadFlag(()=>true)
  }, [])

  return (
    <>
      <div>
      <Select
        showSearch
        optionFilterProp='children'
        allowClear
        style={{ width: 200 }}
        placeholder="选择人员"
        onChange={(userId) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_user_id:userId || null}))}
      > 
        {staffList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="选择公司"
        onChange={(companyId) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_company_id:companyId || null}))}
      > 
        {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="作废状态"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQB_is_cancel:(value ===null || value===undefined ? false : value)}))
        }
      > 
        {cancelStatus.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        showSearch
        optionFilterProp='children'
        allowClear
        style={{ width: 200 }}
        placeholder="业务类型"
        onChange={(businessId) => setTempSearchParams(() => ({...tempSearchParams, filter_EQL_business_id:businessId || null}))}
      > 
        {businessList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="收支类型"
        onChange={(incomeType) => setTempSearchParams(() => ({...tempSearchParams, filter_EQL_income_Type:incomeType || null}))}
      > 
        {incomeTypes.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="收/付款方式"
        onChange={(payWay) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_pay_way:payWay || null}))}
      > 
        {payWay.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="凭证状态"
        onChange={(proofStatus) => setTempSearchParams(() => ({...tempSearchParams, filter_EQL_proof_status:proofStatus || null}))}
      > 
        {proofStatusList.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Input
        allowClear
        style={{ width: 200 }}
        placeholder="输入备注"
        onChange={(e) => setTempSearchParams(() => ({...tempSearchParams, filter_LIKES_remark:e.target.value || null}))}
      ></Input>&nbsp;
      <Input
        allowClear
        style={{ width: 200 }}
        placeholder="输入金额"
        onChange={(e:any) => setTempSearchParams(() => ({...tempSearchParams, filter_EQL_pay_price_OR_collection_price:(isNaN(Number(e.target.value)) || !e.target.value) ? null : Number(e.target.value)*100}))}
      ></Input>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          placeholder={['账单开始日期', '账单结束日期']}
          ranges={csDateRages}
          onChange={(dates, dateStrings) => setTempSearchParams(()=>({...tempSearchParams, filter_BETWEENS_bill_time: dateStrings[0] ? dateStrings[0]+","+dateStrings[1]+' 23:59:59' : null}))}
        />
      </ConfigProvider>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          placeholder={['收/付款开始日期', '收/付款结束日期']}
          ranges={csDateRages}
          onChange={(dates, dateStrings) => setTempSearchParams(()=>({...tempSearchParams, filter_BETWEENS_collection_time_OR_pay_time: dateStrings[0] ? dateStrings[0]+","+dateStrings[1]+' 23:59:59' : null}))}
        />
      </ConfigProvider>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          placeholder={['创建开始日期', '创建结束日期']}
          ranges={csDateRages}
          onChange={(dates, dateStrings) => setTempSearchParams(()=>({...tempSearchParams, filter_BETWEENS_create_date: dateStrings[0] ? dateStrings[0]+","+dateStrings[1]+' 23:59:59' : null}))}
        />
      </ConfigProvider>&nbsp;
      <Button onClick={() => {pagination.current=1;uniAxSearchList({setSearchParams, uniRowData, params: {...tempSearchParams,size:pagination.pageSize}})}} type='primary'>查询</Button>
      </div>
      <p></p>
      <p>
      <Button type="primary" danger onClick={() => {setCancelRemark(()=>'');uniCancel({selectedRows,setCancelModal})}}>作废操作</Button> &nbsp;
      <Button type="primary" onClick={() => uniStaticLast({
        axApi:getUserBillList,
        params:{...searchParams, page:null},
        staticColumns,
        staticRows,
        setStaticModal,
        setStaticTableData,
        setStaticLoading
      })}>统计所有页数据</Button> &nbsp;
      <Button type="primary" onClick={() => uniExportExcel({columns, rowList: uniDeepArray(rowList), modifyFunc:uniModifyAxList, modifyData:exportModify,title:'账单汇总', fileName: '账单汇总'+uniCurrentTime({})})}>导出当前页</Button> &nbsp;
      <Button type="primary" loading={exportLoading} onClick={() => uniExportExcel({columns,setExportLoading, axApi:getUserBillList, params:{...searchParams, page:null}, modifyFunc:uniModifyAxList, modifyData:{...uniRowData.modifyData, ...exportModify},title:'账单汇总',fileName: '账单汇总'+uniCurrentTime({})})}>导出所有页码数据</Button> &nbsp;
      </p>
      <Table
        size='small'
        className={tablecss.height280}
        rowKey={'id'}
        columns={columns}
        rowSelection={{ ...uniSelectRows(setSelectedRowKeys, setSelectedRows), checkStrictly:true }}
        dataSource={rowList}
        pagination={false}
        loading={tableLoading}
        scroll={{x:2400, y:'calc(100vh - 340px)'}}
        // onChange={(newPagination)=>{pagination.pageSize = newPagination.pageSize;pagination.current=newPagination.current ;uniAxList({...uniRowData,params: {...searchParams, current:pagination.current, size:pagination.pageSize}})}}
        sticky
        summary={(pageData) => <SummeryRow pageData={pageData} sumInfo={[{mapIndex:10, mapKeys:['collectionPrice', 'payPrice'], sum:0}]} total={columns.length} title={'总金额'} />}
      />
      <div style={{textAlign:'right'}}>
        <p></p>
        <ConfigProvider locale={locale}>
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.current}
            total={pagination.total}
            showSizeChanger
            showQuickJumper
            showTotal={total => `共 ${total} 条`}
            onChange={(pageNumbe)=>{
              pagination.current = pageNumbe
              uniAxList({...uniRowData,params:{...searchParams, current:pageNumbe, size:pagination.pageSize}})
            }}
            onShowSizeChange={(current, pageSize)=>{
              pagination.pageSize = pageSize
              uniAxList({...uniRowData,params:{...searchParams, current:pagination.current, size:pageSize}})
            }}
          />
        </ConfigProvider>
      </div>
      <Modal
        visible={cancelModal}
        title="确定进行作废操作吗？"
        destroyOnClose
        onCancel={()=>setCancelModal(()=>false)}
        onOk={() => uniCancelConfirm({selectedRows,cancelApi:cancelUserBill,cancelRemark,axData:uniRowData,setCancelModal,setModalLoading})}
        footer={[<Button key="back" onClick={()=>setCancelModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => uniCancelConfirm({selectedRows,cancelApi:cancelUserBill,cancelRemark,axData:uniRowData,setCancelModal,setModalLoading})}>确定</Button>]}
      >
        <TextArea style={{ height: 80, resize:'none' }} placeholder="请输入作废说明" onChange={(e) => setCancelRemark(() => e.target.value)} />
      </Modal>
      <Modal visible={staticModal} width={600} title="数据统计" destroyOnClose onCancel={()=>setStaticModal(()=>false)} footer={[<Button key="back" onClick={()=>setStaticModal(()=>false)}>取消</Button>]}>
        <Table
          rowKey={'costTypeName'}
          scroll={{y:'58.2vh'}}
          columns={staticColumns}
          dataSource={staticTableData}
          pagination={false}
          loading={staticLoading}
        />
      </Modal>
    </>
  )
}
