import { Button, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, message, Modal, Pagination, Select, Table, TablePaginationConfig, Typography } from 'antd'
import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { adjustPayLog, companyBillBusiness, getBusinessList, getCompanyAccoutList, getCompanyList, getCostTypeTreeV2, getPaylogList, getPaylogTotal, updatePaylog } from '../../utils/api'
import { uniAddRowSubmit, uniApiStatic, uniAxList, uniAxSearchList, uniChgRowSubmit, uniConfirm, uniCurrentTime, uniDeepArray, uniExportExcel, uniFilterItem, uniFormProcess, uniModifyAxList, uniSelectRows, uniStaticLast, uniTableChange, uniValueToName } from '../../utils/uniFucs'
import { actionTypeList, cancelStatus, cancelStatusNums, companyBillType, csDateRages, isCheckList } from '../../utils/constant';
import tablecss from '../../css/custable.module.less'
import { AxDataform, ColumnItem, FormItemObj } from '../../utils/interfaces';
import TopSearch from '../../components/TopSearch';
import SuperTable from '../../components/SuperTable';
import FormDialog from '../../components/FormDialog';

export default function Dailyflow() {
const pathname = "/indexpage/dailyflow"
const searchRef = createRef<FormInstance>()
const initSearchParams = {page:1,filter_EQL_cancel_status:0}
const initStatus = [{id:0,name:'正常'},{id:1,name:'作废'}]
const modalRef = createRef<FormInstance>()
/* hooks */
let location = useLocation()
const navigate = useNavigate()
const formModalRef = createRef<FormInstance>()
const [addModal, setAddModal] = useState(false)
const [chgModal, setChgModal] = useState(false)
const [logModal, setLogModal] = useState(false)
const [confirmModal, setConfirmModal] = useState(false)
const [stockModal, setStockModal] = useState(false)
const [staticModal, setStaticModal] = useState(false)
const [cancelModal, setCancelModal] = useState(false)
const [bachCheckModal, setBachCheckModal] = useState(false)
const [companyList, setCompanyList] = useState([])
const [logList, setLogList] = useState([])
const [rowList, setRowList] = useState([])
const [backRowList, setBackRowList] = useState([])
const [modalItemVisible, setModalItemVisible] = useState({userType:1, stock:0})
const [showCashAccount, setShowCashAccount] = useState(true)
const [selectedRowKeys, setSelectedRowKeys] = useState([])
const [selectedRows, setSelectedRows] = useState([])
const [currentRow, setCurrentRow] = useState({} as any)
let [searchParams, setSearchParams] = useState({...initSearchParams} as any)
// const [searchParams, setSearchParams] = useState({page:1,order:'desc,account_id---desc,pay_time---desc,id',filter_EQL_cancel_status:0} as any)
const [tempSearchParams, setTempSearchParams] = useState({page:1,order:'desc,account_id---desc,pay_time---desc,id',filter_EQL_cancel_status:0} as any)
const [staffList, setStaffList] = useState([])
const [feeTypeList, setFeeTypeList] = useState([])
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
const [accountList, setAccountList] = useState([])
const [customAddKeys, setCustomAddKeys] = useState({} as any)
const [unCashRows, setUnCashRows] = useState([] as any)
const [unStockRows, setUnStockRows] = useState([] as any)
const [isUnderLinePay, setIsUnderLinePay] = useState(true)
const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
const [cancelRemark, setCancelRemark] = useState('')
const [staticTableData, setStaticTableData] = useState([] as any)
const [positionList, setPositionList] = useState([])
const [custList, setCustList] = useState([])
const [confirmItemVisible, setConfirmItemVisible] = useState({paytype: 2})
const [confirmCompanyList, setConfirmCompanyList] = useState([])
const [confirmModalInitial, setConfirmModalInitial] = useState({})
const [currentConfirmRow, setCurrentConfirmRow] = useState({} as any)
const [custCompanyList, setCustCompanyList] = useState([])
const [addTableData, setAddTableData] = useState([])
const [modalCpBusinessList, setModalCpBusinessList] = useState([])
const [modalFeeTypeList, setModalFeeTypeList] = useState([])
const [importModal, setImportModal] = useState(false)
const [accountantSubjectList, setAccountantSubjectList] = useState([])
const [businessList, setBusinessList] = useState([])
const [currentSubjectId, setCurrentSubjectId] = useState({id:1})
const [menuList, setMenuList] = useState([])
const [initTreeIds, setInitTreeIds] = useState([])
const [selectTreeIds, setSelectTreeIds] = useState([])
const [materialList, setMaterialList] = useState([])
const [customModalInitial, setCustomModalInitial] = useState({} as any)
const [staticRows, setStaticRows] = useState([] as any)
const [loadFlag, setLoadFlag] = useState(false)
const [modalLoading, setModalLoading] = useState(false)
const [exportLoading, setExportLoading] = useState(false)
const [staticLoading,setStaticLoading] = useState(false)
const [adjustModal, setAdjustModal] = useState(false)
const [searchYmRange,setSearchYmRange] = useState([] as any)
let [selectBzList, setSelectBzList] = useState([])
const [modalFlag, setModalFlag] = useState('')
const [showModal, setShowModal] = useState(false)
let [formInitials, setFormInitials] = useState({} as any)
let [modalaccountList,setModalAccountList] = useState([])

/* funcs */
//路由刷新请求
const routerFlash = () => {
  // uniAxList(dataRow())
  // uniAxList(uniCaData)
  uniAxList(uniBusinessData)
  uniAxList(uniCompanyData)
}
const searchConfirm = async (formValus:any) => {
  pagination.current = 1
  setPagination({...pagination,current:1});
  setSelectedRows([])
  setSelectedRowKeys([])
  searchParams = await uniFormProcess({...initSearchParams,...formValus},searchList)
  setSearchParams(searchParams)
  uniAxList(dataRow())
}
//批量对账
const onCheckPay = () => {
  if (!selectedRows.length) message.warning('请选择至少一条记录')
  else {
    let tempRows:any = []
    selectedRows.forEach((item:any) => {
      if (item.isCheck === 0) tempRows.push(item)
    })
    if (tempRows.length > 0) uniChgRowSubmit({currentRows:tempRows, axData:dataRow(),updateApi:updatePaylog,setModalLoading, customKeys: {isCheck:1}})({})
    else message.warning('所需记录已对账，无需重复对账')
  }
  setBachCheckModal(()=>false)
}
//modal点击确定
const modalConfirm = async (formValues:any) => {
  let tempparms = await uniFormProcess(formValues,adjustItems)
  uniAxList(dataAdjustPayLog(tempparms))
}

  /* elements */
  //搜索行
const searchList:FormItemObj[] = [
{label: '所属公司', prop:'filter_EQS_company_id',type:'select',dataList:companyList,required:true,clearable:false,change:(value:any)=>{
  uniAxList(dataAccountList(value))
  searchRef.current?.setFieldsValue({'filter_BETWEENS_pay_time':null})
  let finditem:any = companyList.find((item:any)=>item.id===value)
  if (finditem.initialize===1) setSearchYmRange([finditem.startYm,finditem.endYm])
  else setSearchYmRange([])
}},
{label: '公司账户',prop: 'filter_EQS_account_id',type:'select',dataList:accountList,selectProps:{childrenKeys:['name','cardNo']}},
{label: '收付日期', prop:'filter_BETWEENS_pay_time',type:'dateRange',daterangeProps:{returnType:'string',disabledDate:(current)=>{
  if (searchYmRange.length<2) return true
  else return current<moment(searchYmRange[0]).startOf('month') || current>moment(searchYmRange[1]).endOf('month')
}}},
// {label: '收支类型', prop:'filter_EQL_bill_type',type:'select',dataList:companyBillType,change:(value:any)=>{
//   searchRef.current?.setFieldsValue({filter_EQL_business_id:null,filter_EQL_cost_type_id:null})
//   if (value && value!==3) uniAxList(dataBusinessList(value))
//   else setSelectBzList([])
// }},
{label: '所属业务', prop:'filter_EQL_business_id',type:'select',dataList:selectBzList},
{label: '对账状态', prop:'filter_EQL_is_check',type:'select',dataList:isCheckList},
{label: '作废状态', prop:'filter_EQL_cancel_status',type:'select',dataList:initStatus},
]
//列项
const columns:ColumnItem[] = [
  {title: '所属公司',key: 'companyName',},
  {title: '账户名称',key: 'accountName',},
  {title: '作废状态',key: 'cancelStatus',custval:({row,value}:any)=>uniValueToName({list:initStatus,value})},
  {title: '所属业务',key: 'businessName',},
  {title: '期初余额',key: 'beginMoney',type:'price'},
  {title: '金额',key: 'money',type:'price'},
  {title: '期末余额',key: 'endMoney',type:'price'},
  {title: '收/付日期',key: 'payTime',custval:({row,value}:any)=>value?value.split(' ')[0]:''},
  {title: '状态',key: 'isCheck',custval:({row,value}:any)=>uniValueToName({list:isCheckList,value})},
  {title: '操作人',key: 'createBy'},
  {title: '账单日期',key: 'billTime',custval:({row,value}:any)=>value?value.split(' ')[0]:''},
  {title: '创建时间',key: 'createDate'},
  {title: '操作',key:'operation',type: 'operation',width:90,opProps:{opList:[
    {name:'对账',disabled:({row}:any)=>row.cancelStatus === 1,action:({row}:any) =>{
      row.isCheck === 0 ? uniChgRowSubmit({currentRows:[row], axData:dataRow(),updateApi:updatePaylog, customKeys: {isCheck:1}})({}) : message.warning('已对账')
    }},
  ]}}
  // {
  //   title: '操作',
  //   dataIndex: 'operation',
  //   fixed: 'right',
  //   width: 120,
  //   render: (_: any, record: any) => {
  //     return (
  //       <span>
  //         { record.cancelStatus === 1
  //           ?<span style={{color:'#ccc'}}>对账</span> 
  //           :<Typography.Link
  //             onClick={() => record.isCheck === 0 ? uniChgRowSubmit({currentRows:[record], axData:dataRow(),updateApi:updatePaylog, customKeys: {isCheck:1}})({}) : message.warning('已对账')}
  //             style={{ marginRight: 8 }}>对账
  //           </Typography.Link>
  //         }
  //       </span>
  //     )
  //   }
  // }
]
//校正modal的item
const adjustItems:FormItemObj[] = [
{label: '公司',prop: 'companyId',type:'select',dataList:companyList,clearable:false,change:(value:any)=>{
  uniAxList(dataAccountList(value,true))
}},
{label: '公司账户',prop: 'accountId',type:'select',dataList:modalaccountList,selectProps:{childrenKeys:['name','cardNo']}}
]
  //统计列
  const staticColumns:any = [
    {
      title: '公司账户',
      dataIndex: 'accountName',
      key: 'accountName',
    },
    {
      title: '收入金额',
      dataIndex: 'inPrice',
      key: 'inPrice',
      render:(value:number) => isNaN(value) ? '0' : (value/100).toFixed(2)
    },
    {
      title: '支出金额',
      dataIndex: 'outPrice',
      key: 'outPrice',
      render:(value:number) => isNaN(value) ? '0' : (value/100).toFixed(2)
    },
  ]

  //导出当前页，需要的数据修饰
  const exportModify:any = {
    funcKey:(record:any) => {
      if (record.cancelStatus === 1) record.collectionMoney = '-'
      else if (record.cancelStatus === 0) {
        if (record.type === 1) {
          record.collectionMoney = record.money
          record.costMoney = ''
        } else {
          record.collectionMoney = ''
          record.costMoney = record.money
        }
      }
    },
    copyKey: [
      {newKey: 'beginMoney', checkKey: 'cancelStatus', checkInfo:[{value:0,copyKey:'beginMoney'},{value:1,copyKey:null}]},
      {newKey: 'endMoney', checkKey: 'cancelStatus', checkInfo:[{value:0,copyKey:'endMoney'},{value:1,copyKey:null}]}
    ],
    divideKey:[
      {newKey: 'collectionMoney', key:'collectionMoney', value: 100},
      {newKey: 'costnMoney', key:'costnMoney', value: 100},
      {newKey: 'endMoney', key:'endMoney', value: 100},
      {newKey: 'beginMoney', key:'beginMoney', value: 100}
    ],
    splitTime:['payTime','billTime'],
    checkStatus:[
      {newKey:'cancelStatus', checkKey: 'cancelStatus', sourceList:cancelStatusNums, sourceCheckKey:'value', sourceValueKey:'name'},
      {newKey:'isCheck', checkKey: 'isCheck', sourceList:isCheckList, sourceCheckKey:'value', sourceValueKey:'name'},
    ],
    notNullColumns: columns,
  }

/* 接口参数 */
const dataRow = ():any => ({
  params: {...searchParams,current:pagination.current,size:pagination.pageSize},
  axApi: getPaylogList,
  setList: setRowList,
  setTableLoading,
  setPagination
})
const dataAccountList = (companyId:any,ismodal?:boolean):any => ({
  params: {companyId},
  axApi: getCompanyAccoutList,
  setList: ismodal?setModalAccountList:setAccountList
})
const uniBusinessData:any = {
  axApi: getBusinessList,
  setList: setBusinessList
}
const uniCompanyData:any = {
  axApi: getCompanyList,
  setList: setCompanyList,
}
//用户流水校正
const dataAdjustPayLog = (params:any):AxDataform => ({
  params,
  axApi: adjustPayLog,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
const dataBusinessList:AxDataform = {
  // params:{type,orderBy:"sort", orderDir: "desc"},
  params:{orderBy:"sort", orderDir: "desc"},
  axApi: companyBillBusiness,
  setList: setSelectBzList,
}
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if(loadFlag && location.pathname===pathname) routerFlash()
  }, [location])

  /* mounted */
  React.useEffect(() => {
    // uniAxList(dataRow())
    // uniAxList(uniCaData)
    uniAxList(uniCompanyData)
    uniAxList(uniBusinessData)
    uniAxList(dataBusinessList)
    setLoadFlag(()=>true)
  }, [])

  return (
    <>
      <TopSearch
        ref={searchRef}
        searchList={searchList}
        searchConfirm={searchConfirm}
        initialValues={{filter_EQL_cancel_status:0}}
      />
      <p>
      <Button type="primary" onClick={() => selectedRows.length>0 ? setBachCheckModal(()=>true) : message.warning('请选择至少一条记录')}>批量对账</Button> &nbsp;
      <Button type="primary" onClick={() => uniApiStatic({
        axApi:getPaylogTotal,
        params:{...searchParams,page:null,order:null},
        setStaticModal,
        setStaticTableData,
        setStaticLoading
      })}>数据统计</Button> &nbsp;
      <Button type="primary" onClick={() => uniExportExcel({columns, rowList: uniDeepArray(rowList), modifyFunc:uniModifyAxList, modifyData:exportModify,title:'每日流水对账表', fileName: '每日流水对账表'+uniCurrentTime({})})}>导出当前页</Button> &nbsp;
      <Button type="primary" loading={exportLoading} onClick={() => uniExportExcel({columns,setExportLoading, axApi:getPaylogList, params:{...searchParams, page:null}, modifyFunc:uniModifyAxList, modifyData:{...dataRow().modifyData, ...exportModify},title:'每日流水对账表',fileName: '每日流水对账表'+uniCurrentTime({})})}>导出所有页码数据</Button> &nbsp;
      <Button type="primary" onClick={()=>{
        setModalFlag('adjust')
        setShowModal(true)
        // setAdjustModal(()=>true)
      }}>账户流水校正</Button> &nbsp;
      </p>
      <SuperTable
        columns={columns}
        rowList={rowList}
        tableLoading={tableLoading}
        pagination={pagination}
        dataRow={dataRow}
        rowSelection={{setSelectedRowKeys, setSelectedRows, selectedRowKeys}}
      />
      <FormDialog
        ref={modalRef}
        drawerSize="70%"
        title='流水校正'
        show={showModal}
        formItems={adjustItems}
        modalLoading={modalLoading}
        formInitials={formInitials}
        cancel={()=>{
          setShowModal(()=>false)
          setFormInitials({})
        }}
        confirm={modalConfirm}
      />
      <Modal
        visible={bachCheckModal}
        title="提示"
        destroyOnClose
        onCancel={()=>setBachCheckModal(()=>false)}
        footer={[<Button key="back" onClick={()=>setBachCheckModal(()=>false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => onCheckPay()}>确定</Button>]}
      >
        <h4>确定对账所选记录吗?</h4>
      </Modal>
      <Modal width={600} visible={staticModal} title="统计" destroyOnClose onCancel={()=>setStaticModal(()=>false)} footer={[<Button key="back" onClick={()=>setStaticModal(()=>false)}>取消</Button>]}>
        <Table
          rowKey={'accountId'}
          columns={staticColumns}
          dataSource={staticTableData}
          pagination={false}
          scroll={{y:'58.2vh'}}
          loading={staticLoading}
        />
      </Modal>
      {/* <Modal
        width={600}
        maskClosable={false} title={'账户流水校正'}
        visible={adjustModal}
        onOk={() => formModalRef.current?.submit()}
        onCancel={()=>setAdjustModal(false)}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>setAdjustModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}
      >
        <Form
          preserve={false}
          ref={formModalRef}
          name="form"
          labelCol={{ span: 6 }}
          initialValues={{}}
          wrapperCol={{ span: 16 }}
          onFinish={(formValus) => {
            uniAddRowSubmit({axGetData:dataRow(),axAddApi:adjustPayLog, setAddModal:setAdjustModal,setModalLoading})(formValus)
          }}
          autoComplete="off"
        >
          {adjustItems.map((item:any) => <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
        </Form>
      </Modal> */}
    </>
  )
}
