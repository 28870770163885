import React, { createRef, useState } from 'react'
import { Button, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, message, Modal, Pagination, Radio, Select, Table, TablePaginationConfig, TreeSelect, Typography } from 'antd'
import moment from 'moment'
import 'moment/locale/zh-cn'
import locale from 'antd/es/locale/zh_CN'

import { NameValue, UserBillItem } from '../../../utils/beans'
import { cancelUserBill, createOtherCost, getCompanyAccoutList, getCompanyList, getCostTypeList, getCostTypeTree, getSiteInfo, getStaffList, getUserBillList, otherConfirm, updateUserBill } from '../../../utils/api'
import { uniAddRowSubmit, uniAxList, uniAxSearchList, uniCancel, uniCancelConfirm, uniChgRowSubmit, uniConfirm, uniCurrentTime, uniDatePikcerChange, uniDeepArray, uniExportExcel, uniFilterItem, uniModifyAxList, uniModifyRow, uniQuitModal, uniSelectRows, uniStaticLast, uniTableChange, uniToProof } from '../../../utils/uniFucs'
import FormModal from '../../../components/FormModal'
import { allCashStatus, cancelStatus, cashStatus, collectionStatus, csDateRages, incomeTypes, onlineAccounts, payStatus, payWay, proofStatusList, shouldColOrPay, staticAllColumns } from '../../../utils/constant'
import TextArea from 'antd/lib/input/TextArea'
import SummeryRow from '../../../components/SummeryRow'
import UniOperationColumn from '../../../components/UniOperationColumn'
import { useLocation, useNavigate } from 'react-router-dom'
import tablecss from '../../../css/custable.module.less'

export default function OtherFee() {
  const pathname = "/indexpage/otherfee"
  /* hooks */
  let location = useLocation()
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const treeRef:any = createRef()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [staticModal, setStaticModal] =useState(false)
  const [companyList, setCompanyList] = useState([])
  const [rowList, setRowList] = useState([])
  const [backRowList, setBackRowList] = useState([])
  const [itemVisible, setItemVisible] = useState({cashStatus:'all'})
  const [modalItemVisible, setModalItemVisible] = useState({time:true} as any)
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [currentRow, setCurrentRow] = useState({} as any)
  const [searchParams, setSearchParams] = useState({page:1,filter_EQL_business_id:78,orderBy:"create_date", orderDir: "desc",filter_EQB_is_cancel:false} as any)
  const [tempSearchParams, setTempSearchParams] = useState({page:1,filter_EQL_business_id:78,orderBy:"create_date", orderDir: "desc",filter_EQB_is_cancel:false} as any)
  const [staffList, setStaffList] = useState([])
  const [feeTypeList, setFeeTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
  const [staffAccounts, setStaffAccounts] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [unCashRows, setUnCashRows] = useState([] as any)
  const [isUnderLinePay, setIsUnderLinePay] = useState(true)
  const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [cancelModal, setCancelModal] = useState(false)
  const [cancelRemark, setCancelRemark] = useState('')
  const [cashStatusValue, setCashStatusValue] = useState({value:null} as any)
  const [collectCostTypeList, setCollectCostTypeList] = useState([])
  const [payCostTypeList, setPayCostTypeList] = useState([])
  const [outfeeFlag, setOutfeeFlag] = useState({value:-1})
  const [modalfeeFlag, setModalfeeFlag] = useState({value:-1})
  const [loadFlag, setLoadFlag] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [siteInfo, setSiteInfo] = useState({} as any)
  const [exportLoading, setExportLoading] = useState(false)
  const [uncollectionRows, setUncollectionRows] = useState([])
  const [unpayRows, setUnpayRows] = useState([])
  const [staticLoading,setStaticLoading] = useState(false)

  /* funcs */
  //点击确认收付款
  const onConfirm = () => {
    let tempUncollection:any = []
    let tempUnpay:any = []
    selectedRows.forEach((rowItem:any)=>{
      if ((rowItem.incomeType === 1 || rowItem.incomeType === 3) && rowItem.isCollection === 0) tempUncollection.push(rowItem)
      else if ((rowItem.incomeType === 2 || rowItem.incomeType === 4) && rowItem.isPay === 0) tempUnpay.push(rowItem)
    })
    if (tempUncollection.length === 0 && tempUnpay.length === 0) message.info("没有可以收付款的记录!")
    else {
      if (tempUncollection.length === 0) {
        modalItemVisible.unCollectionCounts = false
        modalItemVisible.unCollectionMounts = false
        modalItemVisible.unPayCounts = true
        modalItemVisible.unPayMounts = true
      } else if (tempUnpay.length === 0) {
        modalItemVisible.unCollectionCounts = true
        modalItemVisible.unCollectionMounts = true
        modalItemVisible.unPayCounts = false
        modalItemVisible.unPayMounts = false
      } else {
        modalItemVisible.unCollectionCounts = true
        modalItemVisible.unCollectionMounts = true
        modalItemVisible.unPayCounts = true
        modalItemVisible.unPayMounts = true
      }
      setUncollectionRows(()=>tempUncollection)
      setUnpayRows(()=>tempUnpay)
      setConfirmModal(()=>true)
    }
  }
  //路由刷新请求
  const routerFlash = () => {
    uniAxList(uniRowData)
    uniAxList(uniCompanyData)
    uniAxList(uniStaffData)
    uniAxList(uniSaData)
    uniAxList(uniRtData)
    uniAxList(uniCollectCostData)
    uniAxList(uniPayCostData)
    uniAxList(uniSiteData)
  }
  /* elements */
  //列项
  const columns:any = [
    {
      title: '工号',
      dataIndex: 'userJobNum',
      key: 'userJobNum',
      fixed: 'left'
    },
    {
      title: '姓名',
      dataIndex: 'userName',
      key: 'userName',
      fixed: 'left'
    },
    {
      title: '作废状态',
      dataIndex: 'isCancel',
      key: 'isCancel',
      render: (value:any) => value ? '作废' : '正常'
    },
    {
      title: '所属公司',
      dataIndex: 'companyName',
      key: 'companyName'
    },
    {
      title: '收付类型',
      dataIndex: 'incomeType',
      key: 'incomeType',
      render: (incomeType:number) => {
        let tempItem:any = uniFilterItem(incomeTypes, 'value', incomeType)
        return tempItem ? tempItem.name : incomeType
      }
    },
    {
      title: '费用类型',
      dataIndex: 'costTypeName',
      key: 'costTypeName'
    },
    {
      title: '运单号',
      dataIndex: 'trackNo',
      key: 'trackNo'
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark'
    },
    {
      title: '金额',
      dataIndex: 'collectionPrice',
      key: 'collectionPrice',
      render: (collectionPrice:number, record:any) => record.incomeType === 1 || record.incomeType === 3 ? collectionPrice.toFixed(2) : (record.payPrice).toFixed(2)
    },
    {
      title: '凭证状态',
      dataIndex: 'proofStatus',
      key: 'proofStatus',
      render: (value:any) => {
        let tempItem:any = uniFilterItem(proofStatusList, 'value', value)
        return tempItem ? tempItem.name : value
      }
    },
    {
      title: '凭证号',
      dataIndex: 'accountProof',
      key: 'accountProof',
      render: (value:any,row:any) => {
        return value.map((item:any) => <span key={item.accountProofId}><Typography.Link onClick={()=>uniToProof(row,item.accountProofId,navigate)}>{item.accountProofNo}</Typography.Link> </span>)
      }
    },
    {
      title: '账单日期',
      dataIndex: 'billTime',
      key: 'billTime',
      render: (value:string) => value ? value.split(' ')[0] : ''
    },
    {
      title: '收/付款状态',
      dataIndex: 'isCollection',
      key: 'isCollection',
      render: (isCollection:number, record:any) =>  record.incomeType === 1 || record.incomeType === 3
        ? isCollection === 0 ? '未收款' : '已收款'
        : record.isPay=== 0 ? '未付款' : '已付款'
    },
    {
      title: '收/付款方式',
      dataIndex: 'collectionWay',
      key: 'collectionWay',
      render: (collectionWay:string, record:any) => record.incomeType === 1 || record.incomeType === 3 
        ? collectionWay === '2' ? '线下' : '线上'
        : record.payWay === '2' ? '线下' : '线上'
    },
    {
      title: '收/付款账户',
      dataIndex: 'collectionAccount',
      key: 'collectionAccount',
      render: (collectionAccount:string, record:any) => record.incomeType === 1 || record.incomeType === 3 ? collectionAccount : record.payAccount
    },
    {
      title: '收/付款日期',
      dataIndex: 'collectionTime',
      key: 'collectionTime',
      render: (collectionTime:string, record:any) => record.incomeType === 1 || record.incomeType === 3
        ? collectionTime ? collectionTime.split(' ')[0] : ''
        : record.payTime ? record.payTime.split(' ')[0] : ''
    },
    {
      title: '操作人',
      dataIndex: 'createBy',
      key: 'createBy'
    },
    {
      title: '创建时间',
      dataIndex: 'createDate',
      key: 'createDate'
    },
    {
      title: '操作',
      dataIndex: 'operation',
      fixed: 'right',
      width: 120,
      render: (_: any, record: any) => {
        return (
          (record.incomeType === 1 && record.isCollection === 1) || (record.incomeType === 2 && record.isPay=== 1)
          ? <span style={{color:'#ccc'}}>修改</span>
          :<Typography.Link onClick={() => {
            if (record.incomeType === 1) modalfeeFlag.value = 1
            else if (record.incomeType === 2) modalfeeFlag.value = 2
            record.price = record.incomeType === 1 || record.incomeType === 3 ? record.collectionPrice : record.payPrice
            record.payMethod = record.incomeType
            uniModifyRow({record, setCurrentRow, setChgModal, setCustomAddKeys})
          }} style={{ marginRight: 8 }}>修改</Typography.Link>
        )
      }
    }
  ]
  //添加和修改中的model项
  const modalItems = [
    {
      label: '所属公司',
      name: 'companyId',
      rules: [{ required: true, message: '所属公司不能为空' }],
      element: <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="所属公司"
                onChange={(value) => {
                  const tempItem:any = companyList.find((item:any) => value === item.id)
                  setCustomAddKeys(()=>({...customAddKeys, companyName: tempItem?tempItem.name : null}))
                }}
              >
                {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
              </Select>
    },
    {
      label: '人员名称',
      name: 'userId',
      rules: [{ required: true, message: '人员名称不能为空' }],
      element: <Select
                showSearch
                optionFilterProp='children'
                allowClear
                style={{ width: '100%' }}
                placeholder="请选择人员名称"
                onChange={(value) => {
                  const tempItem:any = staffList.find((item:any) => value === item.id)
                  setCustomAddKeys(()=>({...customAddKeys, userJobNum: tempItem?tempItem.jobNum : null, userName: tempItem?tempItem.name : null}))
                }}
              >
                {staffList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
              </Select>
    },
    {
      label: '收付类型',
      name: 'payMethod',
      rules: [{ required: true, message: '收付类型不能为空' }],
      element: <Radio.Group onChange={(e)=>{
        if (e.target.value === 1) setModalfeeFlag(()=>({value:1}))
        else if (e.target.value === 2) setModalfeeFlag(()=>({value:2}))
        else setModalfeeFlag(()=>({value:-1}))
      }}>
        {shouldColOrPay.map((item:NameValue) => <Radio key={item.value} value={item.value}>{item.name}</Radio>)}
      </Radio.Group>
    },
    {
      label: '费用类型',
      name: 'costTypeId',
      rules: [{ required: true, message: '费用类型不能为空' }],
      element:<TreeSelect
                showSearch
                treeNodeFilterProp='title'
                fieldNames={{value: 'id'} as any}
                style={{ width: '100%' }}
                treeData={modalfeeFlag.value === 1 ? collectCostTypeList : modalfeeFlag.value === 2 ? payCostTypeList : []}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="先选择收付类型"
                allowClear
                treeDefaultExpandAll
                onChange={(value)=>{
                  const tempItem:any = uniFilterItem(feeTypeList, 'id', value)
                  setCustomAddKeys(()=>({...customAddKeys, costTypeName: tempItem?tempItem.name : null}))
                }}
              />
    },
    {
      label: '金额',
      name: 'price',
      rules: [{ required: true, message: '金额不能为空' }],
      element: <InputNumber precision={2} style={{width:'100%'}} placeholder="请输入金额" onChange={(value:any)=>setCustomAddKeys(()=>({...customAddKeys,price:(value*100).toFixed()}))}/>
    },
    {
      label: '账单时间',
      name: 'actionTime',
      rules: [{ required: true, message: '申请时间不能为空' }],
      element:<ConfigProvider locale={locale}>
                <DatePicker disabledDate={(current)=>{return current && current > moment().endOf('day')}} style={{width:'100%'}} defaultValue={(chgModal && currentRow.billTime) ? moment((currentRow.billTime as string).split(' ')[0], 'YYYY-MM-DD') : null as any} onChange={(date, dateString)=>uniDatePikcerChange({formRef:formModalRef,dateString:dateString,keyWord:'actionTime'})}/>
              </ConfigProvider>      
    },
    {
      label: '运单号',
      name: 'trackNo',
      rules: [{ required: false }],
      element: <Input placeholder="请输入运单号"/>
    },
    {
      label: '备注',
      name: 'remark',
      rules: [{ required: false }],
      element: <TextArea style={{ height: 80, resize:'none' }} />
    },
  ]
  //确认收款modal中的item
  const confirmItems = [
    {
      label: '收/付款方式',
      name: 'way',
      rules: [{ required: false }],
      element: <Radio.Group onChange={(event) => {
        if (event.target.value === '1') modalItemVisible.time = false
        else modalItemVisible.time = true
        setIsUnderLinePay(()=>!isUnderLinePay)
      }}>
        {payWay.map((item:NameValue) => <Radio key={item.value} value={item.value}>{item.name}</Radio>)}
      </Radio.Group>
    },
    {
      label: '收/付款账户',
      name: 'accountId',
      rules: [{ required: false, message: '收/付款账户不能为空' }],
      element: isUnderLinePay
              ?<Select
                allowClear
                style={{ width: '100%' }}
                placeholder="请选择收/付款账户"
                onChange={(value) => {
                  if (isUnderLinePay) {
                    const tempItem:any = staffAccounts.find((item:any) => value === item.id)
                    setCustomConfirmKeys(()=>({...customConfirmKeys, account: tempItem?tempItem.name : null}))
                  }
                }}
              >
                { staffAccounts.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>) }
              </Select>
              : siteInfo.accountSubjectId ? <Input readOnly defaultValue='默认账户'/> : <span>没有线上账户，请先设置站点会计科目</span>
              
    },
    {
      label: '未收款条数',
      name: 'unCollectionCounts',
      rules: [{ required: false }],
      element: <InputNumber style={{width:'100%'}} precision={0} placeholder="未收款条数" readOnly/>
    },
    {
      label: '未收款金额',
      name: 'unCollectionMounts',
      rules: [{ required: false }],
      element: <InputNumber style={{width:'100%'}} precision={2} placeholder="未收款金额" readOnly/>
    },
    {
      label: '未付款条数',
      name: 'unPayCounts',
      rules: [{ required: false }],
      element: <InputNumber style={{width:'100%'}} precision={0} placeholder="未付款条数" readOnly/>
    },
    {
      label: '未付款总金额',
      name: 'unPayMounts',
      rules: [{ required: false }],
      element: <InputNumber style={{width:'100%'}} precision={2} placeholder="未付款总金额" readOnly/>
    },
    {
      label: '确认收/付款时间',
      name: 'time',
      rules: [{ required: true, message: '时间不能为空' }],
      element:<ConfigProvider locale={locale}>
                <DatePicker disabledDate={(current)=>{return current && current > moment().endOf('day')}} onChange={(date, dateString)=>uniDatePikcerChange({formRef:formModalRef,dateString:dateString,keyWord:'time'})}/>
              </ConfigProvider>      
    },
  ]
  //统计table的列
  const staticColumns:any = [
    {
      title: '收付类型',
      dataIndex: 'rowName',
      key: 'rowName',
    },
    {
      title: '费用类型',
      dataIndex: 'costTypeName',
      key: 'costTypeName',
    },
    {
      title: '记录条数',
      dataIndex: 'count',
      key: 'count',
      addValue: (record:any,children:any) => {
        let price = (record.incomeType === 1 || record.incomeType === 3) ? record.collectionPrice : record.payPrice
        if (children) {
          let childItem = children.find((item:any)=>item.costTypeId === record.costTypeId)
          if (childItem) {
            childItem.count += 1
            childItem.rowTotal += price
          } else children.push({...record,rowName:'',count:1,rowTotal:price})
        }
        return 1
      }
    },
    {
      title: '小计',
      dataIndex: 'rowTotal',
      key: 'rowTotal',
      render: (value:any) => (value/100).toFixed(2),
      addValue: (record:any) => (record.incomeType === 1 || record.incomeType === 3) ? record.collectionPrice : record.payPrice,
      totalAdd: (record:any) => (record.incomeType === 1 || record.incomeType === 3) ? record.collectionPrice : -record.payPrice
    }
  ]
  //统计table中的行
  const staticRows:any = [
    {rowName: '应收', checkKey: 'incomeType', checkValue: 1, costTypeName:'应收费用', count:0, rowTotal:0, children:[]},
    {rowName: '应付', checkKey: 'incomeType', checkValue: 2, costTypeName:'应付费用', count:0, rowTotal:0, children:[]},
    {rowName: '实收', checkKey: 'incomeType', checkValue: 3, costTypeName:'实收费用', count:0, rowTotal:0, children:[]},
    {rowName: '实付', checkKey: 'incomeType', checkValue: 4, costTypeName:'实付费用', count:0, rowTotal:0, children:[]},
    {rowName: '合计', checkKey: 'staticTotal', costTypeName:'应收-应付+实收-实付', count:0, rowTotal:0}
  ]

  //导出当前页，需要的数据修饰
  const exportModify:any = {
    splitTime: ['collectionTime','actionTime'],
    copyKey: [
      {newKey: 'collectionPrice', checkKey: 'incomeType', checkInfo:[{value:1,copyKey:'collectionPrice'},{value:2,copyKey:'payPrice'},{value:3,copyKey:'collectionPrice'},{value:4,copyKey:'payPrice'}]},
      {newKey: 'isCollection', checkKey: 'incomeType', checkInfo:[{value:1,copyKey:'isCollection'},{value:2,copyKey:'isPay'},{value:3,copyKey:'isCollection'},{value:4,copyKey:'isPay'}]},
      {newKey: 'collectionAccount', checkKey: 'incomeType', checkInfo:[{value:1,copyKey:'collectionAccount'},{value:2,copyKey:'payAccount'},{value:3,copyKey:'collectionAccount'},{value:4,copyKey:'payAccount'}]},
      {newKey: 'collectionTime', checkKey: 'incomeType', checkInfo:[{value:1,copyKey:'collectionTime'},{value:2,copyKey:'payTime'},{value:3,copyKey:'collectionTime'},{value:4,copyKey:'payTime'}]}
    ],
    checkStatus:[
      {newKey:'incomeType', checkKey: 'incomeType', sourceList:incomeTypes, sourceCheckKey:'value', sourceValueKey:'name'},
      {newKey:'isCollection', checkKey: 'isCollection', sourceList:collectionStatus, sourceCheckKey:'value', sourceValueKey:'name'},
      {newKey:'isPay', checkKey: 'isPay', sourceList:payStatus, sourceCheckKey:'value', sourceValueKey:'name'},
      {newKey:'collectionWay', checkKey: 'collectionWay', sourceList:payWay, sourceCheckKey:'value', sourceValueKey:'name'},
      {newKey:'isCancel', checkKey: 'isCancel', sourceList:cancelStatus, sourceCheckKey:'value', sourceValueKey:'name'},
      {newKey:'proofStatus', checkKey: 'proofStatus', sourceList:proofStatusList, sourceCheckKey:'value', sourceValueKey:'name'},
    ],
    notNullColumns: columns,
  }
  /* 接口参数 */
  const uniRowData:any = {
    params: {...searchParams, current:pagination.current, size:pagination.pageSize},
    axApi: getUserBillList,
    modifyFunc: uniModifyAxList,
    modifyData: {
      divideKey:[{newKey: 'payPrice', key:'payPrice', value: 100}, {newKey: 'collectionPrice', key: 'collectionPrice', value: 100}],
    },
    setList: setRowList,
    setTableLoading,
    pagination
  }
  const uniCompanyData:any = {
    params:{filter_EQL_type:1},
    axApi: getCompanyList,
    setList: setCompanyList,
  }
  const uniStaffData:any = {
    params: {size:999},
    axApi: getStaffList,
    setList: setStaffList
  }
  //费用类型
  const uniRtData:any = {
    params: {filter_EQL_business_id: 78, size:999},
    axApi: getCostTypeList,
    modifyFunc: uniModifyAxList,
    modifyData: {titleList:['title', 'name']},
    setList: setFeeTypeList
  }
  //应收费用类型
  const uniCollectCostData:any = {
    params: {filter_EQL_business_id: 78, size:999, filter_EQL_income_type:1},
    axApi: getCostTypeList,
    modifyFunc: uniModifyAxList,
    modifyData: {titleList:['title', 'name']},
    setList: setCollectCostTypeList
  }
  //应付费用类型
  const uniPayCostData:any = {
    params: {filter_EQL_business_id: 78, size:999, filter_EQL_income_type:2},
    axApi: getCostTypeList,
    modifyFunc: uniModifyAxList,
    modifyData: {titleList:['title', 'name']},
    setList: setPayCostTypeList
  }
  //个人账户
  const uniSaData:any = {
    axApi: getCompanyAccoutList,
    setList: setStaffAccounts
  }
  const uniSiteData:any = {
    axApi: getSiteInfo,
    setList: setSiteInfo,
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    uniAxList(uniRowData)
    uniAxList(uniCompanyData)
    uniAxList(uniStaffData)
    uniAxList(uniSaData)
    uniAxList(uniRtData)
    uniAxList(uniCollectCostData)
    uniAxList(uniPayCostData)
    uniAxList(uniSiteData)
    setLoadFlag(()=>true)
  }, [])

  return (
    <>
      <p>
      <Select
        showSearch
        optionFilterProp='children'
        allowClear
        style={{ width: 200 }}
        placeholder="选择人员"
        onChange={(userId) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_user_id:userId || null}))}
      > 
        {staffList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="作废状态"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQB_is_cancel:(value ===null || value===undefined ? false : value)}))
        }
      > 
        {cancelStatus.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="选择公司"
        onChange={(companyId) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_company_id:companyId || null}))}
      > 
        {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="收付类型"
        onChange={(incomeType) => {
          cashStatusValue.value = null
          itemVisible.cashStatus = incomeType === 1 ? 'collection' : incomeType === 2 ? 'pay' : 'all'
          if (incomeType === 1) outfeeFlag.value = 1
          else if (incomeType === 2) outfeeFlag.value = 2
          setTempSearchParams(() => ({...tempSearchParams, filter_EQL_income_type:incomeType || null}))
        }}
      > 
        {shouldColOrPay.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        value={cashStatusValue.value}
        style={{ width: 200 }}
        placeholder="收/付款状态"
        onChange={(value) => {
          cashStatusValue.value = value
          let customKey:any = null
          if (itemVisible.cashStatus === 'all') {
            switch (value) {
              case 1: return customKey = {filter_EQL_is_collection: 0,filter_EQL_is_pay: null}
              case 2: return customKey = {filter_EQL_is_pay: 0,filter_EQL_is_collection: null}
              case 3: return customKey = {filter_EQL_is_collection: 1,filter_EQL_is_pay: null}
              case 4: return customKey = {filter_EQL_is_pay: 1,filter_EQL_is_collection: null}
              default: return customKey = {filter_EQL_is_pay: null,filter_EQL_is_collection: null}
            }
          } else if (itemVisible.cashStatus === 'collection') {
            customKey = value === 0 ? {filter_EQL_is_collection: 0,filter_EQL_is_pay: null} : value === 1 ? {filter_EQL_is_collection: 1,filter_EQL_is_pay: null} : {filter_EQL_is_pay: null,filter_EQL_is_collection: null}
          } else if (itemVisible.cashStatus === 'pay') {
            customKey = value === 0 ? {filter_EQL_is_pay: 0,filter_EQL_is_collection: null} : value === 1 ? {filter_EQL_is_pay: 1,filter_EQL_is_collection: null} : {filter_EQL_is_pay: null,filter_EQL_is_collection: null}
          }
          setTempSearchParams(() => ({...tempSearchParams, ...customKey}))
        }}
      > 
        { itemVisible.cashStatus === 'all'
          ? allCashStatus.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)
          : itemVisible.cashStatus === 'collection'
            ? collectionStatus.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)
            : itemVisible.cashStatus === 'pay' 
              ? payStatus.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)
              : []
        }
      </Select>&nbsp;
      <TreeSelect
        showSearch
        treeNodeFilterProp='title'
        fieldNames={{value: 'id'} as any}
        style={{ width: 200 }}
        treeData={outfeeFlag.value === 1 ? collectCostTypeList : outfeeFlag.value === 2 ? payCostTypeList : feeTypeList}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        placeholder="费用类型"
        allowClear
        treeDefaultExpandAll
        onChange={(costTypeId)=> setTempSearchParams(() => ({...tempSearchParams, filter_EQL_cost_type_id:costTypeId || null}))}
      />&nbsp;
      <Input
        allowClear
        style={{ width: 200 }}
        placeholder="运单号"
        onChange={(e) => setTempSearchParams(() => ({...tempSearchParams, filter_LIKES_track_no:e.target.value || null}))}
      ></Input>&nbsp;
      <Input
        allowClear
        style={{ width: 200 }}
        placeholder="输入备注"
        onChange={(e) => setTempSearchParams(() => ({...tempSearchParams, filter_LIKES_remark:e.target.value || null}))}
      ></Input>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          placeholder={['账单开始日期', '账单结束日期']}
          ranges={csDateRages}
          onChange={(dates, dateStrings) => setTempSearchParams(()=>({...tempSearchParams, filter_BETWEENS_bill_time: dateStrings[0] ? dateStrings[0]+","+dateStrings[1]+' 23:59:59' : null}))}
        />
      </ConfigProvider>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          placeholder={['收/付款开始日期', '收/付款结束日期']}
          ranges={csDateRages}
          onChange={(dates, dateStrings) => setTempSearchParams(()=>({...tempSearchParams, filter_BETWEENS_collection_time_OR_pay_time: dateStrings[0] ? dateStrings[0]+","+dateStrings[1]+' 23:59:59' : null}))}
        />
      </ConfigProvider>&nbsp;
      <Button onClick={() => {pagination.current=1;uniAxSearchList({setSearchParams, uniRowData, params: {...tempSearchParams,size:pagination.pageSize}})}} type='primary'>查询</Button>
      </p>
      <p>
        <Button type="primary" onClick={() => setAddModal(() => true)}>新增</Button> &nbsp;
        <Button type="primary" disabled={selectedRows.length===0} onClick={() => {
          modalItemVisible.time=true
          setIsUnderLinePay(()=>true)
          onConfirm()
        }}>批量收/付款</Button> &nbsp;
        <Button type="primary" onClick={() => uniStaticLast({
          axApi:getUserBillList,
          params:{...searchParams, page:null},
          staticColumns,
          staticRows,
          setStaticModal,
          setStaticTableData,
          setStaticLoading
        })}>统计所有页数据</Button> &nbsp;
        <Button type="primary" danger onClick={() => {setCancelRemark(()=>'');uniCancel({selectedRows,setCancelModal})}}>作废操作</Button> &nbsp;
        <Button type="primary" onClick={() => uniExportExcel({columns, rowList: uniDeepArray(rowList), modifyFunc:uniModifyAxList, modifyData:exportModify,title:'其他费用', fileName: '其他费用'+uniCurrentTime({})})}>导出当前页</Button> &nbsp;
        <Button type="primary" loading={exportLoading} onClick={() => uniExportExcel({columns,setExportLoading, axApi:getUserBillList, params:{...searchParams, page:null}, modifyFunc:uniModifyAxList, modifyData:{...uniRowData.modifyData, ...exportModify},title:'其他费用',fileName: '其他费用'+uniCurrentTime({})})}>导出所有页码数据</Button> &nbsp;
      </p>
      <Table
        size='small'
        className={tablecss.height280}
        rowKey={'id'}
        columns={columns}
        rowSelection={{ ...uniSelectRows(setSelectedRowKeys, setSelectedRows), checkStrictly:true }}
        dataSource={rowList}
        pagination={false}
        loading={tableLoading}
        scroll={{x:2500,y:'calc(100vh - 340px)'}}
        // onChange={(newPagination)=>{pagination.pageSize = newPagination.pageSize;pagination.current=newPagination.current ;uniAxList({...uniRowData,params: {...searchParams, current:pagination.current, size:pagination.pageSize}})}}
        sticky
        summary={(pageData) => <SummeryRow pageData={pageData} sumInfo={[{mapIndex:9, mapKeys:['collectionPrice', 'payPrice'], sum:0}]} total={columns.length} title={'总金额'} />}
      />
      <div style={{textAlign:'right'}}>
        <p></p>
        <ConfigProvider locale={locale}>
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.current}
            total={pagination.total}
            showSizeChanger
            showQuickJumper
            showTotal={total => `共 ${total} 条`}
            onChange={(pageNumbe)=>{
              pagination.current = pageNumbe
              uniAxList({...uniRowData,params:{...searchParams, current:pageNumbe, size:pagination.pageSize}})
            }}
            onShowSizeChange={(current, pageSize)=>{
              pagination.pageSize = pageSize
              uniAxList({...uniRowData,params:{...searchParams, current:pagination.current, size:pageSize}})
            }}
          />
        </ConfigProvider>
      </div>
      <Modal
        width={600}
        maskClosable={false} title={'新增'}
        visible={addModal}
        onOk={() => formModalRef.current?.submit()}
        onCancel={()=>{setModalfeeFlag(()=>({value:-1}));setAddModal(false)}}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>{setModalfeeFlag(()=>({value:-1}));setAddModal(false)}}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}
      >
        <Form
          preserve={false}
          ref={formModalRef}
          name="form"
          labelCol={{ span: 6 }}
          initialValues={{}}
          wrapperCol={{ span: 16 }}
          onFinish={(formValus) => {
            uniAddRowSubmit({axGetData:uniRowData, axAddApi:createOtherCost, setAddModal,setModalLoading, customAddKeys})(formValus)
          }}
          autoComplete="off"
        >
          {modalItems.map((item:any) => <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
        </Form>
      </Modal>
      <Modal
        width={600}
        maskClosable={false}
        title={'修改'}
        visible={chgModal}
        onOk={() => formModalRef.current?.submit()}
        onCancel={()=>setChgModal(false)}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>setChgModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}
      >
        <Form
          preserve={false}
          ref={formModalRef}
          name="form"
          labelCol={{ span: 7 }}
          initialValues={{...currentRow}}
          wrapperCol={{ span: 16 }}
          onFinish={(formValus) => {
            uniChgRowSubmit({currentRows:[currentRow],axData:uniRowData, updateApi:updateUserBill, setModal:setChgModal,setModalLoading, customKeys:customAddKeys})(formValus)
          }}
          autoComplete="off"
        >
          {modalItems.map((item:any) => <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
        </Form>
      </Modal>
      <Modal
        title='确认收/付款'
        width={600}
        visible={confirmModal}
        onOk={() => formModalRef.current?.submit()}
        onCancel={()=>setConfirmModal(()=>false)}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>setConfirmModal(false)}>取消</Button>, <Button disabled={!siteInfo.accountSubjectId && !isUnderLinePay} loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}
      >
        <Form
          ref={formModalRef}
          name="form"
          labelCol={{ span: 7 }}
          initialValues={{
            way: '2',
            unCollectionCounts:uncollectionRows.length>0 ? uncollectionRows.reduce((total:any, item:UserBillItem)=>total+1, 0) : 0,
            unCollectionMounts:uncollectionRows.length>0 ? uncollectionRows.reduce((total:any, item:UserBillItem)=>total+item.collectionPrice, 0) : 0,
            unPayCounts:unpayRows.length ? unpayRows.reduce((total:any, item:UserBillItem)=>total+1, 0) : 0,
            unPayMounts:unpayRows.length ? unpayRows.reduce((total:any, item:UserBillItem)=>total+item.payPrice, 0) : 0,
          }}
          wrapperCol={{ span: 16 }}
          onFinish={(formValus) => {
            let axRow = [...uncollectionRows,...unpayRows]
            if (axRow.length > 0 && axRow.find((item:any)=>moment(item.billTime) > moment(formValus.time))) message.error('有记录的账单日期晚于确认收付款日期，请检查!')
            else uniChgRowSubmit({currentRows:axRow, axData:uniRowData,updateApi:otherConfirm,setModal:setConfirmModal,setModalLoading,customKeys:customConfirmKeys,deleteKeys:['counts', 'mounts']})(formValus)
          }}
          autoComplete="off"
        >
          {confirmItems.map((item:any) => {
            return (modalItemVisible && item.name in modalItemVisible && !modalItemVisible[item.name])?null: <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules} preserve={false}>{item.element}</Form.Item>
          })}
        </Form>
      </Modal>
      <Modal width={600} visible={staticModal} title="数据统计" destroyOnClose onCancel={()=>setStaticModal(()=>false)} footer={[<Button key="back" onClick={()=>setStaticModal(()=>false)}>取消</Button>]}>
        <Table
            rowKey={'costTypeName'}
            columns={staticColumns}
            dataSource={staticTableData}
            pagination={false}
            loading={staticLoading}
          />
      </Modal>
      <Modal
        visible={cancelModal}
        title="确定进行作废操作吗？"
        destroyOnClose
        onCancel={()=>setCancelModal(()=>false)}
        onOk={() => uniCancelConfirm({selectedRows,cancelApi:cancelUserBill,cancelRemark,axData:uniRowData,setCancelModal,setModalLoading})}
        footer={[<Button key="back" onClick={()=>setCancelModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => uniCancelConfirm({selectedRows,cancelApi:cancelUserBill,cancelRemark,axData:uniRowData,setCancelModal,setModalLoading})}>确定</Button>]}
      >
        <TextArea style={{ height: 80, resize:'none' }} placeholder="请输入作废说明" onChange={(e) => setCancelRemark(() => e.target.value)} />
      </Modal>
    </>
  )
}
