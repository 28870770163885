import React, { createRef, useState } from 'react'
import { Button, ConfigProvider, DatePicker, FormInstance, Pagination, Select, Table, TablePaginationConfig, Tooltip, Typography } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { UserBillItem } from '../../../utils/beans'
import { getBusinessList, getCompanyList, getCostTypeTree, getPositionList, getStaffList, getUserBillList } from '../../../utils/api'
import { uniAxList, uniAxSearchList, uniFilterItem, uniModifyAxList, uniSelectRows, uniTableChange, uniToProof } from '../../../utils/uniFucs'
import SummeryRow from '../../../components/SummeryRow'
import { businessTypes, csDateRages, proofStatusList } from '../../../utils/constant'
import { useLocation, useNavigate } from 'react-router-dom';
import tablecss from '../../../css/custable.module.less'

export default function InvalidDetail() {
  const pathname = "/indexpage/invaliddetail"
  /* hooks */
  let location = useLocation()
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [staticModal, setStaticModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [rowList, setRowList] = useState([])
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({showRealName:true, showCardNo:true, showBeginBalance:true, showBeginTime:true})
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [currentRow, setCurrentRow] = useState({} as UserBillItem)
  const [searchParams, setSearchParams] = useState({page:1,filter_EQB_is_cancel:true} as any)
  const [tempSearchParams, setTempSearchParams] = useState({page:1,filter_EQB_is_cancel:true} as any)
  const [staffList, setStaffList] = useState([])
  const [feeTypeList, setFeeTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
  const [staffAccounts, setStaffAccounts] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [unCashRows, setUnCashRows] = useState([] as any)
  const [isUnderLinePay, setIsUnderLinePay] = useState(true)
  const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
  const [cancelRemark, setCancelRemark] = useState('')
  const [collectionStaticData, setCollectionStaticData] = useState([{counts:0, total:0}] as any)
  const [payStaticData, setPayStaticData] = useState([{counts:0, total:0}] as any)
  const [realCollectionData, setRealCollectionData] = useState([{counts:0, total:0}] as any)
  const [realPayData, setRealPayData] = useState([{counts:0, total:0}] as any)
  const [businessList, setBusinessList] = useState([])
  const [loadFlag, setLoadFlag] = useState(false)
  //路由刷新请求
  const routerFlash = () => {
    uniAxList(uniRowData)
    uniAxList(uniStaffData)
    uniAxList(uniBusinessData)
  }
  /* elements */
  //列项
  const columns:any = [
    {
      title: '工号',
      dataIndex: 'userJobNum',
      key: 'userJobNum',
      fixed: 'left'
    },
    {
      title: '姓名',
      dataIndex: 'userName',
      key: 'userName',
      fixed: 'left'
    },
    {
      title: '收付类型',
      dataIndex: 'incomeType',
      key: 'incomeType',
      render: (incomeType:number) => {
        switch (incomeType) {
          case 1: return '应收'
          case 2: return '实收'
          case 3: return '应付'
          case 4: return '实付'
          default:  return ''
        }
      }
    },
    {
      title: '金额',
      dataIndex: 'collectionPrice',
      key: 'collectionPrice',
      render: (collectionPrice:number, record:any) => {
        if (record.incomeType === 1 || record.incomeType === 3) return (collectionPrice / 100).toFixed(2)
        else return (record.payPrice / 100).toFixed(2)
      }
    },
    {
      title: '凭证状态',
      dataIndex: 'proofStatus',
      key: 'proofStatus',
      render: (value:any) => {
        let tempItem:any = uniFilterItem(proofStatusList, 'value', value)
        return tempItem ? tempItem.name : value
      }
    },
    {
      title: '凭证号',
      dataIndex: 'proofNo',
      key: 'proofNo',
      render: (value:string,record:any) => {
        let prooflist = value ? value.split(',') : []
        return prooflist.map((item:string) => <span><Typography.Link onClick={()=>uniToProof(record,item, navigate)}>{item}</Typography.Link> </span>)
      }
    },
    {
      title: '费用分类',
      dataIndex: 'businessName',
      key: 'businessName'
    },
    {
      title: '费用类型',
      dataIndex: 'costTypeName',
      key: 'costTypeName'
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      ellipsis: {showTitle: false},
      render: (value:string) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    
    {
      title: '收款方式',
      dataIndex: 'collectionWay',
      key: 'collectionWay',
      render: (collectionWay:string) => collectionWay === '2' ? '线下' : '线上'
    },
    {
      title: '作废日期',
      dataIndex: 'cancelDate',
      key: 'cancelDate',
      render: (value:string) => value ? value.split(' ')[0] : ''
    },
    {
      title: '账单日期',
      dataIndex: 'billTime',
      key: 'actionTime',
      render: (value:string) => value ? value.split(' ')[0] : ''
    },
    {
      title: '作废说明',
      dataIndex: 'cancelRemark',
      key: 'cancelRemark',
      ellipsis: {showTitle: false},
      render: (value:string) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: '操作人',
      dataIndex: 'createBy',
      key: 'createBy'
    }
  ]

  /* 接口参数 */
  const uniRowData:any = {
    params: {...searchParams, current:pagination.current, size:pagination.pageSize},
    axApi: getUserBillList,
    setList: setRowList,
    setTableLoading,
    pagination
  }
  const uniStaffData:any = {
    params: {size:999},
    axApi: getStaffList,
    setList: setStaffList
  }
  const uniBusinessData:any = {
    axApi: getBusinessList,
    setList: setBusinessList,
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    uniAxList(uniRowData)
    uniAxList(uniStaffData)
    uniAxList(uniBusinessData)
    setLoadFlag(()=>true)
  }, [])

  return (
    <>
      <p>
      <Select
        showSearch
        optionFilterProp='children'
        allowClear
        style={{ width: 200 }}
        placeholder="选择人员"
        onChange={(userId) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_user_id:userId || null}))}
      > 
        {staffList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        showSearch
        optionFilterProp='children'
        allowClear
        style={{ width: 200 }}
        placeholder="费用分类"
        onChange={(businessId) => setTempSearchParams(() => ({...tempSearchParams, filter_EQL_business_id:businessId || null}))}
      > 
        {businessList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          placeholder={['作废开始日期', '作废结束日期']}
          ranges={csDateRages}
          onChange={(dates, dateStrings) => setTempSearchParams(()=>({...tempSearchParams, filter_BETWEENS_cancel_date: dateStrings[0] ? dateStrings[0]+","+dateStrings[1]+' 23:59:59' : null}))}
        />
      </ConfigProvider>&nbsp;
      <Button onClick={() => {pagination.current=1;uniAxSearchList({setSearchParams, uniRowData, params: {...tempSearchParams,size:pagination.pageSize}})}} type='primary'>查询</Button>
      </p>
      <Table
        size='small'
        className={tablecss.height260}
        rowKey={'id'}
        columns={columns}
        // rowSelection={{ ...uniSelectRows(setSelectedRowKeys, setSelectedRows), checkStrictly:true }}
        dataSource={rowList}
        pagination={false}
        loading={tableLoading}
        scroll={{x:2500,y:'calc(100vh - 260px)'}}
        // onChange={(newPagination)=>{pagination.pageSize = newPagination.pageSize;pagination.current=newPagination.current ;uniAxList({...uniRowData,params: {...searchParams, current:pagination.current, size:pagination.pageSize}})}}
        sticky
        // summary={(pageData) => <SummeryRow pageData={pageData} sumInfo={[{mapIndex:8, mapKeys:['collectionPrice', 'payPrice'], sum:0}]} total={columns.length} title={'总金额'} />}
      />
      <div style={{textAlign:'right'}}>
        <p></p>
        <ConfigProvider locale={locale}>
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.current}
            total={pagination.total}
            showSizeChanger
            showQuickJumper
            showTotal={total => `共 ${total} 条`}
            onChange={(pageNumbe)=>{
              pagination.current = pageNumbe
              uniAxList({...uniRowData,params:{...searchParams, current:pageNumbe, size:pagination.pageSize}})
            }}
            onShowSizeChange={(current, pageSize)=>{
              pagination.pageSize = pageSize
              uniAxList({...uniRowData,params:{...searchParams, current:pagination.current, size:pageSize}})
            }}
          />
        </ConfigProvider>
      </div>
    </>
  )
}
