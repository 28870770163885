import React, { createRef, useState } from 'react'
import { Button, ConfigProvider, DatePicker, FormInstance, Pagination, Select, Table, TablePaginationConfig } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { getCompanyList, getStaffList, getUserBillStatistic } from '../../../utils/api'
import { uniAxList, uniAxSearchList, uniSelectRows, uniTableChange } from '../../../utils/uniFucs'
import { UserBillStatisticItem } from '../../../utils/beans'
import SummeryRow from '../../../components/SummeryRow'
import { csDateRages, payWay } from '../../../utils/constant';
import { useLocation } from 'react-router-dom';
import tablecss from '../../../css/custable.module.less'

export default function BillStatistics() {
  const pathname = "/indexpage/billstatistics"
  /* hooks */
  let location = useLocation()
  const formModalRef = createRef<FormInstance>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [staticModal, setStaticModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [rowList, setRowList] = useState([])
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({showRealName:true, showCardNo:true, showBeginBalance:true, showBeginTime:true})
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [currentRow, setCurrentRow] = useState({} as any)
  const [searchParams, setSearchParams] = useState({} as any)
  const [tempSearchParams, setTempSearchParams] = useState({} as any)
  const [staffList, setStaffList] = useState([])
  const [feeTypeList, setFeeTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
  const [staffAccounts, setStaffAccounts] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [unCashRows, setUnCashRows] = useState([] as any)
  const [isUnderLinePay, setIsUnderLinePay] = useState(true)
  const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
  const [cancelRemark, setCancelRemark] = useState('')
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [positionList, setPositionList] = useState([])
  const [loadFlag, setLoadFlag] = useState(false)

  /* funcs */
  //路由刷新请求
  const routerFlash = () => {
    uniAxList(uniRiData)
    uniAxList(uniStaffData)
  }
  const modifyAxList = (rows:any) => {
    const staticKeys = ['bxmx', 'cz', 'fhf', 'fjds','fjdf', 'fk', 'pf', 'pjdf', 'pjds', 'qt', 'qt2', 'tx', 'xfk', 'yjjk', 'yjjk2', 'companySum']
    let keycount = 1
    rows.forEach((item:any) => {
      item.companyName = '合计'
      item.children = item.data
      item.key = keycount
      keycount++
      staticKeys.forEach((keyitem:string) => {
        item.data.forEach((dataItem:any)=>{
          if (dataItem != 'companySum') dataItem[keyitem] /= 100
        })
        item[keyitem] = item.data.reduce((total:number, dataItem:any) => {
          dataItem.key = keycount
          dataItem.userName = ''
          dataItem.deptName = ''
          dataItem.companySum = 0
          dataItem.companySum = dataItem.bxmx + dataItem.fjds + dataItem.fjdf + dataItem.pf + dataItem.qt2 + dataItem.yjjk2 + dataItem.cz - dataItem.pjds - dataItem.pjdf - dataItem.xfk - dataItem.fhf - dataItem.fk - dataItem.qt - dataItem.yjjk - dataItem.tx
          console.log('companySum-------',dataItem.companySum)
          keycount++
          return total + dataItem[keyitem]
        }, 0)
      })
    })
  }

  /* elements */
  //列项
  const columns:any = [
    {
      title: '姓名',
      dataIndex: 'userName',
      key: 'userName',
      fixed: 'left'
    },
    {
      title: '所属部门',
      dataIndex: 'deptName',
      key: 'deptName',
      fixed: 'left'
    },
    {
      title: '所属公司',
      dataIndex: 'companyName',
      key: 'companyName',
      fixed: 'left'
    },
    {
      title: '应收(全部)',
      children: [
        {
          title: '派件代收款',
          dataIndex: 'pjds',
          key: 'pjds',
          render:(value:number) => isNaN(value) ? 0 : value.toFixed(2)
        },
        {
          title: '派件到付款',
          dataIndex: 'pjdf',
          key: 'pjdf',
          render:(value:number) => isNaN(value) ? 0 : value.toFixed(2)
        },
        {
          title: '现付',
          dataIndex: 'xfk',
          key: 'xfk',
          render:(value:number) => isNaN(value) ? 0 : value.toFixed(2)
        },
        {
          title: '发货费',
          dataIndex: 'fhf',
          key: 'fhf',
          render:(value:number) => isNaN(value) ? 0 : value.toFixed(2)
        },
        {
          title: '罚款',
          dataIndex: 'fk',
          key: 'fk',
          render:(value:number) => isNaN(value) ? 0 : value.toFixed(2)
        },
        {
          title: '其他费用',
          dataIndex: 'qt',
          key: 'qt',
          render:(value:number) => isNaN(value) ? 0 : value.toFixed(2)
        },
        {
          title: '押金借款',
          dataIndex: 'yjjk',
          key: 'yjjk',
          render:(value:number) => isNaN(value) ? 0 : value.toFixed(2)
        },
      ]
    },
    {
      title: '应付(全部)',
      children: [
        {
          title: '报销款',
          dataIndex: 'bxmx',
          key: 'bxmx',
          render:(value:number) => isNaN(value) ? 0 : value.toFixed(2)
        },
        {
          title: '发件代收',
          dataIndex: 'fjds',
          key: 'fjds',
          render:(value:number) => isNaN(value) ? 0 : value.toFixed(2)
        },
        {
          title: '发件到付',
          dataIndex: 'fjdf',
          key: 'fjdf',
          render:(value:number) => isNaN(value) ? 0 : value.toFixed(2)
        },
        {
          title: '派费',
          dataIndex: 'pf',
          key: 'pf',
          render:(value:number) => isNaN(value) ? 0 : value.toFixed(2)
        },
        {
          title: '其他费用',
          dataIndex: 'qt2',
          key: 'qt2',
          render:(value:number) => isNaN(value) ? 0 : value.toFixed(2)
        },
        {
          title: '押金借款',
          dataIndex: 'yjjk2',
          key: 'yjjk2',
          render:(value:number) => isNaN(value) ? 0 : value.toFixed(2)
        },
      ]
    },
    {
      title: '实收',
      children: [
        {
          title: '充值',
          dataIndex: 'cz',
          key: 'cz',
          render:(value:number) => isNaN(value) ? 0 : value.toFixed(2)
        },
      ]
    },
    {
      title: '实付',
      children: [
        {
          title: '提现',
          dataIndex: 'tx',
          key: 'tx',
          render:(value:number) => isNaN(value) ? 0 : value.toFixed(2)
        },
      ]
    },
    {
      title: '单个所属公司合计',
      children: [
        {
          title: '应付+实收-应收-实付',
          dataIndex: 'companySum',
          key: 'companySum',
          render:(value:number) => isNaN(value) ? 0 : value.toFixed(2)
        },
      ]
    }
  ]
  //table统计列
  const staticColumns = [
    {mapIndex:3, mapKeys:['pjds'], sum:0},
    {mapIndex:4, mapKeys:['pjdf'], sum:0},
    {mapIndex:5, mapKeys:['xfk'], sum:0},
    {mapIndex:6, mapKeys:['fhf'], sum:0},
    {mapIndex:7, mapKeys:['fk'], sum:0},
    {mapIndex:8, mapKeys:['qt'], sum:0},
    {mapIndex:9, mapKeys:['yjjk'], sum:0},
    {mapIndex:10, mapKeys:['bxmx'], sum:0},
    {mapIndex:11, mapKeys:['fjds'], sum:0},
    {mapIndex:12, mapKeys:['fjdf'], sum:0},
    {mapIndex:13, mapKeys:['pf'], sum:0},
    {mapIndex:14, mapKeys:['qt2'], sum:0},
    {mapIndex:15, mapKeys:['yjjk2'], sum:0},
    {mapIndex:16, mapKeys:['cz'], sum:0},
    {mapIndex:17, mapKeys:['tx'], sum:0},
    {mapIndex:18, mapKeys:['companySum'], sum:0},
  ]

  /* 接口参数 */
  const uniRiData:any = {
    params: {...searchParams,current:pagination.current, size:pagination.pageSize},
    axApi: getUserBillStatistic,
    modifyFunc: modifyAxList,
    setList: setRowList,
    setTableLoading,
    pagination
  }
  const uniStaffData:any = {
    params: {size:999},
    axApi: getStaffList,
    setList: setStaffList
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])

  /* mounted */
  React.useEffect(() => {
    uniAxList(uniRiData)
    uniAxList(uniStaffData)
    setLoadFlag(()=>true)
  }, [])
  return (
    <>
      <p>
      <Select
        showSearch
        optionFilterProp='children'
        allowClear
        style={{ width: 200 }}
        placeholder="选择人员"
        onChange={(userId) => setTempSearchParams(() => ({...tempSearchParams, userId:userId || null}))}
      > 
        {staffList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="收/付款方式"
        onChange={(way) => setTempSearchParams(() => ({...tempSearchParams, way:way || null}))}
      > 
        {payWay.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          placeholder={['账单开始日期', '账单结束日期']}
          ranges={csDateRages}
          onChange={(dates, dateStrings) => setTempSearchParams(()=>({...tempSearchParams, actionTimeBegin: dateStrings[0] ? dateStrings[0]+" 00:00:00" : null, actionTimeEnd:dateStrings[1]?dateStrings[1]+" 23:59:59" : null}))}
        />
      </ConfigProvider>&nbsp;
      <Button onClick={() => uniAxSearchList({setSearchParams, uniRowData:uniRiData, params: tempSearchParams})} type='primary'>查询</Button>
      </p>
      <Table
        size='small'
        className={tablecss.height200}
        bordered
        columns={columns}
        // rowSelection={{ ...uniSelectRows(setSelectedRowKeys, setSelectedRows), checkStrictly:true }}
        dataSource={rowList}
        pagination={false}
        loading={tableLoading}
        scroll={{x:3200, y:'calc(100vh - 305px)'}}
        // onChange={(newPagination)=>uniTableChange({uniAxData:uniRiData, searchParams:searchParams, newPagination:newPagination})}
        sticky
        summary={(pageData) => <SummeryRow pageData={pageData} sumInfo={staticColumns} total={19} title={'总金额'} />}
      />
      <div style={{textAlign:'right'}}>
        <p></p>
        <ConfigProvider locale={locale}>
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.current}
            total={pagination.total}
            showSizeChanger
            showQuickJumper
            showTotal={total => `共 ${total} 条`}
            onChange={(pageNumbe)=>{
              pagination.current = pageNumbe
              uniAxList({...uniRiData,params:{...searchParams, current:pageNumbe, size:pagination.pageSize}})
            }}
            onShowSizeChange={(current, pageSize)=>{
              pagination.pageSize = pageSize
              uniAxList({...uniRiData,params:{...searchParams, current:pagination.current, size:pageSize}})
            }}
          />
        </ConfigProvider>
      </div>
    </>
  )
}
