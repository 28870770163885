import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker, FormInstance, Input, Pagination, Select, Table, TablePaginationConfig, Tooltip, message } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { axClosureGenvou, axOriginProofBactch, axSubjectBindList, axSubjectList, axUpdateVoucherCon, axVoucherConfig, getCompanyList, getHeadOrigin } from '../../../utils/api'
import { uniAxList, uniAxSearchList, uniFormProcess, uniOpAlarm, uniToProof, uniValueToName } from '../../../utils/uniFucs'
import { csBillTypes, csIncomeTypes, csDateRages, csYesNo } from '../../../utils/constant';
import tablecss from '../../../css/custable.module.less'
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../../utils/interfaces';
import TopSearch from '../../../components/TopSearch';
import SuperTable from '../../../components/SuperTable';
import FormDialog from '../../../components/FormDialog';

export default function HqOrigin() {
/* constant */
const pathname = "/indexpage/hqorigin"
const initSearchParams = {page:1,orderBy:'create_date',orderDir:'desc'}
const searchRef = createRef<FormInstance>()
const modalRef = createRef<FormInstance>()

/* data */
let location = useLocation()
const {state} = useLocation()
const navigate = useNavigate()
const formModalRef = createRef<FormInstance>()
const [companyList, setCompanyList] = useState([] as any)
const [logList, setLogList] = useState([])
const [rowList, setRowList] = useState([])
const [selectedRowKeys, setSelectedRowKeys] = useState([])
const [selectedRows, setSelectedRows] = useState([] as any)
const [currentRow, setCurrentRow] = useState({} as any)
let [searchParams, setSearchParams] = useState({...initSearchParams} as any)
const [tempSearchParams, setTempSearchParams] = useState({page:1,orderBy:'create_date',orderDir:'desc'} as any)
const [staffList, setStaffList] = useState([])
const [feeTypeList, setFeeTypeList] = useState([])
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
const [loadFlag, setLoadFlag] = useState(false)
const [columnControl, setColumnControl] = useState({companyType: 'default'})
const [selectControl, setSelectControl] = useState({companyId:null,costTypeName:null,billTime:[],payTime:[]} as any)
const [searchData, setSearchData] = useState({} as any)
const [searchYmRange,setSearchYmRange] = useState([] as any)
const [modalFlag, setModalFlag] = useState('')
const [showModal, setShowModal] = useState(false)
const [modalLoading, setModalLoading] = useState(false)
const [modalrowList, setModalrowList] = useState([] as any)
const [modaltableLoading, setModaltableLoading] = useState(false)
let [companyItem, setCompanyItem] = useState({} as any)
let [subjectList, setSubjectList] = useState([])

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '公司', prop:'filter_EQL_company_id',type:'select',dataList:companyList,clearable:false,required:true,change:(value:any)=>{
  searchRef.current?.setFieldsValue({'filter_BETWEENS_bill_time':null})
  let finditem:any = companyList.find((item:any)=>item.id===value)
  setCompanyItem(finditem)
  if (finditem.initialize===1) setSearchYmRange([finditem.startYm,finditem.endYm])
  else setSearchYmRange([])
}},
{label: '单据日期', prop:'filter_BETWEENS_bill_time',type:'dateRange',daterangeProps:{returnType: 'string',disabledDate:(current)=>{
  if (searchYmRange.length<2) return true
  else return current<moment(searchYmRange[0]).startOf('month') || current>moment(searchYmRange[1]).endOf('month')
}}},
{label: '单据类型', prop:'filter_EQL_bill_type',type:'select',dataList:csBillTypes},
{label: '名称', prop:'filter_EQS_name'},
{label: '收支类型', prop:'filter_EQL_income_type',type:'select',dataList:csIncomeTypes},
{label: '单号', prop:'filter_EQS_bill_no'},
{label: '用户/供应商', prop:'filter_LIKES_target_name'}
]
//列项
const columns:ColumnItem[] = [
{title: '单据类型',key:'billType',custval:({row,value}:any)=>uniValueToName({list:csBillTypes,value})},
{title: '收支类型',key:'incomeType',custval:({row,value}:any)=>uniValueToName({list:csIncomeTypes,value})},
{title: '单号',key:'billNo',width:200},
{title: '公司',key:'companyName'},
{title: '单据日期',key:'billTime'},
{title: '单据金额',key:'billPrice',custval:({row,value}:any)=>value?(value/100).toFixed(2):0},
{title: '付款总额',key:'payPrice',custval:({row,value}:any)=>value?(value/100).toFixed(2):0},
{title: '未付金额',key:'notPayPrice',custval:({row,value}:any)=>value?(value/100).toFixed(2):0},
{title: '用户/供应商',key:'targetName'},
{title: '税额',key:'taxPrice',custval:({row,value}:any)=>value?(value/100).toFixed(2):0},
{title: '备注',key:'remark'},
{title: '凭证号',key:'proofNo',type:'underline',unlineProps:{action:({row,value}:any)=>uniToProof(row,row.accountProof, navigate)}}
]
//底部操作
const bottomActions:ColumnAction[] = [
{label: '生成凭证', type:'primary',disabled:selectedRowKeys.length<=0,action: () => {
  let companys:any = []
  let dates:any = []
  for (let i=0;i<selectedRows.length;i++) {
    if (selectedRows[i].billType !== 5) return message.error('单据类型必须为[暂估款]')
    if (selectedRows[i].incomeType !== 2) return message.error('收支类型必须为[应付]')
    if (selectedRows[i].proofNo) return message.error('有单据已生成凭证')
    if (!companys.find((key:any)=>key===selectedRows[i].companyId)) {
      companys.push(selectedRows[i].companyId)
      if (companys.length>1) return message.error('必须是同一公司')
    }
    let tempdate = selectedRows[i].billTime.split('-')[1]
    if (!dates.find((key:any)=>key===tempdate)) {
      dates.push(tempdate)
      if (dates.length>1) return message.error('必须是同一月份')
    }
  }
  uniOpAlarm(()=>uniAxList(datacreateProofBactch()))
}},
{label: '集配凭证配置', type:'primary',action: () => {
  if (!companyItem.id) return message.error('请先选择公司')
  setModalFlag('config')
  setShowModal(true)
  uniAxList(dataVoucherConfig(companyItem.id))
  uniAxList(dataSubjectList(companyItem.id))
}},
{label: '结款生成凭证', type:'primary',action: () => {
  setModalFlag('genvoucher')
  setShowModal(true)
}}
]
//固定资产配置中的列项
const configColumns:ColumnItem[] = [
{title: '配置名',key: 'configName'},
{title: '会计科目',key: 'subjectId',type:'select',clearable:false,dataList:()=>subjectList,selectProps:{propName:'title'},change: async (value:any,row:any)=>{
  row.subjectId = value
  row.subjectAccountingDetailId = null
  if (row.needAccounting) {
    let resdata = await uniAxList(dataSubjectBindList(companyItem.id,value))
    row.compundList = resdata
  }
  setModalrowList(()=>[...modalrowList])
}},
{title: '辅助选择',key: 'needAccounting',type:'tag',custval:({row,value}:any)=>uniValueToName({list:csYesNo,value})},
{title: '辅助详情组合',key: 'subjectAccountingDetailId',type:'select',disabled:({row}:any)=>!row.needAccounting,dataList:(row:any)=>row.compundList || [],selectProps:{propName:'noName'},change:(value:any,row:any)=>{
  row.subjectAccountingDetailId = value
  setModalrowList(()=>[...modalrowList])
}},
{title: '描述',key: 'remark'},
]
//结款生成凭证
const genItems:FormItemObj[] = [
{label: '月份',prop:'ym',type:'datePicker',datepickerProps:{returnType:'string',type:'month'}},
{label: '公司',prop:'companyId',type:'select',dataList:companyList}
]

/* funcs */
const searchConfirm = async (formValus:any) => {
  setSelectedRowKeys([])
  pagination.current = 1
  setPagination({...pagination,current:1})
  let temprangestr
  let rangearr = formValus['filter_BETWEENS_bill_time']
  if (rangearr && rangearr.length>0) temprangestr = moment(rangearr[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss') + ',' + moment(rangearr[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss')
  searchParams = {...initSearchParams,...formValus,filter_BETWEENS_bill_time:temprangestr}
  setSearchParams({...initSearchParams,...formValus,filter_BETWEENS_bill_time:temprangestr})
  uniAxList(dataRow())
}
//modal取消
const modalCancel = () => {
  setShowModal(()=>false)
}
//modal点击确定
const modalConfirm = async (formValues:any) => {
  if (modalFlag==='config') {
    for (let i=0;i<modalrowList.length;i++) {
      if (modalrowList[i].needAccounting && modalrowList[i].compundList.length>0 && !modalrowList[i].subjectAccountingDetailId) {
        return message.error(`第${i+1}行, 辅助详情组合必须`)
      }
    }
    uniAxList(dataAssetConfigUpdate())
  } else if (modalFlag==='genvoucher') {
    let tempparms = await uniFormProcess(formValues,genItems)
    uniAxList(dataGenvou(tempparms))
  }
}
//路由刷新请求
const routerFlash = () => {
  // uniAxList(dataRow())
}

/* 接口参数 */
const dataRow = (current?:number):AxDataform => ({
  params: {...searchParams, current: current || pagination.current, size:pagination.pageSize},
  axApi: getHeadOrigin,
  setList: setRowList,
  setTableLoading,
  setPagination
})
const dataCompany:any = {
  params:{filter_EQL_type:1},
  axApi: getCompanyList,
  setList: setCompanyList,
}
//暂估款批量生成凭证
const datacreateProofBactch = ():AxDataform => ({
  params: selectedRowKeys,
  axApi: axOriginProofBactch,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//公司会计科目列表
const dataSubjectList = (companyId:any):AxDataform => ({
  params: {page:1, size:999,companyId,showProof:true},
  axApi: axSubjectList,
  itemModify: {modifyFunc:({item}:any)=>item.title=item.subjectNo+' '+item.name},
  setList: setSubjectList
})
//获取科目详情组合
const dataSubjectBindList = (companyId:any,subjectId:any):AxDataform => {
  return {
    params:{companyId,subjectId},
    axApi: axSubjectBindList
  }
}
//集配凭证配置列表
const dataVoucherConfig = (companyId:any):AxDataform => ({
  params: {companyId},
  axApi: axVoucherConfig,
  setTableLoading: setModaltableLoading,
  finishLoading: false,
  awaitFunc: async (predata:any) => {
    let temparr:any = []
    for (let i=0;i<predata.config.length;i++) {
      let item = predata.config[i]
      let tempobj:any = {configId:item.id,configName:item.name,companyId:companyItem.id,needAccounting:item.needAccounting,remark:item.remark}
      let detailitem = predata.detail.find((detailitem:any)=>detailitem.configId===item.id)
      if (detailitem) {
        tempobj.subjectId = detailitem.subjectId
        tempobj.subjectAccountingDetailId = detailitem.subjectAccountingDetailId
        if (predata.config[i].needAccounting) {
          let resdata = await uniAxList(dataSubjectBindList(companyItem.id,detailitem.subjectId))
          tempobj.compundList = resdata
        } else tempobj.compundList = []
      }
      temparr.push(tempobj)
    }
    setModalrowList(temparr)
    setModaltableLoading(false)
  }
})
//更新集配凭证配置列表
const dataAssetConfigUpdate = ():AxDataform => ({
  params: modalrowList,
  axApi:axUpdateVoucherCon,
  setModalLoading,
  setShowModal,
  successTip: true
})
//结款生成凭证
const dataGenvou = (params:any):AxDataform => ({
  params,
  axApi:axClosureGenvou,
  setModalLoading,
  setShowModal,
  successTip: true
})

/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])
/* mounted */
React.useEffect(() => {
  // uniAxList(dataRow())
  if(!state) uniAxList(dataCompany)
  setLoadFlag(()=>true)
}, [])
//路由跳转
React.useEffect(() => {
  if (state && location.pathname===pathname) {
    selectControl.billTime = []
    selectControl.payTime =[]
    selectControl.companyId = null
    selectControl.costTypeName = null
    let mySate:any = state
    
    selectControl.companyId = mySate.companyId
    selectControl.costTypeName = mySate.costTypeName
    tempSearchParams.filter_EQL_company_id = mySate.companyId
    tempSearchParams.filter_LIKES_cost_type_name = mySate.costTypeName
    searchParams.filter_EQL_company_id = mySate.companyId
    searchParams.filter_LIKES_cost_type_name = mySate.costTypeName
    if(mySate.companyId===6) columnControl.companyType = 'jt'
    else if (mySate.companyId===3) columnControl.companyType = 'yd'
    else if (mySate.companyId===4) columnControl.companyType = 'yt'
    else if (mySate.companyId===2) columnControl.companyType = 'zt'
    else columnControl.companyType = 'default'

    let tempbill:any
    let temppay:any
    if (mySate.type===1) {
      let start = moment(mySate.billYm).startOf('month').format("YYYY-MM-DD")
      let end = moment(mySate.billYm).endOf('month').format("YYYY-MM-DD")
      tempbill = start+','+end
      selectControl.billTime = [moment(start,"YYYY-MM-DD"),moment(end,"YYYY-MM-DD")]
      tempSearchParams.filter_BETWEENS_bill_time = tempbill
      searchParams.filter_BETWEENS_bill_time = tempbill
    } else if (mySate.type===2) {
      let start = moment(mySate.payYm).startOf('month').format("YYYY-MM-DD")
      let end = moment(mySate.payYm).endOf('month').format("YYYY-MM-DD")
      temppay = start+','+end
      selectControl.payTime = [moment(start,"YYYY-MM-DD"),moment(end,"YYYY-MM-DD")]
      tempSearchParams.filter_BETWEENS_pay_time = temppay
      searchParams.filter_BETWEENS_pay_time = temppay
    }
    uniAxList({...dataCompany, awaitFunc: (companyList:any)=>{
      uniAxList({...dataRow(),params:{...searchParams, current:pagination.current, size:pagination.pageSize,filter_BETWEENS_bill_time:tempbill,filter_BETWEENS_pay_time:temppay}})
    }})
  }
}, [state])
return (
  <>
    <TopSearch
      ref={searchRef}
      searchList={searchList}
      searchConfirm={searchConfirm}
    />
    <SuperTable
      columns={columns}
      rowList={rowList}
      tableLoading={tableLoading}
      pagination={pagination}
      bottomActions={bottomActions}
      rowSelection={{setSelectedRowKeys,setSelectedRows,selectedRowKeys}}
      dataRow={dataRow}
    />
    <FormDialog
      ref={modalRef}
      type={modalFlag==='config'?"drawer":'modal'}
      drawerSize="70%"
      title={modalFlag==='config'?`集配凭证配置-${companyItem.name}`:'结款生成凭证'}
      show={showModal}
      formItems={modalFlag==='config'?[]:genItems}
      modalLoading={modalLoading}
      formInitials={{}}
      cancel={modalCancel}
      confirm={modalConfirm}
      showCustom={modalFlag==='config'}
    >
      <></>
      <SuperTable
        rowKey='configId'
        columns={configColumns}
        rowList={modalrowList}
        tableLoading={modaltableLoading}
      />
    </FormDialog>
  </>
)
}
