import React, { createRef, useState } from 'react'
import { Button, Checkbox, ConfigProvider, DatePicker, Drawer, Form, FormInstance, Input, List, message, Modal, Pagination, Popconfirm, Radio, Select, Space, Table, TablePaginationConfig, TreeSelect, Typography } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';
import {QuestionCircleFilled,DownOutlined,UpOutlined} from '@ant-design/icons'

import { axAddAffixDetail, axAffixExSubjectList, axAffixSubjectDetail, axAffixSubjectList, axCreateAffixExSubject, axCreateAffixSubject, axDeleteAffixDetail, axDeleteAffixExSubject, axDeleteAffixSubject, axSubjectBindAffix, axSubjectBindAffixdetails, axSubjectBinddetailList, axSubjectDelAffixdetails, axSubjectList, axUpdateAffixDetail, axUpdateAffixSubject, createAccountantSubject, deleteAccountantSubjectList, getCompanyList, updateAccountantSubject } from '../../../utils/api'
import { AccountSubjectItem, NameValue } from '../../../utils/beans'
import { uniSelectRows, uniFilterItem, uniModifyAxList, uniAxList, UniDeleteAll, uniDeleteRow, uniModifyRow, uniAxSearchList, uniQuitModal, uniAddRowSubmit, uniChgRowSubmit, uniAssetButton, uniGetMaxFromListkey, uniBatchConfirm, uniFormProcess, uniValueToName, uniInitFormdata, uniOpAlarm } from '../../../utils/uniFucs'
import { adaptTypes, assetType, csRegitType, csDateRages, forbidenStatus, initAccountSubjectItem, leadBorrow, borrowOrLend } from '../../../utils/constant'
import UniOperationColumn from '../../../components/UniOperationColumn'
import { useLocation, useNavigate } from 'react-router-dom';
import tablecss from '../../../css/custable.module.less'
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../../utils/interfaces';
import TopSearch from '../../../components/TopSearch';
import SuperTable from '../../../components/SuperTable';
import FormDialog from '../../../components/FormDialog';
import Tooltip from 'antd/es/tooltip';

export default function ExpressAccountingSubject() {
  /* constant */ 
  const pathname = "/indexpage/expressaccountingsubject"
  const navigate = useNavigate()
  const searchRef = createRef<FormInstance>()
  const modalsearchRef = createRef<FormInstance>()
  const modalRef = createRef<FormInstance>()
  const initSearchParams = {showAccountingDetail:true,orderBy:"subject_no", orderDir: "asc"}
  const initYesNot = [{id:true,name:'是'},{id:false,name:'否'}]
  const initModalrowObj = {}
  let idcount = 1
  /* hooks */
  let location = useLocation()
  const formModalRef = createRef<FormInstance>()
  const [tableLoading, setTableLoading] = useState(false)
  let [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
  const [rowList, setRowList] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([] as any)
  let [searchParams, setSearchParams] = useState({ ...initSearchParams } as any)
  const [tempSearchParams, setTempSearchParams] = useState({page:1,orderBy:"subject_no", orderDir: "asc"} as any)
  const [activeButton, setActiveButton] = useState({value: ''})
  const [chgModal, setChgModal] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [currentRow, setCurrentRow] = useState({} as any)
  const [subjectNoPrefix, setSubjectNoPrefix] = useState('')
  const [initSubjectNo, setInitSubjectNo] = useState('')
  const [selectTreeNode, setSelectTreeNode] = useState({} as any)
  const [accountantSubjectList, setAccountantSubjectList] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [loadFlag, setLoadFlag] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [modalItemVisible, setModalItemVisible] = useState({affixIds:false} as any)
  const [affixModal, setAffixModal] = useState(false)
  const [affixList,setAffixList] = useState([])
  const [modalFlag, setModalFlag] = useState('')
  const [showModal, setShowModal] = useState(false)
  let [formInitials, setFormInitials] = useState({})
  let [companyList,setCompanyList] = useState([])
  let [affixdetailList,setAffixdetailList] = useState([])
  let [modalrowList,setModalrowList] = useState([] as any)
  let [modalCheckedAffixs,setModalCheckedAffixs] = useState([])
  let [modalCheckedKeys,setModalCheckedKeys] = useState([] as any)
  let [showModaltable,setShowModaltable] = useState(false)
  let [affixDetailInfo,setAffixDetailInfo] = useState({} as any)
  let [affixColumns,setAffixColumns] = useState([] as ColumnItem[])
  let [needAffixkeys, setNeedAffixkeys] = useState([] as any)
  let [companItem,setCompanyItem] = useState({} as any)
  let [originModalrowList,setOriginModalrowList] = useState([] as any)
  let [affixHaschange,setAffixHaschange] = useState(false)

/* funcs */
const searchConfirm = async (formValus:any) => {
  let finditem:any = companyList.find((item:any)=>item.id===formValus.companyId)
  setCompanyItem(finditem)
  pagination = {...pagination,current:1}
  setPagination(pagination);
  setSelectedRows([])
  setSelectedRowKeys([])
  searchParams = await uniFormProcess({...initSearchParams,...formValus},searchList)
  setSearchParams({...searchParams})
  // setTableParams({...searchParams})
  uniAxList(dataRow())
}
const modalsearchConfirm = async (formValus:any) => {
}
//modal点击取消
const modalCancel = () => {
  setShowModal(()=>false)
  setCurrentRow({})
  setFormInitials({})
  setModalCheckedAffixs([])
  setModalCheckedKeys([])
  setNeedAffixkeys([])
  setAffixDetailInfo({})
  setShowModaltable(false)
  setAffixHaschange(false)
  setModalrowList([])
  idcount = 1
}
//modal点击确定
const modalConfirm = async (formValues:any) => {
  let tempItems = formItems
  let tempparms = await uniFormProcess(formValues,tempItems)
  if (modalFlag === 'new') uniAxList(dataNewrow({accountantSubject:{...tempparms,...customAddKeys}}))
  else if (modalFlag === 'update') uniAxList(dataUpdaterow({...tempparms,id:currentRow.id}))
  else if (modalFlag === 'affix') {
    if (modalCheckedKeys.length<=0) return message.error('请选择辅助核算')
    setTableLoading(true)
    let outarr = []
    let delarr:any = []
    let exitKeyArr:any = []
    for (let i=0;i<modalrowList.length;i++) {
      let emptyobj = needAffixkeys.find((key:any)=>!modalrowList[i][key+'_detailId'])
      if (emptyobj) return message.error('有必选辅助，未选择详情')
      //新添加的组合需要新建
      if (!modalrowList[i]['originId']) {
        let temparr:any = []
        modalCheckedAffixs.forEach((item:any)=>{
          let detailid = modalrowList[i][item.id+'_detailId']
          let finditem
          if (detailid) finditem = affixDetailInfo[item.id].find((detailitem:any)=>detailitem.id === detailid)
          temparr.push({accountingId:item.id,accountingName:item.name,detailId:detailid,detailName:finditem?.name,detailNo:finditem?.no})
        })
        outarr.push({companyId:companItem.id,subjectId:currentRow.id,accountingDetail:temparr})
      } else exitKeyArr.push(modalrowList[i]['originId'])
    }
    //如果辅助核算有变化，要先清除当前详情组合，重新绑定
    if (affixHaschange) {
      let subjectAccounting = modalCheckedAffixs.map((item:any)=>({accountingId:item.id,accountingName:item.name,needAccounting:needAffixkeys.includes(item.id)}))
      if (originModalrowList.length > 0) {
        let funcs:any = []
        originModalrowList.forEach((item:any)=>funcs.push(uniAxList(dataDelAffixdetails({companyId:companItem.id,subjectAccountingDetailId:item.originId,subjectId:currentRow.id}))))
        await Promise.all(funcs)
      }
      await uniAxList(dataBindAffix({companyId:companItem.id,subjectId:currentRow.id,subjectAccounting}))
    } else originModalrowList.forEach((item:any)=>{
      if (!exitKeyArr.includes(item.originId)) delarr.push(item)
    })
    let profuncs:any = []
    if (outarr.length > 0) outarr.forEach((item:any)=>profuncs.push(uniAxList(dataBindAffixdetails({...item}))))
    if (delarr.length > 0) delarr.forEach((item:any)=>profuncs.push(uniAxList(dataDelAffixdetails({companyId:companItem.id,subjectAccountingDetailId:item.originId,subjectId:currentRow.id}))))
    await Promise.all(profuncs)
    setTableLoading(false)
    setShowModal(false)
    uniAxList(dataRow())
  }
}
//批量删除
const onBatchDelete = () => {
  let deleteKeys:any = []
  selectedRows.forEach((item:any) => {
    if (item.canDelete === 1) deleteKeys.push(item.id)
  })
  if (deleteKeys.length <= 0) message.info('没有可以删除的记录')
  else UniDeleteAll({deleteKeyList:selectedRowKeys, deleteApi:deleteAccountantSubjectList, setSelectedRowKeys,axParams:dataRow()})
}
//路由刷新请求
const routerFlash = () => {
  // uniAxList(dataRow())
  uniAxList(dataAffixList)
  uniAxList(dataSubjectList)
  uniAxList(dataCompanyList)
}
//展开收起辅助详情组合
const affixtoggle = () => {
  let curshow = showModaltable
  if (curshow) setShowModaltable(false)
  else if (!curshow) {
    if (modalCheckedAffixs.length<=0) return message.error('请选择辅助核算')
    //如果当前设置了辅助核算，检查是否修改； 没有设置辅助核算，则不检查
    let hascheng = false
    if (currentRow.subjectAccounting) {
      let needkeys:any = []
      if (modalCheckedKeys.length !== currentRow.subjectAccounting.length) hascheng = true
      if (!hascheng) {
        currentRow.subjectAccounting.forEach((item:any)=>{
          if (!modalCheckedKeys.includes(item.accountingId)) hascheng = true
          if (item.needAccounting) needkeys.push(item.accountingId)
        })
      }
      if (!hascheng && needAffixkeys.length !== needkeys.length) hascheng = true
      if (!hascheng) {
        needAffixkeys.forEach((key:any)=>{
          if (!needkeys.includes(key)) hascheng = true
        })
      }
    }
    //如有修改，提示会清空当前组合详情； 否则不提示
    if (hascheng && currentRow.subjectAccounting.length>0 && originModalrowList.length>0) uniOpAlarm(()=>{
      let tempcols:ColumnItem[] = [{title: '组合',key: 'count',custval:({index}:any)=>index + 1}]
      modalCheckedAffixs.forEach((item:any)=>{
        uniAxList(dataAffixDetailList(item.id,companItem.id))
        tempcols.push({title: item.name, key: item.id+'_detailId',type:'select',dataList:()=>affixDetailInfo[item.id] || []})
      })
      affixColumns = [...tempcols,...initaffixColumns]
      setAffixColumns(affixColumns)
      setModalrowList([{...initModalrowObj,id:1}])
      setShowModaltable(true)
      idcount = 1
    },'修改辅助核算, 会清空当前详情组合, 是否继续?')
    else if(affixColumns.length<=0 || currentRow.subjectAccounting.length<=0 || originModalrowList.length<=0) {
      let tempcols:ColumnItem[] = [{title: '组合',key: 'count',custval:({index}:any)=>index + 1}]
      modalCheckedAffixs.forEach((item:any)=>{
        uniAxList(dataAffixDetailList(item.id,companItem.id))
        tempcols.push({title: item.name, key: item.id+'_detailId',type:'select',dataList:()=>affixDetailInfo[item.id] || []})
      })
      affixColumns = [...tempcols,...initaffixColumns]
      idcount = 1
      setAffixColumns(affixColumns)
      setModalrowList([{...initModalrowObj,id:1}])
      setShowModaltable(true)
    } else setShowModaltable(true)
    setAffixHaschange(hascheng)
  }
}

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '公司', prop:'companyId',type:'select',dataList:companyList,required:true},
{label: '类型', prop:'subjectType',type:'select',dataList:assetType},
{label: '显示辅助详情', prop:'showAccountingDetail',type:'select',dataList:initYesNot},
{label: '借贷方向', prop:'borrowLend',type:'select',dataList:borrowOrLend},
{label: '名称', prop:'name'},
{label: '编码', prop:'subjectNo'}
]
//列首行
const columns:ColumnItem[]= [
{ title: '科目编码',key: 'subjectNo',render:(value:any,row:any)=>{
  let tempstr = value
  if (row.level===2) tempstr = '\xA0\xA0\xA0\xA0' + value
  else if (row.level===3) tempstr = '\xA0\xA0\xA0\xA0\xA0\xA0' + value
  return <>
    {row.accounting?<span style={{color:'red'}}> 辅</span>:null}
    <span>{tempstr}</span>
  </>
}},
{title: '科目名称', key: 'name',custval:({row,value}:any)=>{
  let tempstr = value
  if (row.level===2) tempstr = '\xA0\xA0\xA0\xA0' + value
  else if (row.level===3) tempstr = '\xA0\xA0\xA0\xA0\xA0\xA0' + value
  return tempstr
}},
{title: '类型',key: 'subjectType', custval: ({row,value}:any)=>uniValueToName({list:assetType,value:value})},
{title: '期初余额', key: 'balance',type:'price'},
{title: '借贷方向',key: 'borrowLend',type:'tag',custval: ({row,value}:any)=>uniValueToName({list:leadBorrow,value})},
{title: '状态',key: 'status',type:'tag',custval: ({row,value}:any)=>uniValueToName({list:forbidenStatus,value})},
{title: '有无辅助核算',key: 'containAccounting',type:'tag',tagProps:{values:[false,true]},custval: ({row,value}:any)=>value?'有':!row.accounting?'无':''},
{title: '操作',key:'operation',type: 'operation',width:100,opProps:{opList:[
  {name:'修改',show:({row}:any)=>!row.accounting,action:({row}:any) => {
    setModalFlag('update')
    setCurrentRow(row)
    setFormInitials({...uniInitFormdata(row,formItems)})
    setShowModal(true)
  }},
  {name:'删除',show:({row}:any)=>!row.accounting,action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataDelrow(row.id)))},
  {name:'辅助核算',show:({row}:any)=>companItem.startYm && !row.accounting,action:({row}:any) => {
    if (!companItem.startYm) return message.error('该公司未启用, 不能设置辅助核算')
    if (row.subjectAccounting && row.subjectAccounting.length > 0) {
      let tempcols:ColumnItem[] = [{title: '组合',key: 'count',custval:({index}:any)=>index + 1}]
      let tempaffixs:any = []
      let tempaffixKeys:any = []
      let tempneedkeys:any = []
      row.subjectAccounting.forEach((item:any)=>{
        tempaffixKeys.push(item.accountingId)
        //获取辅助核算详情列表
        uniAxList(dataAffixDetailList(item.accountingId,item.companyId))
        //组装column
        tempcols.push({title:item.accountingName,key:item.accountingId+'_detailId',type:'select',disabled:({row,value}:any)=>row.originId,dataList:()=>affixDetailInfo[item.accountingId] || []})
        //组合已选辅助核算 和 必选辅助核算
        tempaffixs.push({id:item.accountingId,name:item.accountingName,label:item.accountingName,value:item.accountingId})
        if (item.needAccounting) tempneedkeys.push(item.accountingId)
      })
      setModalCheckedKeys(tempaffixKeys)
      setModalCheckedAffixs(tempaffixs)
      setNeedAffixkeys(tempneedkeys)
      setAffixColumns([...tempcols,...initaffixColumns])
      //辅助核算组合详情
      uniAxList(dataBinddetailList({companyId:companItem.id,subjectId:row.id}))
    }
    setModalFlag('affix')
    setCurrentRow(row)
    // setFormInitials({...uniInitFormdata(row,formItems)})
    setShowModal(true)
  }}
]}}
]
//列首行
const initaffixColumns:ColumnItem[]= [
{title: '操作',key:'operation',type: 'operation',opProps:{type:'button',opList:[
  {name:'+',action:({row,index}:any) =>setModalrowList((prelist:any)=>{
    idcount += 1
    let temparr1 = prelist.slice(0,index+1)
    let temparr2 = prelist.slice(index+1)
    return [...temparr1,{...initModalrowObj,id:idcount},...temparr2]
  })},
  {name:'-',action:({row,index}:any) =>{
    setModalrowList((prelist:any)=>{
      // if (prelist.length <= 1) return prelist
      let temparr1 = prelist.slice(0,index)
      let temparr2 = prelist.slice(index+1)
      return [...temparr1,...temparr2]
    })
  }},
]}}
]
//底部操作
const bottomActions:ColumnAction[] = [
{label: '新增', type:'primary', action: () => {setModalFlag('new');setShowModal(() => true)}}
]
//添加和修改中的model项
const formItems:FormItemObj[] = [
{label: '科目名称',prop: 'name',type:'input'},
{label: '上级科目',prop: 'parentId',type:'tree',dataList:[{subjectNo:'', title:'一级会计科目', level: 0, id: 0, children: accountantSubjectList}],treeProps:{propName:'title',expandKeys:[0]},change:(value:any,node:any)=>{
  let maxSubNum = 0
  if (value !==null && value === 0)  {
    maxSubNum = uniGetMaxFromListkey({rowList:accountantSubjectList, key:'subjectNo'})
    customAddKeys.level = 1
  } else {
    const subjectItem:any = uniFilterItem(accountantSubjectList, 'id', value)
    customAddKeys.level = subjectItem ? subjectItem.level + 1 : null
    if (subjectItem && subjectItem.children) maxSubNum = uniGetMaxFromListkey({rowList:subjectItem.children, key: 'subjectNo'})
    else if (subjectItem) maxSubNum = Number.parseInt(subjectItem.subjectNo+"00")
  }
  modalRef.current?.setFieldsValue({subjectNo: maxSubNum + 1})
  if (value) modalRef.current?.setFieldsValue({subjectType:node.subjectType,borrowLend:node.borrowLend})
}},
{label: '科目编码',prop: 'subjectNo',type:'input'},
{label: '类型',prop: 'subjectType',type:'select',dataList:assetType},
// {label: '辅助核算',prop: 'affixIds',type:'select',dataList:affixList,selectProps:{mode:'multiple'}},
{label: '借贷方向',prop: 'borrowLend',type:'select',dataList:leadBorrow},
{label: '状态',prop: 'status',type:'select',dataList:forbidenStatus}
]
//添加和修改中的model项
const affixItems:FormItemObj[] = [
{label: '公司', prop:'companyId',type:'select',dataList:companyList,required:true,disabled:showModaltable}
]

/* 接口参数 */
const dataRow = ():AxDataform => ({
  // params: {...searchParams,showBalance:true,showAccountingDetail:true, current:pagination.current, size:pagination.pageSize},
  params: {...searchParams,showBalance:true},
  axApi: axSubjectList,
  // itemModify: {modifyFunc:({item}:any)=>{
  //   if (item.children && item.children.length<=0) delete item.children
  // }},
  setList: setRowList,
  setTableLoading,
  setPagination
})
//创建会计科目
const dataNewrow = (params:any):AxDataform => ({
  params,
  axApi: createAccountantSubject,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//修改会计科目
const dataUpdaterow = (params:any):AxDataform => ({
  params,
  axApi: updateAccountantSubject,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//删除会计科目 uniDeleteRow({record,deleteApi:deleteAccountantSubjectList,uniAxListData:dataRow()})}
const dataDelrow = (rowid:any):AxDataform => ({
  params: {id: rowid},
  axApi: deleteAccountantSubjectList,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//会计科目绑定辅助核算
const dataBindAffix = (params:any):AxDataform => ({
  params,
  axApi: axSubjectBindAffix,
})
//会计科目绑定辅助核算详情组合
const dataBindAffixdetails = (params:any):AxDataform => ({
  params,
  axApi: axSubjectBindAffixdetails,
})
//删除辅助核算详情组合
const dataDelAffixdetails = (params:any):AxDataform => ({
  params,
  axApi: axSubjectDelAffixdetails,
})
//会计科目辅助核算详情组合列表
const dataBinddetailList = (params:any):AxDataform => ({
  params,
  axApi: axSubjectBinddetailList,
  awaitFunc: (predata:any) => {
    if (predata && predata.length>0) {
      let temparr:any = []
      predata.forEach((item:any,index:any)=>{
        let tempobj:any = {id:index+1}
        item.accountingIds.forEach((key:any,subindex:any)=>{
          tempobj[key+'_detailId'] = item.accountingDetailIds[subindex]
          tempobj['originId'] = item.id
        })
        temparr.push(tempobj)
      })
      idcount = predata.length
      setModalrowList([...temparr])
      setOriginModalrowList([...temparr])
    } else {
      setModalrowList([])
      setOriginModalrowList([])
    }
    setShowModaltable(true)
  }
})
//会计科目列表
const dataSubjectList:AxDataform  = {
  params: {page:1, size:999},
  itemModify: {modifyFunc: ({item}:any)=>item.title = item.subjectNo + ' ' +item.name},
  axApi: axSubjectList,
  setList: setAccountantSubjectList
}
//辅助核算列表
const dataAffixList:AxDataform  = {
  axApi: axAffixSubjectList,
  itemModify: {modifyFunc:({item}:any)=>{
    item.label = item.name
    item.value = item.id
  }},
  setList: setAffixList
}
//辅助核算详情列表
const dataAffixDetailList = (assistId:any,companyId:any):AxDataform => ({
  params: {accountingId: assistId,companyId},
  axApi: axAffixSubjectDetail,
  awaitFunc: (predata:any)=>{
    affixDetailInfo[assistId] = predata
    setAffixDetailInfo({...affixDetailInfo})
  }
  // setList: setAffixdetailList
})
//公司列表
const dataCompanyList:AxDataform = {
  axApi: getCompanyList,
  setList: setCompanyList
}

/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])
/* mounted */
React.useEffect(() => {
  // uniAxList(dataRow())
  uniAxList(dataAffixList)
  uniAxList(dataSubjectList)
  uniAxList(dataCompanyList)
  setLoadFlag(()=>true)
}, [])
return (
  <>
    <TopSearch
      ref={searchRef}
      searchList={searchList}
      searchConfirm={searchConfirm}
      initialValues={initSearchParams}
    />
    <SuperTable
      columns={columns}
      rowList={rowList}
      bottomActions={bottomActions}
      tableLoading={tableLoading}
    />
    <FormDialog
      ref={modalRef}
      type={modalFlag==='affix'?'drawer':'modal'}
      title={modalFlag==='new'?'新增账户':modalFlag==='update'?'修改账户':modalFlag==='affix'?`辅助核算-${companItem.name}`:'初始化余额'}
      show={showModal}
      formItems={modalFlag==='affix'?[]:formItems}
      modalLoading={modalLoading}
      formInitials={formInitials}
      cancel={modalCancel}
      confirm={modalConfirm}
      showCustom={modalFlag==='affix'}
    >
      <span></span>
      <>
      <div style={{display:'flex'}} >
        <span style={{marginRight:10}} >辅助核算: </span>
        <Checkbox.Group options={affixList} value={modalCheckedKeys} disabled={showModaltable} onChange={(checkedValues)=>{
          console.log('checkevalues-------------',checkedValues)
          setModalCheckedKeys(checkedValues)
          let temparr = affixList.filter((item:any)=>checkedValues.includes(item.id))
          setModalCheckedAffixs(temparr)
        }} />
      </div>
      <div style={{display:'flex'}} >
        <span style={{marginRight:10}} >必选辅助: </span>
        <Checkbox.Group options={modalCheckedAffixs} value={needAffixkeys} disabled={showModaltable} onChange={(checkedValues)=>{
          setNeedAffixkeys(checkedValues)
        }} />
      </div>
      <div style={{marginTop:10,display:'flex'}}>
        <div style={{marginRight:10,cursor:'pointer'}} onClick={affixtoggle} >
          <Tooltip placement="topLeft" title='展开设置详情组合, 收起设置辅助核算'><QuestionCircleFilled /></Tooltip>
          <span>详情组合</span>
          {showModaltable?<UpOutlined />:<DownOutlined />}
        </div>
        <Button disabled={!showModaltable} size='small' type="primary" onClick={()=>setModalrowList((prelist:any)=>{
          idcount += 1
          return [...prelist,{...initModalrowObj,id:idcount}]
        })} >添加组合</Button>
      </div>
      {showModaltable?<SuperTable columns={affixColumns} rowList={modalrowList} />:null}
      </>
    </FormDialog>
  </>
)
}
