import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker, FormInstance, Table, TablePaginationConfig, Tooltip, Typography } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { getCompanyList, gpProfitList } from '../../utils/api'
import { uniAxList, uniAxSearchList, uniCurrentTime, uniDeepArray, uniExportExcel, uniFilterItem, uniFormProcess, uniModifyAxList, uniSelectRows } from '../../utils/uniFucs'
import { companyNameList } from '../../utils/constant'
import tablecss from '../../css/custable.module.less'
import { AxDataform, ColumnItem, FormItemObj } from '../../utils/interfaces';
import TopSearch from '../../components/TopSearch';
import SuperTable from '../../components/SuperTable';

export default function GpMonthProfit() {
const pathname = "/indexpage/gpmonthprofit"
const searchRef = createRef<FormInstance>()
const initSearchParams = {ym: moment().format('YYYY-MM')}
/* hooks */
let location = useLocation()
const navigate = useNavigate()
const formModalRef = createRef<FormInstance>()
const [addModal, setAddModal] = useState(false)
const [chgModal, setChgModal] = useState(false)
const [cancelModal, setCancelModal] = useState(false)
const [confirmModal, setConfirmModal] = useState(false)
const [staticModal, setStaticModal] =useState(false)
const [companyList, setCompanyList] = useState([] as any)
const [rowList, setRowList] = useState([] as any)
const [backRowList, setBackRowList] = useState([])
const [modalItemVisible, setModalItemVisible] = useState({} as any)
const [showCashAccount, setShowCashAccount] = useState(true)
const [selectedRowKeys, setSelectedRowKeys] = useState([])
const [selectedRows, setSelectedRows] = useState([])
const [currentRow, setCurrentRow] = useState({} as any)
let [searchParams, setSearchParams] = useState({ym:uniCurrentTime({day:false})} as any)
const [tempSearchParams, setTempSearchParams] = useState({page:1,ym:uniCurrentTime({day:false})} as any)
const [staffList, setStaffList] = useState([])
const [reinburseTypeList, setReinburseTypeList] = useState([])
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 10})
const [staticTableData, setStaticTableData] = useState([] as any)
const [staffAccounts, setStaffAccounts] = useState([])
const [customAddKeys, setCustomAddKeys] = useState({} as any)
const [unCashRows, setUnCashRows] = useState([] as any)
const [isUnderLinePay, setIsUnderLinePay] = useState(true)
const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
const [businessList, setBusinessList] = useState([])
const [cancelRemark, setCancelRemark] = useState('')
const [loadFlag, setLoadFlag] = useState(false)
const [dataObj, setDataObj] = useState({} as any)

/* funcs */
const searchConfirm = async (formValus:any) => {setPagination({...pagination,current:1});
  searchParams = await uniFormProcess({...initSearchParams,...formValus},searchList)
  uniAxList(dataRow())
}
//路由刷新请求
const routerFlash = () => {
  uniAxList(dataCompanyList)
}
//修饰数据
const modifyFunc = (rowlist: any,modifyData:any, predata:any) => {
  let companylist = predata || companyList
  let companyIdstrs = companylist.map((item:any)=>item.id.toString())
  const loopFunc = (list:any) => {
    list.forEach((item:any)=>{
      companyIdstrs.forEach((key:string)=>item[key]=0)
      item.id = item.subjectId
      item.monthPrice = 0
      item.yearPrice = 0
      //每项的月金额和年金额
      if (item.companyPrice && item.companyPrice.length>0) {
        item.companyPrice.forEach((companyitem:any)=>{
          item.monthPrice += companyitem.monthPrice
          item.yearPrice += companyitem.monthPrice
        })
      }
      //每项的公司金额
      if (item.companyPrice && item.companyPrice.length>0) item.companyPrice.forEach((companyitem:any)=>item[companyitem.companyId.toString()]=companyitem.monthPrice)
      if (item.children.length > 0) loopFunc(item.children)
      else delete item.children
    })
  }
  loopFunc(rowlist)
  let modifylist = rowlist

  let mainIncome = modifylist.find((rowItem:any) => rowItem.name === '主营业务收入')
  let otherIncome = modifylist.find((rowItem:any) => rowItem.name === '其他业务收入')
  let mainCost = modifylist.find((rowItem:any) => rowItem.name === '主营业务成本')
  let otherCost = modifylist.find((rowItem:any) => rowItem.name === '其他业务支出')
  let taxplus = modifylist.find((rowItem:any) => rowItem.name === '税金及附加')
  let manageFee = modifylist.find((rowItem:any) => rowItem.name === '管理费用')
  let saleFee = modifylist.find((rowItem:any) => rowItem.name === '销售费用')
  let finFee = modifylist.find((rowItem:any) => rowItem.name === '财务费用')
  let investProfile = modifylist.find((rowItem:any) => rowItem.name === '投资收益')
  let outIncome = modifylist.find((rowItem:any) => rowItem.name === '营业外收入')
  let outCost = modifylist.find((rowItem:any) => rowItem.name === '营业外支出')
  let taxfee = modifylist.find((rowItem:any) => rowItem.name === '所得税')

  let expressCollect:any = {
    id: -1,
    name: '业务收入',
    monthPrice:mainIncome.monthPrice+otherIncome.monthPrice,
    yearPrice:mainIncome.yearPrice+otherIncome.yearPrice,
    ...Object.fromEntries(companylist.map((item:any) => [item.id.toString(), mainIncome[item.id.toString()] + otherIncome[item.id.toString()]])),
    remark: '主营业务收入 + 其他业务收入'
  }
  let expressCost:any = {
    id: -2,
    name: '业务成本',
    monthPrice: mainCost.monthPrice +otherCost.monthPrice+taxplus.monthPrice,
    yearPrice: mainCost.yearPrice +otherCost.yearPrice+taxplus.yearPrice,
    ...Object.fromEntries(companylist.map((item:any) => [item.id.toString(), mainCost[item.id.toString()] + otherCost[item.id.toString()] + taxplus[item.id.toString()]])),
    remark: '主营业务成本 +  其他业务成本 + 营业税金及附加'
  }
  let expressProfit:any = {
    id: -3,
    name: '业务毛利润',
    monthPrice: expressCollect.monthPrice - expressCost.monthPrice,
    yearPrice: expressCollect.yearPrice - expressCost.yearPrice,
    ...Object.fromEntries(companylist.map((item:any) => [item.id.toString(), expressCollect[item.id.toString()] - expressCost[item.id.toString()]])),
    remark: '业务收入 - 业务成本'
  }
  let marketProfit:any = {
    id: -4,
    name: '经营利润',
    monthPrice: expressProfit.monthPrice-manageFee.monthPrice-saleFee.monthPrice-finFee.monthPrice,
    yearPrice: expressProfit.yearPrice-manageFee.yearPrice-saleFee.yearPrice-finFee.yearPrice,
    ...Object.fromEntries(companylist.map((item:any) => [item.id.toString(), expressProfit[item.id.toString()] - manageFee[item.id.toString()] - saleFee[item.id.toString()] - finFee[item.id.toString()]])),
    remark: '业务毛利润-管理费用-销售费用-财务费用'
  }
  let profitMount:any = {
    id: -5,
    name: '利润总额',
    monthPrice: marketProfit.monthPrice+investProfile.monthPrice+outIncome.monthPrice-outCost.monthPrice,
    yearPrice: marketProfit.yearPrice+investProfile.yearPrice+outIncome.yearPrice-outCost.yearPrice,
    ...Object.fromEntries(companylist.map((item:any) => [item.id.toString(), marketProfit[item.id.toString()] + investProfile[item.id.toString()] + outIncome[item.id.toString()] - outCost[item.id.toString()]])),
    remark: '经营利润 + 投资收益 + 营业外收入 - 营业外支出'
  }
  let netProfit:any = {
    id: -6,
    name: '净利润',
    monthPrice: profitMount.monthPrice-taxfee.monthPrice,
    yearPrice: profitMount.yearPrice-taxfee.yearPrice,
    ...Object.fromEntries(companylist.map((item:any) => [item.id.toString(), profitMount[item.id.toString()] + taxfee[item.id.toString()]])),
    remark: '利润总额 - 所得税费用'
  }
  let divideRow = {
    id: -7,
    name: '',
    monthPrice:'--具体项目--',
    yearPrice:'--具体项目--',
    ...Object.fromEntries(companylist.map((item:any) => [item.id.toString(), '--具体项目--'])),
  }

  setRowList(() =>[expressCollect, expressCost, expressProfit, marketProfit, profitMount, netProfit, divideRow, ...modifylist])
  return modifylist
}

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '月份', prop:'ym',type:'datePicker',required:true,clearable:false,datepickerProps:{returnType:'string',type:'month'}},
]
//列首行
const columns:ColumnItem[] = [
{title: '项目',key: 'name',width: 220},
{title: '本月累计',key: 'monthPrice',width: 120,render: (value:any, record:any) => {
  return record.id > 0
  ?<Typography.Link onClick={()=>navigate('/indexpage/accountbook', {replace: true, state: {ymd:searchParams.ym,subjectId:record.id}})}>{isNaN(value) ? value: (value / 100).toFixed(2)}</Typography.Link>
  :isNaN(value) ? value: (value / 100).toFixed(2)
}},
{title: '本年累计',key: 'yearPrice',width: 120,render: (value:any, record:any) => {
  return record.id > 0
  ?<Typography.Link onClick={()=>navigate('/indexpage/accountbook', {replace: true, state: {year:searchParams.ym,subjectId:record.id}})}>{isNaN(value) ? value: (value / 100).toFixed(2)}</Typography.Link>
  :isNaN(value) ? value: (value / 100).toFixed(2)
}},
{title: '备注',key: 'remark'}
]

/* 接口参数 */
const dataRow = ():AxDataform => ({
  params: searchParams,
  axApi: gpProfitList,
  modifyFunc: modifyFunc,
  setTableLoading
})
const dataCompanyList:AxDataform = {
  axApi: getCompanyList,
  setList: setCompanyList,
  awaitFunc: (predata:any) => uniAxList({...dataRow(),predata:predata})
}
/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])
/* mounted */
React.useEffect(() => {
  uniAxList(dataCompanyList)
  setLoadFlag(()=>true)
}, [])

return (<>
  <TopSearch
    ref={searchRef}
    initialValues={{ym: moment()}}
    searchList={searchList}
    searchConfirm={searchConfirm}
  />
  <SuperTable
    rowList={rowList}
    tableLoading={tableLoading}
    dataRow={dataRow}
    columns={[...columns,...companyList.map((item:any)=>({title:item.name,key:item.id.toString(),width:120,render: (value:any, record:any) => {
      return record.id > 0
      ?<Typography.Link onClick={()=>navigate('/indexpage/accountbook', {replace: true, state: {ymd:searchParams.ym,companyId:item.id,subjectId:record.id}})}>{isNaN(value) ? value: (value / 100).toFixed(2)}</Typography.Link>
      :isNaN(value) ? value: (value / 100).toFixed(2)
    }}))]}
  />
</>)
}
