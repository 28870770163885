import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormInstance, Popconfirm, Table, TablePaginationConfig, Typography } from 'antd'
import { deleteMenu, getMenuTree } from '../../utils/api'
import { uniAxList, uniDeleteRow, uniModifyAxList, uniModifyRow, uniSelectRows } from '../../utils/uniFucs'
import tablecss from '../../css/custable.module.less'

export default function MenuSetting() {
  const pathname = "/indexpage/menusetting"
  /* hooks */
  let location = useLocation()
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [logModal, setLogModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [staticModal, setStaticModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [logList, setLogList] = useState([])
  const [rowList, setRowList] = useState([])
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({businessId:true,costTypeId:true,collectionPrice:true,payPrice:false,bothPrice:false,servicePrice:false,collectionAccountId:true,payAccountId:false,serviceAccountId:false,collectionTime:true,payTime:false,bothTime:false})
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [currentRow, setCurrentRow] = useState({} as any)
  const [searchParams, setSearchParams] = useState({} as any)
  const [tempSearchParams, setTempSearchParams] = useState({} as any)
  const [staffList, setStaffList] = useState([])
  const [feeTypeList, setFeeTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 11})
  const [accountList, setAccountList] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [unCashRows, setUnCashRows] = useState([] as any)
  const [isUnderLinePay, setIsUnderLinePay] = useState(true)
  const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
  const [cancelRemark, setCancelRemark] = useState('')
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [positionList, setPositionList] = useState([])
  const [custList, setCustList] = useState([])
  const [confirmItemVisible, setConfirmItemVisible] = useState({paytype: 2})
  const [confirmCompanyList, setConfirmCompanyList] = useState([])
  const [confirmModalInitial, setConfirmModalInitial] = useState({})
  const [currentConfirmRow, setCurrentConfirmRow] = useState({} as any)
  const [custCompanyList, setCustCompanyList] = useState([])
  const [addTableData, setAddTableData] = useState([])
  const [modalCpBusinessList, setModalCpBusinessList] = useState([])
  const [modalFeeTypeList, setModalFeeTypeList] = useState([])
  const [importModal, setImportModal] = useState(false)
  const [accountantSubjectList, setAccountantSubjectList] = useState([])
  const [businessList, setBusinessList] = useState([])
  const [currentSubjectId, setCurrentSubjectId] = useState({id:1})
  const [menuList, setMenuList] = useState([])
  const [loadFlag, setLoadFlag] = useState(false)
  //路由刷新请求
  const routerFlash = () => {
    uniAxList(uniRowData)
  }
  /* elements */
  //列项
  const columns:any = [
    {
      title: '菜单名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '排序',
      dataIndex: 'sort',
      key: 'sort'
    },
    /* {
      title: '操作',
      dataIndex: 'operation',
      fixed: 'right',
      width: 120,
      render: (_: any, record: any) => {
        return (
          <span>
            <Typography.Link onClick={() => uniModifyRow({record, setCurrentRow, setChgModal:setChgModal})} style={{ marginRight: 8 }}>修改</Typography.Link>
            <Popconfirm title={"确定删除该菜单吗?"} onConfirm={() => uniDeleteRow({record,deleteApi:deleteMenu,uniAxListData:uniRowData})} okText="确定" cancelText="取消">
              <a href="#">删除</a>
            </Popconfirm>
          </span>
        )
      }
    } */
  ]

  /* 接口参数 */
  const uniRowData:any = {
    params: searchParams,
    modifyFunc: uniModifyAxList,
    axApi: getMenuTree,
    setList: setRowList,
    setTableLoading
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    uniAxList(uniRowData)
    setLoadFlag(()=>true)
  }, [])
  return (
    <>
      <Table
        size='small'
        className={tablecss.height240}
        rowKey={'id'}
        columns={columns}
        rowSelection={{ ...uniSelectRows(setSelectedRowKeys, setSelectedRows), checkStrictly:true }}
        dataSource={rowList}
        pagination={false}
        scroll={{y:'calc(100vh - 240px)'}}
        loading={tableLoading}
        sticky
      />
    </>
  )
}
