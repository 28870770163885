import React, { Fragment, forwardRef, useEffect, useState } from 'react'
import { Modal, Form, Button, Input, Row, Col, Drawer, TreeSelect, Select, InputNumber, DatePicker, ConfigProvider, Upload, UploadFile } from 'antd';
import locale from 'antd/es/locale/zh_CN';
import moment from 'moment';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons'
import { csDateRages } from '../utils/constant';

type modalProp = {title:string, show:boolean,drawerSize?:number | string,formInitials?:any, cancel:any,overBody?:boolean, confirm:any, formItems:any[],showCustom?:boolean,modalLoading?:boolean,hasConfirm?:boolean,type?:'modal' | 'drawer',children?:any}
function FormModal(props: modalProp, ref: any) {
  /* constant */
  const {title, show, formInitials, cancel, confirm, formItems,modalLoading,hasConfirm=true,overBody=true,type='modal',drawerSize='60%',showCustom=true,children} = props
  console.log('children-------------',children)
  let buttonList = [<Button key="back" onClick={cancel}>取消</Button>]
  if (hasConfirm) buttonList.push(<Button loading={modalLoading} key="submit" type="primary" onClick={() => ref.current?.submit()}>确定</Button>)
  let initfiles:any = []
  let finditem = formItems.find((item:any)=>item.type==='upload')
  if (finditem && formInitials[finditem.prop] && formInitials[finditem.prop].length > 0) initfiles = formInitials[finditem.prop]
  /* data */
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([...initfiles])
  let [isinit, setIsinit] = useState(true)
  
  return (<>{
    type==='modal'
    ?<Modal
      width={1000}
      style={{height:'400px'}}
      maskClosable={false}
      title={title}
      visible={show}
      onOk={() => ref.current?.submit()}
      onCancel={cancel}
      afterClose={cancel}
      destroyOnClose
      footer={buttonList}
    >
      <div style={{height:400,display:'flex',flexDirection:'column'}}>
        {(children && showCustom)?children[0]:null}
        <ConfigProvider locale={locale}>
        <Form preserve={false} ref={ref} name="dialogform" initialValues={formInitials} onFinish={(formValus) => confirm(formValus)} autoComplete="off">
        <Row gutter={24}>
        {formItems.map((item:any) => (item.show===false?false:true) ? <Col span={item.span || 8} key={item.prop}>
          <div style={{display:'flex'}}>
            <Form.Item style={{height:50,flex:1}} label={item.label} name={item.prop} rules={item.rules ? item.rules :[{ required: item.required===false?false:true, message: item.requiredMsg || item.label }]}>{
              getElement(item,item.type,children,setPreviewOpen,setPreviewImage,setPreviewTitle,fileList,setFileList,initfiles,isinit,setIsinit)
            }</Form.Item>
            {item.suffix}
          </div>
        </Col> : null)}
        </Row>
        </Form>
        </ConfigProvider>
        {(children && showCustom)?children[1]:null}
      </div>
    </Modal>
    :<Drawer
      width={drawerSize}
      title={title}
      visible={show}
      onClose={cancel}
      getContainer={overBody?document.body:false}
      mask={overBody?true:false}
      destroyOnClose
      footer={buttonList}
      >
        <div style={{height:'100%',display:'flex',flexDirection:'column'}}>
          {(children && showCustom)?children[0]:null}
          <ConfigProvider locale={locale}>
          <Form preserve={false} ref={ref} name="drawerform" initialValues={formInitials} onFinish={(formValus) => confirm(formValus)} autoComplete="off">
            <Row gutter={24}>
            {formItems.map((item:any) => (item.show===false?false:true) ? <Col span={item.span || 8} key={item.prop}>
              <div style={{display:'flex'}}>
                <Form.Item style={{height:50,flex:1}} label={item.label} name={item.prop} dependencies={item.dependencies} hasFeedback={item.hasFeedback} rules={item.rules ? item.rules :[{ required: item.required===false?false:true, message: item.requiredMsg || item.label }]}>{
                  getElement(item,item.type,children,setPreviewOpen,setPreviewImage,setPreviewTitle,fileList,setFileList,initfiles,isinit,setIsinit)
                }</Form.Item>
                {item.suffix}
              </div>
            </Col>:null)}
            </Row>
          </Form>
          </ConfigProvider>
          {(children && showCustom)?children[1]:null}
        </div>
      </Drawer>}
      <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  )
}

const getBase64 = (file: any): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = (error) => reject(error);
})

const getElement = (item:any,type='input',children:any,setPreviewOpen:any,setPreviewImage:any,setPreviewTitle:any,fileList:any, setFileList:any,initfiles:any,isinit:boolean,setIsinit:any) => {
  let tempel
  if (type==='input') tempel = <Input allowClear={item.clearable===false?false:true} disabled={item.disabled} size='small' readOnly={item.readonly} placeholder={item.placeholder || '请输入'}></Input>
  else if (type==='select') tempel = (<Select
    disabled={item.disabled}
    mode={item.selectProps?item.selectProps.mode:null}
    style={{ width: '100%' }}
    size="small"
    maxTagCount='responsive'
    optionLabelProp={(item.selectProps && item.selectProps.mode==='multiple')?'label':''}
    allowClear={item.clearable===false?false:true}
    placeholder={item.placeholder || '请选择'}
    onChange={(value,operation)=>item.change?item.change(value,operation):null}
    showSearch
    filterOption={(inputkey:any,option:any)=>(option?.children ?? '').includes(inputkey)}
  > 
    {(typeof(item.dataList) === 'function'?item.dataList():item.dataList).map((listitem:any) => <Select.Option label={listitem[item.selectProps?.propName || 'name'].slice(0,1)} key={listitem[(item.selectProps && item.selectProps.propId)?item.selectProps.propId:'id']} value={listitem[(item.selectProps && item.selectProps.propId)?item.selectProps.propId:'id']}>
      {item.selectProps&&item.selectProps.childrenKeys
        ?item.selectProps.childrenKeys.map((keyitem:string)=>listitem[keyitem]).join(' ')
        :listitem[(item.selectProps&&item.selectProps.propName)?item.selectProps.propName:'name']
      }
    </Select.Option>)}
  </Select>)
  else if (type==='tree') tempel = (<TreeSelect
    disabled={item.disabled}
    size='small'
    showSearch
    treeNodeFilterProp={item.treeProps?.propName || 'name'}
    fieldNames={{value: item.treeProps?.propId || 'id', label: item.treeProps?.propName || 'name', title: item.treeProps?.propName || 'name'} as any}
    style={{ width: '100%' }}
    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
    placeholder={item.placeholder || '请选择'}
    allowClear={item.clearable===false ? false : true}
    treeDefaultExpandAll={item.treeProps?.expandAll}
    treeDefaultExpandedKeys={item.treeProps?.expandKeys}
    treeData={item.dataList}
    treeCheckable={item.treeProps?item.treeProps.checkable:null}
    treeCheckStrictly={item.treeProps?item.treeProps.strictMode:null}
    // onChange={(keyarr,labelarr,event)=>item.change?item.change(keyarr,labelarr,event.triggerNode.props):null}
    onSelect={(value:any, node:any)=>item.change?item.change(value, node):null}
  />)
  else if (type==='number' || type==='price') tempel = (<InputNumber disabled={item.disabled} style={{width:'100%'}} size='small' precision={item.numProps && item.numProps.precision ? item.numProps.precision : (type==='price'?2:0)} min={item.numProps && item.numProps.min ? item.numProps.min : 0} placeholder={item.placeholder || '请输入'} onChange={(value)=>item.change?item.change(value):null} />)
  else if (type==='datePicker') tempel = (<DatePicker
    style={{width:'100%'}}
    disabled={item.disabled}
    size='small'
    disabledDate={(current)=>(item.datepickerProps?.disabledDate)?(item.datepickerProps.disabledDate(current)):(current > moment().endOf('day'))}
    allowClear={item.clearable===false?false:true}
    picker={(item.datepickerProps && item.datepickerProps.type)?item.datepickerProps.type:'date'}
    onChange={item.change}
  />)
  else if (type==='dateRange') tempel = (<DatePicker.RangePicker
    style={{ width: '100%' }}
    allowClear={item.clearable===false?false:true}
    size="small"
    picker={(item.daterangeProps && item.daterangeProps.type)?item.daterangeProps.type:'date'}
    placeholder={['开始日期', '结束日期']}
    ranges={csDateRages}
    disabledDate={(current)=>(item.daterangeProps?.disabledDate)?(item.daterangeProps.disabledDate(current)):(current > moment().endOf('day'))}
  />)
  else if (type==='password') tempel = <Input.Password disabled={item.disabled} size='small' placeholder={item.placeholder} onChange={item.change || null}/>
  else if (type==='upload') tempel = <Upload
      listType={(item.uploadProps && item.uploadProps.listType) ?item.uploadProps.listType : 'picture-card'}
      accept={(item.uploadProps && item.uploadProps.accept)?item.uploadProps.accept:'jpg,.png,.jpeg'}
      onRemove={file => {
        const index = fileList.indexOf(file);
        const newList = fileList.slice();
        newList.splice(index, 1);
        setFileList(newList);
      }}
      onChange={({ fileList: newFileList }) =>{
        setFileList(newFileList)
        if (item.change) item.change([...newFileList],setIsinit)
      }}
      beforeUpload = {(file) => {
        // setFileList([...fileList, file])
        // if (item.change) item.change([...fileList, file])
        return false
      }}
      onPreview={async (file: UploadFile) => {
        if (!file.url && !file.preview) file.preview = await getBase64(file.originFileObj as any)
        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
      }}
      fileList={isinit ? initfiles : fileList}
    >
      {
        fileList.length >= (item.uploadProps && item.uploadProps.max ? item.uploadProps.max :1)
        ? null
        : item.uploadProps && item.uploadProps.listType && item.uploadProps.listType==='text'
          ?  <Button icon={<UploadOutlined />}>选择附件</Button>
          :<button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined /> <div style={{ marginTop: 8 }}>上传</div>
          </button>
      }
    </Upload>
  else if (type==='slot') tempel = item.slotProps
  return tempel
}

export default forwardRef(FormModal)
