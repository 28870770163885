import React, { createRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker, Form, FormInstance, InputNumber, message, Modal, Progress, Radio, Select, TablePaginationConfig, Typography } from 'antd'
import { CheckCircleTwoTone,CloseCircleTwoTone } from '@ant-design/icons'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';
import QRCode from 'qrcodejs2'

import { createSiteBank, getAlichargeLink, getBankInfo, getCompanyList, getHomeBalance, getHomeFindata, getHomeFindataYear, getHomeReview, getHomeYjdata, getSiteBankList, getWxchargeLink, userLoginOut, wxOrderQuery } from '../utils/api'
import { uniAddRowSubmit, uniAxList, uniAxSearchList, uniCurrentTime, uniFilterItem } from '../utils/uniFucs'
import maincss from '../css/main.module.less';
import MainEcharts from '../components/MainEcharts';
import Input from 'antd/lib/input/Input';
import { privateTypes } from '../utils/constant';
import { NameValue } from '../utils/beans';
import { getLocalItem, getUserLocal } from '../utils/localstorage';

export default function Main(props:any) {
  const initModalItemVisible = {otherValue:false}
  const menuObj = getLocalItem('userMenu', [])
  /* hooks */
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const addModalRef = createRef<FormInstance>()
  const qrcoderef = createRef<any>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [staticModal, setStaticModal] = useState(false)
  const [depoModal, setDepoModal] = useState(false)
  const [chargeModal, setChargeModal] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [rowList, setRowList] = useState([])
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({otherValue:false} as any)
  const [chargeStatusVisible, setChargeStatusVisible] = useState({form:true,qrcode:false,success:false,fail:false})
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [currentRow, setCurrentRow] = useState({} as any)
  const [searchParams, setSearchParams] = useState({} as any)
  const [tempSearchParams, setTempSearchParams] = useState({} as any)
  const [balanceParams,setBalanceParams] = useState({} as any)
  const [tempBalanceParams,seTemptBalanceParams] = useState({} as any)
  const [custParams,setCustParams] = useState({ym:moment().subtract(1,'month').format('YYYY-MM')} as any)
  const [tempCustParams,setTempCustParams] = useState({ym:moment().subtract(1,'month').format('YYYY-MM')} as any)
  const [staffList, setStaffList] = useState([])
  const [reinburseTypeList, setReinburseTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 10})
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [staffAccounts, setStaffAccounts] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [unCashRows, setUnCashRows] = useState([] as any)
  const [isUnderLinePay, setIsUnderLinePay] = useState(true)
  const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
  const [businessList, setBusinessList] = useState([])
  const [cancelRemark, setCancelRemark] = useState('')
  const [balanceData, setBalanceData] = useState({} as any)
  const [reviewData, setReviewData] = useState({} as any)
  const [finData, setFinData] = useState({} as any)
  const [yjData, setYjData] = useState({} as any)
  const [finYearData, setFinYearData] = useState({incomeList:[],costList:[],profitList:[],profitRateList:[]} as any)
  const [isBalanceTotal, setIsBalanceTotal] = useState({value:true})
  const [balanceCompany, setBalanceCompany] = useState({value:[]} as any)
  const [isMonthTotal, setIsMonthTotal] = useState({value:true})
  const [monthCompany, setMonthCompany] = useState({value:[] as any,year:uniCurrentTime({month:false,day:false})})
  const [siteBankList, setSiteBankList] = useState([])
  const [bankList, setBankList] = useState([])
  const [addBankLoading, setAddBankLoading] = useState(false)
  const [chargeMoney, setChargeMoney] = useState({value:''})
  const [buttonDisable,setButtonDisable] = useState({value:false})
  const [wxquerytimer,setWxquerytimer] = useState({value:null} as any)
  /* funs */
  const modifyFinYear = (dataList:any) => {
    const monthIndex = Number(uniCurrentTime({day:false}).split('-')[1])
    let incomeList = [0,0,0,0,0,0,0,0,0,0,0,0].slice(0,monthIndex)
    let costList = [0,0,0,0,0,0,0,0,0,0,0,0].slice(0,monthIndex)
    let profitList = [0,0,0,0,0,0,0,0,0,0,0,0].slice(0,monthIndex)
    let profitRateList = [0,0,0,0,0,0,0,0,0,0,0,0].slice(0,monthIndex)
    dataList.forEach((item:any) => {
      let itemIndex = Number(item.ym.split('-')[1]) - 1
      incomeList[itemIndex] = Number((item.inCome / 1000000).toFixed(2))
      costList[itemIndex] = Number((item.cost / 1000000).toFixed(2))
      profitList[itemIndex] = Number((item.np / 1000000).toFixed(2))
      profitRateList[itemIndex] = Number((item.np * 100/item.inCome).toFixed(2))
    })
    return {incomeList,costList,profitList,profitRateList}
  }
  //获取二维码
  const getWxQrcode = async (formValus:any) => {
    setModalLoading(()=>true)
    let priceValue = formValus.otherValue || formValus.chargeValue
    chargeMoney.value = priceValue.toString()
    let linkres
    let qrCode = ''
    let outTradeNo = ''
    if (formValus.chargeWay === 1) {
      linkres = await getWxchargeLink({amount:priceValue*100,billType:2})
      qrCode = linkres.data['code_url']
      outTradeNo = linkres.data['out_trade_no']
    } else if (formValus.chargeWay === 2) {
      linkres = await getAlichargeLink({amount:priceValue*100,billType:2})
      qrCode = linkres.data['qrCode']
      outTradeNo = linkres.data['outTradeNo']
    }
    console.log('linkresss------------------',linkres)
    new QRCode(document.getElementById('qrcoderef'), {
      width: 170,                     //二维码的宽度
      height: 170,                    //二维码的高度
      textAlign: 'center',
      text:qrCode,    //需要生成二维码的支付链接
      colorDark: '#000',              //二维码颜色
      colorLight: '#fff',             //二维码底色
    })
    buttonDisable.value = true
    setModalLoading(()=>false)
    setChargeStatusVisible(()=>({form:false,qrcode:true,success:false,fail:false}))
    getOrderstate(outTradeNo)
  }
  //扫描微信二维码后
  const getOrderstate = (outTradeNo:string) => {
    let num = 0
    wxquerytimer.value = setInterval(() => {
      if (num === 500) {
        closeBoxShowPrices()
      }
      num ++
      wxOrderQuery({rechargeNo:outTradeNo}).then((res:any) => {
        if (res.data && res.data === true) {
          closeBoxShowSuccess()
          return
        } else if (res.data && res.data === false) {
          return
        }
          // else closeBoxShowWrong()
      }).catch((error:any) => closeBoxShowWrong())
    }, 1500)
    // setWxquerytimer(()=>({value:timer}))
  }
  //扫码成功后显示成功信息
  const closeBoxShowSuccess = () => {
    setChargeStatusVisible(()=>({form:false,qrcode:false,success:true,fail:false}))
    if (wxquerytimer.value) clearInterval(wxquerytimer.value)
    const qrcodediv:any = document.getElementById('qrcoderef')
    qrcodediv.removeChild(qrcodediv.children[1])
    uniAxList(uniBalanceData)
    setTimeout(() => {
      setChargeModal(()=>false)
    }, 3000)
  }
  //扫码失败后跳转失败界面
  const closeBoxShowWrong = () => {
    setChargeStatusVisible(()=>({form:false,qrcode:false,success:false,fail:true}))
    if (wxquerytimer.value) clearInterval(wxquerytimer.value)
    const qrcodediv:any = document.getElementById('qrcoderef')
    qrcodediv.removeChild(qrcodediv.children[1])
  }
  //超市不扫码跳转到form表单界面
  const closeBoxShowPrices = () => {
    buttonDisable.value = false
    setChargeStatusVisible(()=>({form:true,qrcode:false,success:false,fail:false}))
    if (wxquerytimer.value) clearInterval(wxquerytimer.value)
    const qrcodediv:any = document.getElementById('qrcoderef')
    // console.log('children', qrcode.children)
    qrcodediv.removeChild(qrcodediv.children[1])
  }
  /* elements */
  //提现modal
  const depoItems = [
    {
      label: '可提现金额',
      rules: [{ required: false }],
      element: <span>{balanceData.siteMoney/100}</span>
    },
    {
      label: '最低提现金额',
      rules: [{ required: false }],
      element: <span>1000</span>
    },
    {
      label: '最高提现金额',
      rules: [{ required: false }],
      element: <span>20000</span>
    },
    {
      label: '请选择银行',
      element: <div style={{display:'flex'}}>
        <Form.Item rules={[{ required: true, message: '银行不能为空' }]}>
          <Select
            showSearch
            optionFilterProp='children'
            allowClear
            style={{ width: 400 }}
            placeholder="选择银行"
            onChange={(value) => {}}
          > 
            {siteBankList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.bankName+'      '+item.name+'(****'+item.bankNo.substr(-4)+')'}</Select.Option>)}
          </Select>
        </Form.Item>
        <Button type='primary' onClick={()=>setAddModal(()=>true)}>添加银行卡</Button>
      </div>
    },
  ]
  //充值modal
  const chargeItems = [
    {
      label: '充值金额',
      name:'chargeValue',
      rules: [{ required: true, message: '金额不能为空' }],
      element: <Radio.Group size="large" onChange={(event) => {
        if (event.target.value === -1) setModalItemVisible(() => ({...modalItemVisible,otherValue:true}))
        else setModalItemVisible(() => ({...modalItemVisible,otherValue:false}))
      }}>
        <Radio.Button value={200}>¥200</Radio.Button>
        <Radio.Button value={1000}>¥1000</Radio.Button>
        <Radio.Button value={3000}>¥3000</Radio.Button>
        <Radio.Button value={-1}>其他</Radio.Button>
      </Radio.Group>
    },
    {
      label: '其他金额',
      name:'otherValue',
      rules: [{ required: true, message: '充值金额不能为空' }],
      element: <InputNumber precision={2} min={0} style={{width:280}}></InputNumber>
    },
    {
      label: '充值方式',
      name:'chargeWay',
      rules: [{ required: true, message: '充值方式不能为空' }],
      element: <Radio.Group size="large" onChange={() => {}}>
                <Radio value={1}>微信</Radio>
                <Radio value={2}>支付宝</Radio>
              </Radio.Group>
    },
  ]

  /* elements */
  const bankItems = [
    {
      label: '选择银行',
      name: 'bankCode',
      rules: [{ required: true, message: '银行不能为空' }],
      element: <Select
        showSearch
        optionFilterProp='children'
        allowClear
        style={{ width: '100%' }}
        placeholder="选择银行"
        onChange={(value,operation:any) => {
          setCustomAddKeys(()=>({...customAddKeys,bankName:value?operation.children:null}))
        }}
      > 
        {bankList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.bankName}</Select.Option>)}
      </Select>
    },
    {
      label: '银行卡号',
      name: 'bankNo',
      rules: [{ required: true, message: '银行卡号不能为空' }],
      element: <Input placeholder="请输入银行卡号" />
    },
    {
      label: '银行账户名',
      name: 'name',
      rules: [{ required: true, message: '账户名不能为空' }],
      element: <Input placeholder="请输入账户名" />
    },
    {
      label: '公私类型',
      name: 'accountType',
      rules: [{ required: true, message: '公私类型不能为空' }],
      element: <Radio.Group>
                {privateTypes.map((item:NameValue) => <Radio key={item.value} value={item.value}>{item.name}</Radio>)}
              </Radio.Group>
    },
  ]

  /* 接口参数 */
  const uniBalanceData:any = {
    axApi: getHomeBalance,
    setList: setBalanceData
  }
  const uniReviewData:any = {
    axApi: getHomeReview,
    setList: setReviewData
  }
  const uniFinData:any = {
    params: searchParams,
    axApi: getHomeFindata,
    setList: setFinData
  }
  const uniYjData:any = {
    params:custParams,
    axApi: getHomeYjdata,
    setList: setYjData,
  }
  const uniCompanyData:any = {
    axApi: getCompanyList,
    setList: setCompanyList,
    awaitFunc:(predata:any)=>{
      if (predata && predata.length===1) {
        balanceCompany.value = [predata[0].id]
        monthCompany.value = [predata[0].id]
      }
    }
  }
  const uniFinYearData:any = {
    params:{year:uniCurrentTime({month:false,day:false})},
    axApi: getHomeFindataYear,
    modifyFunc: modifyFinYear,
    setList: setFinYearData,
  }
  const uniSiteBankData:any = {
    axApi: getSiteBankList,
    setList: setSiteBankList,
  }
  const uniBankData:any = {
    axApi: getBankInfo,
    setList: setBankList,
  }
  /* mounted */
  React.useEffect(() => {
    if (menuObj[0] && menuObj[0].code === "PC_HOME") {
      uniAxList(uniBalanceData)
      uniAxList(uniReviewData)
      uniAxList(uniFinData)
      uniAxList(uniCompanyData)
      uniAxList(uniYjData)
      uniAxList(uniFinYearData)
    }
  }, [])
  /* willUnmount */
  React.useEffect(() => {
    return () => clearInterval(wxquerytimer.value)
  }, [])

  return (
    menuObj[0] && menuObj[0].code === "PC_HOME"
    ?<>
      <div className={maincss.center}>
      <div className={maincss.row1}>
        <div className={maincss.row1item}>
          {/* 查询行 */}
          <div>
            <ConfigProvider locale={locale}>
              <DatePicker disabledDate={(current)=>{return current && current > moment().endOf('day')}} defaultValue={moment(uniCurrentTime({}), 'YYYY-MM-DD')} onChange={(date, dateString)=>seTemptBalanceParams(()=>({...tempBalanceParams,ymd:dateString || null}))}/>
            </ConfigProvider>&nbsp;
            {/* <Radio.Group defaultValue={1} onChange={(event) => {
              if (event.target.value === 1) {
                balanceCompany.value = null
                isBalanceTotal.value = true
                seTemptBalanceParams(()=>({...tempBalanceParams,companyId:null}))
              } else if (event.target.value === 2) setIsBalanceTotal(()=>({value:false}))
            }}>
              <Radio key={1} value={1}>汇总</Radio>
              <Radio key={2} value={2}>单公司</Radio>
            </Radio.Group> */}
            <Select
              // disabled={isBalanceTotal.value}
              value={balanceCompany.value}
              mode='multiple'
              maxTagCount='responsive'
              showSearch
              optionFilterProp='children'
              optionLabelProp="label"
              allowClear
              style={{ width: 229 }}
              placeholder="选择公司(不选返回全部)"
              onChange={(companyIdarr) => {
                balanceCompany.value = companyIdarr
                seTemptBalanceParams(() => ({...tempBalanceParams, companyId:companyIdarr || null}))
              }}
            > 
              {companyList.map((item:any) => <Select.Option label={companyList.length>1?item.name.slice(0,1):item.name} key={item.id} value={item.id}>{item.name}</Select.Option>)}
            </Select>&nbsp;
            <Button onClick={() => uniAxSearchList({setSearchParams:setBalanceParams, uniRowData:uniBalanceData, params: tempBalanceParams})} type='primary'>查询</Button>&nbsp;
          </div>
          {/* 余额充值行 */}
          <div style={{marginTop:20,display:'flex',justifyContent:'space-around',alignItems:'flex-end'}}>
            <ul className={maincss.itemul}>
            {/* <li><div className={maincss.subtitle}>总部余额 (元)</div><div className={maincss.money}>{balanceData.totalMoney?(balanceData.totalMoney/100).toFixed(2):0}</div></li> */}
              <li style={{flex:4}}><div className={maincss.subtitle}>账户总余额 (元)</div><div className={maincss.money}>{(balanceData.money/100).toFixed(2)}</div></li>
              <li style={{flex:3}}><div className={maincss.subtitle}>流入金额 (元)</div><div className={maincss.money}>{(balanceData.inMoney/100).toFixed(2)}</div></li>
              <li style={{flex:3}}><div className={maincss.subtitle}>流出金额 (元)</div><div className={maincss.money}>{(balanceData.outMoney/100).toFixed(2)}</div></li>
              {/* <li><div className={maincss.subtitle}>系统余额 (元)</div><div className={maincss.money}>{(balanceData.siteMoney/100).toFixed(2)}</div></li> */}
            </ul>
            {/* <div>
              <Button style={{backgroundColor:'orange',border:'1px solid orange',color:'#fff'}} onClick={()=>{setCustomAddKeys(()=>[]);uniAxList(uniBankData);uniAxList(uniSiteBankData);setDepoModal(()=>true)}}>提现</Button>&nbsp;
              <Button type='primary' onClick={()=>{setModalItemVisible(()=>initModalItemVisible);buttonDisable.value=false;setChargeStatusVisible(()=>({form:true,qrcode:false,success:false,fail:false}));setChargeModal(()=>true)}}>充值</Button>
            </div> */}
          </div>
        </div>
        <div className={maincss.row1item}>
        <p className={maincss.title}>待审批项</p>
          <ul className={maincss.itemul}>
            <li className={maincss.itemli}>
              <span className={maincss.bigfont}>{reviewData.companyBillCount}</span>
              <div className={maincss.subtitle}><div>收支</div><div>审批</div></div>
            </li>
            <li className={maincss.itemli}>
              <span className={maincss.bigfont}>{reviewData.finCount}</span>
              <div className={maincss.subtitle}><div>财务</div><div>报销</div></div>
            </li>
            <li className={maincss.itemli}>
              <span className={maincss.bigfont}>{reviewData.materielCount}</span>
              <div className={maincss.subtitle}><div>物料</div><div>申领</div></div>
            </li>
            <li className={maincss.itemli}>
              <span className={maincss.bigfont}>{reviewData.rechargeCount}</span>
              <div className={maincss.subtitle}><div>充值</div><div>提现</div></div>
            </li>
          </ul>
        </div>
      </div>
      <div className={maincss.row2}>
        <div className={maincss.headerwrap}>
          <div className={maincss.row2header}>月度财务驾驶舱</div>
          <div>
          <ConfigProvider locale={locale}>
            <DatePicker disabledDate={(current)=>{return current && current > moment().endOf('day')}} placeholder='选择月份' picker='month' defaultValue={moment(uniCurrentTime({day:false}), 'YYYY-MM')} onChange={(date, dateString)=>{
              setTempSearchParams(() => ({...tempSearchParams, ym: dateString || null}))
            }}/>
          </ConfigProvider>&nbsp;
          {/* <Radio.Group defaultValue={1} onChange={(event) => {
            if (event.target.value === 1) {
              monthCompany.value = null
              isMonthTotal.value = true
              setTempSearchParams(()=>({...tempSearchParams,companyId:null}))
            } else if (event.target.value === 2) setIsMonthTotal(()=>({value:false}))
          }}>
            <Radio key={1} value={1}>汇总</Radio>
            <Radio key={2} value={2}>单公司</Radio>
          </Radio.Group> */}
          <Select
              // disabled={isMonthTotal.value}
              value={monthCompany.value}
              mode='multiple'
              maxTagCount='responsive'
              showSearch
              optionFilterProp='children'
              optionLabelProp="label"
              allowClear
              style={{ width: 229 }}
              placeholder="选择公司(不选返回全部)"
              onChange={(companyIdarr) => {
                monthCompany.value = companyIdarr
                setTempSearchParams(() => ({...tempSearchParams, companyId:companyIdarr || null}))
              }}
            > 
              {companyList.map((item:any) => <Select.Option label={companyList.length>1?item.name.slice(0,1):item.name} key={item.id} value={item.id}>{item.name}</Select.Option>)}
            </Select>&nbsp;
          <Button onClick={() => {
            console.log('tempSearchParams-------',tempSearchParams)
            let yeartime = tempSearchParams.ym ? tempSearchParams.ym.split('-')[0] : uniCurrentTime({month:false,day:false})
            monthCompany.year = yeartime
            uniAxSearchList({setSearchParams, uniRowData:uniFinData, params: tempSearchParams})
            uniAxList({...uniFinYearData,params:{companyId:tempSearchParams.companyId,year:yeartime}})
          }} type='primary'>查询</Button>&nbsp;
          </div>
        </div>
        <ul className={maincss.row2ul}>
          <li style={{flex:1}}>
            <p className={maincss.row2p}>丨&nbsp;&nbsp;&nbsp;业务总收入&nbsp;&nbsp;&nbsp;¥&nbsp;{(finData.inCome/100).toFixed(2)}</p>
            <p className={maincss.row2p}>丨&nbsp;&nbsp;&nbsp;业务总成本&nbsp;&nbsp;&nbsp;¥&nbsp;{(finData.cost/100).toFixed(2)}</p>
          </li>
          <li style={{flex:1}}>
            <p className={maincss.row2p}>丨&nbsp;&nbsp;&nbsp;毛利润额&nbsp;&nbsp;&nbsp;¥&nbsp;{(finData.kdGp/100).toFixed(2)}</p>
            <div className={maincss.row2div}>丨&nbsp;&nbsp;&nbsp;毛利润率&nbsp;&nbsp;&nbsp;<div style={{width:'150px'}}><Progress strokeWidth={15} trailColor='#ddd' strokeLinecap="butt" percent={Number((finData.gp * 100/finData.inCome).toFixed(2))}/></div></div>
          </li>
          <li style={{flex:1}}>
            <p className={maincss.row2p}>丨&nbsp;&nbsp;&nbsp;净利润额&nbsp;&nbsp;&nbsp;¥&nbsp;{(finData.np/100).toFixed(2)}</p>
            <div className={maincss.row2div}>丨&nbsp;&nbsp;&nbsp;净利润率&nbsp;&nbsp;&nbsp;<div style={{width:'150px'}}><Progress strokeWidth={15} trailColor='#ddd' strokeLinecap="butt" percent={Number((finData.np * 100/finData.inCome).toFixed(2))}/></div></div>
          </li>
        </ul>
      </div>
      <div className={maincss.row3}>
        <div style={{flex:3}}>
          <MainEcharts finYearData={finYearData} year={monthCompany.year}/>
        </div>
        <div style={{flex:1}} className={maincss.row3yj}>
          <div style={{display:'flex'}}>
            <div className={maincss.row3header}>月结客户</div>&nbsp;
            <ConfigProvider locale={locale}>
              <DatePicker disabledDate={(current)=>{return current && current > moment().endOf('day')}} placeholder='选择月份' picker='month' defaultValue={moment(moment().subtract(1,'month'), 'YYYY-MM')} onChange={(date, dateString)=>setTempCustParams(() => ({...tempCustParams, ym: dateString || null}))}/>
            </ConfigProvider>&nbsp;
            <Button onClick={() => uniAxSearchList({setSearchParams:setCustParams, uniRowData:uniYjData, params: tempCustParams})} type='primary'>查询</Button>&nbsp;
          </div>
          <ul className={maincss.row3ul}>
            <li>
              <p className={maincss.row3p}>丨&nbsp;&nbsp;&nbsp;应收总额&nbsp;&nbsp;&nbsp;¥&nbsp;{(yjData.shouldMoneyAmount/100).toFixed(2)}</p>
              <p className={maincss.row3p}>丨&nbsp;&nbsp;&nbsp;已收总额&nbsp;&nbsp;&nbsp;¥&nbsp;{(yjData.hadMoneyAmount/100).toFixed(2)}</p>
              <p className={maincss.row3p}>丨&nbsp;&nbsp;&nbsp;欠款总额&nbsp;&nbsp;&nbsp;<Typography.Link onClick={()=>navigate('/indexpage/custmonthclear', {replace: true, state: {billMonth:custParams.ym}})}>{'¥ '+((yjData.shouldMoneyAmount-yjData.hadMoneyAmount)/100).toFixed(2)}</Typography.Link></p>
              <p className={maincss.row3p}>丨&nbsp;&nbsp;&nbsp;预付款余额&nbsp;&nbsp;&nbsp;¥&nbsp;{(yjData.prePayMoney/100).toFixed(2)}</p>
            </li>
            <li>
              <Progress trailColor='#ddd' type="circle" percent={Number((yjData.hadMoneyAmount * 100/yjData.shouldMoneyAmount).toFixed(2))} />
              <div style={{fontWeight:'bold',paddingLeft:'40px',textAlign:'left'}}>回款率</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Modal
      width={800}
      title='充值'
      visible={chargeModal}
      onOk={() => formModalRef.current?.submit()}
      onCancel={()=> {
        console.log('oncancle------------------',wxquerytimer)
        clearInterval(wxquerytimer.value)
        setChargeModal(()=>false)
      }}
      destroyOnClose
      footer={[<Button key="back" onClick={()=>{
        if (wxquerytimer.value) clearInterval(wxquerytimer.value)
        setChargeModal(false)
      }}>取消</Button>, <Button disabled={buttonDisable.value} loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}
    >
    <div style={{height:192}}>
      <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
        <div id='qrcoderef' ref={qrcoderef}></div>
      </div>
      {
        chargeStatusVisible.form
          ?<Form
            preserve={false}
            ref={formModalRef}
            name="form"
            labelCol={{ span: 6 }}
            initialValues={{chargeValue:0.01,chargeWay:1}}
            wrapperCol={{ span: 16 }}
            onFinish={(formValus) => {
              console.log('formvalues----------',formValus)
              if (formValus.otherValue !==null && formValus.otherValue <=0) message.error('充值金额必须大于0')
              else getWxQrcode(formValus)
            }}
            autoComplete="off"
          >
            {chargeItems.map((item:any) => (modalItemVisible && item.name in modalItemVisible && !modalItemVisible[item.name]) ? null : <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
          </Form>
        :chargeStatusVisible.qrcode
          ? <p style={{textAlign:'center'}}>扫描二维码支付 ¥ {chargeMoney.value}</p>
          : chargeStatusVisible.success
            ? <div style={{width:'100%',height:190,fontSize:25,display:'flex',justifyContent:'center',alignItems:'center'}}>
                <CheckCircleTwoTone twoToneColor="#52c41a"/>&nbsp;
                <span>支付成功, 即将跳转... ...</span>
              </div>
            : <div style={{width:'100%',height:190,fontSize:25,display:'flex',justifyContent:'center',alignItems:'center'}}>
                <CloseCircleTwoTone twoToneColor="#eb2f96"/>
                <span>支付失败, 请稍后重试！</span>
              </div>
      }
    </div>
    </Modal>
    <Modal
      width={800}
      title='提现申请'
      visible={depoModal}
      onOk={() => formModalRef.current?.submit()}
      onCancel={()=> setDepoModal(()=>false)}
      destroyOnClose
      footer={[<Button key="back" onClick={()=>setDepoModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}
    >
      <Form
          preserve={false}
          ref={formModalRef}
          name="form"
          labelCol={{ span: 6 }}
          initialValues={{}}
          wrapperCol={{ span: 16 }}
          onFinish={async (formValus) => {}}
          autoComplete="off"
        >
          {depoItems.map((item:any) => <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
        </Form>
    </Modal>
    <Modal
        width={600}
        maskClosable={false} title={'添加银行卡'}
        visible={addModal}
        onOk={() => addModalRef.current?.submit()}
        onCancel={()=>setAddModal(false)}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>setAddModal(false)}>取消</Button>, <Button loading={addBankLoading} key="submit" type="primary" onClick={() => addModalRef.current?.submit()}>确定</Button>]}
      >
        <Form
          preserve={false}
          ref={addModalRef}
          name="form"
          labelCol={{ span: 6 }}
          initialValues={{}}
          wrapperCol={{ span: 16 }}
          onFinish={async (formValus) => {
            let userItem = getUserLocal()
            let axValues = {...formValus,userType:2,userId:0,siteId:userItem.siteId}
            uniAddRowSubmit({axGetData:uniSiteBankData,axAddApi:createSiteBank, setAddModal,setModalLoading:setAddBankLoading, customAddKeys})(axValues)
          }}
          autoComplete="off"
        >
          {bankItems.map((item:any) =><Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
        </Form>
      </Modal>
    </>
    :<h1>欢迎访问</h1>
  )
}
