import React, { createRef, useState } from 'react'
import { Button, ConfigProvider, Form, FormInstance, Input, InputNumber, message, Modal, Pagination, Popconfirm, Radio, RadioChangeEvent, Select, Table, TablePaginationConfig, Tooltip, TreeSelect, Typography } from 'antd'
import locale from 'antd/es/locale/zh_CN';

import { getCostTypeTree, getBusinessList, axSubjectList, createCostType, updateCostType, deleteCostType, getCompanyList, axCtypeBindBtype, axCostTypeCode } from '../../../utils/api'
import { AccountSubjectItem, FeeTypeItem, ModifyParams, NameValue } from '../../../utils/beans'
import { uniAddRowSubmit, uniAxList, uniAxSearchList, uniChgRowSubmit, uniCurrentTime, uniDeepArray, UniDeleteAll, uniDeleteRow, uniExportExcel, uniFilterItem, uniFilterWithPa, uniFormProcess, uniInitFormdata, uniModifyAxList, uniModifyDpList, uniModifyRow, uniOpAlarm, uniQuitModal, uniSelectRows, uniValueToName } from '../../../utils/uniFucs'
import TextArea from 'antd/lib/input/TextArea'
import UniOperationColumn from '../../../components/UniOperationColumn'
import { adaptTypes, banOrNot, forbidenStatus, incomeTypes, proofTypeList, staffCashType } from '../../../utils/constant'
import { useLocation } from 'react-router-dom'
import tablecss from '../../../css/custable.module.less'
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../../utils/interfaces';
import TopSearch from '../../../components/TopSearch';
import SuperTable from '../../../components/SuperTable';
import FormDialog from '../../../components/FormDialog';

export default function FeeTypeSetting() {
const pathname = "/indexpage/feetypesetting"
const searchRef = createRef<FormInstance>()
const modalRef = createRef<FormInstance>()
const initSearchParams = {page:1}
const initForminitial = {}
  /* hooks */
  let location = useLocation()
  const formModalRef = createRef<FormInstance>()
  const [rowList, setRowList] = useState([])
  const [businessList, setBusinessList] = useState([])
  const [accountantSubjectList, setAccountantSubjectList] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([] as any)
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
  let [searchParams, setSearchParams] = useState({...initSearchParams} as any)
  const [tempSearchParams, setTempSearchParams] = useState({} as any)
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [selectTreeNode, setSelectTreeNode] = useState({} as any)
  const [showAccountSubject, setShowAccountSubject] = useState(true)
  const [currentRow, setCurrentRow] = useState({} as any)
  const [costTypeList, setCostTypeList] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [loadFlag, setLoadFlag] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [exportLoading, setExportLoading] = useState(false)
  const [modalFlag, setModalFlag] = useState('new')
  let [formInitials, setFormInitials] = useState({...initForminitial})
  const [showModal, setShowModal] = useState(false)
  const [modalSubjectList, setModalSubjectList] = useState([])

  /* funcs */
  const searchConfirm = async (formValus:any) => {setPagination({...pagination,current:1});
    // searchParams = {...initSearchParams,...formValus}
    // setSelectedRows([])
    // setSelectedRowKeys([])
    searchParams = await uniFormProcess({...initSearchParams,...formValus},searchList)
    setSearchParams(searchParams)
    uniAxList(dataRow(1))
  }
  const modalConfirm = async (formValues:any) => {
    let tempparms = await uniFormProcess(formValues,formItems)
    if (modalFlag === 'new') uniAxList(dataNewrow(tempparms))
    else if (modalFlag === 'update') {
      setModalLoading(true)
      let promiseFuncs = [uniAxList(dataUpdaterow(tempparms))]
      if (formValues.businessIdList.join(',') !== currentRow.businessList.map((item:any)=>item.businessId).join(',')) {
        promiseFuncs.push(uniAxList(dataCtypeBindBtype({costTypeId:currentRow.id, businessIdList:formValues.businessIdList})))
      }
      Promise.all(promiseFuncs).then(res => {
        setModalLoading(false)
        setShowModal(false)
        uniAxList(dataRow())
      })
    }
  }
  //路由刷新请求
  const routerFlash = () => {
    // uniAxList(uniAsData)
    uniAxList(dataRow())
    uniAxList(uniBusininessData)
    uniAxList(uniCompanyData)
  }
  //凭证生成方式变化
  const accountTypeChange = (event:RadioChangeEvent) => {
    if (event.target.value === 1) setShowAccountSubject(() => true)
    else if (event.target.value === 3) setShowAccountSubject(() => false)
  }
  //批量删除
  const onBatchDelete = () => {
    let deleteKeys:any = []
    selectedRows.forEach((item:any) => {
      if (item.canDelete === 1) deleteKeys.push(item.id)
    })
    if (deleteKeys.length <= 0) message.info('没有可以删除的记录')
    else UniDeleteAll({deleteKeyList:selectedRowKeys, deleteApi:deleteCostType, setSelectedRowKeys,axParams:dataRow()})
  }
/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '费用名', prop:'name'},
{label: '公司', prop:'companyId',type:'select',dataList:companyList},
{label: '所属业务', prop:'businessId',type:'select',dataList:businessList},
{label: '会计科目', prop:'subjectId',type:'tree',width:220,treeProps:{propName:'title'},dataList:accountantSubjectList},
]
//列首行
const columns:ColumnItem[] = [
{title: '类型名', key: 'name', width: 200},
{title: '编码', key: 'costNo'},
{title: '启用状态', key: 'status', type:'tag',custval:({row,value}:any)=>uniValueToName({list:forbidenStatus,value})},
{title: '所属公司', key: 'companyName'},
{title: '关联会计科目',key: 'subjectId',width: 300,custval:({row,value}:any)=>{
  let {findItem, parents}:any = uniFilterWithPa(accountantSubjectList,value)
  if (parents && parents.length>0) return parents[0].name + '-' + findItem.name
  else if (findItem) return findItem.name
}},
{title: '所属业务', key: 'businessList',width:300,custval:({row,value}:any)=>value?value.map((item:any)=>item.businessName ).join(','):''},
{title: '收支类型',key: 'incomeType',type:'tag',tagProps:{values:[2,1]},custval:({row,value}:any)=>uniValueToName({list:staffCashType,value})},
{title: '排序',key: 'sort'},
{title: '创建时间',key: 'createDate'},
{title: '备注',key: 'remark'},
{title: '操作',key:'operation',type: 'operation',width:100,opProps:{opList:[
  {name:'修改',disabled:({row}:any)=>row.canUpdate !== 1,action:({row}:any) => {
    setFormInitials({...uniInitFormdata(row,formItems),businessIdList:row.businessList.map((item:any)=>item.businessId)})
    setModalFlag('update')
    setCurrentRow(row)
    setShowModal(true)
    uniAxList(dataSubjectList(row.companyId))
  }},
  {name:'删除',disabled:({row}:any)=>row.canDelete !== 1,action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataDelrow(row.id)))}
]}},
]
//底部操作
const bottomActions:ColumnAction[] = [
{label: '新增', type:'primary', action: async () => {
  let resdata = await uniAxList(dataMaxcode())
  let costNo = 'CI' + (Number(resdata.slice(2)) + 1)
  setFormInitials({...forbidenStatus,costNo})
  setModalFlag('new')
  setShowModal(() => true)
}}
]
//添加和修改中的model项
const formItems:FormItemObj[] = [
{label: '费用名称',prop: 'name'},
{label: '编码',prop: 'costNo'},
{label: '所属公司',prop: 'companyId', type:'select', dataList: companyList,clearable:false,change:(value:any)=>{
  uniAxList(dataSubjectList(value))
}},
{label: '关联会计科目', prop: 'subjectId', type:'tree',treeProps:{propName:'title'},dataList:modalSubjectList},
{label: '所属业务', prop: 'businessIdList', type:'select',selectProps:{mode:'multiple'},dataList: businessList},
{label: '收支类型',prop: 'incomeType',required:false, type:'select',dataList:incomeTypes},
{label: '是否启用',prop: 'status', required:false, type:'select',dataList: forbidenStatus},
{label: '排序', prop: 'sort', required:false, type: 'number'},
{label: '备注', prop: 'remark', required:false},
]

/* 接口参数 */
const dataRow =(current?:number):AxDataform => ({
  params: {...searchParams, current:current ||  pagination.current, size:pagination.pageSize},
  axApi: getCostTypeTree,
  setList: setRowList,
  setTableLoading,
  setPagination
})
//新建行
const dataNewrow = (params:any):AxDataform => ({
  params,
  axApi: createCostType,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//修改行
const dataUpdaterow = (params:any):AxDataform => ({
  params: {...params, id:currentRow.id},
  axApi: updateCostType,
  // setModalLoading,
  // setShowModal,
  // awaitFunc: (predata:any) => uniAxList(dataRow())
})
//删除行
const dataDelrow = (rowid:any):AxDataform => ({
  params: {id:rowid},
  axApi: deleteCostType,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//费用类型绑定业务类型
const dataCtypeBindBtype = (params:any):AxDataform => ({
  params,
  axApi: axCtypeBindBtype,
})
//费用类型最大编码
const dataMaxcode = (params?:any):AxDataform => ({
  params,
  axApi: axCostTypeCode
})
const uniBusininessData:any = {
  axApi: getBusinessList,
  setList: setBusinessList,
}
//公司会计科目列表
const dataSubjectList = (companyId?:any):AxDataform => ({
  params: {page:1, size:9999,companyId,showProof:true},
  axApi: axSubjectList,
  itemModify: {modifyFunc:({item}:any)=>item.title=item.subjectNo+' '+item.name},
  setList: companyId ?setModalSubjectList:setAccountantSubjectList
})
const uniCostTypeData = {
  params: {size:9999},
  modifyFunc: uniModifyAxList,
  modifyData: {titleList: ['title','name']},
  axApi: getCostTypeTree,
  setList: setCostTypeList
}
const uniCompanyData:any = {
  axApi: getCompanyList,
  setList: setCompanyList,
}
/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])
/* mounted */
React.useEffect(() => {
  uniAxList(dataRow())
  uniAxList(uniBusininessData)
  uniAxList(dataSubjectList())
  uniAxList(uniCompanyData)
  setLoadFlag(()=>true)
}, [])

return (
  <>
    <TopSearch
      ref={searchRef}
      searchList={searchList}
      searchConfirm={searchConfirm}
    />
    <SuperTable
      columns={columns}
      rowList={rowList}
      bottomActions={bottomActions}
      tableLoading={tableLoading}
      pagination={pagination}
      dataRow={dataRow}
    />
    <FormDialog
      ref={modalRef}
      title={modalFlag==='new'?'新增费用类型':'修改费用类型'}
      show={showModal}
      formItems={formItems}
      modalLoading={modalLoading}
      formInitials={formInitials}
      cancel={()=>{
        setShowModal(()=>false)
        setCurrentRow({})
        setFormInitials({...initForminitial})
        setModalSubjectList([])
      }}
      confirm={modalConfirm}
    />
  </>
)
}
