import { Button, Form, FormInstance, Input, InputNumber, message, Modal, Select, Table, TablePaginationConfig } from 'antd'
import React, { createRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getCompanyList, updateShareRate } from '../../utils/api'
import { brandList } from '../../utils/constant'
import tablecss from '../../css/custable.module.less'
import { uniAddRowSubmit, uniAxList, uniAxSearchList, uniCurrentTime, uniDeepArray, uniExportExcel, uniExportTable, uniModifyAxList, uniSelectRows, uniTableChange } from '../../utils/uniFucs'

export default function GpRatesetting() {
  const pathname = "/indexpage/gpratesetting"
  /* hooks */
  let location = useLocation()
  const formModalRef = createRef<FormInstance>()
  const tableRef = createRef<any>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [rateModal, setRateModal] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [rowList, setRowList] = useState([])
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({showRealName:true, showCardNo:true, showBeginBalance:true, showBeginTime:true})
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  // const [currentRow, setCurrentRow] = useState({} as CustomerItem)
  const [searchParams, setSearchParams] = useState({} as any)
  const [tempSearchParams, setTempSearchParams] = useState({} as any)
  const [staffList, setStaffList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 10})
  const [loadFlag, setLoadFlag] = useState(false)
  const [currentRate, setCurrentRate] = useState({} as any)
  const [modalLoading, setModalLoading] = useState(false)
  /* funcs */
  const onRateSet = () => {
    rowList.forEach((rowItem:any) => currentRate[rowItem.name] = rowItem.shareRate)
    setRateModal(()=>true)
  }
  //路由刷新请求
  const routerFlash = () => {
    uniAxList(uniRowData)
  }
  /* elements */
  //列项
  const columns:any = [
    {
      title: '账套名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '是否总公司',
      dataIndex: 'isHead',
      key: 'isHead',
      render: (isHead:number) => isHead===1?'是':'否'
    },
    {
      title: '共摊比例(%)',
      dataIndex: 'shareRate',
      key: 'shareRate',
    },
    /* {
      title: '所属品牌',
      dataIndex: 'brand',
      key: 'brand',
    }, */
    {
      title: '公司名称',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    /* {
      title: '所属客户',
      dataIndex: 'customer',
      key: 'customer',
    }, */
    /* {
      title: '排序',
      dataIndex: 'sort',
      key: 'sort',
    }, */
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
    },
    {
      title: '创建时间',
      dataIndex: 'createDate',
      key: 'createDate',
    },
  ]

  //导出当前页，需要的数据修饰
  const exportModify:any = {
    checkStatus:[
      {newKey:'isHead', checkKey: 'isHead', sourceList:[{value:1,name:'是'},{value:0,name:'否'}], sourceCheckKey:'value', sourceValueKey:'name'},
    ],
    notNullColumns: columns
  }

  //接口参数
  const uniRowData:any = {
    params: searchParams,
    axApi: getCompanyList,
    setList: setRowList,
    setTableLoading,
    pagination
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    console.log('usereffect--mounted--------------')
    uniAxList(uniRowData)
    setLoadFlag(()=>true)
  }, [])
  return (
    <>
      <div>
      <Input
        allowClear
        style={{ width: 200 }}
        placeholder="账套名称"
        onChange={(e) => setTempSearchParams(() => ({...tempSearchParams, filter_LIKES_name:e.target.value || null}))}
      ></Input>&nbsp;
      {/* <Select
        allowClear
        style={{ width: 200 }}
        placeholder="所属品牌"
        onChange={(brand) => setTempSearchParams(() => ({...tempSearchParams, filter_LIKES_brand:brand || null}))}
      > 
        {brandList.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp; */}
      <Input
        allowClear
        style={{ width: 200 }}
        placeholder="公司名称"
        onChange={(e) => setTempSearchParams(() => ({...tempSearchParams, filter_LIKES_companyName:e.target.value || null}))}
      ></Input>&nbsp;
      {/* <Input
        allowClear
        style={{ width: 200 }}
        placeholder="客户名称"
        onChange={(e) => setTempSearchParams(() => ({...tempSearchParams, filter_LIKES_customer:e.target.value || null}))}
      ></Input>&nbsp; */}
      <Input
        allowClear
        style={{ width: 200 }}
        placeholder="备注"
        onChange={(e) => setTempSearchParams(() => ({...tempSearchParams, filter_LIKES_remark:e.target.value || null}))}
      ></Input>&nbsp;
      <Button onClick={() => {pagination.current=1;uniAxSearchList({setSearchParams, uniRowData, params: {...tempSearchParams,size:pagination.pageSize}})}} type='primary'>查询</Button>&nbsp;
      <Button type="primary" onClick={() => uniExportExcel({columns:columns, rowList: uniDeepArray(rowList),modifyFunc:uniModifyAxList, modifyData:exportModify, title:'共配账套比例', fileName: '共配账套比例'+uniCurrentTime({})})}>导出</Button> &nbsp;
      <Button type='primary' onClick={() => onRateSet()}>设置账套比例</Button>&nbsp;
      </div>
      <p></p>
      <Table
        size='small'
        className={tablecss.height240}
        id='gpratesetting'
        rowKey={'id'}
        columns={columns}
        // rowSelection={{ ...uniSelectRows(setSelectedRowKeys), checkStrictly:true }}
        dataSource={rowList}
        pagination={false}
        scroll={{y:'calc(100vh - 240px)'}}
        loading={tableLoading}
        sticky
      />
      <Modal
        width={600}
        maskClosable={false} title={'比例总和不能超过100%'}
        visible={rateModal}
        onOk={() => formModalRef.current?.submit()}
        onCancel={()=>setRateModal(false)}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>setRateModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}
      >
        <Form
          preserve={false}
          ref={formModalRef}
          name="form"
          labelCol={{ span: 6 }}
          initialValues={currentRate}
          wrapperCol={{ span: 16 }}
          onFinish={(formValus) => {
            let totalRate = 0
            rowList.forEach((rowitem:any)=>{
              totalRate += formValus[rowitem.name]
            })
            if (totalRate !== 100) message.error('账套比例总和必须等于100%')
            else {
              let axdata = rowList.map((item:any)=>({id:item.id, shareRate:formValus[item.name]}))
              uniAddRowSubmit({axGetData:uniRowData,axAddApi:updateShareRate, setAddModal:setRateModal, isArray:true,setModalLoading})(axdata)
            }
          }}
          autoComplete="off"
        >
          {rowList.map((item:any) => <Form.Item key={item.name} label={item.name} name={item.name} rules={item.rules}>
            <InputNumber precision={0} min={0} max={100} style={{width: '100%'}} placeholder="请输入账套比例"/>
          </Form.Item>)}
        </Form>
      </Modal>
    </>
  )
}
