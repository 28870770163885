import React, { forwardRef, useState } from 'react'
import { Modal, Form, Button, Upload, UploadProps, message, Select } from 'antd';

import formModal from '../css/formModal.module.less'
import { getUserToken } from '../utils/localstorage';

function ImportModal(props: any, ref: any) {
  const tokenString:string = getUserToken()
  const [companyId, setCompanyId] = useState(0)
  const [errorMessage, setErrorMessage] = useState({value:''})
  const {title, modalVisible, initialValues, quitModal, modalConfirm, axAction, companyList, modalFresh} = props
  const uploadProps: UploadProps = {
    name: 'file',
    action: (process.env.NODE_ENV === 'production') ? '/api/' + axAction : 'http://localhost:3000' + axAction,
    data: {companyId},
    accept: ".xlsx,.xls,.docx,.csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    headers: {
      authorization: tokenString.slice(0,tokenString.length-3),
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success('文件上传成功')
        quitModal(false)
        modalConfirm()
        // message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error('文件上传失败')
        if (modalFresh) modalFresh.value = false
        setErrorMessage(()=>({value:info.file.response.data}))
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Modal
      maskClosable={false}
      className={formModal.container}
      title={title}
      visible={modalVisible}
      onCancel={()=>quitModal(false)}
      destroyOnClose
      footer={[
        <Button key="back" onClick={()=>quitModal(false)}>取消</Button>,
        <Upload key="submit" {...uploadProps}>
          <Button type="primary">批量导入</Button>
        </Upload>
      ]}
    >
      <Form
        preserve={false}
        ref={ref} name="form"
        labelCol={{ span: 4 }}
        initialValues={initialValues}
        wrapperCol={{ span: 16 }}
      >
          <Form.Item key="companyId" label="公司" name="companyId" rules={[{ required: true, message: '所属公司不能为空' }]}>
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder="所属公司"
              onChange={(value)=>setCompanyId(()=>value)}
            >
              {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
            </Select>
          </Form.Item>
          {
          errorMessage.value && !modalFresh.value
          ?<div><b>ERROR</b>: {errorMessage.value}</div>
          :<h4 style={{textAlign:'center'}}>如导入发生错误，提示信息将在这里显示</h4>
        }
      </Form>
    </Modal>
  )
}
export default forwardRef(ImportModal)