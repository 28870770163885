import React, { createRef, useState } from 'react'
import { Button, ConfigProvider, DatePicker, FormInstance, Select, Table, TablePaginationConfig } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { getCompanyList, getHeadbillStatic } from '../../../utils/api'
import { uniAxList, uniAxSearchList, uniSelectRows } from '../../../utils/uniFucs'
import { useLocation } from 'react-router-dom';
import { csDateRages, monthRages } from '../../../utils/constant';
import tablecss from '../../../css/custable.module.less'

export default function HQStatistics() {
  const pathname = "/indexpage/hqstatistics"
  /* hooks */
  let location = useLocation()
  const formModalRef = createRef<FormInstance>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [logModal, setLogModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [staticModal, setStaticModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [logList, setLogList] = useState([])
  const [rowList, setRowList] = useState([])
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({businessId:true,costTypeId:true,collectionPrice:true,payPrice:false,bothPrice:false,servicePrice:false,collectionAccountId:true,payAccountId:false,serviceAccountId:false,collectionTime:true,payTime:false,bothTime:false})
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [currentRow, setCurrentRow] = useState({} as any)
  const [searchParams, setSearchParams] = useState({} as any)
  const [tempSearchParams, setTempSearchParams] = useState({} as any)
  const [staffList, setStaffList] = useState([])
  const [feeTypeList, setFeeTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 10})
  const [accountList, setAccountList] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [unCashRows, setUnCashRows] = useState([] as any)
  const [isUnderLinePay, setIsUnderLinePay] = useState(true)
  const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
  const [cancelRemark, setCancelRemark] = useState('')
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [positionList, setPositionList] = useState([])
  const [custList, setCustList] = useState([])
  const [confirmItemVisible, setConfirmItemVisible] = useState({paytype: 2})
  const [confirmCompanyList, setConfirmCompanyList] = useState([])
  const [confirmModalInitial, setConfirmModalInitial] = useState({})
  const [currentConfirmRow, setCurrentConfirmRow] = useState({} as any)
  const [custCompanyList, setCustCompanyList] = useState([])
  const [addTableData, setAddTableData] = useState([])
  const [modalCpBusinessList, setModalCpBusinessList] = useState([])
  const [modalFeeTypeList, setModalFeeTypeList] = useState([])
  const [loadFlag, setLoadFlag] = useState(false)

  /* funcs */
  //路由刷新请求
  const routerFlash = () => {
    uniAxList(uniRowData)
    uniAxList(uniCompanyData)
  }
  const modifyAxList = (rows:any) => {
    let tableData = []
    const staticKeys = ['price']
    let collectionSum = 0
    let collection2Sum = 0
    let paySum = 0
    let pay2Sum = 0
    for (let key in rows) {
      rows[key].forEach((item:any) => {
        item['costTypeId'] = item['costTypePid']
        item['costTypeName'] = item['costTypePidName']
        staticKeys.forEach((keyitem:string) => {
          item[keyitem] = item.children.reduce((total:number, dataItem:any) => total + dataItem[keyitem], 0)
        })
        if (key === 'collection') collectionSum += item['price']
        else if (key === 'collection2') collection2Sum += item['price']
        else if (key === 'pay') paySum += item['price']
        else if (key === 'pay2') pay2Sum += item['price']
      })
      if (key === 'collection') tableData.push({costTypeId: '0001', costTypeName: '统计收入', price: collectionSum, children: rows[key]})
      else if (key === 'collection2') tableData.push({costTypeId: '0002', costTypeName: '其他收入', price: collection2Sum, children: rows[key]})
      else if (key === 'pay') tableData.push({costTypeId: '0003', costTypeName: '统计支出', price: paySum, children: rows[key]})
      else if (key === 'pay2') tableData.push({costTypeId: '0004', costTypeName: '其他支出', price: pay2Sum, children: rows[key]})
    }
    return tableData
  }

  /* elements */
  //列项
  const columns:any = [
    {
      title: '费用类型',
      dataIndex: 'costTypeName',
      key: 'costTypePidName'
    },
    {
      title: '金额小计',
      dataIndex: 'price',
      key: 'price',
      render: (value:any) => (value / 100).toFixed(2)
    }
  ]

  /* 接口参数 */
  const uniRowData:any = {
    params: searchParams,
    axApi: getHeadbillStatic,
    modifyFunc: modifyAxList,
    setList: setRowList,
    setTableLoading
  }
  const uniCompanyData:any = {
    axApi: getCompanyList,
    setList: setCompanyList,
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    uniAxList(uniRowData)
    uniAxList(uniCompanyData)
    setLoadFlag(()=>true)
  }, [])
  
  return (
    <>
      <p>
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="所属公司"
        onChange={(companyId) => setTempSearchParams(() => ({...tempSearchParams, companyId:companyId || null}))}
      > 
        {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          ranges={monthRages}
          placeholder={['收/付开始日期', '收/付结束日期']}
          picker='month'
          onChange={(dates, dateStrings) => setTempSearchParams(()=>({...tempSearchParams, payYmBegin: dateStrings[0] || null, payYmEnd:dateStrings[1] || null}))}
        />
      </ConfigProvider>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          ranges={monthRages}
          placeholder={['业务开始日期', '业务结束日期']}
          picker='month'
          onChange={(dates, dateStrings) => setTempSearchParams(()=>({...tempSearchParams, billYmBegin: dateStrings[0] || null, billYmEnd:dateStrings[1] || null}))}
        />
      </ConfigProvider>&nbsp;
      <Button onClick={() => uniAxSearchList({setSearchParams, uniRowData, params: tempSearchParams})} type='primary'>查询</Button>
      </p>
      <Table
        size='small'
        className={tablecss.height240}
        rowKey={'costTypeId'}
        columns={columns}
        // rowSelection={{ ...uniSelectRows(setSelectedRowKeys, setSelectedRows), checkStrictly:true }}
        dataSource={rowList}
        pagination={false}
        scroll={{y:'calc(100vh - 240px)'}}
        loading={tableLoading}
        // onChange={(newPagination)=>uniAxList({...uniRowData, params:{...searchParams, current:newPagination.current}})}
        sticky
      />
    </>
  )
}
