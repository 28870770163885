import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker, FormInstance, Input, Pagination, Select, Table, TablePaginationConfig, TreeSelect } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { getBusinessList, getCompanyList, getCostTypeTree, getStaffList, getUserBillLog } from '../../../utils/api'
import { uniAxList, uniAxSearchList, uniFilterItem, uniModifyAxList, uniSelectRows } from '../../../utils/uniFucs'
import { csDateRages, staffCashType, userTypes } from '../../../utils/constant';
import tablecss from '../../../css/custable.module.less'

export default function UserbalanceDetail() {
  const pathname = "/indexpage/userbalancedetail"
  /* hooks */
  let location:any = useLocation()
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const [addModal, setAddModal] = useState(false)
  const [chgModal, setChgModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [staticModal, setStaticModal] =useState(false)
  const [companyList, setCompanyList] = useState([])
  const [dpList, setDpList] = useState([])
  const [rowList, setRowList] = useState([])
  const [backRowList, setBackRowList] = useState([])
  const [modalItemVisible, setModalItemVisible] = useState({})
  const [showCashAccount, setShowCashAccount] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([] as any)
  const [currentRow, setCurrentRow] = useState({} as any)
  const [searchParams, setSearchParams] = useState({page:1,orderBy:"id", orderDir: "dsc"} as any)
  const [tempSearchParams, setTempSearchParams] = useState({page:1,orderBy:"id", orderDir: "dsc"} as any)
  const [staffList, setStaffList] = useState([])
  const [reinburseTypeList, setReinburseTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
  const [staticTableData, setStaticTableData] = useState([] as any)
  const [staffAccounts, setStaffAccounts] = useState([])
  const [customAddKeys, setCustomAddKeys] = useState({} as any)
  const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
  const [isUnderLinePay, setIsUnderLinePay] = useState(true)
  const [unCashRows, setUnCashRows] = useState([] as any)
  const [businessList, setBusinessList] = useState([])
  const [cancelModal, setCancelModal] = useState(false)
  const [cancelRemark, setCancelRemark] = useState('')
  const [loadFlag, setLoadFlag] = useState(false)
  const [costTypeList, setCostTypeList] = useState([])
  const [defaultUserId, setDefaultUserId] = useState({value:null})

  /* funcs */
  //路由刷新请求
  const routerFlash = (state:any) => {
    if(state) {
      defaultUserId.value = state.userId
      tempSearchParams['filter_EQS_user_id'] = state.userId
    }
    uniAxList({...uniRowData, params: {filter_EQS_user_id: state ? state.userId : null}})
    uniAxList(uniStaffData)
    uniAxList(uniBusinessData)
    uniAxList(uniCompanyData)
  }
  /* elements */
  //table中的列
  const columns:any = [
    {
      title: '员工名称',
      dataIndex: 'userName',
      key: 'userName',
      fixed: 'left'
    },
    {
      title: '收支类型',
      dataIndex: 'type',
      key: 'type',
      render: (value:number) => value === 1 ? '收入' : value === 2 ? '支出' : value
    },
    {
      title: '用户类型',
      dataIndex: 'userType',
      key: 'userType',
      render: (value:number) => {
        let tempItem:any = uniFilterItem(userTypes,'value',value)
        return tempItem ? tempItem.name : value
      }
    },
    {
      title: '所属业务',
      dataIndex: 'businessName',
      key: 'businessName',
    },
    {
      title: '账单日期',
      dataIndex: 'billTime',
      key: 'billTime',
      render: (value:string) => value ? value.split(' ')[0] : ''
    },
    {
      title: '付款日期',
      dataIndex: 'payTime',
      key: 'payTime',
      render: (value:string) => value ? value.split(' ')[0] : ''
    },
    {
      title: '公司',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: '期初余额',
      dataIndex: 'beginMoney',
      key: 'beginMoney',
      render: (value:any) => (value && !isNaN(value)) ? (value / 100).toFixed(2) : 0
    },
    {
      title: '收付金额',
      dataIndex: 'money',
      key: 'money',
      render: (value:any) => (value && !isNaN(value)) ? (value / 100).toFixed(2) : 0
    },
    {
      title: '期末余额',
      dataIndex: 'endMoney',
      key: 'endMoney',
      render: (value:any) => (value && !isNaN(value)) ? (value / 100).toFixed(2) : 0
    },
    {
      title: '是否对账',
      dataIndex: 'isCheck',
      key: 'isCheck',
      render: (value:any) => value === 0 ? '未对账' : value === 1 ? '已对账' : value
    },
    {
      title: '创建人',
      dataIndex: 'createBy',
      key: 'createBy',
    },
    {
      title: '创建时间',
      dataIndex: 'createDate',
      key: 'createDate',
    }
  ]

  /* 接口参数 */
  const uniRowData:any = {
    params: {...searchParams, current:pagination.current, size:pagination.pageSize},
    axApi: getUserBillLog,
    setList: setRowList,
    setTableLoading,
    pagination
  }
  const uniStaffData:any = {
    params: {size:999},
    axApi: getStaffList,
    setList: setStaffList
  }
  const uniBusinessData:any = {
    axApi: getBusinessList,
    setList: setBusinessList,
  }
  const uniCompanyData:any = {
    axApi: getCompanyList,
    setList: setCompanyList,
  }

  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash(location.state)
    else routerFlash(null)
  }, [location])
  /* mounted */
  React.useEffect(() => {
    if (location.state) {
      defaultUserId.value = location.state.userId
      tempSearchParams['filter_EQS_user_id'] = location.state.userId
      uniAxList({...uniRowData, params:{...searchParams,filter_EQS_user_id:location.state.userId}})
    } else uniAxList(uniRowData)
    uniAxList(uniStaffData)
    uniAxList(uniBusinessData)
    uniAxList(uniCompanyData)
    setLoadFlag(()=>true)
  }, [])

  return (
    <>
      <Select
        showSearch
        optionFilterProp='children'
        allowClear
        value={defaultUserId.value}
        style={{ width: 200 }}
        placeholder="选择员工"
        onChange={(userId) => {
          defaultUserId.value = userId
          setTempSearchParams(() => ({...tempSearchParams, filter_EQS_user_id:userId || null}))
        }}
      > 
        {staffList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        showSearch
        optionFilterProp='children'
        allowClear
        style={{ width: 200 }}
        placeholder="收支类型"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQL_type:value || null}))}
      > 
        {staffCashType.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        showSearch
        optionFilterProp='children'
        allowClear
        style={{ width: 200 }}
        placeholder="所属业务"
        onChange={(businessId) => setTempSearchParams(() => ({...tempSearchParams, filter_EQL_business_id:businessId || null}))}
      > 
        {businessList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="选择公司"
        onChange={(companyId) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_company_id:companyId || null}))}
      > 
        {companyList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Select
        allowClear
        style={{ width: 200 }}
        placeholder="用户类型"
        onChange={(value) => setTempSearchParams(() => ({...tempSearchParams, filter_EQS_user_type:value || null}))}
      > 
        {userTypes.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
      </Select>&nbsp;
      <Input
        allowClear
        style={{ width: 200 }}
        placeholder="创建人"
        onChange={(e) => setTempSearchParams(() => ({...tempSearchParams, filter_LIKES_create_by:e.target.value || null}))}
      ></Input>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          placeholder={['账单开始日期', '账单结束日期']}
          ranges={csDateRages}
          onChange={(dates, dateStrings) => setTempSearchParams(()=>({...tempSearchParams, filter_BETWEENS_bill_time: dateStrings[0] ? dateStrings[0]+","+dateStrings[1]+' 23:59:59' : null}))}
        />
      </ConfigProvider>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          placeholder={['付款开始日期', '付款结束日期']}
          ranges={csDateRages}
          onChange={(dates, dateStrings) => setTempSearchParams(()=>({...tempSearchParams, filter_BETWEENS_pay_time: dateStrings[0] ? dateStrings[0]+","+dateStrings[1]+' 23:59:59' : null}))}
        />
      </ConfigProvider>&nbsp;
      <ConfigProvider locale={locale}>
        <DatePicker.RangePicker
          placeholder={['创建开始日期', '创建结束日期']}
          ranges={csDateRages}
          onChange={(dates, dateStrings) => setTempSearchParams(()=>({...tempSearchParams, filter_BETWEENS_create_date: dateStrings[0] ? dateStrings[0]+","+dateStrings[1]+' 23:59:59' : null}))}
        />
      </ConfigProvider>&nbsp;
      <Button onClick={() => {pagination.current=1;uniAxSearchList({setSearchParams, uniRowData, params: {...tempSearchParams,size:pagination.pageSize}})}} type='primary'>查询</Button>
      <p></p>
      <Table
        size='small'
        className={tablecss.height270}
        rowKey={'id'}
        columns={columns}
        // rowSelection={{ ...uniSelectRows(setSelectedRowKeys, setSelectedRows), checkStrictly:true }}
        dataSource={rowList}
        pagination={false}
        loading={tableLoading}
        scroll={{y:'calc(100vh - 300px)'}}
        // onChange={(newPagination)=>{pagination.pageSize = newPagination.pageSize;pagination.current=newPagination.current ;uniAxList({...uniRowData,params: {...searchParams, current:pagination.current, size:pagination.pageSize}})}}
        sticky
        // summary={(pageData) => <SummeryRow pageData={pageData} sumInfo={[{mapIndex:6, mapKeys:['collectionPrice'], sum:0}]} total={16} title={'总金额'} />}
      />
      <div style={{textAlign:'right'}}>
        <p></p>
        <ConfigProvider locale={locale}>
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.current}
            total={pagination.total}
            showSizeChanger
            showQuickJumper
            showTotal={total => `共 ${total} 条`}
            onChange={(pageNumbe)=>{
              pagination.current = pageNumbe
              uniAxList({...uniRowData,params:{...searchParams, current:pageNumbe, size:pagination.pageSize}})
            }}
            onShowSizeChange={(current, pageSize)=>{
              pagination.pageSize = pageSize
              uniAxList({...uniRowData,params:{...searchParams, current:pagination.current, size:pageSize}})
            }}
          />
        </ConfigProvider>
      </div>
    </>
  )
}
