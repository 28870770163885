import React, { useState } from 'react'

import { Button, message, TreeSelect } from 'antd'
import { uniAxList, uniModifyAxList } from '../../utils/uniFucs'
import { axSubjectList, getSiteInfo, updateSite } from '../../utils/api'

export default function SiteSetting() {
  /* hooks */
  const [defaultSiteInfo, setDefaultSiteInfo] = useState({} as any)
  const [accountantSubjectList, setAccountantSubjectList] = useState([])
  const [tempSiteInfo, setTempSiteInfo] = useState({} as any)
  const [siteInfo, setSiteInfo] = useState({} as any)

  /* funs */
  //获取站点信息
  const axSiteInfo = async () => {
    let res = await getSiteInfo({})
    setDefaultSiteInfo(()=>({...res.data}))
    setSiteInfo(()=>res.data)
  }
  //设置站点科目
  const onSiteSubject = async () => {
    let res = await updateSite({...tempSiteInfo, id:siteInfo.id})
    if (res && res.message && res.message.toString().toLowerCase() === 'success') {
      message.success('修改成功')
      axSiteInfo()
    }
  }

  /* 接口参数 */
  const uniAsData = {
    modifyFunc: uniModifyAxList,
    modifyData: {titleList: ['title','subjectNo','name']},
    params: {page:1, size:999},
    axApi: axSubjectList,
    setList: setAccountantSubjectList
  }
  /* mounted */
  React.useEffect(() => {
    uniAxList(uniAsData)
    axSiteInfo()
  }, [])
  return (
    <>
      <div>
        <span>站点会计科目：</span>
        <TreeSelect
          value={defaultSiteInfo.accountSubjectId}
          showSearch
          treeNodeFilterProp='title'
          fieldNames={{value: 'id'} as any}
          style={{ width: 200 }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          placeholder="开始会计科目"
          allowClear
          treeDefaultExpandAll
          treeData={accountantSubjectList}
          onChange={(value, children, record) => {
            defaultSiteInfo.accountSubjectId = value
            setTempSiteInfo(() => ({...tempSiteInfo, accountSubjectId: value || null}))
          }}
        />
        <Button type='primary' onClick={()=>onSiteSubject()}>保存设置</Button>
      </div>
    </>
  )
}
