import React, { createRef, useState } from 'react'
import { Button,Image, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, Modal, Pagination, Radio, Select, Table, TablePaginationConfig, Tooltip, TreeSelect, Typography, Upload, UploadFile, UploadProps, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { ModifyParams, NameValue, UserBillItem } from '../../../utils/beans'
import FormModal from '../../../components/FormModal'
import { uniAddRowSubmit, uniAxList, uniAxSearchList, uniCancel, uniCancelConfirm, uniChgRowSubmit, uniCurrentTime, uniDatePikcerChange, uniDeepArray, uniExportExcel, uniFilterItem, uniFormProcess, uniHandleUpload, uniModifyAxList, uniModifyRow, uniOpAlarm, uniQuitModal, uniSelectRows, uniStaticLast, uniTableChange, uniToProof, uniValueToName } from '../../../utils/uniFucs'
import { cancelUserBill, carryYjjk, confirmYjjk, confirmYjjkPay, createYjjk, fileUpload, getCompanyAccoutList, getCompanyList, getCostTypeTree, getSiteInfo, getStaffList, getUserBillList, getUserBillRegisterLog, searchDepList } from '../../../utils/api'
import { cancelStatus, carryStatusList, collectionStatus, csDateRages, csLendBorrow, incomeTypes, onlineAccounts, payStatus, payWay, proofStatusList } from '../../../utils/constant'
import TextArea from 'antd/lib/input/TextArea';
import UniOperationColumn from '../../../components/UniOperationColumn';
import SummeryRow from '../../../components/SummeryRow';
import { useLocation, useNavigate } from 'react-router-dom';
import tablecss from '../../../css/custable.module.less'
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../../utils/interfaces';
import TopSearch from '../../../components/TopSearch';
import SuperTable from '../../../components/SuperTable';
import FormDialog from '../../../components/FormDialog';

let fileinit:any
export default function PledgeLoan() {
const pathname = "/indexpage/pledgeloan"
const modalRef = createRef<FormInstance>()
const searchRef = createRef<FormInstance>()
const initSearchParams = {page:1,filter_EQL_business_id:6,orderBy:"create_date", orderDir: "desc",filter_EQB_is_cancel:false}
const initForminitial = {}

const initVisible:any = {way:true,accountId:true,upload:true}
/* hooks */
let location = useLocation()
const navigate = useNavigate()
const formModalRef = createRef<FormInstance>()
const [addModal, setAddModal] = useState(false)
const [chgModal, setChgModal] = useState(false)
const [cancelModal, setCancelModal] = useState(false)
const [confirmModal, setConfirmModal] = useState(false)
const [staticModal, setStaticModal] = useState(false)
const [collectModal, setCollectModal] = useState(false)
const [batchCarryModal, setBatchCarryModal] = useState(false)
const [logModal, setLogModal] = useState(false)
const [carryModal, setCarryModal] = useState(false)
const [payModal, setPayModal] = useState(false)
const [companyList, setCompanyList] = useState([])
const [dpList, setDpList] = useState([])
const [rowList, setRowList] = useState([])
const [backRowList, setBackRowList] = useState([])
const [modalItemVisible, setModalItemVisible] = useState({...initVisible} as any)
const [confirmItemVisible, setConfirmItemVisible] = useState({time:true} as any)
const [showCashAccount, setShowCashAccount] = useState(true)
const [selectedRowKeys, setSelectedRowKeys] = useState([])
const [selectedRows, setSelectedRows] = useState([] as any)
const [currentRow, setCurrentRow] = useState({} as any)
let [searchParams, setSearchParams] = useState({...initSearchParams} as any)
const [tempSearchParams, setTempSearchParams] = useState({page:1,filter_EQL_business_id: 95,orderBy:"create_date", orderDir: "desc",filter_EQB_is_cancel:false} as any)
const [staffList, setStaffList] = useState([])
const [reinburseTypeList, setReinburseTypeList] = useState([])
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
const [logPagination, setLogPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 10})
const [staticTableData, setStaticTableData] = useState([] as any)
const [firmAccounts, setFirmAccounts] = useState([])
const [customAddKeys, setCustomAddKeys] = useState({} as any)
const [customConfirmKeys, setCustomConfirmKeys] = useState({} as any)
const [isUnderLinePay, setIsUnderLinePay] = useState(true)
const [unCashRows, setUnCashRows] = useState([] as any)
const [costTypeList, setCostTypeList] = useState([])
const [cancelRemark, setCancelRemark] = useState('')
const [loadFlag, setLoadFlag] = useState(false)
const [liscenseList, setLiscenseList] = useState<UploadFile[]>([])
const [wayFlag, setWayFlag] = useState({value:-1})
const [logList, setLogList] = useState([])
const [carryIdList, setCarryIdList] = useState({value:[]} as any)
const [modalLoading, setModalLoading] = useState(false)
const [siteInfo, setSiteInfo] = useState({} as any)
const [exportLoading, setExportLoading] = useState(false)
const [staticLoading,setStaticLoading] = useState(false)
const [modalFlag, setModalFlag] = useState('new')
const [showModal, setShowModal] = useState(false)
let [formInitials, setFormInitials] = useState({...initForminitial})
const [plusModalKeys, setPlusModalKeys] = useState({} as any)
const [myfileList, setMyfileList] = useState<UploadFile[]>([])

/* funs */
//路由刷新请求
const routerFlash = () => {
  uniAxList(dataRow())
  uniAxList(dataCompanyList)
  // uniAxList(uniStaffData)
  uniAxList(uniRtData)
  // uniAxList(uniSiteData)
}
const searchConfirm = async (formValus:any) => {setPagination({...pagination,current:1});
  // searchParams = {...initSearchParams,...formValus}
  // setSelectedRows([])
  // setSelectedRowKeys([])
  searchParams = await uniFormProcess({...initSearchParams,...formValus},searchList)
  setSearchParams(searchParams)
  uniAxList(dataRow(1))
}
//modal点击确定
const modalConfirm = async (formValues:any) => {
  let tempitems = formItems
  let datafuc = dataNewrow
  let tempparms = await uniFormProcess(formValues,tempitems)
  uniAxList(datafuc({...tempparms,...plusModalKeys}))
}
//modal取消
const modalCancel = () => {
  setShowModal(()=>false)
  setPlusModalKeys({})
  setCurrentRow({})
  setFormInitials({...initForminitial})
  if (fileinit) fileinit(true)
}
const onBatchCarry = () => {
  let carryIds:any = []
  selectedRows.forEach((item:any) => {
    if (!item.isCarry) carryIds.push(item.id)
  })
  if (carryIds.length <= 0) message.info('没有可以结转的记录')
  else {
    carryIdList.value = carryIds
    setBatchCarryModal(()=>true)
  }
}
//批量结转modal点击确定
const onBatchConfirm = () => {
  setModalLoading(()=>true)
  let axfuncs:Function[] = []
  carryIdList.value.forEach((item:string)=>axfuncs.push(carryYjjk({id:item})))
  Promise.all(axfuncs).then((res)=>{
    setModalLoading(()=>false)
    setBatchCarryModal(()=>false)
    uniAxList(dataRow())
  })
}
/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '人员',isCompound:true,prop:'filter_LIKES_user_name'},
{label: '备注',isCompound:true,prop:'filter_LIKES_remark',placeholder:'备注'},
{label: '作废状态', prop:'filter_EQB_is_cancel',type:'select',dataList:cancelStatus},
{label: '公司', prop:'filter_EQS_company_id',type:'select',dataList:companyList},
{label: '借还类型', prop:'filter_EQS_income_type',type:'select',dataList:csLendBorrow},
{label: '费用类型', prop:'filter_EQS_cost_type_id',type:'select',dataList:costTypeList},
{label: '账单日期', prop:'filter_BETWEENS_bill_time',type:'dateRange'}
]
//列项
const columns:ColumnItem[] = [
{title: '工号',key: 'userJobNum'},
{title: '结算对象',key: 'userName'},
{title: '作废状态',key: 'isCancel',type:'tag',tagProps:{values:[true,false]},custval: ({row,value}:any) =>uniValueToName({list:cancelStatus,value})},
{title: '备注',key: 'remark'},
{title: '所属公司',key: 'companyName'},
{title: '费用类型',key: 'costTypeName'},
{title: '借还类型',key: 'incomeType',type:'tag',tagProps:{values:[2,1]},custval: ({row,value}:any) =>uniValueToName({list:csLendBorrow,value})},
{title: '账单日期',key: 'billTime', custval:({rwo,value}:any)=>value ? value.split(' ')[0] : ''},
{title: '金额',key: 'shouldCollection',custval: ({row,value}:any) => row.incomeType===1?(value/100).toFixed(2):row.incomeType===2?(row.shouldPay/100).toFixed(2):'-'},
{title: '账户',key: 'collectionAccount',custval: ({row,value}:any) => row.incomeType===1?value:row.incomeType===2?row.payAccount:'-'},
{title: '凭证号',key: 'accountProof',ellipsis:false,render: (value:any,row:any) => {
  return <div style={{maxWidth:170}}>
    {value.map((item:any) => <span key={item.accountProofId}><Typography.Link onClick={()=>uniToProof(row,item.accountProofId,navigate)}>{item.accountProofNo}</Typography.Link> </span>)}
  </div>
}},
{title: '操作',key:'operation',type: 'operation',width:60,opProps:{opList:[
  {name:'作废',color:'red',action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataDelrow(row.id)))}
]}}
]
//底部操作
const bottomActions:ColumnAction[] = [
{label: '新增', type:'primary', action: () => {setModalFlag('new');setShowModal(() => true)}},
// {label: '收款记录', type:'primary', action: () => {setLogModal(()=>true);uniAxList(uniLogData)}},
// {label: '数据统计', type:'primary',action: () => uniStaticLast({
//   axApi:getUserBillList,
//   params:{...searchParams, page:null},
//   staticColumns,
//   staticRows,
//   setStaticModal,
//   setStaticTableData,
//   setStaticLoading
// })}
]
//添加和修改中的model项
const formItems:FormItemObj[] = [
  {label: '所属公司',prop: 'companyId',clearable:false,type:'select',selectProps:{requireName:'companyName'},dataList:companyList,change:(value:any)=>{
    uniAxList(dataFirmacountList(value))
    uniAxList(dataStaffList(value))
  }},
  {label: '人员',prop: 'userId',type:'select',selectProps:{requireName:'userName'},dataList:staffList,change:(value:any) => {
    let finditem:any = staffList.find((item:any) => value === item.id)
    setPlusModalKeys(()=>({...plusModalKeys, userJobNum: finditem?.jobNum}))
  }},
  {label: '借还类型',prop: 'incomeType',type:'select',dataList:csLendBorrow},
  {label: '费用类型',prop: 'costTypeId',type:'select',selectProps:{requireName:'costTypeName'},dataList:costTypeList},
  {label: '金额',prop: 'price',type:'price'},
  {label: '账户',prop: 'accountId',type:'select',selectProps:{requireName:'account',childrenKeys:['name','cardNo']},dataList:firmAccounts},
  {label: '结算日期',prop: 'actionTime',type:'datePicker',datepickerProps:{returnType:'string'}},
  {label: '备注',prop: 'remark',required:false},
  {label: '结算凭证',prop: 'rechargeFileUrl',span:24,required:false,type:'upload',uploadProps:{max:3},change:(filearr:any,setIsinit:any)=>{
    setIsinit(false)
    fileinit = setIsinit
    setMyfileList(filearr.map((fileitem:any)=>fileitem.exist ? fileitem : fileitem.originFileObj))
  }}
]
// //收款记录中的列项
// const logColumns:any = [
//   {
//     title: '结算对象',
//     dataIndex: 'userName',
//     key: 'userName',
//     fixed: 'left'
//   },
//   {
//     title: '摘要备注',
//     dataIndex: 'remark',
//     key: 'remark',
//     ellipsis: {showTitle: false},
//     render: (value:string) => (
//       <Tooltip placement="topLeft" title={value}>
//         {value}
//       </Tooltip>
//     )
//   },
//   {
//     title: '所属公司',
//     dataIndex: 'companyName',
//     key: 'companyName',
//   },
//   {
//     title: '费用类型',
//     dataIndex: 'costTypeName',
//     key: 'costTypeName',
//   },
//   {
//     title: '收付类型',
//     dataIndex: 'incomeType',
//     key: 'incomeType',
//     render: (value:any) => {
//       let tempItem:any = uniFilterItem(incomeTypes, 'value', value)
//       return tempItem ? tempItem.name : value
//     }
//   },
//   {
//     title: '实收/实付金额',
//     dataIndex: 'actualCollection',
//     key: 'actualCollection',
//     render: (value:any, record:any) => record.incomeType === 1 || record.incomeType === 3 ? (record.actualCollection / 100).toFixed(2) : (record.actualPay / 100).toFixed(2)

//   },
//   {
//     title: '收款方式',
//     dataIndex: 'way',
//     key: 'way',
//     render: (value:any) => value === '1' ? '线上' : value === '2' ? '线下' : value
//   },
//   {
//     title: '收/付款账户',
//     dataIndex: 'account',
//     key: 'account',
//   },
//   {
//     title: '转结金额',
//     dataIndex: 'carryMoney',
//     key: 'carryMoney',
//     render: (value:any) => value && !isNaN(value) ? value/100 : value
//   },
//   {
//     title: '收/付款/转结日期',
//     dataIndex: 'billTime',
//     key: 'billTime',
//     render:(value:string) => value ? value.split(' ')[0] : ''
//   },
//   {
//     title: '凭证状态',
//     dataIndex: 'proofStatus',
//     key: 'proofStatus',
//     render: (value:any) => {
//       let tempItem:any = uniFilterItem(proofStatusList, 'value', value)
//       return tempItem ? tempItem.name : value
//     }
//   },
//   {
//     title: '凭证号',
//     dataIndex: 'accountProof',
//     key: 'accountProof',
//     render: (value:any,row:any) => {
//       return value.map((item:any) => <span key={item.accountProofId}><Typography.Link onClick={()=>uniToProof(row,item.accountProofId,navigate)}>{item.accountProofNo}</Typography.Link> </span>)
//     }
//   },
//   {
//     title: '收款凭证',
//     dataIndex: 'rechargeFileUrl',
//     key: 'rechargeFileUrl',
//     render: (value:string) => value ? <Image width={40} src={value}/> : '无'
//   },
//   {
//     title: '操作人',
//     dataIndex: 'createBy',
//     key: 'createBy'
//   },
//   {
//     title: '创建时间',
//     dataIndex: 'createDate',
//     key: 'createDate'
//   }
// ]
// //图片上传的prop
// const imageProps: UploadProps = {
//   accept: ".jpg,.png,.jpeg,.PNG,.gif,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//   onRemove: file => {
//     const index = liscenseList.indexOf(file);
//     const newFileList = liscenseList.slice();
//     newFileList.splice(index, 1);
//     setLiscenseList(newFileList);
//   },
//   beforeUpload: file => {
//     setLiscenseList([...liscenseList, file]);
//     return false;
//   },
//   fileList: liscenseList,
// };

  // //实收实付modal
  // const collectModalItems = [
  //   {
  //     label: '收付类型',
  //     name: 'incomeType',
  //     rules: [{ required: true, message: '收付类型不能为空' }],
  //     element: <Input readOnly />
  //   },
  //   {
  //     label: '金额',
  //     name: 'price',
  //     rules: [{ required: true, message: '金额不能为空' }],
  //     element: <InputNumber precision={2} style={{width:'100%'}} max={
  //     collectModal
  //     ?  currentRow.incomeType === 1
  //       ? (currentRow.shouldCollection - currentRow.actualCollection)/100
  //       : currentRow.incomeType === 2
  //         ? (currentRow.shouldPay - currentRow.actualPay)/100
  //         : 999999999
  //     : payModal
  //       ? currentRow.incomeType === 1 || currentRow.incomeType === 3
  //         ? (currentRow.actualCollection-currentRow.actualPay)/100 : (currentRow.actualPay-currentRow.actualCollection)/100
  //       : 999999999
  //     } placeholder={
  //       collectModal
  //       ?currentRow.incomeType === 1
  //         ? '剩余'+(currentRow.shouldCollection - currentRow.actualCollection)/100
  //         : currentRow.incomeType === 2
  //           ? '剩余'+(currentRow.shouldPay - currentRow.actualPay)/100
  //           : '输入金额'
  //       :payModal
  //         ? currentRow.incomeType === 1 || currentRow.incomeType === 3
  //           ? '剩余'+(currentRow.actualCollection-currentRow.actualPay)/100
  //           : '剩余'+(currentRow.actualPay-currentRow.actualCollection)/100
  //         : '输入金额'
  //     }/>
  //   },
  //   {
  //     label: '收款方式',
  //     name: 'way',
  //     rules: [{ required: false }],
  //     element: <Radio.Group onChange={(event) => {
  //       if (event.target.value === '1') modalItemVisible.time = false
  //       else modalItemVisible.time = true
  //       setIsUnderLinePay(()=>!isUnderLinePay);
  //       // formModalRef.current?.setFieldsValue({accountId:null})
  //     }}>
  //       {payWay.map((item:NameValue) => <Radio key={item.value} value={item.value}>{item.name}</Radio>)}
  //     </Radio.Group>
  //   },
  //   {
  //     label: '账户',
  //     name: 'accountId',
  //     rules: [{ required: false, message: '账户不能为空' }],
  //     element: 
  //             isUnderLinePay
  //             ?<Select
  //               allowClear
  //               style={{ width: '100%' }}
  //               placeholder="请选择账户"
  //               onChange={(value) => {
  //                 if (isUnderLinePay) {
  //                   const tempItem:any = firmAccounts.find((item:any) => value === item.id)
  //                   setCustomAddKeys(()=>({...customAddKeys, account: tempItem?tempItem.name : null}))
  //                 }
  //               }}
  //             >
  //               { firmAccounts.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>) }
  //             </Select>
  //             : siteInfo.accountSubjectId ? <Input readOnly defaultValue='默认账户'/> : <span>没有线上账户，请先设置站点会计科目</span>
              
  //   },
  //   {
  //     label: '收/付款日期',
  //     name: 'time',
  //     rules: [{ required: true, message: '收/付款日期不能为空' }],
  //     element:<ConfigProvider locale={locale}>
  //               <DatePicker disabledDate={(current)=>{return current && current > moment().endOf('day')}} style={{width:'100%'}} onChange={(date, dateString)=>uniDatePikcerChange({formRef:formModalRef,dateString:dateString,keyWord:'time'})}/>
  //             </ConfigProvider>      
  //   },
  //   {
  //     label: '上传凭证',
  //     name: 'upload',
  //     rules: [{ required: false }],
  //     element: <Upload {...imageProps}><Button icon={<UploadOutlined />}>选择图片</Button></Upload>
  //   },
  //   {
  //     label: '备注',
  //     name: 'remark',
  //     rules: [{ required: false }],
  //     element: <TextArea style={{ height: 80, resize:'none' }} />
  //   }
  // ]

  // //列项
  // const exportColumns:any = [
  //   {title: '结算对象',dataIndex: 'userName'},
  //   {title: '备注',dataIndex: 'remark'},
  //   {title: '所属公司',dataIndex: 'companyName'},
  //   {title: '费用类型',dataIndex: 'costTypeName'},
  //   {title: '收付类型',dataIndex: 'incomeTypeName'},
  //   {title: '应收/应付金额',dataIndex: 'shouldCollection'},
  //   {title: '账单日期',dataIndex: 'actionTime'},
  //   {title: '实收/实付金额',dataIndex: 'collectionPrice'},
  //   {title: '剩余金额',dataIndex: 'payPrice'},
  //   {title: '收/付款状态',dataIndex: 'isCollection'},
  //   {title: '操作人',dataIndex: 'createBy'},
  //   {title: '创建时间',dataIndex: 'createDate'}
  // ]

  // //统计列
  // const staticColumns:any = [
  //   {
  //     title: '',
  //     dataIndex: 'rowName',
  //     key: 'rowName',
  //   },
  //   {
  //     title: '应收/付金额',
  //     dataIndex: 'shouldCash',
  //     key: 'shouldCash',
  //     render: (value:any) => (value/100).toFixed(2),
  //     addValue: (record:any) => record.incomeType === 1 ? record.shouldCollection : record.incomeType === 2 ? record.record.shouldPay : 0
  //   },
  //   {
  //     title: '实收/付金额',
  //     dataIndex: 'actualCash',
  //     key: 'hadCash',
  //     render: (value:any) => (value/100).toFixed(2),
  //     addValue: (record:any) => record.incomeType === 1 || record.incomeType === 3 ? record.actualCollection : record.actualPay
  //   },
  //   {
  //     title: '已还/回款金额',
  //     dataIndex: 'hadCash',
  //     key: 'hadCash',
  //     render: (value:any) => (value/100).toFixed(2),
  //     addValue: (record:any) => record.incomeType === 1 || record.incomeType === 3 ? record.actualPay : record.actualCollection
  //   },
  //   {
  //     title: '剩余金额',
  //     dataIndex: 'restCash',
  //     key: 'restCash',
  //     render: (value:any) => (value/100).toFixed(2),
  //     addValue: (record:any) => record.incomeType === 1 || record.incomeType === 3 ? (record.actualCollection - record.actualPay-record.carryMoney) : (record.actualPay-record.actualCollection-record.carryMoney)
  //   },
  //   {
  //     title: '条数小计',
  //     dataIndex: 'countTotal',
  //     key: 'countTotal',
  //     addValue: (record:any) => 1
  //   },
  // ]

  // //统计行
  // const staticRows = [
  //   {rowName: '收押金', checkKey: 'costTypeId', checkValue: 439, shouldCash:0, actualCash:0, hadCash:0, restCash:0, countTotal:0},
  //   {rowName: '付押金', checkKey: 'costTypeId', checkValue: 459, shouldCash:0, actualCash:0, hadCash:0, restCash:0, countTotal:0},
  //   {rowName: '借出款', checkKey: 'costTypeId', checkValue: 439, shouldCash:0, actualCash:0, hadCash:0, restCash:0, countTotal:0},
  //   {rowName: '借入款', checkKey: 'costTypeId', checkValue: 250, shouldCash:0, actualCash:0, hadCash:0, restCash:0, countTotal:0},
  //   {rowName: '合计', checkKey: 'staticTotal', shouldCash:0, actualCash:0, hadCash:0, restCash:0, countTotal:0},
  // ]

/* 接口参数 */
const dataRow =(current?:number):AxDataform => ({
  params: {...searchParams, current: current || pagination.current, size:pagination.pageSize},
  axApi: getUserBillList,
  setList: setRowList,
  setTableLoading,
  setPagination
})
//新建行
const dataNewrow = (params:any):AxDataform => ({
  params,
  axApi: createYjjk,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//作废删除行
const dataDelrow = (rowid:any):AxDataform => ({
  params: {id:rowid},
  axApi: cancelUserBill,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//公司列表
const dataCompanyList:any = {
  axApi: getCompanyList,
  setList: setCompanyList,
}
//人员列表
const dataStaffList = (companyId:any):AxDataform => ({
  params: {groupItemId:companyId,size:999},
  axApi: getStaffList,
  setList: setStaffList
})
//公司账户
const dataFirmacountList = (companyId:any):AxDataform => ({
  params:{companyId},
  axApi: getCompanyAccoutList,
  setList: setFirmAccounts
})
//押金借款费用类型
const uniRtData:any = {
  params: {businessId: 6,size:9999},
  axApi: getCostTypeTree,
  setList: setCostTypeList
}
const uniLogData:any = {
  params: {page:1,filter_EQS_user_bill_register_id: selectedRows.length > 0 ? selectedRows[0].id : null, current:logPagination.current,size:logPagination.pageSize},
  axApi: getUserBillRegisterLog,
  setList: setLogList,
  pagination: logPagination
}
const uniSiteData:any = {
  axApi: getSiteInfo,
  setList: setSiteInfo,
}
/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])
/* mounted */
React.useEffect(() => {
  uniAxList(dataRow())
  uniAxList(dataCompanyList)
  // uniAxList(uniStaffData)
  uniAxList(uniRtData)
  // uniAxList(uniSiteData)
  setLoadFlag(()=>true)
}, [])

return (
  <>
  <TopSearch
    ref={searchRef}
    initialValues={initSearchParams}
    searchList={searchList}
    searchConfirm={searchConfirm}
  />
  <SuperTable
    columns={columns}
    rowList={rowList}
    bottomActions={bottomActions}
    tableLoading={tableLoading}
    pagination={pagination}
    dataRow={dataRow}
  />
  <FormDialog
    ref={modalRef}
    title="新增"
    show={showModal}
    formItems={formItems}
    modalLoading={modalLoading}
    formInitials={formInitials}
    cancel={modalCancel}
    confirm={modalConfirm}
  />
      {/* <Modal width={800} visible={staticModal} title="统计" destroyOnClose onCancel={()=>setStaticModal(()=>false)} footer={[<Button key="back" onClick={()=>setStaticModal(()=>false)}>取消</Button>]}>
        <Table
          rowKey={'rowName'}
          columns={staticColumns}
          dataSource={staticTableData}
          pagination={false}
          loading={staticLoading}
        />
      </Modal>
      <Modal
        visible={cancelModal}
        title="确定进行作废操作吗？"
        destroyOnClose
        onCancel={()=>setCancelModal(()=>false)}
        onOk={() => uniCancelConfirm({selectedRows,cancelApi:cancelUserBill,cancelRemark,axData:dataRow(),setCancelModal,setModalLoading})}
        footer={[<Button key="back" onClick={()=>setCancelModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => uniCancelConfirm({selectedRows,cancelApi:cancelUserBill,cancelRemark,axData:dataRow(),setCancelModal,setModalLoading})}>确定</Button>]}
      >
        <TextArea style={{ height: 80, resize:'none' }} placeholder="请输入作废说明" onChange={(e) => setCancelRemark(() => e.target.value)} />
      </Modal>
      <Modal
        width={600}
        maskClosable={false} title={'实收款登记'}
        visible={collectModal}
        onOk={() => formModalRef.current?.submit()}
        onCancel={()=>setCollectModal(false)}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>setCollectModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}
      >
        <Form
          preserve={false}
          ref={formModalRef}
          name="form"
          labelCol={{ span: 6 }}
          initialValues={{
            incomeType: currentRow.incomeType === 1 ? '应收':currentRow.incomeType === 2 ? '应付' :currentRow.incomeType === 3 ? '实收' :currentRow.incomeType === 4?'实付':'其他',
            way: '2'
          }}
          wrapperCol={{ span: 16 }}
          onFinish={async (formValus) => {
            let liscenseres:any
            if(liscenseList.length > 0) liscenseres = await uniHandleUpload({fileList:liscenseList,axUploadApi:fileUpload})
            let axvalue = {...formValus, price:(formValus.price * 100).toFixed(), rechargeFileUrl:liscenseres?liscenseres[0].data : null}
            uniChgRowSubmit({currentRows:[currentRow],axData:dataRow(),updateApi:confirmYjjk,setModal:setCollectModal,setModalLoading, customKeys:customAddKeys})(axvalue)
            // uniAddRowSubmit({axGetData:dataRow(),axAddApi:createCompanyBill, setAddModal, customAddKeys})(axvalue)
          }}
          autoComplete="off"
        >
          {collectModalItems.map((item:any) =><Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
        </Form>
      </Modal>
      <Modal
        width={600}
        maskClosable={false} title={'确认返/回款'}
        visible={payModal}
        onOk={() => formModalRef.current?.submit()}
        onCancel={()=>setPayModal(false)}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>setPayModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => formModalRef.current?.submit()}>确定</Button>]}
      >
        <Form
          preserve={false}
          ref={formModalRef}
          name="form"
          labelCol={{ span: 6 }}
          initialValues={{
            incomeType: currentRow.incomeType === 1 ? '应收':currentRow.incomeType === 2 ? '应付' :currentRow.incomeType === 3 ? '实收' :currentRow.incomeType === 4?'实付':'其他',
            way: '2'
          }}
          wrapperCol={{ span: 16 }}
          onFinish={async (formValus) => {
            let liscenseres:any
            if(liscenseList.length > 0) liscenseres = await uniHandleUpload({fileList:liscenseList,axUploadApi:fileUpload})
            let axvalue = {...formValus, price:(formValus.price * 100).toFixed(),rechargeFileUrl:liscenseres?liscenseres[0].data : null}
            uniChgRowSubmit({currentRows:[currentRow],axData:dataRow(),updateApi:confirmYjjkPay,setModal:setPayModal,setModalLoading, customKeys:customAddKeys})(axvalue)
            // uniAddRowSubmit({axGetData:dataRow(),axAddApi:createCompanyBill, setAddModal, customAddKeys})(axvalue)
          }}
          autoComplete="off"
        >
          {collectModalItems.map((item:any) =><Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
        </Form>
      </Modal> */}
      {/* <Modal
        visible={logModal}
        title="收款记录"
        width={1400}
        destroyOnClose
        onCancel={()=>setLogModal(()=>false)}
        footer={[<Button key="back" onClick={()=>setLogModal(()=>false)}>取消</Button>]}>
      <div style={{height:'64vh'}}>
        <Table
          rowKey={'id'}
          columns={logColumns}
          pagination={false}
          dataSource={logList}
          loading={tableLoading}
          scroll={{x:2000,y:'58.2vh'}}
          sticky
        />
      </div>
        <div style={{textAlign:'right'}}>
        <p></p>
        <ConfigProvider locale={locale}>
          <Pagination
            current={logPagination.current}
            total={logPagination.total}
            showSizeChanger
            showQuickJumper
            showTotal={total => `共 ${total} 条`}
            onChange={(pageNumbe)=>{
              logPagination.current = pageNumbe
              uniAxList({...uniLogData,params:{current:pageNumbe, size:logPagination.pageSize}})
            }}
            onShowSizeChange={(current, pageSize)=>{
              logPagination.pageSize = pageSize
              uniAxList({...uniLogData,params:{current:logPagination.current, size:pageSize}})
            }}
          />
        </ConfigProvider>
      </div>
      </Modal> */}
    </>
  )
}
