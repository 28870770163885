import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Checkbox, ConfigProvider, DatePicker, FormInstance, Select, Table, TablePaginationConfig, TreeSelect, Typography } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import tablecss from '../../css/custable.module.less'
import { accountantSubjectRemainder, accountantSubjectTree, axSubjectAffixDetail, axSubjectList, getCompanyList } from '../../utils/api'
import { uniAxList, uniAxSearchList, uniCurrentTime, uniDeepArray, uniDivideKey, uniExportExcel, uniExportToExcel, uniFilterItem, uniFormProcess, uniModifyAxList, uniSelectRows } from '../../utils/uniFucs'
import { csDateRages, monthRages } from '../../utils/constant';
import SummeryRow from '../../components/SummeryRow';
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../utils/interfaces';
import TopSearch from '../../components/TopSearch';
import SuperTable from '../../components/SuperTable';

export default function AccountBalance() {
  const pathname = "/indexpage/accountbalance"
  const initMainControl = {showSummary:false,showAffix:false}
  const searchRef = createRef<FormInstance>()
  const initShowtypes = [{id:false,name:'是'},{id:true,name:'否'}]
  const initShowtypes2 = [{id:true,name:'是'},{id:false,name:'否'}]
  const initsumProps:any = ['subjectBeginPriceBorrow','subjectBeginPriceLend','subjectChangeBorrowPrice','subjectChangeLendPrice','subjectYearChangeBorrowPrice','subjectYearChangeLendPrice','subjectEndPriceBorrow','subjectEndPriceLend']
  /* hooks */
  let location = useLocation()
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const [companyList, setCompanyList] = useState([])
  const [logList, setLogList] = useState([])
  const [rowList, setRowList] = useState([] as any)
  let [searchParams, setSearchParams] = useState({beginYm:moment().format('YYYY-MM'),endYm:moment().format('YYYY-MM')} as any)
  const [tempSearchParams, setTempSearchParams] = useState({beginYm:moment().format('YYYY-MM'),endYm:moment().format('YYYY-MM')} as any)
  const [tableLoading, setTableLoading] = useState(false)
  const [accountantSubjectList, setAccountantSubjectList] = useState([])
  const [loadFlag, setLoadFlag] = useState(false)
  const [noZero, setShowZero] = useState(false)
  const [fullRowList, setFullRowList] = useState([])
  const [effectRowList, setEffectRowList] = useState([])
  const [summaryrow,setSummaryrow] = useState({subjectNo:'',name:'总计',level:1,inBalance: 0,outBalance: 0,currentBorrowPrice: 0,currentLendPrice:0,yearBorrowPrice:0,yearLendPrice:0,inRestBalance:0,outRestBalance:0})
  const [searchYmRange,setSearchYmRange] = useState([] as any)
  let [showDatarow, setShowDatarow] = useState(false)
  const [initRowlist, setInitRowlist] = useState([] as any)
  const [dataRowlist, setDataRowlist] = useState([])
  const [statInfo,setStatInfo] = useState({
    subjectBeginPriceBorrow:0,subjectBeginPriceLend:0,subjectChangeBorrowPrice:0,subjectChangeLendPrice:0,
    subjectYearChangeBorrowPrice:0,subjectYearChangeLendPrice:0,subjectEndPriceBorrow:0,subjectEndPriceLend:0
  } as any)
  
  /* funcs */
  //路由刷新请求
  const routerFlash = () => {
    // uniAxList(dataRow())
    uniAxList(uniCompanyData)
    uniAxList(uniAsData)
  }
  const searchConfirm = async (formValus:any) => {
    setShowDatarow(false)
    searchParams = {...await uniFormProcess(formValus,searchList)}
    setSearchParams(searchParams)
    uniAxList(dataRow())
  }
  //去除0数据
  const omitNull = (list:any,parent?:any) => {
    let temparr:any = []
    list.forEach((item:any)=>{
      if (item.accountingBeginPrice || item.accountingChangeBorrowPrice || item.accountingChangeLendPrice || item.accountingEndPrice || item.accountingYearChangeBorrowPrice || item.accountingYearChangeLendPrice || item.subjectBeginPrice || item.subjectChangeBorrowPrice || item.subjectChangeLendPrice || item.subjectEndPrice || item.subjectYearChangeBorrowPrice || item.subjectYearChangeLendPrice) {
        temparr.push(item)
        if (item.children) omitNull(item.children,item)
      }
    })
    if (parent) parent.children = temparr
    return temparr
  }

/* elements */
//顶部操作
const topActions:ColumnAction[] = [
{label: '导出', type:'primary', action: () => uniExportExcel({
  columns,rowList,twoHeader:true,showSummary:true,sumProps:initsumProps,title:'科目余额表', fileName: '科目余额表',
  sumFunc: (dataList:any,citem:any) => {
    let sum = 0
    dataList.forEach((ritem:any)=>{
      if (ritem.level === 1) sum += ritem[citem.key]
    })
    return sum
  }
})},
]
//搜索行
const searchList:FormItemObj[] = [
{label: '公司', prop:'companyId',type:'select',dataList:companyList,required:true,change:(value:any)=>{
  let finditem:any = companyList.find((item:any)=>item.id===value)
  if (finditem.initialize===1) setSearchYmRange([finditem.startYm,finditem.endYm])
  else setSearchYmRange([])
}},
{label: '月份', prop:'ym',type:'dateRange',required:true,daterangeProps:{type:'month',returnType:'string',rangeKeys:['beginYm','endYm'],shortCuts:{},disabledDate:(current)=>{
  if (searchYmRange.length<2) return true
  else return current<moment(searchYmRange[0]).startOf('month') || current>moment(searchYmRange[1]).endOf('month')
}}},
{label: '名称', prop:'name'},
{label: '仅显示有数据项', prop:'showAll',type:'select',dataList:initShowtypes},
{label: '显示辅助详情组合', prop:'showAccountingDetail',type:'select',dataList:initShowtypes2}
]
//顶部操作
// const topActions:ColumnAction[] = [
//   {label: '数据切换', type:'primary',render:<Checkbox checked={showDatarow} onChange={(e)=>{
//     setShowDatarow(e.target.checked)
//     if (e.target.checked) setRowList(()=>dataRowlist)
//     else setRowList(()=>initRowlist)
//   }}>仅显示有数据项</Checkbox>}
// ]
//列项 render: (value:any, record:any) =>record.isAffix?value:<Typography.Link onClick={()=>navigate('/indexpage/detailbook', {replace: true, state: {subjectId:record.id,companyId:searchParams.companyId,startYmd:searchParams.startYmd,endYmd:searchParams.endYmd}})}>{value+(record.hasAffix?' 辅助核算':'')}</Typography.Link>
const columns:ColumnItem[] = [
{title: '科目编码',key: 'subjectNo',maxWidth:150,render:(value:any,row:any)=>{
  let tempstr = value
  if (row.level===2) tempstr = '\xA0\xA0\xA0\xA0' + value
  else if (row.level===3) tempstr = '\xA0\xA0\xA0\xA0\xA0\xA0' + value
  return <>
    {row.accounting?<span style={{color:'red'}}> 辅</span>:null}
    <span>{tempstr}</span>
  </>
},custval:({row,value}:any)=>{
  let tempstr = value
  if (row.level===2) tempstr = '\xA0\xA0' + value
  else if (row.level===3) tempstr = '\xA0\xA0\xA0\xA0' + value
  return tempstr
}},
{title: '科目名称',key: 'subjectName',type:'underline',maxWidth:500,unlineProps:{action:({row,vlaue}:any)=>navigate('/indexpage/detailbook', {replace: true, state: {subjectId:(row.accounting?row.parentId:row.subjectId),subjectAccountingDetailId:row.subjectAccountingDetailId,companyId:searchParams.companyId,ymarr:[searchParams.beginYm,searchParams.endYm]}})},custval:({row,value}:any)=>{
  let tempstr = value
  if (row.level===2) tempstr = '\xA0\xA0\xA0\xA0' + value
  else if (row.level===3) tempstr = '\xA0\xA0\xA0\xA0\xA0\xA0' + value
  return tempstr
}},
{title: '期初余额',key:'init',children: [
  {title: '科目借方',key: 'subjectBeginPriceBorrow',type:'price',align:'center'},
  {title: '科目贷方',key: 'subjectBeginPriceLend',type:'price',align:'center'},
  // {title: '辅助借方',key: 'accountingBeginPriceBorrow',type:'price',align:'center'},
  // {title: '辅助贷方',key: 'accountingBeginPriceLend',type:'price',align:'center'},
]},
{title: '本期发生额',key:'cur',children: [
  {title: '科目借方',key: 'subjectChangeBorrowPrice',type:'price',align:'center'},
  {title: '科目贷方',key: 'subjectChangeLendPrice',type:'price',align:'center'},
  // {title: '辅助借方',key: 'accountingChangeBorrowPrice',type:'price',align:'center'},
  // {title: '辅助贷方',key: 'accountingChangeLendPrice',type:'price',align:'center'},
]},
{title: '本年累计发生额',key:'year',children: [
  {title: '科目借方',key: 'subjectYearChangeBorrowPrice',type:'price',align:'center'},
  {title: '科目贷方',key: 'subjectYearChangeLendPrice',type:'price',align:'center'},
  // {title: '辅助借方',key: 'accountingYearChangeBorrowPrice',type:'price',align:'center'},
  // {title: '辅助贷方',key: 'accountingYearChangeBorrowPrice',type:'price',align:'center'}
]},
{title: '期末余额',key:'end',children: [
  {title: '科目借方',key: 'subjectEndPriceBorrow',type:'price',align:'center'},
  {title: '科目贷方',key: 'subjectEndPriceLend',type:'price',align:'center'},
  // {title: '辅助借方',key: 'accountingEndPriceBorrow',type:'price',align:'center'},
  // {title: '辅助贷方',key: 'accountingEndPriceLend',type:'price',align:'center'}
]},
]
//底部操作
const bottomActions:ColumnAction[] = [
{label: 'bottomslot', render:<>
  <span style={{color: '#1890ff'}}>期初借: </span>&nbsp;<span>{statInfo.subjectBeginPriceBorrow}</span>&nbsp;&nbsp;
  <span style={{color: '#1890ff'}}>期初贷: </span>&nbsp;<span>{statInfo.subjectBeginPriceLend}</span>&nbsp;&nbsp;
  <span style={{color: '#1890ff'}}>本期借: </span>&nbsp;<span>{statInfo.subjectChangeBorrowPrice}</span>&nbsp;&nbsp;
  <span style={{color: '#1890ff'}}>本期贷: </span>&nbsp;<span>{statInfo.subjectChangeLendPrice}</span>&nbsp;&nbsp;
  <span style={{color: '#1890ff'}}>本年借: </span>&nbsp;<span>{statInfo.subjectEndPriceBorrow}</span>&nbsp;&nbsp;
  <span style={{color: '#1890ff'}}>本年贷: </span>&nbsp;<span>{statInfo.subjectEndPriceLend}</span>&nbsp;&nbsp;
  <span style={{color: '#1890ff'}}>期末借: </span>&nbsp;<span>{statInfo.subjectYearChangeBorrowPrice}</span>&nbsp;&nbsp;
  <span style={{color: '#1890ff'}}>期末贷: </span>&nbsp;<span>{statInfo.subjectYearChangeLendPrice}</span>
</>},
]
//subjectBeginPriceBorrow,subjectBeginPriceLend,subjectChangeBorrowPrice,subjectChangeLendPrice,subjectYearChangeBorrowPrice,subjectYearChangeLendPrice,subjectEndPriceBorrow,subjectEndPriceLend

  /* 接口参数 */
  const dataRow = ():AxDataform => ({
    params: searchParams,
    axApi: accountantSubjectTree,
    itemModify:{modifyFunc:({item}:any)=>{
      item.id = item.subjectId || item.subjectAccountingDetailId
      //期初
      item.subjectBeginPriceBorrow = item.borrowLend === 1?item.subjectBeginPrice:0
      item.subjectBeginPriceLend = item.borrowLend === 2?item.subjectBeginPrice:0
      //期末
      item.subjectEndPriceBorrow = item.borrowLend === 1?item.subjectEndPrice:0
      item.subjectEndPriceLend = item.borrowLend === 2?item.subjectEndPrice:0
    }},
    // modifyFunc: modifyRowlist,
    setTableLoading: setTableLoading,
    awaitFunc: (predata:any) => {
      let templist = predata
      if (searchParams.name) templist = predata.filter((item:any)=>item.subjectName.includes(searchParams.name))
      let tempinfo:any = {}
      templist.forEach((item:any)=>{
        if (item.level===1) initsumProps.forEach((key:string)=>{
          if (tempinfo[key]) tempinfo[key] += (item[key] || 0)
          else tempinfo[key] = (item[key] || 0)
        })
      })
      for (let key in tempinfo) {
        tempinfo[key] = (tempinfo[key]/100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
      setStatInfo(tempinfo)
      setRowList(templist) 
    }
  })
  const uniCompanyData:any = {
    axApi: getCompanyList,
    setList: setCompanyList,
  }
  const uniAsData = {
    modifyFunc: uniModifyAxList,
    modifyData: {titleList: ['title','subjectNo','name']},
    params: {page:1, size:999},
    axApi: axSubjectList,
    setList: setAccountantSubjectList
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location]);
  /* mounted */
  React.useEffect(() => {
    // uniAxList(dataRow())
    uniAxList(uniCompanyData)
    uniAxList(uniAsData)
    setLoadFlag(()=>true)
  }, [])

  return (
    <>
    <TopSearch
      ref={searchRef}
      topActions={topActions}
      searchList={searchList}
      initialValues={{showAll:false,showAccountingDetail:false}}
      searchConfirm={searchConfirm}
    />
    <SuperTable
      bordered={true}
      columns={columns}
      bottomActions={bottomActions}
      rowList={rowList}
      tableLoading={tableLoading}
      dataRow={dataRow}
    />
    </>
  )
}
