import React, { useState } from 'react'

export default function GuidTags(props:any) {
  /* constant */
  const {tags,checkedtagFunc} = props
  /* hooks */
  const [checkedTag,setCheckedTag] = useState('基础信息设置')

  return (<>
    <div style={{display:'flex',justifyContent:'center'}}>
      {
        tags.map((item:any,index:number)=>{
          if (index !== tags.length-1) return <>
            <div>
              <div key={item.title} style={{textAlign:'center',backgroundColor:checkedTag===item.title?'#3eaefa':'#deebf7',width:160,padding:10,height:160,borderRadius:80,boxShadow:'-2px 0 5px 1px #aaa',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} onClick={()=>{
                setCheckedTag(()=>item.title)
                checkedtagFunc(item.title)
              }}>
                {item.icon}
                <div style={{fontSize:16,color:checkedTag===item.title?'#fff':'#3eaefa',fontWeight:'bold'}}>{item.title}</div>
              </div>
            </div>
            <div style={{width:60,height:120,lineHeight:'120px',color:'#3eaefa',fontSize:40,textAlign:'center'}}>......</div>
          </>
          else return <div key={item.title} style={{textAlign:'center',backgroundColor:'#deebf7',width:160,padding:10,height:160,borderRadius:80,boxShadow:'-2px 0 5px 1px #aaa',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} onClick={()=>{
            setCheckedTag(()=>item.title)
            checkedtagFunc(item.title)
          }}>
            {item.icon}
            <div style={{fontSize:16,color:'#3eaefa',fontWeight:'bold'}}>{item.title}</div>
          </div>
        })
      }
    </div>
    <p></p>
    {tags[0].content}
  </>)
}
