import React, { createRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Button, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, message, Modal, Pagination, Popconfirm, Radio, Select, Table, TablePaginationConfig, Tooltip, TreeSelect, Typography, Upload, UploadFile, UploadProps } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import FormModal from '../../../components/FormModal'
import { getStaffList, getStaffCharList, getRoleList, createUser, getCompanyList, updateStaff, deleteStaff, searchDepList, getDepTree, getPositionList, getStaffDetailList, fileUpload, axStaffmax, axUserimport } from '../../../utils/api'
import { NameValue, PsItem, StaffItem, TableParams } from '../../../utils/beans'
import { uniSelectRows, uniModifyRow, uniDeleteRow, uniAxList, uniModifyAxList, uniModifyDpList, uniAxSearchList, UniDeleteAll, uniBatchQuit, uniQuitModal, staffAddRowSubmit, staffChgRowSubmit, uniFilterItem, uniDatePikcerChange, uniGetKeybykeyFromlist, uniGetMaxFromListkey, uniAddRowSubmit, uniPhoneValidator, uniIdNumValidator, uniExportExcel, uniTableChange, uniCurrentTime, uniChgRowSubmit, uniDeepArray, uniHandleUpload, uniValueToName, uniInitFormdata, uniOpAlarm, uniFormProcess } from '../../../utils/uniFucs'
import TextArea from 'antd/lib/input/TextArea'
import { getLocalItem, setLocalItem } from '../../../utils/localstorage'
import { csStaffActives, initStaffItem, postStatus } from '../../../utils/constant'
import SuperTable from '../../../components/SuperTable'
import UniOperationColumn from '../../../components/UniOperationColumn'
import formModal from '../../../css/formModal.module.less'
import TopSearch from '../../../components/TopSearch';
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../../utils/interfaces';
import FormDialog from '../../../components/FormDialog';

let fileinit:any
export default function StaffManagement() {
/* 不需要刷新页面的参数constructor */
const pathname = "/indexpage/staffmanagement"
const searchRef = createRef<FormInstance>()
const modalRef = createRef<FormInstance>()
const initSearchParams = {page:1}
/* hooks */
let location = useLocation()
const formModalRef = createRef<FormInstance>()
const [rowList, setRowList] = useState([])
const [tableLoading, setTableLoading] = useState(false)
const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
const [addModal, setAddModal] = useState(false)
const [chgModal, setChgModal] = useState(false)
const [dpList, setDpList] = useState([])
const [positionList, setPositionList] = useState([])
const [staffCharList, setStaffCharList] = useState([])
const [roleList, setRoleList] = useState([])
const [tempJobNum, setTempJobNum] = useState(Number(getLocalItem('jobNum', 1)))
const [companyList, setCompanyList] = useState([])
const [currentRow, setCurrentRow] = useState({} as any)
const [selectedRowKeys, setSelectedRowKeys] = useState([])
const [selectedRows, setSelectedRows] = useState([])
let [searchParams, setSearchParams] = useState({...initSearchParams} as any)
const [tempSearchParams, setTempSearchParams] = useState({page:1} as any)
const [backList, setBackList] = useState([])
const [customAddKeys, setCustomAddKeys] = useState({jobNum:null} as any)
const [loadFlag, setLoadFlag] = useState(false)
const [modalLoading, setModalLoading] = useState(false)
const [modalItemVisible, setModalItemVisible] = useState({confirmpwd:false,password:true} as any)
const [myfileList, setMyfileList] = useState<UploadFile[]>([])
const [exportLoading, setExportLoading] = useState(false)
let [modalFlag, setModalFlag] = useState('new')
let [formInitials, setFormInitials] = useState({})
const [showModal, setShowModal] = useState(false)
const [modalDplist,setModalDplist] = useState([])
const [selectDplist,setSelectDplist] = useState([])

/* elements */
//搜索行
const searchList:FormItemObj[] = [
{label: '工号/姓名', prop:'nameNo'},
{label: '公司', prop:'groupItemId',type:'select',dataList:companyList,change:(value:any)=>{
  if (value) {
    searchRef.current?.setFieldsValue({departmentId:null})
    let finditem:any = dpList.find((item:any)=>item.id===value)
    setSelectDplist(finditem ? finditem.children : [])
  } else setSelectDplist([])
}},
{label: '部门', prop:'departmentId',type:'tree',dataList:selectDplist},
{label: '人员性质', prop:'userCharacterId',type:'select',dataList:staffCharList},
{label: '在职状态', prop:'status',type:'select',dataList:csStaffActives},
{label: '角色', prop:'roleId',type:'select',dataList:roleList,selectProps:{propName:'roleName'}},
{label: '职位', prop:'positionId',type:'select',dataList:positionList}
]
//列首行
const columns:ColumnItem[] = [
{title: '工号',key: 'jobNum',},
{title: '姓名',key: 'name'},
{title: '所属公司',key: 'groupItemName'},
{title: '手机号',key: 'mobile'},
{title: '部门',key: 'departmentName'},
{title: '职位',key: 'positionName'},
{title: '人员性质',key: 'userCharacterName'},
{title: '角色权限',key: 'role',custval:({row,value}:any)=>value.map((item:any)=>item.roleName).join(',')},
{title: '在职状态',key: 'status',type:'tag',tagProps:{values:['0','1']},custval:({row,value}:any)=>uniValueToName({list:csStaffActives,value})},
{title: '身份证号',key: 'identityCard'},
{title: '地址',key: 'address'},
{title: '入职日期',key: 'joinTime',custval: ({value}:any) => value ? value.split(' ')[0] : ''},
// {title: '合同附件',key: 'contractFile', type:'download',downloadProps:{fileName:'合同附件'}},
{title: '创建时间',key: 'createDate'},
{title: '备注',key: 'remark'},
{title: '操作',key: 'operation',fixed: 'right',width: 100, type:'operation', opProps:{opList:[
  {name:'修改',action:({row}:any) => {
    setModalFlag('update')
    setCurrentRow(row)
    let roleIds = row.role.map((item:any)=>item.id)
    let jobNum = row.jobNum.slice(2)
    let finditem:any = dpList.find((item:any)=>item.id===row.groupItemId)
    setModalDplist(finditem ? finditem.children : [])
    setFormInitials(uniInitFormdata({...row,roleIds,jobNum},formItems))
    setMyfileList(row['contractFile']?.split(',').map((url:any,index:any)=>({exist:true,uid:index,name:'exist'+index+'.png',status:'done',url})))
    setShowModal(true)
  }},
  {name:'删除',action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataDelrow(row.id)))}
]}}
]
//底部操作
const bottomActions:ColumnAction[] = [
{label: '新增人员', type:'primary', action: () => {
  setModalFlag('new')
  setShowModal(() => true)
}},
{label: '模板下载',type:'link', action: "/用户导入模板.xlsx"},
{label: '选择文件',isfile:true,type:'primary', action: async (filedata:any,importloading:any) => {
  await uniAxList(dataUserimport(filedata))
  importloading(false)
}},
]
//添加和修改中的model项
const formItems:FormItemObj[] = [
{label: '工号',prop: 'jobNum',type:'slot',slotProps:<Input.Search type={'number'} prefix="YG"
  placeholder="输入工号"
  enterButton="自动生成"
  style={{width: '100%'}}
  size="small"
  onSearch={async (value) => {
    let res = await axStaffmax()
    let maxJobNum = uniGetMaxFromListkey({rowList:[{jobNum:res.data}], key: 'jobNum', omitHead: 'YG'})
    let newJobNum = (maxJobNum+1).toString().padStart(4, '0')
    modalRef.current?.setFieldsValue({jobNum: newJobNum})
  }}
  onBlur={(e) => {
    customAddKeys.jobNum = 'YG'+e.target.value.toString().padStart(4, '0')
    modalRef.current?.setFieldsValue({jobNum: e.target.value.toString().padStart(4, '0')})
  }}/>
},
{label: '姓名',prop: 'name',},
{label: '手机号',prop: 'mobile',rules: [{ required: true, message: '手机号不能为空' }, {validator: (rule:any, value:any)=>uniPhoneValidator(value)}]},
// {label: '密码',prop: 'password',type:'password',show: modalFlag==='new'},
// {label: '确认密码', prop: 'confirmpwd',type:'password',show: modalFlag==='new',dependencies:['password'],hasFeedback:true,rules: [
//   {required: true, message: '确认密码'}, ({ getFieldValue }:any) => ({
//     validator(_:any, value:any) {
//       if (!value || getFieldValue('password') === value) return Promise.resolve();
//       return Promise.reject(new Error('密码不一致'))
//     }
//   })
// ]},
{label: '入职时间',prop: 'joinTime',type:'datePicker',datepickerProps:{returnType: 'string'}},
{label: '所属公司',prop: 'groupItemId',type:'select',dataList:companyList,change:(value:any)=>{
  modalRef.current?.setFieldsValue({departmentId:null})
  let finditem:any = dpList.find((item:any)=>item.id===value)
  setModalDplist(finditem ? finditem.children : [])
}},
{label: '部门',prop: 'departmentId',type:'tree',dataList:modalDplist},
{label: '职位',prop: 'positionId',type:'select',dataList:positionList},
{label: '角色权限',prop: 'roleIds',type:'select',dataList:roleList,selectProps:{mode:'multiple',propName:'roleName'}},
{label: '人员性质',prop: 'userCharacterId',type:'select',dataList:staffCharList},
{label: '在职状态',prop: 'status',type:'select',dataList:csStaffActives},
{label: '身份证号',prop: 'identityCard',rules: [{ required: false }, { validator: (rule:any, value:any)=>uniIdNumValidator(value) }]},
{label: '地址',prop: 'address',required:false},
{label: '备注',prop: 'remark',required:false},
{label: '合同附件',prop: 'contractFile',span:24,required:false,type:'upload',uploadProps:{max:2},change:(filearr:any,setIsinit:any)=>{
  setIsinit(false)
  fileinit = setIsinit
  setMyfileList(filearr.map((fileitem:any)=>fileitem.exist ? fileitem : fileitem.originFileObj))
}},
]

//导出列
const exportColumns:any = [
  {
    title: '工号',
    dataIndex: 'jobNum',
    key: 'jobNum'
  },
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '所属公司',
    dataIndex: 'companyList',
    key: 'companyList'
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    key: 'mobile'
  },
  {
    title: '部门',
    dataIndex: 'departmentName',
  },
  {
    title: '职位',
    dataIndex: 'positionName'
  },
  {
    title: '人员性质',
    dataIndex: 'userCharacterName',
  },
  {
    title: '所属角色权限',
    dataIndex: 'roleList',
  },
  {
    title: '在职状态',
    dataIndex: 'statusName',
  },
  {
    title: '身份证号',
    dataIndex: 'identityCard',
  },
  {
    title: '地址',
    dataIndex: 'address'
  },
  {
    title: '入职时间',
    dataIndex: 'joinTime',
    key: 'joinTime'
  },
  {
    title: '合同附件',
    dataIndex: 'contractFile'
  },
  {
    title: '创建时间',
    dataIndex: 'createDate'
  },
  {
    title: '备注',
    dataIndex: 'remark',
    ellipsis: {showTitle: false},
    render: (value:string) => (
      <Tooltip placement="topLeft" title={value}>
        {value}
      </Tooltip>
    ),
  }
]

//导出当前页，需要的数据修饰
const exportModify:any = {
  checkStatus:[
    {newKey:'statusName', checkKey: 'status', sourceList:postStatus, sourceCheckKey:'value', sourceValueKey:'name'}
  ]
}

/* funcs */
//路由刷新请求
const routerFlash = () => {
  uniAxList(dataRow())
  uniAxList(uniAxDpListData)
  uniAxList(uniAxPositionListData)
  uniAxList(uniAxRoleListData)
  uniAxList(uniAxCompanyListData)
  uniAxList(uniStaffCharData)
}
const searchConfirm = async (formValus:any) => {setPagination({...pagination,current:1});
  searchParams = {...initSearchParams,...formValus}
  uniAxList(dataRow(1))
}
//modal点击确认
const onModalconfirm = async (formValues:any) => {
  let tempforms:any = await uniFormProcess(formValues,formItems,myfileList)
  tempforms.jobNum = 'YG'+formValues.jobNum
  if (formValues.companyIds && formValues.companyIds.length > 0) {
    let tempcompanys:any = []
    formValues.companyIds.forEach((key:any)=>{
      let finditem:any = companyList.find((item:any)=>item.id===key)
      if (finditem) tempcompanys.push({id:finditem.id, companyName:finditem.companyName, name:finditem.companyName})
    })
    tempforms.company = tempcompanys
  }
  if (formValues.roleIds && formValues.roleIds.length > 0) {
    let temproles:any = []
    formValues.roleIds.forEach((key:any)=>{
      let finditem:any = roleList.find((item:any)=>item.id===key)
      if (finditem) temproles.push({id:finditem.id, roleName:finditem.roleName})
    })
    tempforms.role = temproles
  }
  console.log('tempforms------------',tempforms)
  if (modalFlag==='new') uniAxList(dataNewrow(tempforms))
  else if (modalFlag==='update') uniAxList(dataUpdaterow(tempforms))
}

/* 接口参数 */
const dataRow = (current?:number):AxDataform => ({
  params: {...searchParams, current: current || pagination.current, size:pagination.pageSize},
  axApi: getStaffDetailList,
  setPagination,
  setList: setRowList,
  setTableLoading
})
//新建人员
const dataNewrow = (params:any):AxDataform => ({
  params,
  axApi: createUser,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//人员批量导入
const dataUserimport = (params:any):AxDataform => ({
  params,
  axApi: axUserimport,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//修改人员
const dataUpdaterow = (params:any):AxDataform => ({
  params: {...params, id:currentRow.id},
  axApi: updateStaff,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//删除人员
const dataDelrow = (userid:any):AxDataform => ({
  params: {id:userid},
  axApi: deleteStaff,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//搜索中公司和部门
const uniAxDpListData:any = {
  axApi: getDepTree,
  modifyFunc: uniModifyDpList,
  setList: setDpList
}
const uniAxPositionListData:any = {
  axApi: getPositionList,
  setList: setPositionList
}
const uniAxRoleListData:any = {
  axApi: getRoleList,
  setList: setRoleList
}
const uniAxCompanyListData:any = {
  axApi: getCompanyList,
  setList: setCompanyList
}
const uniStaffCharData:any = {
  axApi: getStaffCharList,
  setList: setStaffCharList
}
/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])
/* mounted */
React.useEffect(() => {
  uniAxList(dataRow())
  uniAxList(uniAxDpListData)
  uniAxList(uniAxPositionListData)
  uniAxList(uniAxRoleListData)
  uniAxList(uniAxCompanyListData)
  uniAxList(uniStaffCharData)
  setLoadFlag(()=>true)
}, [])


return (
  <>
    <TopSearch
      ref={searchRef}
      searchList={searchList}
      searchConfirm={searchConfirm}
    />
    <SuperTable
      columns={columns}
      rowList={rowList}
      bottomActions={bottomActions}
      tableLoading={tableLoading}
      pagination={pagination}
      dataRow={dataRow}
    />
    <FormDialog
      ref={modalRef}
      title={modalFlag==='new'?'新增人员':'修改人员'}
      show={showModal}
      formItems={formItems}
      modalLoading={modalLoading}
      formInitials={formInitials}
      cancel={()=>{
        setShowModal(()=>false)
        setCurrentRow({})
        setFormInitials({})
        if (fileinit) fileinit(true)
      }}
      confirm={(formValues:any)=>onModalconfirm(formValues)}
    />
  </>
)
}
