import React, { forwardRef } from 'react'
import { Modal, Form, Button } from 'antd';

import formModal from '../css/formModal.module.less'

function FormModal(props: any, ref: any) {
  const {title, modalVisible, initialValues, quitModal, modalConfirm, modelItems, modalItemVisible, modalLoading } = props

  return (
    <Modal
      width={600}
      maskClosable={false}
      className={formModal.container}
      title={title}
      visible={modalVisible}
      onOk={() => ref.current?.submit()}
      onCancel={()=>quitModal(false)}
      destroyOnClose
      footer={[<Button key="back" onClick={()=>quitModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => ref.current?.submit()}>确定</Button>]}
    >
      <Form preserve={false} ref={ref} name="form" labelCol={{ span: 7 }} initialValues={initialValues} wrapperCol={{ span: 16 }} onFinish={(formValus) => modalConfirm(formValus)} autoComplete="off">
        {modelItems.map((item:any) => (modalItemVisible && item.name in modalItemVisible && !modalItemVisible[item.name]) ? null : <Form.Item key={item.name} label={item.label} name={item.name} rules={item.rules}>{item.element}</Form.Item>)}
      </Form>
    </Modal>
  )
}
export default forwardRef(FormModal)
